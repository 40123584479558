import { Direction } from '../../../constants/locale';
import { gsap, Linear } from '../../../entities/Gsap/GsapService';

const cloneFlavours = (flavoursElement: HTMLElement): void => {
    const flavours = Array.from(flavoursElement.childNodes);

    for (let i = 0; i < flavours.length * 2; i += 1) {
        const clone = flavours[i % (flavours.length)].cloneNode(true) as HTMLElement;
        clone.setAttribute('data-clone', 'true');

        flavoursElement.append(clone);
    }
};

const getFlavoursElementInlineEnd = (flavoursElement: HTMLElement, direction: Direction): number => {
    const { right, left } = flavoursElement.getBoundingClientRect();

    return direction === Direction.rtl ? left : right;
};

const getBlockEndOffset = (
    flavoursBlockEndElement: HTMLElement,
    flavoursElementInlineEnd: number,
    flavourWidth: number,
    direction: Direction,
): number => {
    const { left, right } = flavoursBlockEndElement.getBoundingClientRect();

    if (direction === Direction.rtl) {
        return flavoursElementInlineEnd - right + flavourWidth;
    }

    return left - flavoursElementInlineEnd + flavourWidth;
};

const getFlavoursTimeline = (
    flavoursId: string,
    flavoursBlockEndId: string,
    direction: Direction,
): { timeline: gsap.core.Timeline, startTime: number } => {
    const flavoursElement = document.getElementById(flavoursId);
    const flavoursBlockEndElement = document.getElementById(flavoursBlockEndId);
    const mainTimeline = gsap.timeline({ paused: true });
    const cycleTimeline = gsap.timeline({ paused: true });

    if (!flavoursElement) {
        throw new Error('Flavours element not found');
    }

    if (!flavoursBlockEndElement) {
        throw new Error('Flavours block end element not found');
    }

    if (flavoursElement.childNodes.length < 2) {
        gsap.set(flavoursElement, { scale: 1.5 });

        return { timeline: mainTimeline, startTime: 0 };
    }

    cloneFlavours(flavoursElement);

    const { height: flavoursHeight } = flavoursElement.getBoundingClientRect();
    const flavourWidth = flavoursHeight;
    const flavoursElementInlineEnd = getFlavoursElementInlineEnd(flavoursElement, direction);
    const blockEndOffset = getBlockEndOffset(flavoursBlockEndElement, flavoursElementInlineEnd, flavourWidth, direction);

    const flavours = Array.from(flavoursElement.childNodes);
    const uniqueFlavoursLength = flavours.length / 3;
    const startTime = uniqueFlavoursLength * 9999;

    for (let i = 0; i < flavours.length; i += 1) {
        const timeline = gsap.timeline();
        const flavour = flavours[i] as HTMLElement;

        let flavourPosition = 0;

        for (let j = 0; j < flavours.length; j += 1) {
            const isVisible = Math.abs(j - i) < uniqueFlavoursLength;
            const hasFocus = i === (j + 1);
            const isPastFocus = i === j;
            const beforeFocus = i > (j + 1);
            const inlineStartDistance = direction === Direction.ltr ? 50 : -50;
            const increment = isPastFocus ? blockEndOffset : flavourWidth;
            const justifiedIncrement = direction === Direction.ltr ? increment : -increment;
            flavourPosition += justifiedIncrement;

            timeline.add(gsap.to(flavour, {
                duration: 1,
                x: flavourPosition - (beforeFocus ? inlineStartDistance : 0),
                scale: hasFocus ? 1.5 : 0.85,
                opacity: isVisible ? 1 : 0,
                filter: `brightness(${hasFocus ? 1 : 0.875}`,
                ease: Linear.easeNone,
            }), j);
        }

        cycleTimeline.add(timeline, 0);
    }

    gsap.set(cycleTimeline, { time: uniqueFlavoursLength });
    mainTimeline.to(cycleTimeline, { duration: uniqueFlavoursLength, time: uniqueFlavoursLength * 2, ease: Linear.easeNone, repeat: -1 });

    gsap.set(mainTimeline, { time: startTime });

    return { timeline: mainTimeline, startTime };
};

export default getFlavoursTimeline;
