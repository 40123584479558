import {
    FC,
    ImgHTMLAttributes,
    ReactElement,
    SourceHTMLAttributes,
} from 'react';

import './Picture.scss';

type SourceAttributes = 'type' | 'srcSet' | 'media' | 'sizes';
type PictureSource = Pick<SourceHTMLAttributes<HTMLSourceElement>, SourceAttributes>;

export interface PictureProps extends ImgHTMLAttributes<HTMLPictureElement> {
    sources?: PictureSource[];
    className?: string;
    imageClassName?: string;
}

const Picture: FC<PictureProps> = ({
    loading,
    sources = [],
    src,
    alt,
    className = '',
    imageClassName = '',
    ...pictureProps
}): ReactElement => (
    <picture
        {...pictureProps}
        className={`picture ${className}`}
    >
        {sources.map(source => (
            <source key={source.srcSet} {...source} />
        ))}

        <img
            loading={loading}
            src={src}
            alt={alt}
            className={`picture__image ${imageClassName}`}
        />
    </picture>
);

export default Picture;
