/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconBin: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={73.4} viewBoxHeight={100} className={className}>
        <path d="M7.2,0h59.1c2.3,0,4.3,1.7,4.9,4.3l2.1,8.9c0.5,1.9-0.8,3.7-2.5,3.7H2.6c-1.7,0-2.9-1.9-2.5-3.7l2.1-8.9C2.9,1.7,4.9,0,7.2,0z" />
        <path d="M71,26.9H2.4c-1.4,0-2.5,1.2-2.4,2.7l5.5,65.8c0.2,2.6,2.4,4.6,4.9,4.6h52.8c2.5,0,4.7-2,4.8-4.6l5.4-65.8C73.5,28.2,72.5,26.9,71,26.9z M20.7,88.7c-0.1,0-0.2,0-0.3,0c-1.9,0-3.6-1.5-3.7-3.4L13,42.2c-0.2-2.1,1.4-3.9,3.4-4.1c2.1-0.2,3.9,1.4,4.1,3.4l3.7,43.1C24.3,86.7,22.8,88.5,20.7,88.7z M40.5,85c0,2.1-1.7,3.8-3.8,3.8S33,87,33,85V41.9c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8V85z M56.8,85.3c-0.2,2-1.8,3.4-3.7,3.4c-0.1,0-0.2,0-0.3,0c-2.1-0.2-3.6-2-3.4-4.1L53,41.6c0.2-2.1,2-3.6,4.1-3.4c2.1,0.2,3.6,2,3.4,4.1L56.8,85.3z" />
    </Svg>
);

export default IconBin;
/* eslint-enable max-len */
