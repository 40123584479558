import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import CookieProvider from './context/CookieContext';
import CountryProvider from './context/CountryContext';
import LocaleProvider from './context/LocaleContext';
import ParamProvider from './context/ParamContext';
import ScrollProvider from './context/ScrollContext';
import { isProduction } from './helpers';
import { registerServiceWorker } from './helpers/pwa';
import { store } from './redux/store';

import './styles/global.scss';

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || '6Lcr8TYqAAAAACqtyJZLS46Vuf803cI1V8cpVchH'}
            scriptProps={{ defer: true }}
        >
            <BrowserRouter>
                <ParamProvider>
                    <CookieProvider>
                        <LocaleProvider>
                            <CountryProvider>
                                <HelmetProvider context={helmetContext}>
                                    <ScrollProvider>
                                        <App />
                                    </ScrollProvider>
                                </HelmetProvider>
                            </CountryProvider>
                        </LocaleProvider>
                    </CookieProvider>
                </ParamProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    </StoreProvider>,
);

// Service worker registration
if (isProduction) {
    registerServiceWorker().catch(error => {
        console.log('ServiceWorker registration failed:', error);
    });
}
