/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './PlaygroundSectionBackground.scss';

interface PlaygroundSectionBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const PlaygroundSectionBackground: FC<PlaygroundSectionBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'playground-section-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                magnitude: 40,
                end: 14,
            }}
            preserveAspectRatio="xMidYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1280}
            viewBoxHeight={1160}
            className={`playground-section-background ${className}`}
        >
            <radialGradient id={gradientId} cx="25%" cy="15%" r="80%">
                <stop offset="0%" className="playground-section-background__gradient-start" />
                <stop offset="100%" className="playground-section-background__gradient-end" />
            </radialGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="m 1077.076 72.365 c 173.666 -99.109 312.619 32.494 296.257 352.32 c -16.363 319.826 -293.892 578.915 -589.025 621.348 c -295.132 42.434 -722.975 -143.068 -743.461 -505.993 c -20.482 -362.925 336.088 -359.1 472.744 -359.247 c 0 0 389.82 -9.319 563.485 -108.428 z"
                className="playground-section-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default PlaygroundSectionBackground;
/* eslint-enable max-len */
