import { RichText } from 'prismic-dom';

import { Language } from '../../../constants/locale';
import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { transformToCallToAction } from '../../Link/LinkTransformers';
import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../Prismic/Prismic';
import { transformToImage } from '../Image/ImageTransformers';
import { CleanHubSection, PrismicCleanHubSection } from './CleanHubSection';

export const transformToCleanHubSection = (
    prismicCleanHubSection: PrismicCleanHubSection,
    language: Language,
): CleanHubSection => {
    const titleSizeKey = getEnumKeyByEnumValue(PrismicSliceTitleSize, prismicCleanHubSection.cleanhub_section_title_size);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicCleanHubSection.cleanhub_section_title_colour);

    const paragraphHtml = prismicCleanHubSection.cleanhub_section_paragraph
        ? RichText.asText(prismicCleanHubSection.cleanhub_section_paragraph)
        : '';

    const callToAction = prismicCleanHubSection.cleanhub_section_call_to_action_link
        ? transformToCallToAction(
            prismicCleanHubSection.cleanhub_section_call_to_action_link,
            prismicCleanHubSection.cleanhub_section_call_to_action_label,
            language,
        )
        : undefined;

    return {
        isMirrored: prismicCleanHubSection.cleanhub_section_is_mirrored,
        isMirroredMobile: prismicCleanHubSection.cleanhub_section_is_mirrored_mobile,
        image: transformToImage(prismicCleanHubSection.cleanhub_section_image),
        title: prismicCleanHubSection.cleanhub_section_title || '',
        titleSize: titleSizeKey
            ? PrismicSliceTitleSize[titleSizeKey]
            : undefined,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        paragraphHtml,
        callToAction,
    };
};
