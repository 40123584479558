import { FC, ReactElement, useState } from 'react';

import { Wrapper } from '../../components';
import { FlavoursCarousel, SelectInput } from '../../compositions';
import { ConnectedIngredients } from '../../connectors';
import { Flavour, flavourSets } from '../../constants/flavours';
import { useCountry } from '../../context/CountryContext';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import useTrans from '../../hooks/useTrans';
import { FormOption } from '../../types';
import { FlavoursAndIngredientsHeader } from './subcomponents';

import './FlavoursAndIngredients.scss';

interface FlavoursAndIngredientsProps {
    className?: string;
}

const FlavoursAndIngredients: FC<FlavoursAndIngredientsProps> = ({ className = '' }): ReactElement => {
    const trans = useTrans();
    const { continent } = useCountry();

    const [selectedFlavour, setSelectedFlavour] = useState<Flavour>(Flavour.berryBoost);

    const flavourOptions: FormOption[] = flavourSets[continent].map(flavour => ({
        label: trans(`flavours.${flavour}.name`),
        value: flavour,
    }));

    const handleChangeFlavour = (selected: FormOption): void => {
        const flavourKey = getEnumKeyByEnumValue(Flavour, selected.value);

        if (flavourKey) {
            setSelectedFlavour(Flavour[flavourKey]);
        }
    };

    return (
        <div className={`flavours-and-ingredients ${className}`}>
            <FlavoursAndIngredientsHeader className="flavours-and-ingredients__header" />

            <Wrapper className="flavours-and-ingredients__intro-wrapper">
                <p className="flavours-and-ingredients__intro">
                    {trans('containers.flavoursAndIngredients.description')}
                </p>
            </Wrapper>

            <FlavoursCarousel
                continent={continent}
                className="flavours-and-ingredients__carousel"
            />

            <Wrapper className="flavours-and-ingredients__nutrition-facts-wrapper">
                <h2 className="flavours-and-ingredients__nutrition-title">
                    {trans('containers.flavoursAndIngredients.nutritionFacts.title')}
                </h2>

                <h3 className="flavours-and-ingredients__nutrition-subtitle">
                    {trans('containers.flavoursAndIngredients.nutritionFacts.subtitle')}
                </h3>

                <p className="flavours-and-ingredients__nutrition-description">
                    {trans('containers.flavoursAndIngredients.nutritionFacts.description')}
                </p>
            </Wrapper>

            <Wrapper className="flavours-and-ingredients__ingredients-wrapper">
                <SelectInput
                    label={trans('containers.flavoursAndIngredients.ingredientsSelector.label')}
                    name="flavour"
                    value={flavourOptions.find(option => option.value === selectedFlavour)}
                    options={flavourOptions}
                    onChange={handleChangeFlavour}
                    className="flavours-and-ingredients__ingredients-selector"
                />

                <ConnectedIngredients
                    flavour={selectedFlavour}
                    className="flavours-and-ingredients__ingredients"
                />
            </Wrapper>
        </div>
    );
};

export default FlavoursAndIngredients;
