import { useEffect, useState } from 'react';

const useCleanHubWidget = (clientId: string): void => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    useEffect((): () => void => {
        const widgetScriptTag = document.createElement('script');
        const widgetScript = document.getElementsByTagName('script')[0];

        widgetScriptTag.addEventListener('load', (): void => {
            setHasLoaded(true);
        });

        widgetScriptTag.async = true;
        widgetScriptTag.src = 'https://cdn.cleanhub.io/embed/CleanhubWidget.js';

        if (widgetScript.parentNode) {
            widgetScript.parentNode.insertBefore(widgetScriptTag, widgetScript);
        }

        return (): void => {
            window.CleanhubWidget.unmount();
        };
    }, []);

    useEffect((): void => {
        if (hasLoaded) {
            window.CleanhubWidget.mount({ clientId });
        }
    }, [hasLoaded]);
};

export default useCleanHubWidget;
