/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={80} className={className}>
        <polygon points="86.7,0 33.3,53.3 13.3,33.3 0,46.7 33.3,80 100,13.3" />
    </Svg>
);

export default IconCheck;
/* eslint-enable max-len */
