/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './NextFlavourBackground.scss';

interface NextFlavourBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const NextFlavourBackground: FC<NextFlavourBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'next-flavour-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                angle: 30,
                magnitude: 15,
            }}
            preserveAspectRatio="xMinYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1000}
            viewBoxHeight={500}
            className={`next-flavour-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="next-flavour-background__gradient-start" />
                <stop offset="50%" className="next-flavour-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M779,37.7C905.1,7,991.6,83.9,958.4,236.2c-33.2,152.3-241.7,249.7-447.4,241.2C305.2,468.9,24,337.7,34.8,161C45.6-15.6,290.4,21.2,384.3,34.6C384.3,34.6,652.8,68.3,779,37.7z"
                className="next-flavour-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default NextFlavourBackground;
/* eslint-enable max-len */
