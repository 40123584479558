import { PostmarkControllerResponse } from '../../_httpserver/controllers/postmarkController';
import { isSSR } from '../../helpers';
import trans from '../../helpers/trans';
import { ContactFormData } from '../Contact/Contact';
import { FetchResult, FetchResultType } from '../FetchResult';
import { PostmarkResponse } from './Postmark';

const postmarkApiUrl = isSSR
    ? `${process.env.PUBLIC_URL}/postmark`
    : `${window.location.origin}/postmark`;

export const sendContactFormEmail = async (formData: ContactFormData): Promise<FetchResult<PostmarkResponse>> => {
    try {
        const response = await fetch(`${postmarkApiUrl}/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const data: PostmarkControllerResponse = await response.json();

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: {
                    title: trans('entities.postmark.toast.error.title'),
                    description: data.message,
                },
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: {
                title: trans('entities.postmark.toast.success.title'),
                description: trans('entities.postmark.toast.success.description'),
            },
        };
    } catch (error) {
        console.error('Error sending contact form email:', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: {
                title: trans('entities.postmark.toast.error.title'),
                description: trans('entities.postmark.toast.error.description'),
            },
        };
    }
};
