import { FC, ReactElement } from 'react';

import { LinkButton, Wrapper } from '../../../../components';
import { Vacancy } from '../../../../entities/Vacancy/Vacancy';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './VacancyFooter.scss';

interface VacancyFooterProps {
    vacancy: Vacancy;
    className?: string;
}

const VacancyFooter: FC<VacancyFooterProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    return (
        <footer className={`vacancy-footer ${className}`}>
            <Wrapper className="vacancy-footer__wrapper">
                <div className="vacancy-footer__date-wrapper">
                    <p className="vacancy-footer__date-label">
                        {trans('containers.vacancyContent.createdDate', {
                            date: formatDate(vacancy.createdAt, { dateStyle: 'long' }),
                        })}
                    </p>

                    <p className="vacancy-footer__date-label">
                        {trans('containers.vacancyContent.expirationDate', {
                            date: formatDate(vacancy.expirationDate, { dateStyle: 'long' }),
                        })}
                    </p>
                </div>

                {vacancy.applyCallToAction && (
                    <LinkButton
                        to={vacancy.applyCallToAction.url}
                        text={vacancy.applyCallToAction.label}
                        className="vacancy-footer__apply-button"
                    />
                )}
            </Wrapper>
        </footer>
    );
};

export default VacancyFooter;
