import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { thunk } from 'redux-thunk';

import { isProduction, isSSR } from '../helpers';
import reducers, { Reducers } from './reducers';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, UnknownAction>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;

export const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    preloadedState: !isSSR
        ? window.__PRELOADED_STATE__
        : undefined,
    devTools: !isProduction,
});

// Allow the passed state from SSR to be garbage-collected
if (!isSSR) {
    delete window.__PRELOADED_STATE__;
}
