import { FC, ReactElement } from 'react';

import { SafeHtml } from '../../components';
import { Switch } from '../../compositions';
import { Continent } from '../../constants/countries';
import { Flavour } from '../../constants/flavours';
import { useCountry } from '../../context/CountryContext';
import { NutritionTableRow } from '../../entities/NutritionTableRow/NutritionTableRow';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import useTrans from '../../hooks/useTrans';
import { FormOption } from '../../types';
import { IngredientRegion, NutritionType } from '../../types/ingredient';
import { NutritionTable } from './subcomponents';

import './Ingredients.scss';

interface IngredientsProps {
    showNutritionTypeControls?: boolean;
    isLoading: boolean;
    flavour: Flavour;
    ingredientRegion: IngredientRegion;
    nutritionType: NutritionType;
    data: NutritionTableRow[];
    onChangeIngredientRegion: (region: IngredientRegion) => void;
    onChangeNutritionType: (type: NutritionType) => void;
    className?: string;
}

const Ingredients: FC<IngredientsProps> = ({
    showNutritionTypeControls,
    isLoading,
    flavour,
    ingredientRegion,
    nutritionType,
    data,
    onChangeIngredientRegion,
    onChangeNutritionType,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { continent } = useCountry();

    const isWithCaffeine = nutritionType === NutritionType.withCaffeine;
    const showControls = continent === Continent.northAmerica || showNutritionTypeControls;

    const ingredientRegionOptions: [FormOption, FormOption] = [
        { value: IngredientRegion.usa, label: trans('containers.ingredients.iAmBasedIn.usa') },
        { value: IngredientRegion.canada, label: trans('containers.ingredients.iAmBasedIn.canada') },
    ];

    const nutritionTypeOptions: [FormOption, FormOption] = [
        { value: NutritionType.flavouredWater, label: trans('containers.ingredients.iWantToSee.flavouredWater') },
        { value: NutritionType.withCaffeine, label: trans('containers.ingredients.iWantToSee.withCaffeine') },
    ];

    const handleChangeIngredientRegion = (value: string): void => {
        const key = getEnumKeyByEnumValue(IngredientRegion, value);

        if (key) {
            onChangeIngredientRegion(IngredientRegion[key]);
        }
    };

    const handleChangeNutritionType = (value: string): void => {
        const key = getEnumKeyByEnumValue(NutritionType, value);

        if (key) {
            onChangeNutritionType(NutritionType[key]);
        }
    };

    return (
        <div className={`ingredients ${className}`}>
            {continent === Continent.northAmerica && isWithCaffeine && (
                <SafeHtml
                    html={trans(`containers.ingredients.caffeineWarning.${ingredientRegion}`)}
                    className="ingredients__caffeine-warning"
                />
            )}

            {showControls && (
                <div className="ingredients__control-wrapper">
                    {continent === Continent.northAmerica && (
                        <Switch
                            label={trans('containers.ingredients.iAmBasedIn.label')}
                            name="location"
                            value={ingredientRegion}
                            options={ingredientRegionOptions}
                            onChange={handleChangeIngredientRegion}
                            className="ingredients__control-switch"
                            labelClassName="ingredients__control-switch-label"
                        />
                    )}

                    {showNutritionTypeControls && (
                        <Switch
                            label={trans('containers.ingredients.iWantToSee.label')}
                            name="type"
                            value={nutritionType}
                            options={nutritionTypeOptions}
                            onChange={handleChangeNutritionType}
                            className="ingredients__control-switch"
                            labelClassName="ingredients__control-switch-label"
                        />
                    )}
                </div>
            )}

            <div className="ingredients__table-wrapper">
                <NutritionTable
                    isLoading={isLoading}
                    data={data}
                    continent={continent}
                    className="ingredients__table"
                />

                <div className="ingredients__ingredients-card">
                    <h4 className="ingredients__ingredients-title">
                        {trans('containers.ingredients.title')}
                    </h4>

                    <SafeHtml
                        html={trans(`flavours.${flavour}.ingredients`)}
                        className="ingredients__ingredients"
                    />

                    <SafeHtml
                        html={trans(`containers.ingredients.ingredientsList.${ingredientRegion}.notice`)}
                        className="ingredients__notice"
                    />

                    <SafeHtml
                        html={trans(`containers.ingredients.ingredientsList.${ingredientRegion}.intensityNotice`)}
                        className="ingredients__intensity-notice"
                    />

                    {isWithCaffeine && (
                        <SafeHtml
                            html={trans(`containers.ingredients.ingredientsList.${ingredientRegion}.caffeineNotice`)}
                            className="ingredients__caffeine-notice"
                        />
                    )}
                </div>
            </div>

            {continent === Continent.northAmerica && (
                <p className="ingredients__table-footnote">
                    {trans(`containers.ingredients.footnote.${ingredientRegion}`)}
                </p>
            )}
        </div>
    );
};

export default Ingredients;
