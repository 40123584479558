import {
    CSSProperties,
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Button, Wrapper } from '../../components';
import { SubmitButton } from '../../compositions';
import { AppRoute, appRoutes } from '../../constants/routing';
import { useCountry } from '../../context/CountryContext';
import { useLocale } from '../../context/LocaleContext';
import { PersonalityTestDirection, PersonalityTestInput, PersonalityTestQuestion } from '../../entities/PersonalityTest/PersonalityTest';
import { scrollToTop } from '../../helpers/scroll';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTimeout from '../../hooks/useTimeout';
import useTrans from '../../hooks/useTrans';
import {
    generateAnswerList,
    generatePersonalityTestQuestions,
    getPersonalityTestResult,
    trackTestInput,
} from './helpers';
import {
    PersonalityTestBackground,
    PersonalityTestIntro,
    PersonalityTestProgress,
    PersonalityTestQuestionSlide,
} from './subcomponents';

import './PersonalityTest.scss';

interface PersonalityTestProps {
    allowedToStore?: boolean;
    allowedToTrack?: boolean;
    className?: string;
}

export interface PersonalityTestNavigateState {
    skipIntro: boolean;
}

const PersonalityTest: FC<PersonalityTestProps> = ({
    allowedToStore,
    allowedToTrack,
    className = '',
}): ReactElement => {
    const { continent } = useCountry();
    const { language } = useLocale();
    const navigate = useNavigate();
    const { state } = useLocation() as { state: PersonalityTestNavigateState | null };
    const trans = useTrans();

    const skipIntro = !!state?.skipIntro;

    const amountOfQuestions = 10;
    const transitionDuration = 1000;

    const [isStarting, setIsStarting] = useState<boolean>(skipIntro);
    const [hasStarted, setHasStarted] = useState<boolean>(false);
    const [isEnding, setIsEnding] = useState<boolean>(false);
    const [hasEnded, setHasEnded] = useState<boolean>(false);

    const [progress, setProgress] = useState<number>(0);
    const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);

    const [answers, setAnswers] = useState<PersonalityTestInput[]>([]);
    const [storedAnswers, storeAnswers] = useLocalStorage<PersonalityTestInput[]>('personality-test-answers');

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeQuestion, setActiveQuestion] = useState<PersonalityTestQuestion>();
    const [transitionDirection, setTransitionDirection] = useState<PersonalityTestDirection>();

    const [selectedInput, setSelectedInput] = useState<PersonalityTestInput>();

    const questions = useMemo((): PersonalityTestQuestion[] => (
        generatePersonalityTestQuestions(language, amountOfQuestions)
    ), [language]);

    useEffect((): void => {
        if (storedAnswers && storedAnswers.length > 0) {
            setAnswers(storedAnswers);
            setActiveIndex(storedAnswers.length);
        }
    }, []);

    useEffect((): void => {
        setActiveQuestion(questions[activeIndex]);

        setProgress(((activeIndex + 1) / amountOfQuestions) * 100);
        setIsLastQuestion(activeIndex === amountOfQuestions - 1);
    }, [questions, activeIndex]);

    useEffect((): void => {
        if (allowedToStore) {
            storeAnswers(answers);
        }
    }, [allowedToStore, answers]);

    useEffect((): void => {
        if (!hasEnded) return;

        const resultFlavour = getPersonalityTestResult(continent, answers);

        const resultFlavourSlug = trans(`flavours.${resultFlavour}.slug`);
        const resultFlavourPath = trans(appRoutes[AppRoute.personalityTestResult].path);
        const resultFlavourRoute = replaceUrlParamKeysWithValues(resultFlavourPath, {
            flavour: resultFlavourSlug,
        });

        if (allowedToStore) {
            storeAnswers([]);
        }

        navigate(resultFlavourRoute);
    }, [hasEnded]);

    useTimeout((): void => {
        if (!isStarting) return;

        setHasStarted(true);
    }, transitionDuration / 2, [isStarting]);

    useTimeout((): void => {
        if (!isEnding) return;

        setHasEnded(true);
    }, transitionDuration, [isEnding]);

    useTimeout((): void => {
        if (transitionDirection) {
            if (transitionDirection === PersonalityTestDirection.forwards) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(activeIndex - 1);
            }

            setSelectedInput(undefined);
        }
    }, transitionDuration / 2, [transitionDirection]);

    useTimeout((): void => {
        if (transitionDirection) {
            setTransitionDirection(undefined);
        }
    }, transitionDuration, [transitionDirection]);

    const handleInput = (input: PersonalityTestInput): void => {
        if (!answers) return;

        const updatedAnswers = generateAnswerList(answers, input);

        setAnswers(updatedAnswers);

        if (allowedToTrack) {
            trackTestInput(input);
        }
    };

    const handleStartTest = (): void => {
        setIsStarting(true);
        scrollToTop();
    };

    const handleRestartTest = (): void => {
        setAnswers([]);
        setActiveIndex(0);
        handleStartTest();
    };

    const handleNextClick = (): void => {
        if (selectedInput) {
            handleInput(selectedInput);
        }

        setTransitionDirection(PersonalityTestDirection.forwards);
        scrollToTop();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (answers && selectedInput) {
            handleInput(selectedInput);
            setIsEnding(true);
        }
    };

    const personalityTestClassNames = classNames('personality-test', {
        'personality-test--is-starting': isStarting,
        'personality-test--has-started': hasStarted,
        'personality-test--is-ending': isEnding,
        'personality-test--is-transitioning': transitionDirection,
        'personality-test--moving-forwards': transitionDirection === PersonalityTestDirection.forwards,
        'personality-test--moving-backwards': transitionDirection === PersonalityTestDirection.backwards,
    }, className);

    const cssVariables = {
        '--personality-test-transition-duration': `${transitionDuration}ms`,
        '--personality-test-half-transition-duration': `${transitionDuration / 2}ms`,
        '--personality-test-progress': `${progress}%`,
    } as CSSProperties;

    return (
        <section style={cssVariables} className={personalityTestClassNames}>
            <Wrapper className="personality-test__wrapper">
                <PersonalityTestIntro
                    answers={answers}
                    onStartClick={handleStartTest}
                    onRestartClick={handleRestartTest}
                    className="personality-test__intro-slide"
                />

                <form onSubmit={handleSubmit} className="personality-test__form">
                    {activeQuestion && (
                        <PersonalityTestQuestionSlide
                            {...activeQuestion}
                            selectedAnswerKey={selectedInput?.index === activeQuestion.index ? selectedInput.answer : undefined}
                            onSelectAnswer={setSelectedInput}
                            className="personality-test__question-slide"
                        />
                    )}

                    <footer className="personality-test__footer">
                        <PersonalityTestProgress className="personality-test__progress-indicator" />

                        {isLastQuestion ? (
                            <SubmitButton
                                text={trans('containers.personalityTest.submitButton')}
                                disabled={!selectedInput}
                                className="personality-test__next-button"
                            />
                        ) : (
                            <Button
                                text={trans('containers.personalityTest.nextButton')}
                                disabled={!selectedInput}
                                onClick={handleNextClick}
                                className="personality-test__next-button"
                            />
                        )}
                    </footer>
                </form>
            </Wrapper>

            <PersonalityTestBackground
                isAnimating={!hasStarted}
                isEnding={isEnding}
                className="personality-test__background"
            />
        </section>
    );
};

export default PersonalityTest;
