import { FC, ReactElement, useEffect } from 'react';

import { Faq } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { fetchFaqData } from '../../redux/faq/faqActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedFaqProps {
    className?: string;
}

const ConnectedFaq: FC<ConnectedFaqProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.faqReducer.isLoading);
    const faqData = useTypedSelector(state => state.faqReducer.faqData);

    useEffect((): void => {
        if (!faqData.title || language !== faqData.language) {
            dispatch(fetchFaqData(language));
        }
    }, [language]);

    return (
        <Faq
            {...faqData}
            isLoading={isLoading}
            className={className}
        />
    );
};

export default ConnectedFaq;
