/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconPlus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <rect y="43.7" width="100" height="12.7" />
        <rect x="43.7" width="12.7" height="100" />
    </Svg>
);

export default IconPlus;
/* eslint-enable max-len */
