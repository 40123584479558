/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyMarketing: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={72} viewBoxHeight={100} className={className}>
        <path d="M35.3,68.9c-10.3,0-18.7-8.4-18.7-18.7c0-10.3,8.4-18.7,18.7-18.7c1.1,0,2,0.9,2,2s-0.9,2-2,2c-8.1,0-14.7,6.6-14.7,14.7c0,8.1,6.6,14.7,14.7,14.7c8.1,0,14.7-6.6,14.7-14.7c0-1.1,0.9-2,2-2s2,0.9,2,2C54,60.5,45.6,68.9,35.3,68.9z" />
        <path d="M35.3,85.6C15.9,85.6,0,69.7,0,50.2s15.9-35.3,35.3-35.3c2.4,0,4.8,0.2,7.1,0.7c1.1,0.2,1.8,1.3,1.6,2.4s-1.3,1.8-2.4,1.6c-2-0.4-4.2-0.6-6.3-0.6C18.1,18.9,4,33,4,50.2c0,17.3,14.1,31.3,31.3,31.3c17.3,0,31.3-14.1,31.3-31.3c0-2.1-0.2-4.2-0.6-6.3c-0.2-1.1,0.5-2.1,1.6-2.4c1.1-0.2,2.1,0.5,2.4,1.6c0.5,2.3,0.7,4.7,0.7,7.1C70.7,69.7,54.8,85.6,35.3,85.6z" />
        <path d="M35.4,52.6c-0.6,0-1.3-0.2-1.8-0.7c-1-1-1-2.6,0-3.5l14.3-14.3c-3.7-9.7,3.9-16,7.2-18.7c1-0.9,2.4-1.1,3.7-0.7c1.3,0.4,2.3,1.4,2.8,2.7l1.5,5.3l4.9,1.2c1.4,0.4,2.5,1.5,2.9,2.8c0.4,1.3,0.1,2.7-0.8,3.8c-3.2,3.9-9,10.9-18.8,7.2L37.2,51.9C36.7,52.4,36.1,52.6,35.4,52.6z M52.9,32.9c5.6,2.3,9-0.4,12.5-4.5l-4.9-1.2c-0.9-0.2-1.6-0.9-1.8-1.8l-1.5-5.3C52.8,24,50.6,27.7,52.9,32.9z M66.7,28.7L66.7,28.7L66.7,28.7z" />
    </Svg>
);

export default IconVacancyMarketing;
/* eslint-enable max-len */
