import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { ArticleRendererSkeletons } from '..';

import './ArticleContentSkeletons.scss';

interface ArticleContentSkeletonsProps {
    className?: string;
}

const ArticleContentSkeletons: FC<ArticleContentSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <Wrapper className={`article-content-skeletons ${className}`}>
        <div className="article-content-skeletons__breadcrumbs">
            <Skeleton />
        </div>

        <div className="article-content-skeletons__title-wrapper">
            <Skeleton className="article-content-skeletons__title" />
            <Skeleton className="article-content-skeletons__title" />
            <Skeleton className="article-content-skeletons__title" />
        </div>

        <div className="article-content-skeletons__body">
            <ArticleRendererSkeletons className="article-content-skeletons__renderer" />
        </div>
    </Wrapper>
);

export default ArticleContentSkeletons;
