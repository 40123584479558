import { gsap, Power1 } from '../entities/Gsap/GsapService';

export interface WaveHelperProps {
    hasOutline?: boolean;
    isClampedLeft?: boolean;
    isClampedRight?: boolean;
    amplitude?: number;
    bones?: number;
    container: SVGSVGElement;
    sourceElement: SVGPathElement;
    sourceOutlineElement?: SVGPathElement;
    height?: number;
    heightOffset?: number;
    speed?: number;
    timeOffset?: number;
}

interface WaveHelperSettings extends Partial<WaveHelperProps> {
    isClampedLeft: boolean;
    isClampedRight: boolean;
    amplitude: number;
    bones: number;
    container: SVGSVGElement;
    element: SVGPathElement;
    outlineElement?: SVGPathElement;
    height: number;
    speed: number;
    timeOffset: number;
}

interface Point {
    x: number;
    y: number;
}

class WaveHelper {
    private settings;

    private width: number;

    private height: number;

    private totalTime = 0;

    private heightOffset = 0;

    private speedOffset = 0;

    private lastUpdate?: number;

    private tween?: gsap.core.Tween;

    private animationInstance?: number;

    constructor(props: WaveHelperProps) {
        const element = props.sourceElement.cloneNode(true) as SVGPathElement;
        const elementParent = props.sourceElement.parentNode as SVGMaskElement;

        const outlineElement = props.sourceOutlineElement
            ? props.sourceOutlineElement.cloneNode(true) as SVGPathElement
            : undefined;

        elementParent.append(element);

        if (outlineElement) {
            props.container.append(outlineElement);
        }

        const settings: WaveHelperSettings = {
            element,
            outlineElement,
            isClampedLeft: false,
            isClampedRight: false,
            amplitude: 100,
            bones: 3,
            height: 200,
            speed: 0.15,
            timeOffset: 0,
            ...props,
        };

        const { width, height } = settings.container.getBBox();

        this.width = width;
        this.height = height;
        this.heightOffset = settings.heightOffset || 0;
        this.settings = settings;
        this.totalTime = this.settings.timeOffset;

        this.init();
    }

    private init() {
        if (!this.animationInstance) {
            const d = this.drawPath(this.drawPoints(0));
            this.settings.element.setAttribute('d', d);

            if (this.settings.outlineElement) {
                this.settings.outlineElement.setAttribute('d', d);
            }
        }
    }

    private drawPoints(factor: number): Point[] {
        const points: Point[] = [];

        for (let i = 0; i <= this.settings.bones; i += 1) {
            const x = (i / this.settings.bones) * this.width;
            const speed = this.settings.speed + (this.settings.speed * this.speedOffset);
            const sinSeed = (factor + (i + (i % this.settings.bones))) * speed * 100;
            const sinHeight = Math.sin(sinSeed / 100) * this.settings.amplitude;
            const yPos = Math.sin(sinSeed / 100) * sinHeight + this.settings.height;
            const yPosOffset = (this.settings.height * this.heightOffset * 2);

            points.push({ x, y: yPos - yPosOffset });
        }

        return points;
    }

    private drawPath(points: Point[]): string {
        const top = this.height + 1;
        const startY = this.settings.isClampedLeft ? top : points[0].y;
        let SVGString = `M ${points[0].x} ${startY}`;

        const cp0: Point = {
            x: (points[1].x - points[0].x) / 2,
            y: points[1].y - points[0].y + points[0].y + (points[1].y - points[0].y),
        };

        SVGString += ` C ${cp0.x} ${cp0.y} ${cp0.x} ${cp0.y} ${points[1].x} ${points[1].y}`;

        let prevCp = cp0;
        let inverted = -1;

        for (let i = 1; i < points.length - 1; i += 1) {
            const lastY = (this.settings.isClampedRight && i === points.length - 2) ? top : points[i + 1].y;
            const cp1 = {
                x: points[i].x - prevCp.x + points[i].x,
                y: points[i].y - prevCp.y + points[i].y,
            };

            SVGString += ` C ${cp1.x} ${cp1.y} ${cp1.x} ${cp1.y} ${points[i + 1].x} ${lastY}`;
            prevCp = cp1;
            inverted = -inverted;
        }

        SVGString += ` L ${this.width} ${top}`;
        SVGString += ` L 0 ${top} Z`;

        return SVGString;
    }

    private draw() {
        const now = window.Date.now();

        if (!this.lastUpdate) {
            this.lastUpdate = now;
            this.animationInstance = requestAnimationFrame(this.draw.bind(this));

            return;
        }

        const elapsed = (now - this.lastUpdate) / 1000;
        this.totalTime += elapsed;
        this.lastUpdate = now;

        const factor = this.totalTime * Math.PI;
        const elements = [
            this.settings.element,
            ...(this.settings.outlineElement ? [this.settings.outlineElement] : []),
        ];

        this.tween = gsap.to(elements, this.settings.speed, {
            attr: {
                d: this.drawPath(this.drawPoints(factor)),
            },
            ease: Power1.easeInOut,
        });

        this.animationInstance = requestAnimationFrame(this.draw.bind(this));
    }

    play(): void {
        if (!this.animationInstance) {
            this.animationInstance = requestAnimationFrame(this.draw.bind(this));
        }
    }

    pause(): void {
        if (this.animationInstance) {
            cancelAnimationFrame(this.animationInstance);
            this.animationInstance = undefined;
        }
    }

    destroy(): void {
        if (this.tween) {
            this.tween.kill();
        }

        if (this.settings.outlineElement) {
            this.settings.outlineElement.remove();
        }

        this.pause();
        this.lastUpdate = undefined;
        this.totalTime = 0;
        this.settings.element.remove();
        this.animationInstance = undefined;
    }

    setHeightOffset(offset: number): void {
        this.heightOffset = offset;
    }

    setSpeedOffset(offset: number): void {
        this.speedOffset = offset;
    }
}

export default WaveHelper;
