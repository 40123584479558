import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import ReactSelect, { OnChangeValue } from 'react-select';

import useTrans from '../../../hooks/useTrans';
import { FormOption } from '../../../types';

import './Select.scss';

export interface SelectProps {
    isLoading?: boolean;
    isLoadingMessage?: string;
    name: string;
    value?: OnChangeValue<FormOption, false>;
    options: FormOption[];
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    error?: string;
    onChange: (selected: FormOption) => void;
    className?: string;
}

const Select: FC<SelectProps> = ({
    isLoading = false,
    isLoadingMessage,
    name,
    value,
    options,
    required,
    disabled,
    placeholder,
    error = '',
    onChange,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const selectClassNames = classNames('select', {
        'select--is-disabled': disabled,
        'select--has-error': !!error,
    }, className);

    const getLoadingMessage = (): string => isLoadingMessage || trans('components.select.loadingMessage');
    const getNoOptionsMessage = (): string => trans('components.select.nullState');

    const handleChange = (selectValue: OnChangeValue<FormOption, false>): void => {
        onChange(selectValue as FormOption);
    };

    return (
        <ReactSelect
            isLoading={isLoading}
            loadingMessage={getLoadingMessage}
            isSearchable
            name={name}
            value={value}
            options={options}
            required={required}
            isDisabled={disabled}
            placeholder={placeholder || trans('components.select.placeholder')}
            noOptionsMessage={getNoOptionsMessage}
            onChange={handleChange}
            classNamePrefix="select"
            className={selectClassNames}
        />
    );
};

export default Select;
