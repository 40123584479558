import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { defaultPrismicDefaultPageData, PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicPageApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToPrismicDefaultPageData } from '../../entities/Prismic/PrismicTransformers';
import { TypedDispatch } from '../store';
import { setAboutData, setIsLoading } from './about';

export const fetchAboutData = (language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setAboutData(defaultPrismicDefaultPageData));

    try {
        const aboutResponse = await getPrismicPageApiCall({
            type: PrismicType.about,
            language,
        });

        if (!isFetchResultSuccessful(aboutResponse)) {
            console.error('[fetchAboutData]', aboutResponse.error);
            return;
        }

        const aboutData = transformPrismicDocumentToPrismicDefaultPageData(aboutResponse.data, language);

        dispatch(setAboutData(aboutData));
    } catch (error) {
        console.error('[fetchAboutData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
