import { RefObject } from 'react';

export const scrollToTop = (behavior: ScrollBehavior = 'smooth'): void => {
    if (typeof window !== 'undefined') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior,
        });
    }
};

export const scrollToElementBottom = <T extends HTMLElement>(ref?: RefObject<T>, behavior: ScrollBehavior = 'auto'): void => {
    if (!ref?.current) return;

    ref.current.scrollTo({
        top: ref.current.scrollHeight,
        behavior,
    });
};

export const scrollIntoView = <T extends HTMLElement>(ref?: RefObject<T>): void => {
    if (!ref?.current) return;

    ref.current.scrollIntoView({
        behavior: 'smooth',
    });
};

export const elementHasScroll = <T extends HTMLElement>(ref?: RefObject<T>): boolean => {
    if (!ref?.current) return false;

    return ref.current.scrollWidth > ref.current.clientWidth;
};
