/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconFlavours: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={81} viewBoxHeight={81} className={className}>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.71529 45.9338C4.2231 45.1115 5.30141 44.8565 6.12375 45.3643L38.5457 65.3853C39.368 65.8932 39.623 66.9715 39.1152 67.7938C33.0787 77.5692 20.2607 80.6002 10.4852 74.5638C0.709833 68.5273 -2.32118 55.7092 3.71529 45.9338ZM5.84816 49.3077C2.00553 57.2 4.69607 66.8753 12.3242 71.5858C19.9523 76.2963 29.8079 74.3684 35.1434 67.3979L32.857 65.986L32.7541 66.1526C32.1544 67.1238 31.4127 67.8869 30.499 68.3853C29.9474 68.6862 29.3756 68.8675 28.7989 68.957C28.7666 68.977 28.7334 68.996 28.6993 69.0141C28.4703 69.1358 28.2265 69.2014 27.9835 69.2159C22.604 72.7443 17.1261 71.5422 14.6045 69.0502C11.2808 67.6743 9.31566 65.1158 8.31257 62.5645C7.43249 60.3259 7.23584 57.9453 7.59553 56.1854C7.27491 55.4424 7.09653 54.6662 7.12833 53.8419C7.16935 52.7785 7.55315 51.8211 8.10325 50.9303L8.20612 50.7637L5.84816 49.3077ZM28.8228 65.3128C28.7951 65.3279 28.7667 65.3423 28.7376 65.356L28.0446 64.0515C27.5912 63.1979 26.5317 62.8736 25.6782 63.327C24.8246 63.7804 24.5003 64.8399 24.9537 65.6934L25.4661 66.6581C22.329 68.4615 19.5606 68.0374 17.9919 67.2134L20.3585 63.4248C20.8705 62.6051 20.6211 61.5255 19.8014 61.0135C18.9817 60.5014 17.9021 60.7508 17.39 61.5706L15.0203 65.3642C13.278 64.368 12.1898 62.8607 11.5699 61.2838C11.1896 60.3166 11.004 59.3713 10.946 58.5585L12.6242 58.5585C13.5907 58.5585 14.3742 57.775 14.3742 56.8085C14.3742 55.842 13.5907 55.0585 12.6242 55.0585L10.9334 55.0585C10.673 54.5606 10.616 54.2283 10.6257 53.9768C10.6363 53.7019 10.7343 53.3311 11.0812 52.7693L11.1841 52.6027L29.879 64.1471L29.7762 64.3136C29.4251 64.8822 29.0959 65.1638 28.8228 65.3128Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.2144 67.1408C71.0894 67.5906 70.7898 67.9718 70.3823 68.1996L63.3325 72.1406C62.7916 72.4429 62.1313 72.4367 61.5962 72.1243C60.931 71.7359 59.7637 71.6826 58.6216 72.2515C57.5421 72.7891 56.7178 73.7757 56.5854 75.1502C56.5313 75.7115 56.2101 76.2123 55.7225 76.4956L48.4854 80.7C48.082 80.9343 47.6019 80.9979 47.1515 80.8767C46.7011 80.7555 46.3177 80.4595 46.0865 80.0545C42.1514 73.1615 44.5493 64.3837 51.4423 60.4486C58.3353 56.5136 67.1131 58.9114 71.0481 65.8044C71.2796 66.2099 71.3395 66.691 71.2144 67.1408ZM53.2514 73.8834C53.7681 71.6204 55.301 69.9953 57.0611 69.1186C58.6786 68.3129 60.662 68.0593 62.3909 68.6572L67.0084 66.0759C63.7153 62.0099 57.8692 60.8098 53.1775 63.4882C48.485 66.1671 46.5464 71.8129 48.375 76.7164L53.2514 73.8834Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.55661 29.069C6.87268 29.5905 7.43182 30.0342 8.55447 30.0365C9.76705 30.039 10.8779 29.9749 11.9613 29.9124C12.0408 29.9078 12.1201 29.9032 12.1993 29.8987C13.3335 29.8336 14.4777 29.7732 15.5756 29.8302C15.9633 29.8503 16.3468 29.8852 16.7265 29.939C14.928 27.1185 13.0081 25.2744 9.91357 24.1067C8.79545 23.6848 8.05243 23.9009 7.52943 24.2862C6.92911 24.7285 6.43469 25.5431 6.23676 26.5353C6.03839 27.5298 6.18629 28.4581 6.55661 29.069ZM5.45335 21.4684C6.98296 20.3414 8.9823 20.0144 11.1492 20.8321C17.0914 23.0744 19.6093 27.4397 22.457 33.0988C22.842 33.8639 22.6167 34.7952 21.9245 35.2997C21.2324 35.8041 20.2768 35.7335 19.6663 35.1328C18.3233 33.8112 17.0395 33.4108 15.3943 33.3255C14.5159 33.2799 13.5566 33.3266 12.3997 33.3929C12.3169 33.3977 12.2333 33.4025 12.1488 33.4074C11.0775 33.4693 9.86875 33.5392 8.54727 33.5365C6.26091 33.5318 4.54184 32.4974 3.56348 30.8832C2.63938 29.3585 2.47664 27.4936 2.80439 25.8506C3.13259 24.2054 4.00105 22.5384 5.45335 21.4684Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M26.0111 3.5266C25.0406 3.44799 23.9797 3.78487 23.0358 4.86876C19.9503 8.4118 20.184 12.4266 22.058 16.6131C22.8746 18.4374 23.9768 20.2242 25.1852 21.9136C25.2052 19.5989 25.9235 17.2547 27.7604 14.9739C31.2084 10.6926 30.4945 7.04943 28.9301 5.16185C28.1054 4.16677 27.0294 3.60908 26.0111 3.5266ZM31.6249 2.92846C34.412 6.29142 34.8112 11.7991 30.4863 17.1692C28.9934 19.023 28.5851 20.8014 28.7039 22.5792C28.8286 24.4451 29.5369 26.3696 30.4444 28.4637C30.7865 29.253 30.5042 30.1739 29.7784 30.6359C29.0527 31.0978 28.0989 30.9638 27.5286 30.3197C24.8259 27.2674 21.015 22.8497 18.8634 18.0431C16.6826 13.1712 16.0725 7.53519 20.3964 2.57018C22.0431 0.679329 24.175 -0.133582 26.2936 0.0380212C28.3644 0.205745 30.2738 1.29827 31.6249 2.92846Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.0894 42.3091C28.4225 41.6096 28.4489 40.5019 29.1485 39.835L69.3438 1.51606C70.0434 0.849163 71.1511 0.875638 71.818 1.57519C83.0663 13.3744 82.6198 32.0581 70.8206 43.3065C59.0215 54.5548 40.3377 54.1083 29.0894 42.3091ZM32.8721 41.1208C42.8751 50.3091 58.4312 50.282 68.4056 40.7732C78.38 31.2644 79.1503 15.7274 70.4503 5.29684L66.4068 9.15157L67.1559 9.93741C68.2291 11.0631 68.9485 12.2714 69.2737 13.5815C69.4846 14.4311 69.5163 15.2692 69.413 16.0875C69.4514 16.1825 69.4819 16.2822 69.5036 16.3859C69.5679 16.6925 69.5476 16.9972 69.4587 17.2757C72.5131 26.043 68.7497 33.0614 64.578 35.4322C61.5572 39.4966 57.3929 41.2681 53.5592 41.7245C50.079 42.1388 46.7015 41.4888 44.5241 40.3426C43.3642 40.5515 42.2447 40.5347 41.1516 40.203C39.8403 39.805 38.7453 39.0077 37.7535 37.9673L37.0044 37.1814L32.8721 41.1208ZM65.8768 14.4248C65.9493 14.7167 65.9803 15.0191 65.9669 15.3391L60.0367 16.5819C59.0908 16.7801 58.4846 17.7077 58.6829 18.6536C58.8811 19.5996 59.8087 20.2057 60.7546 20.0075L66.2947 18.8464C68.325 25.1413 66.0242 29.761 63.7534 31.737L57.7197 25.4227C57.052 24.7239 55.9442 24.6987 55.2454 25.3664C54.5467 26.0341 54.5215 27.1419 55.1892 27.8406L61.1659 34.0954C58.8899 36.7222 55.9563 37.9144 53.1454 38.2491C51.1904 38.4818 49.3731 38.291 47.9599 37.9195C48.0112 37.8151 48.0527 37.7041 48.0831 37.5871L49.9844 30.2744C50.2276 29.339 49.6665 28.3836 48.7311 28.1404C47.7957 27.8972 46.8402 28.4583 46.597 29.3937L44.6957 36.7064C44.6925 36.7189 44.6894 36.7313 44.6864 36.7438C44.5865 36.7513 44.4868 36.7675 44.3882 36.7924C43.4002 37.0427 42.7169 37.0204 42.168 36.8538C41.6117 36.685 41.0119 36.3128 40.2869 35.5523L39.5377 34.7664L63.8735 11.5666L64.6226 12.3524C65.3692 13.1356 65.7274 13.8229 65.8768 14.4248Z" />
    </Svg>
);

export default IconFlavours;
/* eslint-enable max-len */
