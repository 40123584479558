import { Language } from '../../constants/locale';
import { defaultFaqData } from '../../entities/Faq/Faq';
import { transformPrismicDocumentToFaqData } from '../../entities/Faq/FaqTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicPageApiCall } from '../../entities/Prismic/PrismicService';
import { TypedDispatch } from '../store';
import { setFaqData, setIsLoading } from './faq';

export const fetchFaqData = (language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setFaqData(defaultFaqData));

    try {
        const faqResponse = await getPrismicPageApiCall({
            type: PrismicType.faq,
            language,
        });

        if (!isFetchResultSuccessful(faqResponse)) {
            console.error('[fetchFaqData]', faqResponse.error);
            return;
        }

        const faqData = transformPrismicDocumentToFaqData(faqResponse.data, language);

        dispatch(setFaqData(faqData));
    } catch (error) {
        console.error('[fetchFaqData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
