import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { LocalizedHelmet } from '../../compositions';
import { FlavoursAndIngredients } from '../../containers';

const FlavoursPage: FC = (): ReactElement => (
    <Page>
        <LocalizedHelmet pageKey="flavours" />

        <FlavoursAndIngredients />
    </Page>
);

export default FlavoursPage;
