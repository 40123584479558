/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../../compositions';

import './UspSectionBackground.scss';

interface UspSectionBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const UspSectionBackground: FC<UspSectionBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'usp-section-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{ magnitude: 25 }}
            preserveAspectRatio="xMidYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1280}
            viewBoxHeight={1160}
            className={`usp-section-background ${className}`}
        >
            <radialGradient id={gradientId} cx="15%" cy="15%" r="50%">
                <stop offset="0%" className="usp-section-background__gradient-start" />
                <stop offset="100%" className="usp-section-background__gradient-end" />
            </radialGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="m 1358.11 569.86 c 15.25 269.7 -240.48 594.57 -745.21 516.5 c -455.82 -71.6 -578.11 -420.96 -584.69 -630.04 c -5.95 -227.57 63.11 -396.33 267.32 -397.55 c 312.39 1.22 404 215.93 752.4 160.45 c 221.91 -40.03 302.14 102.94 310.64 350.64 z"
                className="usp-section-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default UspSectionBackground;
/* eslint-enable max-len */
