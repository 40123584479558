import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoute } from '../../../../constants/routing';
import useTrans from '../../../../hooks/useTrans';
import { RouteData } from '../../../../LocalizedRoutes';

import './SeoNavigation.scss';

interface SeoNavigationProps {
    label: string;
    navigationItems: RouteData<AppRoute>[];
}

const SeoNavigation: FC<SeoNavigationProps> = ({
    label,
    navigationItems,
}): ReactElement => {
    const trans = useTrans();

    return (
        <nav aria-label={label} className="seo-navigation">
            <ul>
                {navigationItems.map(item => (
                    <li key={item.key}>
                        <Link to={trans(item.path)}>
                            {trans(item.label)}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default SeoNavigation;
