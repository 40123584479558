import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { defaultPrismicDefaultPageData, PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicPageApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToPrismicDefaultPageData } from '../../entities/Prismic/PrismicTransformers';
import { TypedDispatch } from '../store';
import { setIsLoading, setYourClubData } from './yourClub';

export const fetchYourClubData = (language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setYourClubData(defaultPrismicDefaultPageData));

    try {
        const yourClubResponse = await getPrismicPageApiCall({
            type: PrismicType.yourClub,
            language,
        });

        if (!isFetchResultSuccessful(yourClubResponse)) {
            console.error('[fetchYourClubData]', yourClubResponse.error);
            return;
        }

        const yourClubData = transformPrismicDocumentToPrismicDefaultPageData(yourClubResponse.data, language);

        dispatch(setYourClubData(yourClubData));
    } catch (error) {
        console.error('[fetchYourClubData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
