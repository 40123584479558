import { isSSR } from '../helpers';
import { FormOption } from '../types';

export enum Locale {
    arabic = 'عربي',
    german = 'Deutsch',
    englishGb = 'English (GB)',
    englishUs = 'English (US)',
    spanish = 'Español',
    french = 'Français',
    dutch = 'Nederlands',
}

export enum Direction {
    ltr = 'ltr',
    rtl = 'rtl',
}

export enum Language {
    enUs = 'en-us',
    enGb = 'en-gb',
    nl = 'nl',
    de = 'de',
    fr = 'fr',
    es = 'es',
    ar = 'ar',
}

export interface LocaleData {
    name: Locale;
    direction: Direction;
    language: Language;
}

export const locales: Record<Locale, LocaleData> = {
    [Locale.englishUs]: {
        name: Locale.englishUs,
        language: Language.enUs,
        direction: Direction.ltr,
    },
    [Locale.englishGb]: {
        name: Locale.englishGb,
        language: Language.enGb,
        direction: Direction.ltr,
    },
    [Locale.dutch]: {
        name: Locale.dutch,
        language: Language.nl,
        direction: Direction.ltr,
    },
    [Locale.german]: {
        name: Locale.german,
        language: Language.de,
        direction: Direction.ltr,
    },
    [Locale.french]: {
        name: Locale.french,
        language: Language.fr,
        direction: Direction.ltr,
    },
    [Locale.spanish]: {
        name: Locale.spanish,
        language: Language.es,
        direction: Direction.ltr,
    },
    [Locale.arabic]: {
        name: Locale.arabic,
        language: Language.ar,
        direction: Direction.rtl,
    },
};

export const defaultLocale = locales[Locale.englishUs];

export const languageOptions: FormOption[] = Object.values(locales).map(locale => ({
    label: locale.name,
    value: locale.language,
}));

export const getLocaleFromBrowser = (): LocaleData | undefined => {
    if (isSSR) return undefined;

    const { language } = navigator;

    if (language.startsWith('en-GB')) return locales[Locale.englishGb];
    if (language.startsWith('en')) return locales[Locale.englishUs];
    if (language.startsWith('nl')) return locales[Locale.dutch];
    if (language.startsWith('de')) return locales[Locale.german];
    if (language.startsWith('fr')) return locales[Locale.french];
    if (language.startsWith('es')) return locales[Locale.spanish];
    if (language.startsWith('ar')) return locales[Locale.arabic];

    return defaultLocale;
};
