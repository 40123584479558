/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconSpotify: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M50,0C22.4,0,0,22.4,0,50s22.4,50,50,50s50-22.4,50-50S77.6,0,50,0z M73,72.2c-1,1.5-2.8,2-4.2,1c-11.7-7.2-26.5-8.7-44-4.8C23,69,21.5,67.8,21,66.2c-0.5-1.7,0.8-3.2,2.2-3.7c19-4.2,35.5-2.5,48.5,5.5C73.5,68.8,73.7,70.8,73,72.2z M79,58.5c-1.2,1.8-3.5,2.5-5.2,1.2C60.2,51.5,39.8,49,24,54c-2,0.5-4.2-0.5-4.8-2.5s0.5-4.2,2.5-4.8C40,41.3,62.5,44,78,53.5C79.5,54.3,80.2,56.8,79,58.5z M79.5,44.5c-16-9.5-42.7-10.5-58-5.8c-2.5,0.8-5-0.8-5.8-3c-0.8-2.5,0.8-5,3-5.8c17.8-5.2,47-4.2,65.5,6.8c2.2,1.2,3,4.2,1.8,6.5C84.7,45,81.7,45.8,79.5,44.5z" />
    </Svg>
);

export default IconSpotify;
/* eslint-enable max-len */
