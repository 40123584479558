/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyWarehouseLogistics: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={72} viewBoxHeight={100} className={className}>
        <path d="M45,85.8H22.5c-9.8,0-15.2,0-18.8-3.7C0,78.5,0,73.1,0,63.3V36.7c0-9.8,0-15.2,3.7-18.8c3.7-3.7,9.1-3.7,18.8-3.7s15.2,0,18.8,3.7C45,21.5,45,26.9,45,36.7V85.8z M22.5,19.2c-8.7,0-13.1,0-15.3,2.2C5,23.6,5,27.9,5,36.7v26.7c0,8.7,0,13.1,2.2,15.3c2.2,2.2,6.6,2.2,15.3,2.2H40V36.7c0-8.7,0-13.1-2.2-15.3C35.6,19.2,31.2,19.2,22.5,19.2z" />
        <path d="M30.8,62h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S31.9,62,30.8,62z M17.5,62h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S18.6,62,17.5,62z M30.8,48.7h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S31.9,48.7,30.8,48.7z M17.5,48.7h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S18.6,48.7,17.5,48.7zM30.8,35.3h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S31.9,35.3,30.8,35.3z M17.5,35.3h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S18.6,35.3,17.5,35.3z" />
        <path d="M57.5,62h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S58.6,62,57.5,62z M57.5,48.7h-3.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.3c1.1,0,2,0.9,2,2S58.6,48.7,57.5,48.7z" />
        <path d="M55.8,85.3H40.5V34.7h15.3c6.6,0,10.3,0,12.8,2.5c2.5,2.5,2.5,6.2,2.5,12.8v20c0,6.6,0,10.3-2.5,12.8C66.1,85.3,62.4,85.3,55.8,85.3z M44.5,81.3h11.3c6.1,0,8.7-0.1,10-1.4c1.3-1.3,1.4-3.8,1.4-10V50c0-6.1-0.1-8.7-1.4-10c-1.3-1.3-3.8-1.4-10-1.4H44.5V81.3z" />
    </Svg>
);

export default IconVacancyWarehouseLogistics;
/* eslint-enable max-len */
