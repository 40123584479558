import { FC, ReactElement, useEffect } from 'react';

import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

import { CheckIntersection, Page, Wrapper } from '../../components';
import { FixedNavigation, LocalizedHelmet } from '../../compositions';
import { ConnectedContactSection, ConnectedGymLocator, ConnectedSection } from '../../connectors';
import { defaultLocale } from '../../constants/locale';
import { HomeSection, homeSections } from '../../constants/routing';
import {
    FlavoursSection,
    HydrationStationSection,
    HydrationStationSectionBackground,
    PlaygroundSection,
    UspSection,
    WelcomeSection,
} from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { scrollToTop } from '../../helpers/scroll';
import { getLocaleFromLanguageString, getPathFromTranslation } from '../../helpers/trans';
import { getUrlWithLocationParams } from '../../helpers/url';
import useScrollSectionIntoView from '../../hooks/useScrollSectionIntoView';
import useTrans from '../../hooks/useTrans';

import './HomePage.scss';

const HomePage: FC = (): ReactElement => {
    const { language } = useLocale();
    const location = useLocation();
    const navigate = useNavigate();
    const trans = useTrans();
    const { scrollIntoView } = useScrollSectionIntoView();

    useEffect((): void => {
        const { pathname, hash } = location;
        const decodedUrl = decodeURIComponent(pathname + hash);

        const routeLanguage = pathname.replaceAll('/', '');
        const routeLocale = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

        const sectionRouteKey = getPathFromTranslation(routeLocale.language, 'homeSections', decodedUrl);

        if (sectionRouteKey) {
            const sectionId = sectionRouteKey.split('.').slice(0, -1).join('.');

            if (hash) {
                scrollIntoView(sectionId);
            } else {
                scrollToTop('auto');
            }
        }
    }, [language]);

    const handleIntersectionToggle = (sectionId: string) => {
        const path = trans(homeSections[sectionId].path);
        const to = getUrlWithLocationParams(path, location);

        navigate(to, { replace: true });
    };

    return (
        <Page className="home-page">
            <LocalizedHelmet pageKey="home" />

            <FixedNavigation
                navigationLabel={trans('pages.home.fixedNavigation.ariaLabel')}
                className="home-page__fixed-navigation"
            />

            <ConnectedSection
                id={HomeSection.welcome}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--welcome"
            >
                <WelcomeSection />
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.hydrationStation}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--hydration-station"
            >
                {!isMobile && (
                    <CheckIntersection className="home-page__hydration-station-background">
                        {isInView => (
                            <HydrationStationSectionBackground isAnimationEnabled={isInView} />
                        )}
                    </CheckIntersection>
                )}

                <CheckIntersection threshold={0.75} className="home-page__hydration-station">
                    {isInView => (
                        <HydrationStationSection isInView={isInView} />
                    )}
                </CheckIntersection>
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.flavours}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--flavours"
            >
                <FlavoursSection />
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.benefits}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--usp"
            >
                <UspSection />
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.playground}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--playground"
            >
                <PlaygroundSection />
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.gymLocator}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--gym-locator"
            >
                <Wrapper>
                    <h2 className="home-page__gym-locator-title">
                        {trans('pages.home.gymLocatorSection.title')}
                    </h2>
                </Wrapper>

                <ConnectedGymLocator className="home-page__gym-locator" />
            </ConnectedSection>

            <ConnectedSection
                id={HomeSection.contact}
                onIntersectionToggle={handleIntersectionToggle}
                className="home-page__section home-page__section--contact"
            >
                <ConnectedContactSection
                    title={trans('pages.home.contactSection.title')}
                    description={trans('pages.home.contactSection.description')}
                />
            </ConnectedSection>
        </Page>
    );
};

export default HomePage;
