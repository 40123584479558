const getFilteredLength = (line: string[]): number => line.join(' ')
    .replaceAll('\'', '')
    .replaceAll(',', '')
    .length;

const splitUspTitle = (text: string): string[] => {
    const words = text.split(' ');

    if (words.length <= 3) {
        return words;
    }

    const startLine = [];
    const middleLine = [];
    const endLine = [];

    for (let i = 0; i < words.length; i += 1) {
        const word = words[i];

        const startLength = getFilteredLength(startLine);
        const middleLength = startLength + getFilteredLength(middleLine);
        const maxLineLength = Math.floor(text.length / 3);

        if (startLength + word.length < maxLineLength && middleLine.length === 0) {
            startLine.push(word);
        } else if (middleLength + word.length < maxLineLength * 2 && endLine.length === 0) {
            middleLine.push(word);
        } else {
            endLine.push(word);
        }
    }

    return [
        startLine.join(' '),
        middleLine.join(' '),
        endLine.join(' '),
    ];
};

export default splitUspTitle;
