import { FC, ReactElement } from 'react';

import { Icon, LinkButton, Wrapper } from '../../../../components';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Vacancy } from '../../../../entities/Vacancy/Vacancy';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './VacancyHeader.scss';

interface VacancyHeaderProps {
    vacancy: Vacancy;
    className?: string;
}

const VacancyHeader: FC<VacancyHeaderProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    return (
        <header className={`vacancy-header ${className}`}>
            <Wrapper className="vacancy-header__wrapper">
                <LinkButton
                    to={trans(appRoutes[AppRoute.workAt].path)}
                    text={trans('containers.vacancyContent.backButton')}
                    className="vacancy-header__back-button"
                />

                <div className="vacancy-header__content-wrapper">
                    <div className="vacancy-header__text-wrapper">
                        <h1 className="vacancy-header__title">
                            {vacancy.title}
                        </h1>

                        <ul className="vacancy-header__feature-list">
                            {vacancy.hoursPerWeek && (
                                <li className="vacancy-header__feature-item">
                                    <Icon name="vacancy-employment" className="vacancy-header__feature-icon" />

                                    <span className="vacancy-header__feature-label">
                                        {trans('containers.vacancyContent.hoursPerWeek', { hours: vacancy.hoursPerWeek })}
                                    </span>
                                </li>
                            )}

                            {vacancy.educationLevel && (
                                <li className="vacancy-header__feature-item">
                                    <Icon name="vacancy-education" className="vacancy-header__feature-icon" />

                                    <span className="vacancy-header__feature-label">
                                        {vacancy.educationLevel}
                                    </span>
                                </li>
                            )}

                            {vacancy.address.city && (
                                <li className="vacancy-header__feature-item">
                                    <Icon name="vacancy-location" className="vacancy-header__feature-icon" />

                                    <span className="vacancy-header__feature-label">
                                        {vacancy.address.city}
                                    </span>
                                </li>
                            )}
                        </ul>

                        <div className="vacancy-header__date-wrapper">
                            <p className="vacancy-header__date-label">
                                {trans('containers.vacancyContent.createdDate', {
                                    date: formatDate(vacancy.createdAt, { dateStyle: 'long' }),
                                })}
                            </p>

                            <p className="vacancy-header__date-label">
                                {trans('containers.vacancyContent.expirationDate', {
                                    date: formatDate(vacancy.expirationDate, { dateStyle: 'long' }),
                                })}
                            </p>
                        </div>

                        {vacancy.applyCallToAction && (
                            <LinkButton
                                to={vacancy.applyCallToAction.url}
                                text={vacancy.applyCallToAction.label}
                                className="vacancy-header__apply-button"
                            />
                        )}
                    </div>

                    <div className="vacancy-header__image-wrapper">
                        {vacancy.image && (
                            <img
                                src={vacancy.image.src}
                                alt={vacancy.image.alt}
                                className="vacancy-header__image"
                            />
                        )}
                    </div>
                </div>
            </Wrapper>
        </header>
    );
};

export default VacancyHeader;
