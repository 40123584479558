import { FC, ReactElement } from 'react';

import { ContactSection } from '../../containers';
import { ContactFormData } from '../../entities/Contact/Contact';
import { gtmEvent } from '../../helpers/analytics';
import { sendContactForm } from '../../redux/contactForm/contactFormActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedContactSectionProps {
    title: string;
    description: string;
    className?: string;
}

const ConnectedContactSection: FC<ConnectedContactSectionProps> = ({
    title,
    description,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const formIsLoading = useTypedSelector(state => state.contactFormReducer.isLoading);
    const formIsSuccessful = useTypedSelector(state => state.contactFormReducer.isSuccessful);

    const handleSubmit = (formData: ContactFormData): void => {
        dispatch(sendContactForm(formData));

        gtmEvent({
            event: 'form_submit',
            form_id: formData.clientType,
            formData,
        });
    };

    return (
        <ContactSection
            formIsLoading={formIsLoading}
            formIsSuccessful={formIsSuccessful}
            title={title}
            description={description}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedContactSection;
