import { FC, ReactElement } from 'react';

import { Svg } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { scrollToTop } from '../../../../helpers/scroll';
import useTrans from '../../../../hooks/useTrans';

import './ScrollToTop.scss';

interface ScrollToTopProps {
    className?: string;
}

const ScrollToTop: FC<ScrollToTopProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleClick = (): void => scrollToTop();

    return (
        <div className={`scroll-to-top ${className}`}>
            <IconButton
                icon="chevron-up"
                text={trans('containers.footer.scrollToTop')}
                onClick={handleClick}
                className="scroll-to-top__button"
                iconClassName="scroll-to-top__icon"
            />

            <Svg
                viewBoxWidth={1000}
                viewBoxHeight={158.7}
                preserveAspectRatio="none"
                className="scroll-to-top__svg"
            >
                <path d="M500,0C242,0,394.1,158.7,0,158.7c173.7,0,347.3,0,500,0s326.3,0,500,0C605.9,158.7,758,0,500,0z" />
            </Svg>
        </div>
    );
};

export default ScrollToTop;
