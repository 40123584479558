import { FC, ReactElement } from 'react';

import { Vacancy } from '../../entities/Vacancy/Vacancy';
import { SliceRenderer } from '..';
import { VacancyContentSkeletons } from './skeletons';
import { VacancyFooter, VacancyHeader, VacancyHelmet } from './subcomponents';

import './VacancyContent.scss';

interface VacancyContentProps {
    isLoading?: boolean;
    vacancy?: Vacancy;
    className?: string;
}

const VacancyContent: FC<VacancyContentProps> = ({
    isLoading,
    vacancy,
    className = '',
}): ReactElement => {
    if (isLoading || !vacancy) {
        return (
            <VacancyContentSkeletons className={`vacancy-content ${className}`} />
        );
    }

    return (
        <article className={`vacancy-content ${className}`}>
            <VacancyHelmet vacancy={vacancy} />

            <VacancyHeader
                vacancy={vacancy}
                className="vacancy-content__header"
            />

            <SliceRenderer
                slices={vacancy.body}
                className="vacancy-content__body"
            />

            <VacancyFooter
                vacancy={vacancy}
                className="vacancy-content__footer"
            />
        </article>
    );
};

export default VacancyContent;
