import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultPrismicDefaultPageData, PrismicDefaultPageData } from '../../entities/Prismic/Prismic';
import { AsyncReduxState } from '../../types';

export type YourClubState = AsyncReduxState<{
    yourClubData: PrismicDefaultPageData;
}>;

const initialState: YourClubState = {
    ...initialAsyncReduxState,
    yourClubData: defaultPrismicDefaultPageData,
};

const yourClubSlice = createSlice({
    name: 'yourClubReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): YourClubState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): YourClubState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setYourClubData(state, action: PayloadAction<PrismicDefaultPageData>): YourClubState {
            return {
                ...state,
                yourClubData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setYourClubData,
} = yourClubSlice.actions;

export default yourClubSlice;
