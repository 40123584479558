import { FC, ReactElement } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Continent, customCountry, defaultContinentLocale } from '../../constants/countries';
import { LocaleData } from '../../constants/locale';
import { Menu } from '../../containers';
import { useCookieSettings, useCookieSettingsDispatch } from '../../context/CookieContext';
import { useCountryDispatch } from '../../context/CountryContext';
import { useLocaleDispatch } from '../../context/LocaleContext';
import { useParams } from '../../context/ParamContext';
import { getTranslatedLocation } from '../../helpers/url';

interface ConnectedMenuProps {
    className?: string;
}

const ConnectedMenu: FC<ConnectedMenuProps> = ({
    className = '',
}): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const cookieSettings = useCookieSettings();

    const dispatchCookieSettings = useCookieSettingsDispatch();
    const dispatchCountry = useCountryDispatch();
    const dispatchLocale = useLocaleDispatch();

    const handleShowCookieSettings = (): void => {
        dispatchCookieSettings({
            ...cookieSettings,
            cookieSettingsVisible: true,
        });
    };

    const handleSelectLocale = (localeData: LocaleData): void => {
        const translatedRoute = getTranslatedLocation(localeData.language, location, params);

        if (translatedRoute) {
            navigate(translatedRoute, { replace: true });
        }

        dispatchLocale(localeData);
    };

    const handleSelectRegion = (continent: Continent): void => {
        dispatchCountry({
            name: customCountry,
            continent,
        });

        handleSelectLocale(defaultContinentLocale[continent]);
    };

    return (
        <Menu
            onShowCookieSettings={handleShowCookieSettings}
            onSelectRegion={handleSelectRegion}
            onSelectLocale={handleSelectLocale}
            className={className}
        />
    );
};

export default ConnectedMenu;
