import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';

import { locales } from '../../../constants/locale';
import { getFlavourFromPathname } from '../../../helpers/flavours';
import { getTranslation } from '../../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useTrans from '../../../hooks/useTrans';

const FlavourHelmet: FC<PropsWithChildren> = ({ children }): ReactElement => {
    const trans = useTrans();

    const { pathname } = useLocation();
    const { flavour = '' } = useParams();

    const flavourKey = getFlavourFromPathname(pathname, flavour);

    const paramPath = trans('routes.flavourDetail.path');
    const updatedPathname = replaceUrlParamKeysWithValues(paramPath, { flavour });

    const siteUrl = trans('company.url');

    if (!flavourKey) {
        return (
            <Helmet>
                {children}
            </Helmet>
        );
    }

    return (
        <Helmet>
            <title>{trans(`flavours.${flavourKey}.meta.title`)}</title>
            <meta name="description" content={trans(`flavours.${flavourKey}.meta.description`)} />
            <meta name="keywords" content={trans(`flavours.${flavourKey}.meta.keywords`)} />

            <meta property="og:title" content={trans(`flavours.${flavourKey}.meta.title`)} />
            <meta property="og:description" content={trans(`flavours.${flavourKey}.meta.description`)} />
            <meta property="og:image" content={`${siteUrl}/images/flavours/header/${flavourKey}.png`} />
            <meta property="og:image:alt" content={trans(`flavours.${flavourKey}.imageAlts.header`)} />

            <link rel="canonical" href={siteUrl + updatedPathname} />

            {Object.values(locales).map(locale => {
                const localePath = getTranslation(locale.language, 'routes.flavourDetail.path');
                const translatedFlavour = getTranslation(locale.language, `flavours.${flavourKey}.slug`);

                const updatedLocalePathname = replaceUrlParamKeysWithValues(localePath, {
                    flavour: translatedFlavour,
                });

                return (
                    <link
                        key={locale.name}
                        rel="alternate"
                        hrefLang={locale.language}
                        href={siteUrl + updatedLocalePathname}
                    />
                );
            })}

            {children}
        </Helmet>
    );
};

export default FlavourHelmet;
