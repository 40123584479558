/* eslint-disable no-param-reassign */
import { gsap, MorphSVGPlugin } from '../../../entities/Gsap/GsapService';

export interface BlobWaveHelperOptions {
    angle: number;
    duration: number;
    end: number;
    ease?: string;
    invertFlow?: boolean;
    length: number;
    loose: boolean;
    magnitude: number;
    phase: number;
    repeat: number;
    start: number;
    taperEnd?: number;
    taperStart?: number;
}

interface MatrixPoint {
    x: number;
    y: number;
    i: number;
    p: number;
    a: number;
    t: number;
    smooth: boolean;
}

const blobWaveHelper = (event: SVGPathElement, vars: BlobWaveHelperOptions): gsap.core.Timeline => {
    const transformBezier = (b: number[], matrix: gsap.plugins.Matrix2D) => {
        let x;
        let y;

        if (!matrix) {
            return b;
        }

        if (matrix.a !== 1 || matrix.b || matrix.c || matrix.d !== 1 || matrix.e || matrix.f) {
            for (let i = 0; i < b.length; i += 2) {
                x = b[i];
                y = b[i + 1];
                b[i] = x * matrix.a + y * matrix.c + matrix.e;
                b[i + 1] = x * matrix.b + y * matrix.d + matrix.f;
            }
        }

        return b;
    };

    const getLength = (x: number, y: number, x2: number, y2: number) => {
        x = x2 - x;
        y = y2 - y;

        return Math.sqrt(x * x + y * y);
    };

    const getTotalLength = (bezier: number[], start: number, end: number) => {
        let x = bezier[start];
        const y = bezier[start + 1];
        let length = 0;
        let i;

        for (i = start; i < end; i += 2) {
            length += getLength(x, y, x = bezier[i], bezier[i + 1]);
        }

        return length;
    };

    const round = (value: number) => Math.round(value * 1000) / 1000;

    const getSmooth = (
        i: number,
        x: number,
        y: number,
        start: number,
        end: number,
        bezier: number[],
    ): boolean => {
        if (i % 6 === 0 && i > start && i < end) {
            const angle1 = Math.atan2(y - bezier[i - 1], x - bezier[i - 2]);
            const angle2 = Math.atan2(bezier[i + 3] - y, bezier[i + 2] - x);
            const value = Math.abs(angle1 - angle2);

            return value < 0.01;
        }

        return false;
    };

    const taperPoint = (
        taperStart: number,
        taperEnd: number,
        bezierLength: number,
        bezierTotalLength: number,
    ) => {
        if (taperStart && bezierLength < taperStart) {
            return bezierLength / taperStart;
        }

        if (taperEnd && bezierLength > bezierTotalLength - taperEnd && bezierLength < bezierTotalLength) {
            return (bezierTotalLength - bezierLength) / taperEnd;
        }

        return 1;
    };

    const onTimelineUpdate = (
        changes: MatrixPoint[],
        proxy: { a: number },
        start: number,
        magnitude: number,
        sin: number,
        cos: number,
        bezier: number[],
        pathStart: string,
    ) => {
        const l = changes.length;
        const angle = proxy.a;
        let node;
        let i;
        let m;
        let x;
        let y;
        let x2;
        let y2;
        let x1;
        let y1;
        let cp;
        let dx;
        let dy;
        let d;
        let a;
        let cpCos;
        let cpSin;

        for (i = 0; i < l; i += 1) {
            node = changes[i];
            if (node.smooth || i === l - 1 || !changes[i + 1].smooth) {
                m = Math.sin(node.a + angle) * magnitude * node.t;
                x = round(node.x + m * sin);
                y = round(node.y + m * cos);
                bezier[node.i] = x;
                bezier[node.i + 1] = y;

                if (node.smooth) { // make sure smooth anchors stay smooth!
                    cp = changes[i - 1];
                    m = Math.sin(cp.a + angle) * magnitude * cp.t;
                    x1 = cp.x + m * sin;
                    y1 = cp.y + m * cos;

                    cp = changes[i + 1];
                    m = Math.sin(cp.a + angle) * magnitude * cp.t;
                    x2 = cp.x + m * sin;
                    y2 = cp.y + m * cos;

                    a = Math.atan2(y2 - y1, x2 - x1);
                    cpCos = Math.cos(a);
                    cpSin = Math.sin(a);

                    dx = x2 - x;
                    dy = y2 - y;
                    d = Math.sqrt(dx * dx + dy * dy);
                    bezier[cp.i] = round(x + cpCos * d);
                    bezier[cp.i + 1] = round(y + cpSin * d);

                    cp = changes[i - 1];
                    dx = x1 - x;
                    dy = y1 - y;
                    d = Math.sqrt(dx * dx + dy * dy);
                    bezier[cp.i] = round(x - cpCos * d);
                    bezier[cp.i + 1] = round(y - cpSin * d);
                    i += 1;
                }
            }
        }

        if (start) {
            event.setAttribute('d', pathStart + bezier.join(','));
        } else {
            event.setAttribute('d', `M${bezier[0]},${bezier[1]} C${bezier.slice(2).join(',')}`);
        }
    };

    const deg2Rad = Math.PI / 180;
    const elementD = event.getAttribute('d') || 'M 10 10 C 10 10 10 10 20 20';
    const rawPathD = MorphSVGPlugin.stringToRawPath(elementD)[0];
    const bezier = transformBezier(rawPathD, event.transform.baseVal.consolidate()?.matrix || new DOMMatrix());
    let start = (vars.start || 0) * 2;
    let end = (vars.end === 0) ? 0 : (vars.end * 2) || (bezier.length - 1);
    const length = vars.length || 100;
    const magnitude = vars.magnitude || 50;
    const proxy = { a: 0 };
    const phase = (vars.phase || 0) * deg2Rad;
    const taperStart = vars.taperStart || 0;
    const taperEnd = vars.taperEnd || 0;
    const startX = bezier[start];
    const startY = bezier[start + 1];
    const changes: MatrixPoint[] = [];
    let bezierLength = 0;
    const { loose } = vars;
    const timeline = gsap.timeline({ repeat: vars.repeat });

    let angle;
    let i;
    let x;
    let y;
    let dx;
    let dy;
    let m;
    let pathStart: string;
    let t;

    i = bezier.length;

    while (i >= 0) {
        i -= 1;
        bezier[i] = round(bezier[i]);
    }

    if (end >= bezier.length - 1) {
        end = bezier.length - 2;
    }

    if (start >= bezier.length) {
        start = bezier.length - 1;
    }

    const bezierTotalLength = getTotalLength(bezier, start, end);

    dx = bezier[end] - startX;
    dy = bezier[end + 1] - startY;

    if (vars.angle || vars.angle === 0) {
        angle = vars.angle * deg2Rad;
    } else {
        angle = Math.atan2(dy, dx) - Math.PI / 2;
    }

    const sin = Math.sin(angle);
    const cos = Math.cos(angle);
    const sin2 = Math.sin(angle + Math.PI / 2);
    const cos2 = Math.cos(angle + Math.PI / 2);
    const negCos = Math.cos(-angle);
    const negSin = Math.sin(-angle);
    const rotatedStartX = startX * negCos + startY * negSin;

    x = startX;
    y = startY;

    for (i = start; i <= end; i += 2) {
        bezierLength += getLength(x, y, x = bezier[i], y = bezier[i + 1]);
        dx = x * negCos + y * negSin;
        dy = x * negSin + y * negCos;
        t = taperPoint(
            taperStart,
            taperEnd,
            bezierLength,
            bezierTotalLength,
        );
        m = Math.sin((dx / length) * Math.PI * 2 + phase) * magnitude;

        const smooth = getSmooth(i, x, y, start, end, bezier);

        changes.push({
            i: i - (start ? 2 : 0),
            p: dx,
            a: (dx / length) * Math.PI * 2 + phase,
            t,
            x: loose
                ? x - m * sin * t
                : startX + (dx - rotatedStartX) * cos2 * t,
            y: loose
                ? y - m * cos * t
                : startY + (dx - rotatedStartX) * sin2 * t,

            smooth,
        });
    }

    if (start) {
        pathStart = `M${bezier.shift()},${bezier.shift()} C`;
    }

    timeline.to(proxy, {
        duration: vars.duration || 3,
        a: Math.PI * (vars.invertFlow ? -2 : 2),
        ease: vars.ease || 'none',
        onUpdate() {
            onTimelineUpdate(
                changes,
                proxy,
                start,
                magnitude,
                sin,
                cos,
                bezier,
                pathStart,
            );
        },
    });

    return timeline;
};

export default blobWaveHelper;
