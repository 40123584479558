import { FC, ReactElement, useEffect } from 'react';

import { useLocalStorage } from 'react-use';

import { CookieSettingsData, CookieType, CookieTypeConfiguration } from '../../constants/cookieSettings';
import { CookieSettings } from '../../containers';
import { useCookieSettings, useCookieSettingsDispatch } from '../../context/CookieContext';
import { gtmEvent } from '../../helpers/analytics';

const ConnectedCookieSettings: FC = (): ReactElement => {
    const { cookieSettingsVisible } = useCookieSettings();

    const dispatchCookieSettings = useCookieSettingsDispatch();

    const [storedCookieSettings] = useLocalStorage<CookieSettingsData>('cookieSettings', undefined);

    useEffect((): void => {
        if (storedCookieSettings) {
            const marketingCookieConsent = storedCookieSettings[CookieType.marketing] ? 'granted' : 'denied';
            const analyticalCookieConsent = storedCookieSettings[CookieType.analytical] ? 'granted' : 'denied';

            gtmEvent({
                event: 'consentEvent',
                consent: {
                    ad_storage: marketingCookieConsent,
                    ad_user_data: marketingCookieConsent,
                    ad_personalization: marketingCookieConsent,
                    analytics_storage: analyticalCookieConsent,
                },
            });
        }
    }, []);

    const handleUpdateCookieSettings = (configuration: CookieTypeConfiguration) => {
        dispatchCookieSettings({
            ...configuration,
            cookieSettingsVisible: false,
            cookieSettingsConfigured: true,
        });

        const marketingCookieConsent = configuration[CookieType.marketing] ? 'granted' : 'denied';
        const analyticalCookieConsent = configuration[CookieType.analytical] ? 'granted' : 'denied';

        gtmEvent({
            event: 'consentEvent',
            consent: {
                ad_storage: marketingCookieConsent,
                ad_user_data: marketingCookieConsent,
                ad_personalization: marketingCookieConsent,
                analytics_storage: analyticalCookieConsent,
            },
        });
    };

    return (
        <CookieSettings
            isRevealed={cookieSettingsVisible}
            onUpdateCookieSettings={handleUpdateCookieSettings}
        />
    );
};

export default ConnectedCookieSettings;
