/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyFinance: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={69} viewBoxHeight={100} className={className}>
        <path d="M34.2,65.3c-1.1,0-2-0.9-2-2v-1.5c-2.8-0.5-5.1-2.1-6.1-4.3c-0.5-1,0-2.2,1-2.7c1-0.5,2.2,0,2.7,1c0.5,1.1,2.1,2.2,4.5,2.2c2.5,0,4.7-1.4,4.7-3s-2.2-3-4.7-3c-4.8,0-8.7-3.1-8.7-7c0-3.3,2.8-6.1,6.7-6.8v-1.5c0-1.1,0.9-2,2-2s2,0.9,2,2v1.5c2.8,0.5,5.1,2.1,6.1,4.3c0.5,1,0,2.2-1,2.7c-1,0.5-2.2,0-2.7-1c-0.5-1.1-2.1-2.2-4.5-2.2c-2.5,0-4.7,1.4-4.7,3s2.2,3,4.7,3c4.8,0,8.7,3.1,8.7,7c0,3.3-2.8,6.1-6.7,6.8v1.5C36.2,64.4,35.3,65.3,34.2,65.3z" />
        <path d="M34.2,85.8c-2.5,0-3.9-0.5-6.3-1.4C7.2,76.5-3.4,55.6,1,31.4c0.4-2.4,0.7-3.8,1.8-5c1.1-1.2,2.8-1.7,6.4-2.9c2.6-0.8,5-2.2,7.7-3.6c4.8-2.7,10.3-5.7,17.3-5.7c7,0,12.5,3,17.3,5.7c2.6,1.5,5.1,2.8,7.7,3.6c3.7,1.2,5.3,1.7,6.4,2.9c1.1,1.2,1.4,2.7,1.8,5c4.4,24.3-6.2,45.1-26.9,53C38.1,85.3,36.7,85.8,34.2,85.8z M6.5,29.8c-0.2,0.3-0.4,1.4-0.6,2.5c-4,22.1,5.1,40.3,23.8,47.4c2.1,0.8,2.9,1.1,4.5,1.1s2.4-0.3,4.5-1.1c18.7-7.1,27.8-25.3,23.8-47.4c-0.2-1.1-0.4-2.3-0.6-2.6c-0.3-0.2-2.2-0.8-4.2-1.5c-3-1-5.8-2.5-8.6-4c-4.7-2.6-9.1-5-14.9-5s-10.2,2.4-14.9,5c-2.7,1.5-5.5,3.1-8.6,4C8.8,28.8,7,29.4,6.5,29.8z" />
    </Svg>
);

export default IconVacancyFinance;
/* eslint-enable max-len */
