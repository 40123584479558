import { FC, ReactElement, useEffect } from 'react';

import { Page } from '../../components';
import { PersonalityTestResultHelmet } from '../../compositions';
import { ConnectedPersonalityTestResult } from '../../connectors';
import { scrollToTop } from '../../helpers/scroll';

import './PersonalityTestResultPage.scss';

const PersonalityTestResultPage: FC = (): ReactElement => {
    useEffect((): void => {
        scrollToTop();
    }, []);

    return (
        <Page className="personality-test-result-page">
            <PersonalityTestResultHelmet />

            <ConnectedPersonalityTestResult />
        </Page>
    );
};

export default PersonalityTestResultPage;
