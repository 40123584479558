import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../Prismic/Prismic';
import { PrismicTwoColumnText, TwoColumnText } from './TwoColumnText';

export const transformToTwoColumnText = (
    prismicTwoColumnText: PrismicTwoColumnText,
): TwoColumnText => {
    const titleSizeKey = getEnumKeyByEnumValue(PrismicSliceTitleSize, prismicTwoColumnText.title_size);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicTwoColumnText.title_colour);

    return {
        isMirrored: prismicTwoColumnText.columns_are_mirrored,
        title: prismicTwoColumnText.title_column,
        titleSize: titleSizeKey
            ? PrismicSliceTitleSize[titleSizeKey]
            : undefined,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        paragraph: prismicTwoColumnText.paragraph_column,
    };
};
