import { FC, ReactElement } from 'react';

import './PersonalityTestProgress.scss';

interface PersonalityTestProgressProps {
    className?: string;
}

const PersonalityTestProgress: FC<PersonalityTestProgressProps> = ({
    className = '',
}): ReactElement => (
    <div className={`personality-test-progress ${className}`}>
        <div className="personality-test-progress__indicator">
            <div className="personality-test-progress__outer-weight" />
            <div className="personality-test-progress__center-weight" />
            <div className="personality-test-progress__inner-weight" />

            <div className="personality-test-progress__bar">
                <div className="personality-test-progress__progress-wrapper">
                    <div className="personality-test-progress__progress" />
                </div>
            </div>

            <div className="personality-test-progress__inner-weight" />
            <div className="personality-test-progress__center-weight" />
            <div className="personality-test-progress__outer-weight" />
        </div>
    </div>
);

export default PersonalityTestProgress;
