import { CSSProperties, FC, ReactElement } from 'react';

import { Picture, RootPortal } from '../../components';
import { Continent } from '../../constants/countries';
import { flavourSets } from '../../constants/flavours';
import useTrans from '../../hooks/useTrans';

import './Screensaver.scss';

interface ScreensaverProps {
    continent: Continent;
    className?: string;
}

const Screensaver: FC<ScreensaverProps> = ({ continent, className = '' }): ReactElement => {
    const trans = useTrans();

    const flavours = flavourSets[continent];

    const screensaverCssVariables = {
        '--screensaver-flavour-amount': flavours.length,
    } as CSSProperties;

    return (
        <RootPortal>
            <div style={screensaverCssVariables} className={`screensaver ${className}`}>
                <div className="screensaver__flavours">
                    {flavours.map((flavour, index) => {
                        const flavourCssVariables = {
                            '--screensaver-flavour-index': index,
                        } as CSSProperties;

                        return (
                            <Picture
                                key={flavour}
                                style={flavourCssVariables}
                                src={`/images/flavours/${flavour}.png`}
                                sources={[
                                    { srcSet: `/images/flavours/${flavour}.avif`, type: 'image/avif' },
                                    { srcSet: `/images/flavours/${flavour}.webp`, type: 'image/webp' },
                                ]}
                                alt={trans(`flavours.${flavour}.name`)}
                                className="screensaver__flavour"
                                imageClassName="screensaver__flavour-image"
                            />
                        );
                    })}
                </div>
            </div>
        </RootPortal>
    );
};

export default Screensaver;
