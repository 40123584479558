/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyLocation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M50,100c-3.7,0-7.2-1.4-9.9-3.9c-1.5-1.4-3-2.8-4.6-4.3C17.9,75.6-3.9,55.3,8.1,27.1C15.1,10.9,31.9,0,50,0c18.1,0,34.9,10.9,41.9,27.1c12.1,28.3-10,48.6-27.7,65c-1.5,1.3-2.9,2.7-4.3,4v0C57.2,98.6,53.7,100,50,100z M50,7c-15.4,0-29.6,9.2-35.5,22.9C4.4,53.6,22.6,70.4,40.3,86.7c1.6,1.4,3.1,2.9,4.6,4.3c2.7,2.6,7.5,2.6,10.3,0c1.4-1.3,2.9-2.7,4.3-4c17.8-16.4,36.2-33.3,26-57.1C79.6,16.2,65.4,7,50,7z" />
        <path d="M50,65.1c-10.9,0-19.8-8.9-19.8-19.8c0-10.9,8.9-19.8,19.8-19.8c10.9,0,19.8,8.9,19.8,19.8C69.8,56.2,60.9,65.1,50,65.1z M50,32.6c-7.1,0-12.8,5.7-12.8,12.8c0,7.1,5.7,12.8,12.8,12.8c7.1,0,12.8-5.7,12.8-12.8C62.8,38.3,57,32.6,50,32.6z" />
    </Svg>
);

export default IconVacancyLocation;
/* eslint-enable max-len */
