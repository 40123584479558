import { RichText } from 'prismic-dom';

import { Language } from '../../../constants/locale';
import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { prismicLinkResolver } from '../../../helpers/prismic';
import { PrismicLinkResolver } from '../../Link/Link';
import { transformToCallToAction } from '../../Link/LinkTransformers';
import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../Prismic/Prismic';
import { PagePreview, PrismicPagePreview } from './PagePreview';

export const transformToPagePreview = (
    prismicPagePreview: PrismicPagePreview,
    language: Language,
): PagePreview => {
    const titleSizeKey = getEnumKeyByEnumValue(PrismicSliceTitleSize, prismicPagePreview.page_preview_title_size);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicPagePreview.page_preview_title_colour);

    const linkResolver = (doc: PrismicLinkResolver) => prismicLinkResolver(doc, language);
    const description = RichText.asHtml(prismicPagePreview.page_preview_description, linkResolver);

    const callToAction = prismicPagePreview.page_preview_call_to_action_link
        ? transformToCallToAction(
            prismicPagePreview.page_preview_call_to_action_link,
            prismicPagePreview.page_preview_call_to_action_label,
            language,
        )
        : undefined;

    return {
        title: prismicPagePreview.page_preview_title,
        titleSize: titleSizeKey
            ? PrismicSliceTitleSize[titleSizeKey]
            : undefined,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        description,
        callToAction,
    };
};
