import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { RelatedArticles } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { fetchRelatedArticles } from '../../redux/articleDetail/articleDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedRelatedArticlesProps {
    className?: string;
}

const ConnectedRelatedArticles: FC<ConnectedRelatedArticlesProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();
    const { slug = '' } = useParams();

    const relatedArticles = useTypedSelector(state => state.articleDetailReducer.relatedArticles);

    useEffect((): void => {
        dispatch(fetchRelatedArticles(slug, language));
    }, [slug, language]);

    return (
        <RelatedArticles
            relatedArticles={relatedArticles}
            className={className}
        />
    );
};

export default ConnectedRelatedArticles;
