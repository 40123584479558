import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Flavour } from '../../constants/flavours';
import { AppRoute, appRoutes } from '../../constants/routing';
import { FlavourContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useParamsDispatch } from '../../context/ParamContext';
import { getFlavourFromPathname } from '../../helpers/flavours';
import { getUrlWithLocationParams, replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTrans from '../../hooks/useTrans';

interface ConnectedFlavourContentProps {
    className?: string;
}

const ConnectedFlavourContent: FC<ConnectedFlavourContentProps> = ({
    className = '',
}): ReactElement | null => {
    const navigate = useNavigate();
    const trans = useTrans();
    const { language } = useLocale();
    const location = useLocation();
    const { pathname } = location;

    const { flavour = '' } = useParams();
    const dispatchParams = useParamsDispatch();

    const [activeFlavourKey, setActiveFlavourKey] = useState<Flavour>();

    const flavourKey = getFlavourFromPathname(pathname, flavour);

    useEffect((): () => void => {
        const newFlavourKey = getFlavourFromPathname(pathname, flavour);

        setActiveFlavourKey(newFlavourKey);

        dispatchParams({ flavour });

        return (): void => {
            dispatchParams({});
        };
    }, [flavour, language]);

    // Navigate to flavour overview page if no flavour is found, and perform a soft-refresh directly after
    useEffect((): void => {
        if (!flavourKey && !activeFlavourKey) {
            navigate(trans(appRoutes[AppRoute.flavours].path), { replace: true });
            navigate(0);
        }
    }, [flavourKey, activeFlavourKey]);

    useEffect((): void => {
        if (activeFlavourKey) {
            const translatedFlavourSlug = trans(`flavours.${activeFlavourKey}.slug`);
            const translatedFlavourPath = trans(appRoutes[AppRoute.flavourDetail].path);
            const translatedFlavourRoute = replaceUrlParamKeysWithValues(translatedFlavourPath, {
                flavour: translatedFlavourSlug,
            });
            const to = getUrlWithLocationParams(translatedFlavourRoute, location);

            navigate(to, { replace: true });
        }
    }, [activeFlavourKey, language]);

    if (!flavourKey) {
        return null;
    }

    return (
        <FlavourContent
            flavour={flavourKey}
            className={className}
        />
    );
};

export default ConnectedFlavourContent;
