import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Screensaver } from '../../containers';
import { useCountry } from '../../context/CountryContext';

interface ConnectedScreensaverProps {
    className?: string;
}

const ConnectedScreensaver: FC<ConnectedScreensaverProps> = ({ className = '' }): ReactElement | null => {
    const { continent } = useCountry();

    let idleTime = 0;
    const idleTimeInterval = 60000;
    const idleTimeLimit = 5;

    let sequence: string[] = [];
    const manualSequence = 'yangayanga';

    const [isScreensaverActive, setScreensaverIsActive] = useState<boolean>(false);

    useEffect((): () => void => {
        const incrementIdleTime = (): void => {
            if (document.hasFocus()) {
                idleTime += 1;
            }

            if (idleTime >= idleTimeLimit) {
                setScreensaverIsActive(true);
            }
        };

        const resetIdleTime = (): void => {
            idleTime = 0;
            setScreensaverIsActive(false);
        };

        const handleKeyPress = (event: KeyboardEvent): void => {
            sequence.push(event.key);

            for (let i = 0; i < sequence.length; i += 1) {
                if (sequence[i] !== manualSequence[i]) {
                    sequence = [];
                }
            }

            if (sequence.join('') === manualSequence) {
                setScreensaverIsActive(true);
            }
        };

        setInterval(incrementIdleTime, idleTimeInterval);

        window.addEventListener('touchstart', resetIdleTime);
        window.addEventListener('mousemove', resetIdleTime);
        window.addEventListener('keydown', resetIdleTime);
        window.addEventListener('keydown', handleKeyPress);

        return (): void => {
            window.removeEventListener('touchstart', resetIdleTime);
            window.removeEventListener('mousemove', resetIdleTime);
            window.removeEventListener('keydown', resetIdleTime);
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    if (!isScreensaverActive) {
        return null;
    }

    return (
        <Screensaver
            continent={continent}
            className={className}
        />
    );
};

export default ConnectedScreensaver;
