import { Language } from '../../constants/locale';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { getLanguageFromPrismicLanguage } from '../../helpers/prismic';
import { transformToImage } from '../@slices/Image/ImageTransformers';
import { transformToCallToAction } from '../Link/LinkTransformers';
import { defaultSeo } from '../Seo/Seo';
import { transformToSeo } from '../Seo/SeoTransformers';
import { PrismicDefaultPageData, PrismicDefaultPageTemplate, PrismicDocument, PrismicSliceTitleColour } from './Prismic';

export const transformPrismicDocumentToPrismicDefaultPageData = (
    prismicDocument: PrismicDocument<PrismicDefaultPageTemplate>,
    language: Language,
): PrismicDefaultPageData => {
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicDocument.data.title_colour);

    const poster = prismicDocument.data.poster?.url
        ? transformToImage(prismicDocument.data.poster)
        : undefined;

    const posterDroplet = prismicDocument.data.droplet
        ? Number(prismicDocument.data.droplet.replace('Droplet ', ''))
        : 1;

    const callToAction = prismicDocument.data.call_to_action_link
        ? transformToCallToAction(
            prismicDocument.data.call_to_action_link,
            prismicDocument.data.call_to_action_label,
            language,
        )
        : undefined;

    const seo = prismicDocument.data.seo?.length > 0
        ? transformToSeo(prismicDocument.data.seo[0])
        : defaultSeo;

    return {
        language: getLanguageFromPrismicLanguage(prismicDocument.lang),
        title: prismicDocument.data.title,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        intro: prismicDocument.data.intro,
        callToAction,
        poster,
        posterDroplet,
        seo,
        slices: prismicDocument.data.body,
    };
};
