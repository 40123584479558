import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../constants/routing';
import { VacancyContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useParamsDispatch } from '../../context/ParamContext';
import useTrans from '../../hooks/useTrans';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchVacancyContent } from '../../redux/vacancyDetail/vacancyDetailActions';

interface ConnectedVacancyContentProps {
    className?: string;
}

const ConnectedVacancyContent: FC<ConnectedVacancyContentProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();
    const navigate = useNavigate();
    const trans = useTrans();

    const { slug = '' } = useParams();
    const dispatchParams = useParamsDispatch();

    const isLoading = useTypedSelector(state => state.vacancyDetailReducer.isLoading);
    const vacancyData = useTypedSelector(state => state.vacancyDetailReducer.vacancyData);
    const error = useTypedSelector(state => state.vacancyDetailReducer.error);

    useEffect((): () => void => {
        dispatchParams({ slug });

        dispatch(fetchVacancyContent(slug, language));

        return (): void => {
            dispatchParams({});
        };
    }, [slug, language]);

    useEffect((): void => {
        if (error) {
            navigate(trans(appRoutes[AppRoute.workAt].path), { replace: true });
        }
    }, [error]);

    return (
        <VacancyContent
            isLoading={isLoading}
            vacancy={vacancyData}
            className={className}
        />
    );
};

export default ConnectedVacancyContent;
