import { FC, ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import {
    ConnectedCacheBuster,
    ConnectedCookieSettings,
    ConnectedMenu,
    ConnectedScreensaver,
    ConnectedToastList,
} from './connectors';
import { Footer } from './containers';
import { isProduction, isSSR } from './helpers';
import { initTagManager } from './helpers/analytics';
import { scrollToTop } from './helpers/scroll';
import LocalizedRoutes from './LocalizedRoutes';

if (!isSSR && isProduction) {
    initTagManager({
        originalLocation: window?.location?.href,
    });
}

const App: FC = (): ReactElement => {
    const { pathname, hash } = useLocation();

    useEffect((): void => {
        if (!hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    return (
        <>
            <ConnectedMenu />
            <LocalizedRoutes />
            <Footer />

            <ConnectedToastList />
            <ConnectedCookieSettings />
            <ConnectedCacheBuster />
            <ConnectedScreensaver />
        </>
    );
};

export default App;
