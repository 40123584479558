import { FC, PropsWithChildren, ReactElement } from 'react';

import './TableBody.scss';

interface TableBodyProps {
    className?: string;
}

const TableBody: FC<PropsWithChildren<TableBodyProps>> = ({ className = '', children }): ReactElement => (
    <tbody className={`table-body ${className}`}>
        {children}
    </tbody>
);

export default TableBody;
