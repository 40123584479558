import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture } from '../../components';
import { droplets } from '../../constants/droplets';
import { Image } from '../../entities/@slices/Image/Image';
import { clamp } from '../../helpers/number';

import './HeaderDropletImage.scss';

interface HeaderDropletImageProps {
    dropletIndex: number;
    image: Image;
    avifSource?: string;
    webpSource?: string;
    className?: string;
}

const HeaderDropletImage: FC<HeaderDropletImageProps> = ({
    dropletIndex,
    image,
    avifSource,
    webpSource,
    className = '',
}): ReactElement => {
    const justifiedDropletIndex = clamp(dropletIndex, 0, droplets.length - 1);

    const posterWrapperClassNames = classNames('header-droplet-image', {
        [`header-droplet-image--droplet-${justifiedDropletIndex}`]: justifiedDropletIndex,
    }, className);

    return (
        <div className={posterWrapperClassNames}>
            <Picture
                loading="lazy"
                src={image.src}
                sources={[
                    { srcSet: avifSource, type: 'image/avif' },
                    { srcSet: webpSource, type: 'image/webp' },
                ].filter(source => source.srcSet)}
                alt={image.alt}
                className="header-droplet-image__poster"
                imageClassName="header-droplet-image__poster-image"
            />

            {droplets[justifiedDropletIndex] && (
                <svg width="0" height="0" className="header-droplet-image__poster-clip">
                    <defs>
                        <clipPath id="header-droplet-image-clip" clipPathUnits="objectBoundingBox">
                            <path d={droplets[justifiedDropletIndex]} />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </div>
    );
};

export default HeaderDropletImage;
