/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './PersonalityTestResultBackground.scss';

interface PersonalityTestResultBackgroundProps {
    className?: string;
}

const PersonalityTestResultBackground: FC<PersonalityTestResultBackgroundProps> = ({
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'personality-test-result-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled
            options={{
                taperEnd: 80,
                invertFlow: false,
                taperStart: 2,
                length: 50,
                angle: -50,
                magnitude: 90,
                phase: 110,
                duration: 5,
                start: 2,
                end: 10,
                repeat: 10,
            }}
            preserveAspectRatio="none"
            pathRefObject={pathRef}
            viewBoxWidth={1500}
            viewBoxHeight={700}
            className={`personality-test-result-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="personality-test-result-background__gradient-start" />
                <stop offset="50%" className="personality-test-result-background__gradient-end" />
            </linearGradient>

            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M -345.734 1055.01 C 331.654 1069.122 1023.155 1076.178 1860.483 1062.066 C 1909.876 535.208 1728.769 -29.282 1326.569 163.585 C 1077.252 302.356 750.318 330.58 416.328 325.876 C 131.731 323.524 -451.576 295.3 -345.734 1055.01"
                className="personality-test-result-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default PersonalityTestResultBackground;
/* eslint-enable max-len */
