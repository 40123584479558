import { FC, ReactElement } from 'react';

import { CookieType } from '../../constants/cookieSettings';
import { GymLocator } from '../../containers';
import { useCookieSettings, useCookieSettingsDispatch } from '../../context/CookieContext';

interface ConnectedGymLocatorProps {
    className?: string;
}

const ConnectedGymLocator: FC<ConnectedGymLocatorProps> = ({ className = '' }): ReactElement => {
    const cookieSettings = useCookieSettings();
    const { cookieSettingsVisible } = cookieSettings;

    const dispatchCookieSettings = useCookieSettingsDispatch();

    const handleShowCookieSettings = (): void => {
        dispatchCookieSettings({
            ...cookieSettings,
            cookieSettingsVisible: true,
        });
    };

    return (
        <GymLocator
            allowedToLoad={cookieSettings[CookieType.functional]}
            disableCookieSettingsButton={cookieSettingsVisible}
            onShowCookieSettings={handleShowCookieSettings}
            className={className}
        />
    );
};

export default ConnectedGymLocator;
