import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { sanitizeHtml, SanitizeOptions } from '../../helpers/sanitizeHtml';

interface SafeHtmlProps extends RefAttributes<HTMLDivElement> {
    html?: string;
    sanitizeOptions?: SanitizeOptions;
    className?: string;
}

const SafeHtml: ForwardRefExoticComponent<SafeHtmlProps> = forwardRef(({
    html = '',
    sanitizeOptions,
    className = '',
}, ref: Ref<HTMLDivElement>): ReactElement => {
    const htmlOutput = sanitizeHtml(html, sanitizeOptions);

    return (
        <div
            ref={ref}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: htmlOutput }}
            className={className}
        />
    );
});

export default SafeHtml;
