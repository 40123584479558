import { FC, ReactElement } from 'react';

import { Image } from '../../../entities/@slices/Image/Image';

import './ImageSlice.scss';

interface ImageSliceProps extends Image {
    className?: string;
}

const ImageSlice: FC<ImageSliceProps> = ({
    src,
    alt,
    className = '',
}): ReactElement => (
    <div className={`image-slice ${className}`}>
        <img src={src} alt={alt} className="image-slice__image" />
    </div>
);

export default ImageSlice;
