import {
    DialogHTMLAttributes,
    forwardRef,
    ForwardRefExoticComponent,
    MouseEvent,
    ReactElement,
    Ref,
    RefAttributes,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import useCombinedRefs from '../../hooks/useCombinedRef';
import useTrans from '../../hooks/useTrans';
import { LiquidButton } from '..';

import './Dialog.scss';

interface DialogProps extends DialogHTMLAttributes<HTMLDialogElement>, RefAttributes<HTMLDialogElement> {
    enableBackdropClose?: boolean;
    closeButtonRef?: Ref<HTMLButtonElement>;
    onClose: () => void;
    className?: string;
    contentClassName?: string;
}

const Dialog: ForwardRefExoticComponent<DialogProps> = forwardRef(({
    enableBackdropClose,
    closeButtonRef,
    onClose,
    className = '',
    contentClassName = '',
    children,
    ...dialogProps
}, ref: Ref<HTMLDialogElement>): ReactElement => {
    const trans = useTrans();
    const [isHidden, setIsHidden] = useState<boolean>(false);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const combinedDialogRef = useCombinedRefs<HTMLDialogElement>(dialogRef, ref);

    const hideDialog = (): void => setIsHidden(true);

    const handleDialogClick = (event: MouseEvent<HTMLDialogElement>): void => {
        event.preventDefault();
        if (!combinedDialogRef.current || !enableBackdropClose) return;

        const { clientX, clientY } = event;
        const { top, left } = combinedDialogRef.current.getBoundingClientRect();
        const { width, height } = combinedDialogRef.current.getBoundingClientRect();

        const clickedDialog = top <= clientY
            && clientY <= top + height
            && left <= clientX
            && clientX <= left + width;

        if (!clickedDialog) {
            hideDialog();
        }
    };

    const handleAnimationEnd = (): void => {
        if (!isHidden) return;

        onClose();
        setIsHidden(false);
    };

    const dialogClassNames = classNames('dialog', {
        'dialog--is-hidden': isHidden,
        'dialog--has-interactive-backdrop': enableBackdropClose,
    }, className);

    return (
        // eslint-disable-next-line
        <dialog
            {...dialogProps}
            ref={combinedDialogRef}
            onClick={handleDialogClick}
            onAnimationEnd={handleAnimationEnd}
            className={dialogClassNames}
        >
            <div className={`dialog__content ${contentClassName}`}>
                <LiquidButton
                    ref={closeButtonRef}
                    icon="cross"
                    text={trans('common.close')}
                    hideLabel
                    onClick={hideDialog}
                    className="dialog__close-button"
                    iconClassName="dialog__close-button-icon"
                    fillClassName="dialog__close-button-fill"
                />

                {children}
            </div>
        </dialog>
    );
});

export default Dialog;
