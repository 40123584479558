import {
    FC,
    MouseEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDebounce } from 'react-use';
import { noop } from 'react-use/lib/misc/util';

import { LinkButton, Picture, Wrapper } from '../../components';
import { Continent } from '../../constants/countries';
import { flavourSets } from '../../constants/flavours';
import { Direction } from '../../constants/locale';
import { AppRoute, appRoutes } from '../../constants/routing';
import { useLocale } from '../../context/LocaleContext';
import { gsap, Quad, Quart } from '../../entities/Gsap/GsapService';
import { delay } from '../../helpers/delay';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import { useFontLoaded } from '../../hooks/useFontLoaded';
import useTrans from '../../hooks/useTrans';
import { FontName } from '../../types';
import { IconButton } from '..';
import animateMobileFlavours from './helpers/animateMobileFlavours';
import destroyFlavoursAnimations from './helpers/destroyFlavoursAnimations';
import getFlavoursTimeline from './helpers/getFlavoursTimeline';

import './FlavoursCarousel.scss';

interface FlavoursCarouselProps {
    continent: Continent;
    className?: string;
}

const flavoursId = 'flavours-carousel-flavours';
const flavoursBlockEndId = 'flavours-carousel-flavours-block-end';

const FlavoursCarousel: FC<FlavoursCarouselProps> = ({ continent, className = '' }): ReactElement => {
    const trans = useTrans();
    const { direction } = useLocale();
    const isFontLoaded = useFontLoaded([FontName.appetite]);
    const { isMobile, isTabletPortrait } = useDeviceWidth();

    const flavours = flavourSets[continent];

    const flavoursRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const linkRef = useRef<HTMLAnchorElement>(null);

    const timeline = useRef<gsap.core.Timeline | null>(null);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [timelineStartIndex, setTimelineStartIndex] = useState<number>(0);
    const [debouncedIsMobile, setDebouncedIsMobile] = useState<boolean>(isMobile);
    const [debouncedIsTablePortrait, setDebouncedIsTablePortrait] = useState<boolean>(isTabletPortrait);
    const justifiedActiveIndex = (timelineStartIndex + activeIndex) % flavours.length;
    const activeFlavour = flavours[justifiedActiveIndex];

    const flavourPath = trans(appRoutes[AppRoute.flavourDetail].path);
    const flavourLink = replaceUrlParamKeysWithValues(flavourPath, {
        flavour: trans(`flavours.${activeFlavour}.slug`),
    });

    const scrollFlavours = (index: number): void => {
        if (isMobile && flavoursRef.current) {
            const flavourElement = flavoursRef.current.firstChild as HTMLDivElement;
            const flavourWidth = flavourElement.clientWidth;

            const justifiedWidth = direction === Direction.ltr ? flavourWidth : -flavourWidth;

            flavoursRef.current.scroll({
                left: index * justifiedWidth,
                behavior: 'smooth',
            });
        }
    };

    const hideText = (): void => {
        if (titleRef.current && linkRef.current) {
            gsap.killTweensOf([titleRef.current, linkRef.current]);
            gsap.to([titleRef.current, linkRef.current], {
                duration: 0.1,
                opacity: 0,
                ease: Quart.easeOut,
            });
        }
    };

    const handleButtonClick = (newActiveIndex: number): void => {
        if (isMobile) {
            hideText();
            scrollFlavours(newActiveIndex);

            return;
        }

        setActiveIndex(newActiveIndex);
    };

    const handlePrevButtonClick = (): void => {
        const newIndex = debouncedIsMobile ? activeIndex - 1 : activeIndex + 1;

        handleButtonClick(newIndex);
    };

    const handleNextButtonClick = (): void => {
        const newIndex = debouncedIsMobile ? activeIndex + 1 : activeIndex - 1;

        handleButtonClick(newIndex);
    };

    const handleScroll = (event: MouseEvent<HTMLDivElement>): void => {
        const element = event.target as HTMLDivElement;

        if (element && element.firstChild) {
            const flavourElement = element.firstChild as HTMLDivElement;
            const flavourWidth = flavourElement.clientWidth;

            const scrollPosition = direction === Direction.ltr
                ? element.scrollLeft - flavourWidth / 2
                : element.scrollLeft + flavourWidth / 2;

            const itemIndex = Math.round(scrollPosition / flavourWidth);
            const cappedItemIndex = Math.min(itemIndex, flavours.length - 1);
            const absoluteItemIndex = Math.abs(cappedItemIndex);

            setActiveIndex(absoluteItemIndex);
        }
    };

    const buildFlavoursTimeline = async (): Promise<void> => {
        // Wait 1ms to make sure DOM is ready
        await delay(1);

        const { timeline: newTimeline, startTime } = getFlavoursTimeline(
            flavoursId,
            flavoursBlockEndId,
            direction,
        );

        setActiveIndex(0);
        setTimelineStartIndex(startTime);

        timeline.current = newTimeline;
    };

    useDebounce((): void => {
        setDebouncedIsMobile(isMobile);
    }, 100, [isMobile]);

    useDebounce((): void => {
        setDebouncedIsTablePortrait(isTabletPortrait);
    }, 100, [isTabletPortrait]);

    useDebounce((): void => {
        if (isMobile) {
            animateMobileFlavours(flavoursId, justifiedActiveIndex);
        }

        if (titleRef.current && linkRef.current) {
            gsap.to(titleRef.current, { duration: 0.5, opacity: 1, ease: Quad.easeOut });
            gsap.to(linkRef.current, {
                duration: 0.5,
                opacity: 1,
                ease: Quad.easeOut,
                delay: 0.1,
            });
        }
    }, 200, [justifiedActiveIndex]);

    useEffect((): () => void => {
        if (!isFontLoaded) {
            return noop;
        }

        destroyFlavoursAnimations(flavoursId, timeline.current || undefined);

        if (!isMobile) {
            buildFlavoursTimeline();
        }

        return (): void => {
            destroyFlavoursAnimations(flavoursId, timeline.current || undefined);
        };
    }, [
        isFontLoaded,
        debouncedIsMobile,
        debouncedIsTablePortrait,
        continent,
        direction,
    ]);

    useEffect((): void => {
        if (timeline.current) {
            gsap.to(timeline.current, {
                duration: 0.5,
                time: activeIndex + timelineStartIndex,
                ease: Quart.easeOut,
            });
        }

        hideText();
    }, [activeIndex]);

    return (
        <div className={`flavours-carousel ${className}`}>
            <Wrapper className="flavours-carousel__wrapper">
                <div className="flavours-carousel__flavours-wrapper">
                    <div
                        id={flavoursId}
                        ref={flavoursRef}
                        onScroll={handleScroll}
                        className="flavours-carousel__flavours"
                    >
                        {flavours.map(flavour => (
                            <Picture
                                key={flavour}
                                loading="lazy"
                                src={`/images/flavours/${flavour}.png`}
                                sources={[
                                    {
                                        srcSet: `/images/flavours/${flavour}.avif, /images/flavours/${flavour}-2x.avif 2x`,
                                        type: 'image/avif',
                                    },
                                    {
                                        srcSet: `/images/flavours/${flavour}.webp, /images/flavours/${flavour}-2x.webp 2x`,
                                        type: 'image/webp',
                                    },
                                ]}
                                alt={trans(`flavours.${flavour}.name`)}
                                className="flavours-carousel__flavour"
                                imageClassName="flavours-carousel__flavour-image"
                            />
                        ))}
                    </div>
                </div>

                <div className="flavours-carousel__content-wrapper">
                    {flavours.length > 1 && (
                        <div className="flavours-carousel__buttons-wrapper">
                            <IconButton
                                hideLabel
                                disabled={isMobile && activeIndex === 0}
                                icon={direction === Direction.rtl ? 'chevron-right' : 'chevron-left'}
                                text={trans('common.prev')}
                                onClick={handlePrevButtonClick}
                                className="flavours-carousel__button"
                            />

                            <IconButton
                                hideLabel
                                disabled={isMobile && activeIndex === flavours.length - 1}
                                icon={direction === Direction.rtl ? 'chevron-left' : 'chevron-right'}
                                text={trans('common.next')}
                                onClick={handleNextButtonClick}
                                className="flavours-carousel__button"
                            />
                        </div>
                    )}

                    {activeFlavour && (
                        <>
                            <h2 ref={titleRef} className="flavours-carousel__title">
                                {trans(`flavours.${activeFlavour}.name`)}
                            </h2>

                            <LinkButton
                                ref={linkRef}
                                to={flavourLink}
                                text={trans('common.discover')}
                                className="flavours-carousel__discover-button"
                            />
                        </>
                    )}
                </div>

                <div id={flavoursBlockEndId} className="flavours-carousel__flavours-block-end" />
            </Wrapper>
        </div>
    );
};

export default FlavoursCarousel;
