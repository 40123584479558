import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { LocalizedHelmet } from '../../compositions';
import { ConnectedFaq, ConnectedFaqHelmet } from '../../connectors';
import { PlaygroundSection } from '../../containers';

import './FaqPage.scss';

const FaqPage: FC = (): ReactElement => (
    <Page className="faq-page">
        <LocalizedHelmet pageKey="faq" />
        <ConnectedFaqHelmet />

        <ConnectedFaq className="faq-page__content" />

        <section className="faq-page__playground-section-wrapper">
            <PlaygroundSection
                className="faq-page__playground-section"
            />
        </section>
    </Page>
);

export default FaqPage;
