import { transformToLink } from '../../Link/LinkTransformers';
import { transformToImage } from '../Image/ImageTransformers';
import { PrismicVideo, Video } from './Video';

export const transformToVideo = (
    prismicVideo: PrismicVideo,
): Video => {
    const videoSrc = transformToLink(prismicVideo.video);
    const poster = prismicVideo.video_poster.url
        ? transformToImage(prismicVideo.video_poster)
        : undefined;

    return {
        isFullWidth: prismicVideo.video_full_width,
        videoSrc,
        poster,
    };
};
