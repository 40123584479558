import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LocalizedHelmet, PartnerGrid } from '../../compositions';
import { ConnectedGymLocator } from '../../connectors';
import useTrans from '../../hooks/useTrans';

import './GymLocatorPage.scss';

const GymLocatorPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="gym-locator-page">
            <LocalizedHelmet pageKey="gymLocator" />

            <Wrapper className="gym-locator-page__wrapper">
                <h1 className="gym-locator-page__title">
                    {trans('pages.gymLocator.heading')}
                </h1>
            </Wrapper>

            <div className="gym-locator-page__locator-wrapper">
                <ConnectedGymLocator className="gym-locator-page__locator" />
            </div>

            <PartnerGrid className="gym-locator-page__partner-grid" />
        </Page>
    );
};

export default GymLocatorPage;
