export const fetchCsvData = async (url: string): Promise<string> => {
    try {
        const csvResponse = await fetch(url);

        if (!csvResponse.ok) {
            console.error('[fetchCsvData]: Error fetching CSV data', url);
            return '';
        }

        return await csvResponse.text();
    } catch (error) {
        console.error('[fetchCsvData]', error);
        return '';
    }
};
