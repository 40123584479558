import { FC, ReactElement } from 'react';

import {
    Icon,
    LinkButton,
    Picture,
    Wrapper,
} from '../../../../components';
import { Flavour } from '../../../../constants/flavours';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useTrans from '../../../../hooks/useTrans';
import { NextFlavourBackground } from '..';

import './NextFlavour.scss';

interface NextFlavourProps {
    flavour: Flavour;
    regionFlavours: Flavour[];
    className?: string;
}

const NextFlavour: FC<NextFlavourProps> = ({
    flavour,
    regionFlavours,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const currentFlavourIndex = regionFlavours.findIndex(regionFlavour => regionFlavour === flavour);
    const nextFlavour = regionFlavours[(currentFlavourIndex + 1) % regionFlavours.length];

    const flavourPath = trans(appRoutes[AppRoute.flavourDetail].path);
    const nextFlavourLink = replaceUrlParamKeysWithValues(flavourPath, {
        flavour: trans(`flavours.${nextFlavour}.slug`),
    });

    return (
        <div className={`next-flavour ${className}`}>
            <Wrapper className="next-flavour__wrapper">
                <Picture
                    loading="lazy"
                    src={`/images/flavours/${nextFlavour}.png`}
                    sources={[
                        {
                            srcSet: `/images/flavours/${nextFlavour}.avif, /images/flavours/${nextFlavour}-2x.avif 2x`,
                            type: 'image/avif',
                        },
                        {
                            srcSet: `/images/flavours/${nextFlavour}.webp, /images/flavours/${nextFlavour}-2x.webp 2x`,
                            type: 'image/webp',
                        },
                    ]}
                    alt={trans(`flavours.${nextFlavour}.name`)}
                    className="next-flavour__picture"
                />

                <div className="next-flavour__text-wrapper">
                    <div className="next-flavour__label-wrapper">
                        <p className="next-flavour__label">
                            {trans(`flavours.${nextFlavour}.name`)}
                        </p>

                        <LinkButton
                            to={nextFlavourLink}
                            text={trans('common.discover')}
                            className="next-flavour__link-button"
                        />
                    </div>

                    <div className="next-flavour__icon-wrapper">
                        <Icon name="chevron-right" className="next-flavour__icon" />
                    </div>
                </div>

                <div className="next-flavour__blob-wrapper">
                    <NextFlavourBackground
                        isAnimationEnabled
                        className="next-flavour__blob"
                    />
                </div>
            </Wrapper>
        </div>
    );
};

export default NextFlavour;
