import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Article } from '../../entities/Article/Article';
import { AsyncReduxState } from '../../types';

export type ArticlesState = AsyncReduxState<{
    totalPages: number;
    totalResults: number;
    articles: Article[];
}>;

const initialState: ArticlesState = {
    ...initialAsyncReduxState,
    totalPages: 1,
    totalResults: 0,
    articles: [],
};

const articlesSlice = createSlice({
    name: 'articlesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ArticlesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ArticlesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTotalPages(state, action: PayloadAction<number>): ArticlesState {
            return {
                ...state,
                totalPages: action.payload,
            };
        },
        setTotalResults(state, action: PayloadAction<number>): ArticlesState {
            return {
                ...state,
                totalResults: action.payload,
            };
        },
        setArticles(state, action: PayloadAction<Article[]>): ArticlesState {
            return {
                ...state,
                articles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setTotalPages,
    setTotalResults,
    setArticles,
} = articlesSlice.actions;

export default articlesSlice;
