import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { Link, LinkProps } from 'react-router-dom';

import './LinkButton.scss';

export interface LinkButtonProps extends LinkProps {
    text?: string;
    disabled?: boolean;
}

export type LinkWithRefProps = LinkButtonProps & RefAttributes<HTMLAnchorElement>;

const LinkButton: ForwardRefExoticComponent<LinkWithRefProps> = forwardRef(({
    to,
    text,
    className = '',
    children,
    ...linkButtonProps
}, ref: Ref<HTMLAnchorElement>): ReactElement => (
    <Link
        {...linkButtonProps}
        ref={ref}
        to={to}
        title={text}
        className={`link-button ${className}`}
    >
        {children || text}
    </Link>
));

export default LinkButton;
