/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconReplay: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M92.7,9c0-3-2.5-5.5-5.5-5.5H55.1c-3,0-5.5,2.5-5.5,5.5v32.1c0,3,2.5,5.5,5.5,5.5c3,0,5.5-2.5,5.5-5.5V22.4L71,32.8c5.8,5.8,9,13.6,9,21.8c0,8.2-3.2,16-9,21.8c-12,12-31.6,12-43.6,0c-5.8-5.8-9-13.6-9-21.8c0-8.2,3.2-16,9-21.8c2.2-2.2,2.2-5.6,0-7.8c-2.2-2.2-5.6-2.2-7.8,0C11.6,32.9,7.3,43.4,7.3,54.6c0,11.2,4.4,21.7,12.3,29.6c8.2,8.2,18.9,12.2,29.6,12.2s21.5-4.1,29.6-12.2c7.9-7.9,12.3-18.4,12.3-29.6c0-11.2-4.4-21.7-12.3-29.6L68.4,14.6h18.8C90.2,14.6,92.7,12.1,92.7,9z" />
    </Svg>
);

export default IconReplay;
/* eslint-enable max-len */
