import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../components';
import { Flavour } from '../../../../constants/flavours';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Toast, ToastState } from '../../../../entities/Toast/Toast';
import useTrans from '../../../../hooks/useTrans';
import { AtLeast } from '../../../../types';
import { PersonalityTestResultShare } from '..';

import './PersonalityTestResultCard.scss';

interface PersonalityTestResultCardProps {
    allowedToTrack?: boolean;
    flavour: Flavour;
    onShareFeedback: (state: ToastState, toast: AtLeast<Toast, 'title'>) => void;
    className?: string;
}

const PersonalityTestResultCard: FC<PersonalityTestResultCardProps> = ({
    allowedToTrack,
    flavour,
    onShareFeedback,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`personality-test-result-card ${className}`}>
            <h2 className="personality-test-result-card__title">
                {trans('containers.personalityTestResult.card.title')}

                <span className="personality-test-result-card__title-flavour">
                    {trans(`flavours.${flavour}.name`)}
                </span>
            </h2>

            <p className="personality-test-result-card__description">
                {trans(`flavours.${flavour}.result.cardDescription`)}
            </p>

            <Picture
                loading="lazy"
                src={`/images/flavours/test-result/card/${flavour}.jpg`}
                sources={[
                    {
                        srcSet: [
                            `/images/flavours/test-result/card/${flavour}.avif`,
                            // `/images/flavours/test-result/card/${flavour}-2x.avif 2x`,
                        ].join(', '),
                        type: 'image/avif',
                    },
                    {
                        srcSet: [
                            `/images/flavours/test-result/card/${flavour}.webp`,
                            // `/images/flavours/test-result/card/${flavour}-2x.webp 2x`,
                        ].join(', '),
                        type: 'image/webp',
                    },
                ]}
                alt={trans(`flavours.${flavour}.imageAlts.testResult.card`)}
                className="personality-test-result-card__picture"
            />

            <p className="personality-test-result-card__call-to-action">
                {trans('containers.personalityTestResult.card.callToAction.description')}
                <Link
                    to={trans(appRoutes[AppRoute.personalityTest].path)}
                    className="personality-test-result-card__call-to-action-link"
                >
                    {trans('containers.personalityTestResult.card.callToAction.link')}
                </Link>
            </p>

            <PersonalityTestResultShare
                allowedToTrack={allowedToTrack}
                flavour={flavour}
                onShareFeedback={onShareFeedback}
                className="personality-test-result-card__share"
            />
        </div>
    );
};

export default PersonalityTestResultCard;
