import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LocalizedHelmet } from '../../compositions';
import { UspGrid } from '../../containers';
import useTrans from '../../hooks/useTrans';

import './UspPage.scss';

const UspPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="usp-page">
            <LocalizedHelmet pageKey="usp" />

            <Wrapper className="usp-page__wrapper">
                <h1 className="usp-page__title">
                    {trans('pages.usp.heading')}
                </h1>
            </Wrapper>

            <UspGrid className="usp-page__grid" />
        </Page>
    );
};

export default UspPage;
