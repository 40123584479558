import {
    ButtonHTMLAttributes,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import './Button.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, RefAttributes<HTMLButtonElement> {
    text: string;
    hideLabel?: boolean;
}

const Button: ForwardRefExoticComponent<ButtonProps> = forwardRef(({
    type = 'button',
    text,
    hideLabel,
    className = '',
    children,
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => (
    <button
        {...buttonProps}
        ref={ref}
        type={type} // eslint-disable-line react/button-has-type
        title={hideLabel || children ? text : undefined}
        className={`button ${className}`}
    >
        {!hideLabel && (children || text)}
    </button>
));

export default Button;
