import { FC, ReactElement } from 'react';

import { AppRoute } from '../../constants/routing';
import { PrismicDefaultPageData } from '../../entities/Prismic/Prismic';
import { SliceRenderer } from '..';
import { PrismicPageSkeletons } from './skeletons';
import { PrismicPageHeader, PrismicPageHelmet } from './subcomponents';

import './PrismicPageContent.scss';

interface PrismicPageContentProps extends PrismicDefaultPageData {
    isLoading: boolean;
    route: AppRoute;
    className?: string;
}

const PrismicPageContent: FC<PrismicPageContentProps> = ({
    isLoading,
    route,
    title,
    titleColour,
    intro,
    callToAction,
    poster,
    posterDroplet,
    seo,
    slices,
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <PrismicPageSkeletons className={`prismic-page-content ${className}`} />
        );
    }

    return (
        <article className={`prismic-page-content ${className}`}>
            <PrismicPageHelmet route={route} seo={seo} />

            <PrismicPageHeader
                title={title}
                titleColour={titleColour}
                intro={intro}
                callToAction={callToAction}
                poster={poster}
                posterDroplet={posterDroplet}
                className="prismic-page-content__header"
            />

            <SliceRenderer
                slices={slices}
                className="prismic-page-content__slice-renderer"
            />
        </article>
    );
};

export default PrismicPageContent;
