import { Continent } from './countries';

export enum Flavour {
    berryBoost = 'berryBoost',
    blackcurrant = 'blackcurrant',
    coconutPineapple = 'coconutPineapple',
    multiFruit = 'multiFruit',
    lemon = 'lemon',
    orange = 'orange',
    passionFruit = 'passionFruit',
    peach = 'peach',
}

export const flavoursWithCaffeine = [Flavour.berryBoost];

const defaultFlavours: Flavour[] = [
    Flavour.berryBoost,
    Flavour.coconutPineapple,
    Flavour.lemon,
    Flavour.multiFruit,
    Flavour.orange,
    Flavour.passionFruit,
    Flavour.peach,
];

export const flavourSets: Record<Continent, Flavour[]> = {
    [Continent.northAmerica]: defaultFlavours,
    [Continent.europe]: [
        Flavour.berryBoost,
        Flavour.blackcurrant,
        Flavour.coconutPineapple,
        Flavour.lemon,
        Flavour.multiFruit,
        Flavour.orange,
        Flavour.passionFruit,
        Flavour.peach,
    ],
    [Continent.middleEast]: [
        Flavour.coconutPineapple,
        Flavour.blackcurrant,
        Flavour.lemon,
        Flavour.passionFruit,
        Flavour.orange,
        Flavour.multiFruit,
        Flavour.peach,
    ],
    [Continent.africa]: defaultFlavours,
    [Continent.antarctica]: defaultFlavours,
    [Continent.asia]: defaultFlavours,
    [Continent.oceania]: defaultFlavours,
    [Continent.southAmerica]: defaultFlavours,
};
