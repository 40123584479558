/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './SpotifyPlaylistBackground.scss';

interface SpotifyPlaylistBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const SpotifyPlaylistBackground: FC<SpotifyPlaylistBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'spotify-playlist-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                angle: 30,
                magnitude: 15,
            }}
            preserveAspectRatio="xMinYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1000}
            viewBoxHeight={650}
            className={`spotify-playlist-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="spotify-playlist-background__gradient-start" />
                <stop offset="50%" className="spotify-playlist-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M240.3,593.8c-121,58.5-218.4-18-207.9-205C43,201.8,236.2,49.3,442.2,23.5S947.6,104.8,962.9,317C978.1,529,729.1,528,633.6,528.5C633.6,528.5,361.4,535.2,240.3,593.8z"
                className="spotify-playlist-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default SpotifyPlaylistBackground;
/* eslint-enable max-len */
