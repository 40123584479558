import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultPrismicDefaultPageData, PrismicDefaultPageData } from '../../entities/Prismic/Prismic';
import { AsyncReduxState } from '../../types';

export type PrivacyStatementState = AsyncReduxState<{
    privacyStatementData: PrismicDefaultPageData;
}>;

const initialState: PrivacyStatementState = {
    ...initialAsyncReduxState,
    privacyStatementData: defaultPrismicDefaultPageData,
};

const privacyStatementSlice = createSlice({
    name: 'privacyStatementReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): PrivacyStatementState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): PrivacyStatementState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setPrivacyStatementData(state, action: PayloadAction<PrismicDefaultPageData>): PrivacyStatementState {
            return {
                ...state,
                privacyStatementData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setPrivacyStatementData,
} = privacyStatementSlice.actions;

export default privacyStatementSlice;
