import { PrismicVacancyCallToAction, VacancyCallToAction } from './VacancyCallToAction';

export const transformToVacancyCallToAction = (
    prismicVacancyCallToAction: PrismicVacancyCallToAction,
): VacancyCallToAction => ({
    title: prismicVacancyCallToAction.vacancy_call_to_action_title,
    description: prismicVacancyCallToAction.vacancy_call_to_action_description,
    contactName: prismicVacancyCallToAction.vacancy_call_to_action_contact_name,
    contactPhone: prismicVacancyCallToAction.vacancy_call_to_action_contact_phone,
});
