/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconShare: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={91} viewBoxHeight={100} className={className}>
        <path d="M77.3,100H13.6C6.1,100,0,93.9,0,86.4V45.5c0-7.5,6.1-13.6,13.6-13.6h7.3c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5h-7.3c-2.5,0-4.5,2-4.5,4.5v40.9c0,2.5,2,4.5,4.5,4.5h63.6c2.5,0,4.5-2,4.5-4.5V45.5c0-2.5-2-4.5-4.5-4.5H68c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5h9.3c7.5,0,13.6,6.1,13.6,13.6v40.9C90.9,93.9,84.8,100,77.3,100z" />
        <path d="M64.6,17.2L48.7,1.3c-1.8-1.8-4.7-1.8-6.4,0L26.3,17.2c-1.8,1.8-1.8,4.7,0,6.4c1.8,1.8,4.7,1.8,6.4,0l8.1-8.1v52.7c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5V15.5l8.1,8.1c0.9,0.9,2.1,1.3,3.2,1.3c1.2,0,2.3-0.4,3.2-1.3C66.4,21.9,66.4,19,64.6,17.2z" />
    </Svg>
);

export default IconShare;
/* eslint-enable max-len */
