import { Continent } from '../../constants/countries';
import { Flavour } from '../../constants/flavours';
import { fetchCsvData } from '../../entities/Csv/CsvService';
import { transformToEUNutritionTableRow, transformToUSNutritionTableRow } from '../../entities/NutritionTableRow/NutritionTableRowTransformers';
import trans from '../../helpers/trans';
import { IngredientRegion } from '../../types/ingredient';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setTableData } from './nutrition';

interface NutritionTableConfig {
    ingredientRegion: IngredientRegion;
    flavour: Flavour;
    continent: Continent;
}

export const fetchNutritionTableData = (config: NutritionTableConfig) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { ingredientRegion, flavour, continent } = config;
        const csvResponse = await fetchCsvData(`/nutrition/${ingredientRegion}/${flavour}.csv`);

        if (csvResponse) {
            const rawTableRows = csvResponse
                .split('\n')
                .map(row => row.split(','));

            if (!rawTableRows[0] || !Array.isArray(rawTableRows[0]) || rawTableRows[0].length < 4) {
                console.error('[fetchNutritionTableData] The CSV file does not exist or has an invalid format.');

                dispatch(setError(trans('errors.response.404')));
                dispatch(setTableData([]));

                return;
            }

            const tableRows = continent === Continent.northAmerica
                ? rawTableRows.map(transformToUSNutritionTableRow)
                : rawTableRows.map(transformToEUNutritionTableRow);

            dispatch(setTableData(tableRows));
        }
    } catch (error) {
        console.error(error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
