import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { HorizontalAlignment } from '../../../types';

import './TableCell.scss';

interface TableCellProps {
    isHead?: boolean;
    align?: HorizontalAlignment;
    colSpan?: number;
    contentClassName?: string;
    className?: string;
}

const TableCell: FC<PropsWithChildren<TableCellProps>> = ({
    isHead,
    align,
    colSpan,
    contentClassName = '',
    className = '',
    children,
}): ReactElement => {
    const CellComponent = isHead ? 'th' : 'td';

    const tableCellClassNames = classNames('table-cell', {
        [`table-cell--align-${align}`]: align,
    }, className);

    return (
        <CellComponent colSpan={colSpan} className={tableCellClassNames}>
            <span className={`table-cell__content-wrapper ${contentClassName}`}>
                {children}
            </span>
        </CellComponent>
    );
};

export default TableCell;
