/* eslint-disable max-len */
import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Continent } from '../../constants/countries';
import { useCountry } from '../../context/CountryContext';
import { Svg } from '..';

import './Logo.scss';

interface LogoProps {
    className?: string;
}

const Logo: FC<LogoProps> = ({ className = '' }): ReactElement => {
    const { continent } = useCountry();

    const [showChristmasHat, setShowChristmasHat] = useState<boolean>(false);

    useEffect((): void => {
        const today = new Date();
        const year = today.getFullYear();
        const now = today.getTime();

        const [christmasStart, christmasEnd] = [
            new Date(year, 11, 24),
            new Date(year, 11, 27),
        ].map(date => date.getTime());

        const isChristmas = now > christmasStart && now < christmasEnd;
        const christmasCelebratingContinents = [Continent.northAmerica, Continent.europe];

        if (isChristmas && christmasCelebratingContinents.includes(continent)) {
            setShowChristmasHat(true);
        }
    }, []);

    return (
        <Svg viewBoxWidth={1000} viewBoxHeight={454} className={`logo ${className}`}>
            {/* Yanga */}
            <g>
                <path d="M70.8,349.2c11.8,11,25.4,8.8,39.2,4c3.8-1.3,5.2-3.1,4.8-7.4c-3-28.8-1.4-57.5,3.4-86c9-54.1,26.3-105.2,54.6-152.1c16.7-27.6,36.4-52.3,64.9-68.6c1.4-0.8,2.3-4.1,1.9-5.9c-5.2-28.2-33.2-41.4-58-27.5C168.7,12.8,159,23.4,150.4,35c-20.4,27.3-34.8,58.1-47.4,89.6c-5.8,14.5-11,29.2-16.7,44.4c-0.8-1-1.2-1.2-1.3-1.6C69.3,118.9,60.9,69.2,64,17.8c0.2-3.2-0.2-5-3.4-5.8c-6-1.5-11.8-3.9-17.8-4.6C28.7,5.9,15.5,7.9,7.2,21.5c-6.7,11-8,23.3-6.3,35.6C3,72.3,5.7,87.7,9.8,102.5c12.5,44.6,32.6,85.9,55.8,125.6c2.1,3.5,2.6,6.6,1.7,10.7l-1.1,4.7c-5.2,23.6-10.1,47.2-8.6,71.6C58.4,327.9,61.2,340.2,70.8,349.2" />
                <path d="M713.7,179.3c-2.9,32.8-7.5,65.4-22.8,95.1c-3.2,6.3-7.8,12.3-13.1,17c-9.8,8.7-20.8,6.4-27.6-4.9c-6.1-10.1-7.1-21.6-8.1-33.1c-0.3-3.8,0-7.7,0-11.5c0-23.1,3.3-45.6,14.3-66c4-7.4,9.7-14.3,16-19.8c7.5-6.6,17.1-8,26.1-2.8C708.1,158.8,714.8,167.5,713.7,179.3 M731.9,140.6c-4.7-5.9-8.9-12.3-14.3-17.4c-16.5-15.8-43.1-18.2-66.2-6.9c-23.8,11.7-39.6,31.2-50.7,54.9c-14.5,30.9-18.8,63.6-15.2,97.4c1.8,17.4,6.5,34,16,48.9c16.6,26.1,45.8,35,71.9,21.6c15.5-8,25.4-21.6,32.8-37.1c1.1-2.3,2.2-4.7,3.2-7c0.7,9.2,1.2,17.9,0.6,26.5c-1.1,17.7-3.1,35.4-10.3,51.9c-7,16.1-18.4,26.2-36.4,26.2c-11.9,0-23-3.5-31.4-12.2c-6.4-6.6-11.4-14.6-17.9-23.1c-3.8,2.7-9.1,5.4-13.2,9.5c-14.7,14.8-13.3,38.4,2.6,54.7c14.1,14.3,31.9,19.8,51.1,21.3c28.4,2.3,54.1-3.9,73.5-27c12.4-14.8,18.8-32.6,23.5-51c8.5-32.8,10.5-66.4,12.7-99.9l0.5-6.9c0.4-5.8,0.8-11.6,1.3-17.4c2.1-26.5,4.8-53,8.5-79.4c1.8-12.6,6.4-24.8,9.8-37.5C769.9,118.7,753,121.7,731.9,140.6" />
                <path d="M926,182c-2.4,30-6.9,59.7-19.2,87.4c-3.4,7.7-8.2,15.3-13.9,21.4c-11.3,12-25.6,8.7-32.1-6.7c-5.4-12.8-6.3-26.3-6.3-40c0.3-23.1,3.7-45.6,14.7-66c3.8-7,9.2-13.6,15.1-18.9c7.8-7,17.6-8.7,27-3.2C920.6,161.5,926.9,170.2,926,182 M977.2,264.1c-0.5-35.3,5-69.8,14.5-103.7c2.5-8.9,5.6-17.8,8.3-26.5c-12.4-10.9-36.2-12.1-56.1,9.8c-0.8-1.1-1.3-1.6-1.7-2.3c-17-27.1-41.6-35.8-71.5-24.9c-23.7,8.6-39.9,26-52.1,47.6c-18,32.1-23.2,67-20.4,103.2c1.4,18.3,6.2,35.9,16.2,51.5c17.1,26.8,47.2,35.1,73.7,20c14.6-8.3,24.2-21.5,31.6-36.4c1.1-2.1,2.1-4.3,3.2-6.5c0.2,1.4,0.4,2.8,0.6,4.2l0.2,1.7c0.2,1.1,0.3,2.2,0.5,3.3l0.3,1.6c3.1,18.6,9.2,34.8,31.6,38.9c17.1,3.2,33.6-4.8,39.4-16.4c-2.3-3.4-4.7-6.5-6.7-10C979.2,302.1,977.4,283.1,977.2,264.1" />
                <path d="M563.2,202.4c1.6-16.8,2.4-33.8,1.7-50.7c-0.6-13.8-6.4-26.4-18-34.7c-16-11.5-32.6-9-48.7-0.2c-17.1,9.4-29.5,24.2-40.8,39.9c-3.5,4.8-6.7,9.8-10.5,15.2c0.6-8.4,1.7-16,1.5-23.6c-0.3-15.2-8.1-24.3-22.1-25.9c-11.7-1.4-22.2,3.1-31.9,9.3c-1,0.7-1.1,3.4-1,5.2c0.4,10.4,2.4,21.1,1,31.2c-3.4,24.9-8.8,49.6-12.4,74.5c-2.1,14.5-3.3,29.3-3.1,43.9c0.3,17.8,12.3,28.7,29.8,27.9c7.1-0.3,14.1-3.1,21.2-4.9c4.3-1.1,5.7-3.8,5.5-8.4c-0.8-17.1,1.7-33.8,6-50.3c7.2-27.4,17.9-53.1,36.6-74.5c4.4-5,9.9-9.3,15.7-12.6c6.5-3.7,11.7-0.9,13.5,6.5c1.2,5,1.7,10.5,1.1,15.6c-3.1,24.2-6.8,48.3-10.2,72.4c-2.3,16.8-5.3,33.5-2.9,50.6c1.6,11.9,5,23,15.1,30.4c17,12.5,43.8,8.2,58.1-9.3c-11.1-15.1-13.9-24-14.2-42.8C553.7,258.5,560.4,230.7,563.2,202.4" />
                <path d="M289.3,182c-2.4,30-6.9,59.7-19.2,87.4c-3.4,7.7-8.2,15.3-13.9,21.4c-11.3,12-25.6,8.7-32.1-6.7c-5.4-12.8-6.3-26.3-6.3-40c0.3-23.1,3.7-45.6,14.7-66c3.8-7,9.2-13.6,15.1-18.9c7.8-7,17.6-8.7,27-3.2C283.9,161.5,290.2,170.2,289.3,182 M319.3,345.7c17.1,3.2,33.6-4.8,39.4-16.4c-2.3-3.4-4.7-6.5-6.7-10c-9.5-17.2-11.3-36.2-11.6-55.2c-0.5-35.3,5-69.8,14.5-103.7c2.5-8.9,5.6-17.8,8.3-26.5c-12.4-10.9-36.2-12.1-56.1,9.8c-0.8-1.1-1.3-1.6-1.7-2.3c-17-27.1-41.6-35.8-71.5-24.9c-23.7,8.6-39.9,26-52.1,47.6c-18,32.1-23.2,67-20.4,103.2c1.4,18.3,6.2,35.9,16.2,51.5c17.1,26.8,47.2,35.1,73.7,20c14.6-8.3,24.2-21.5,31.6-36.4c1.1-2.1,2.1-4.3,3.2-6.5c0.2,1.4,0.4,2.8,0.6,4.2l0.2,1.7c0.2,1.1,0.3,2.2,0.5,3.3l0.3,1.6C290.8,325.4,296.9,341.6,319.3,345.7" />
            </g>
            {/* sports */}
            <g>
                <path d="M88.8,405.6c-3-1.6-6.6-2.4-10.9-2.5c-3.8-0.1-7.3,0.8-10.7,2.8c-3.4,2-5.3,4.6-5.7,7.9v0.2c-0.3,2.5,0.4,4.6,1.9,6.4c1.5,1.7,3.5,3.1,6,4c0.5,0.2,1.4,0.5,2.7,0.9c1.3,0.4,2.2,0.8,2.7,1c0.5,0.2,1.1,0.5,1.8,0.9c0.7,0.4,1.2,0.9,1.4,1.3c0.2,0.5,0.3,1,0.3,1.7c-0.3,2-1.6,3-4,3c-1.2,0-2.1-0.4-2.8-1.2c-0.7-0.7-0.9-1.7-0.7-3.1c0-0.3,0.1-0.7,0.2-1.2c0.1-0.5,0.1-0.8,0.1-0.9c0-0.1,0-0.4-0.1-0.7c-0.1-0.3-0.7-0.5-1.7-0.8c-1.1-0.3-2.1-0.4-3.2-0.5c-1.9,0-3.5,0.5-4.9,1.7c-1.4,1.2-2.2,2.7-2.4,4.4c-0.3,2,0.4,3.8,2.1,5.2s3.7,2.4,5.8,3c2.2,0.5,4.4,0.8,6.7,0.8c2.8,0,5.5-0.2,8.1-0.8c2.6-0.6,4.9-1.8,7-3.5c2.1-1.7,3.3-3.8,3.6-6.4c0.6-4.9-2.7-8.4-9.7-10.6c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.2-0.1c-2.6-0.8-4.5-1.6-5.6-2.3c-1.1-0.7-1.6-1.8-1.4-3.2c0.1-0.7,0.5-1.4,1.1-2.1c0.6-0.7,1.5-1,2.4-1c1.2,0,2,0.2,2.5,0.9c0.5,0.6,0.6,1.6,0.5,3c-0.3,1.7-0.4,2.7-0.3,2.8c0.1,0.3,0.6,0.6,1.6,0.9c1,0.3,2,0.5,3.1,0.5c2,0,3.7-0.5,5-1.6c1.3-1.1,2-2.5,2.2-4.2C93.1,409.4,91.8,407.2,88.8,405.6" />
                <path d="M121.3,421.9c-0.3,2.7-1,4.9-2.1,6.6c-1.1,1.7-2.4,2.6-4,2.6c-1.6,0-2.7-0.9-3.4-2.6c-0.7-1.7-0.9-4-0.6-6.6c0.4-2.7,1.1-4.9,2.2-6.6c1.1-1.7,2.4-2.6,4-2.6c1.6,0,2.8,0.9,3.4,2.6C121.5,417.1,121.7,419.3,121.3,421.9 M131,406.1c-1.8-1.9-4.1-2.8-6.7-2.8c-4.4,0-8.3,2.6-11.6,7.8c-0.2-2.4-1-4.3-2.5-5.7c-1.5-1.4-3.4-2.1-5.7-2.1c-1.8,0-3.6,0.5-5.3,1.5c-1.7,1-2.9,2.1-3.4,3.3c-0.2,0.4-0.1,0.7,0.2,0.9c0.3,0.2,0.7,0.5,1.1,0.9c0.4,0.4,0.8,1.3,1,2.6c0.2,1.4,0.2,3.3-0.1,5.9c-0.4,3.1-1.8,14.9-4.2,35.2v0.1L94,454h13.4h0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2l2.4-19.7c1.8,4.3,4.9,6.5,9.2,6.5c4.2,0,7.7-1.8,10.5-5.5c2.8-3.7,4.6-8,5.1-13c0.4-3.1,0.3-6.1-0.4-8.8C134.1,410.3,132.9,408,131,406.1" />
                <path d="M163.6,422.2c-0.4,3.2-1.2,5.8-2.3,7.7c-1.1,1.9-2.7,2.8-4.5,2.7c-1.8-0.1-3.1-1.3-3.8-3.4c-0.7-2.1-0.9-4.7-0.5-7.8c0.4-3.1,1.2-5.7,2.3-7.6c1.2-2,2.7-2.9,4.6-2.7c1.8,0.1,3.1,1.2,3.8,3.3C163.9,416.4,164,419,163.6,422.2 M160.3,403.4c-5.3-0.4-10,1.1-14.1,4.4c-4.1,3.3-6.4,7.5-7,12.6c-0.6,5.1,0.6,9.6,3.8,13.4s7.4,5.9,12.8,6.3c5.5,0.4,10.2-1.1,14.1-4.4c4-3.3,6.3-7.5,6.9-12.6c0.6-5.1-0.6-9.6-3.8-13.4C170,405.9,165.7,403.8,160.3,403.4" />
                <path d="M205,403.3c-1.7,0-3.3,0.7-5.1,2c-1.7,1.4-3.2,3.2-4.3,5.4c-0.2-2.3-1-4.1-2.5-5.4c-1.5-1.3-3.3-2-5.6-2c-1.8,0-3.6,0.5-5.3,1.5c-1.7,1-2.9,2.1-3.4,3.3c-0.2,0.4-0.1,0.7,0.2,0.9c0.3,0.2,0.8,0.5,1.3,0.9c0.5,0.4,0.9,1.3,1.2,2.6c0.3,1.4,0.3,3.3,0,5.9c-0.4,3.1-1.3,9.8-3,20.1v0.1l0.2,0.2h13.5c0.2,0,0.3-0.2,0.3-0.3l2.1-18c0.3-2.4,0.7-4.3,1.2-5.7c0.5-1.3,1.2-2,2-2c1.2,0,1.6,1,1.3,3c0,0.4-0.1,0.7-0.2,1.2c-0.1,0.4-0.2,0.7-0.2,0.8c0,0.1,0,0.3,0,0.5c0.1,0.3,0.5,0.6,1.2,0.9c0.7,0.3,1.3,0.5,2,0.5c2.4,0,4.5-0.8,6.3-2.3c1.8-1.6,2.9-3.7,3.2-6.4c0.3-2.4-0.1-4.3-1.4-5.6C208.9,404,207.2,403.3,205,403.3" />
                <path d="M239.7,404.5h-8.2c0.5-3.9,0.9-7.5,1.2-10.8c0,0,0-0.1,0-0.1l-0.2-0.2l-18,11.2c-0.1,0-0.2,0.1-0.3,0.3l-0.7,6c0,0.2,0.1,0.3,0.3,0.3h3.4l-2.3,19c-0.4,3.9,0.7,6.8,3.5,8.6c1.5,0.9,3.4,1.5,5.8,1.6c2.5,0,4.9-0.6,7.3-1.7c2.4-1.2,4.1-2.6,5.2-4.5c0.3-0.5,0.4-0.9,0.3-1.2c-0.1-0.4-0.4-0.5-1-0.5c-0.2,0-0.7,0-1.7,0.1c-0.9,0.1-1.6,0.1-2,0c-0.4-0.1-0.9-0.2-1.5-0.5c-0.7-0.2-1.1-0.7-1.4-1.3c-0.3-0.7-0.5-1.5-0.5-2.6c0-0.4,0-1.1,0.1-2.2l0.1-1.1l1.7-13.7h8.2c0.2,0,0.3-0.2,0.3-0.3l0.6-5.9L239.7,404.5z" />
                <path d="M269.7,405.6c-3-1.6-6.6-2.4-10.9-2.5c-3.8-0.1-7.3,0.8-10.7,2.8c-3.4,2-5.3,4.6-5.7,7.9v0.2c-0.3,2.5,0.4,4.6,1.9,6.4c1.5,1.7,3.5,3.1,6,4c0.5,0.2,1.4,0.5,2.7,0.9s2.2,0.8,2.7,1c0.5,0.2,1.1,0.5,1.8,0.9c0.7,0.4,1.2,0.9,1.4,1.3c0.2,0.5,0.3,1,0.3,1.7c-0.3,2-1.6,3-4,3c-1.2,0-2.1-0.4-2.8-1.2c-0.7-0.7-0.9-1.7-0.7-3.1c0-0.3,0.1-0.7,0.2-1.2c0.1-0.5,0.1-0.8,0.1-0.9c0-0.1,0-0.4-0.1-0.7c-0.1-0.3-0.7-0.5-1.7-0.8c-1.1-0.3-2.1-0.4-3.2-0.5c-1.9,0-3.5,0.5-4.9,1.7c-1.4,1.2-2.2,2.7-2.4,4.4c-0.3,2,0.4,3.8,2.1,5.2c1.7,1.4,3.7,2.4,5.8,3c2.2,0.5,4.4,0.8,6.7,0.8c2.8,0,5.5-0.2,8.1-0.8c2.6-0.6,4.9-1.8,7-3.5c2.1-1.7,3.3-3.8,3.6-6.4c0.6-4.9-2.7-8.4-9.7-10.6c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.1c-2.6-0.8-4.5-1.6-5.6-2.3c-1.1-0.7-1.6-1.8-1.4-3.2c0.1-0.7,0.5-1.4,1.1-2.1c0.6-0.7,1.5-1,2.4-1c1.2,0,2,0.2,2.5,0.9c0.5,0.6,0.6,1.6,0.5,3c-0.3,1.7-0.4,2.7-0.3,2.8c0.1,0.3,0.6,0.6,1.6,0.9c1,0.3,2,0.5,3.1,0.5c2,0,3.7-0.5,5-1.6c1.3-1.1,2-2.5,2.2-4.2C273.9,409.4,272.6,407.2,269.7,405.6" />
            </g>
            {/* water */}
            <g>
                <path d="M338.6,403.1c-2.2,0-4.2,0.6-6,1.8c-1.8,1.2-2.7,2.2-2.8,3c0,0.2,0.2,0.6,0.6,1.1c0.5,0.5,1,1.1,1.6,1.9c0.6,0.7,1.1,2,1.5,3.9c0.4,1.9,0.6,4.2,0.4,6.9c-0.1,3.6-0.9,6.3-2.4,8.1c-1.5,1.8-3,2.7-4.7,2.7c-1.1,0-1.7-0.5-2-1.6c-0.3-1.1-0.2-2.7,0.1-4.9l2.5-20.9v-0.1l-0.2-0.3h-13l-0.3,0.3l-2.6,21c-0.3,2.2-0.8,3.8-1.7,4.9c-0.8,1.1-1.8,1.6-2.8,1.6c-2.3,0-3.3-1.8-3-5.5l2.6-22c0-0.1,0-0.2-0.1-0.3l-0.2-0.1h-13.1l-0.3,0.3l-2.6,23.3c0.2,3.6,1.5,6.5,3.8,8.7c2.3,2.2,5.1,3.3,8.5,3.3c4.5,0,8.2-1.7,11.1-5c2.6,3.4,6.6,5,12,5h0.4c3,0,5.6-0.6,8-1.7c2.4-1.1,4.3-2.5,5.8-4.1s2.7-3.4,3.8-5.5c1.1-2.1,1.8-3.9,2.2-5.5c0.4-1.6,0.8-3.2,0.9-4.6c0.7-5.4,0.2-9.4-1.5-12C343.8,404.4,341.5,403.1,338.6,403.1" />
                <path d="M374.7,421.2c-0.3,2.7-1,4.9-2.1,6.6c-1.1,1.7-2.5,2.6-4.1,2.6c-1.6,0-2.7-0.9-3.4-2.6c-0.7-1.7-0.9-3.9-0.5-6.6c0.3-2.7,1-4.9,2.1-6.6c1.1-1.7,2.4-2.6,4-2.6c1.6,0,2.7,0.9,3.4,2.6C374.8,416.3,375,418.5,374.7,421.2M390.3,404.5c0-0.2-0.1-0.3-0.2-0.3h-13.4l-0.1,0.1c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2l-0.6,4.7c-1.8-4.3-4.9-6.5-9.2-6.5c-4.2,0-7.7,1.7-10.6,5.1c-2.8,3.4-4.6,7.6-5.1,12.5c-0.4,3.1-0.3,6.1,0.4,9c0.6,2.9,1.9,5.4,3.7,7.3c1.8,2,4.1,3,6.8,3c4.4,0,8.3-2.6,11.6-7.7c0.1,2.4,1,4.3,2.4,5.7c1.5,1.4,3.4,2.1,5.7,2.1c1.8,0,3.6-0.5,5.3-1.5c1.7-1,2.9-2.1,3.4-3.3c0.1-0.3,0.1-0.6-0.1-0.7c-0.2-0.2-0.5-0.4-0.9-0.6c-0.4-0.2-0.8-0.6-1.1-1.2c-0.3-0.6-0.6-1.5-0.8-2.7c-0.2-1.2-0.1-2.9,0.1-5.1C387.7,421.6,388.7,414.8,390.3,404.5" />
                <path d="M411.4,404.5c0.5-3.9,0.9-7.5,1.2-10.8c0,0,0-0.1,0-0.1l-0.2-0.2l-18,11.2c-0.1,0-0.2,0.1-0.3,0.3l-0.7,6c0,0.2,0.1,0.3,0.3,0.3h3.4l-2.3,19c-0.4,3.9,0.7,6.8,3.5,8.6c1.5,0.9,3.4,1.5,5.8,1.6c2.5,0,4.9-0.6,7.3-1.7c2.4-1.2,4.1-2.6,5.2-4.5c0.3-0.5,0.4-0.9,0.3-1.2c-0.1-0.4-0.4-0.5-1-0.5c-0.2,0-0.7,0-1.7,0.1c-0.9,0.1-1.6,0.1-2,0c-0.4-0.1-0.9-0.2-1.5-0.5c-0.7-0.2-1.1-0.7-1.4-1.3c-0.3-0.7-0.5-1.5-0.5-2.6c0-0.4,0-1.1,0.1-2.2l0.1-1.1l1.7-13.7h8.2c0.2,0,0.3-0.2,0.3-0.3l0.6-5.9l-0.1-0.3H411.4z" />
                <path d="M433.8,419.5c0.8-4.1,2.3-6.8,4.4-8c0.5-0.3,1.1-0.5,1.7-0.6h0.2c0,0,0.1,0,0.1-0.1c1,0,1.8,0.2,2.5,0.9c0.6,0.6,0.8,1.4,0.7,2.4c-0.2,1.9-1.2,3.3-2.8,4.3C438.9,419.4,436.7,419.7,433.8,419.5 M440.6,425.9c2.5-0.2,4.8-0.6,6.9-1.2c2.1-0.6,4-1.7,5.6-3.4c1.6-1.7,2.5-3.8,2.9-6.4c0.3-2.9-0.7-5.5-3.2-8.1c-2.4-2.5-6.2-3.8-11.2-3.8c-5.6,0-10.5,1.8-14.4,5.4c-4,3.6-6.3,8-6.9,13.1c-0.6,5.1,0.6,9.5,3.7,13.1c3.1,3.6,7.2,5.4,12.3,5.4c4.3,0,8.1-1.3,11.3-3.9c3.3-2.6,5.3-4.9,6.1-7c0.2-0.5,0.2-0.8-0.1-1.1c-0.3-0.2-0.6-0.2-0.9,0.1c-4.4,2.4-8.5,3.4-12.3,2.9c-3.8-0.5-6.1-2.4-6.8-5.7C435.8,425.9,438.1,426.1,440.6,425.9" />
                <path d="M490.8,411c0.3-2.4-0.1-4.3-1.4-5.6c-1.2-1.3-2.9-2-5.1-2c-1.7,0-3.3,0.7-5.1,2c-1.7,1.4-3.2,3.2-4.3,5.4c-0.2-2.3-1-4.1-2.5-5.4s-3.3-2-5.6-2c-1.8,0-3.6,0.5-5.3,1.5c-1.7,1-2.9,2.1-3.4,3.3c-0.2,0.4-0.1,0.7,0.2,0.9s0.8,0.5,1.3,0.9c0.5,0.4,0.9,1.3,1.2,2.6c0.3,1.4,0.3,3.3,0,5.9c-0.4,3.1-1.3,9.8-3,20.1v0.1l0.2,0.2h13.5c0.2,0,0.3-0.2,0.3-0.3l2.1-18c0.3-2.4,0.7-4.3,1.2-5.7c0.5-1.3,1.2-2,2-2c1.2,0,1.6,1,1.3,3c0,0.4-0.1,0.7-0.2,1.2c-0.1,0.4-0.2,0.7-0.2,0.8c0,0.1,0,0.3,0,0.5c0.1,0.3,0.5,0.6,1.2,0.9c0.7,0.3,1.3,0.5,2,0.5c2.4,0,4.5-0.8,6.3-2.3C489.4,415.8,490.5,413.7,490.8,411" />
                <path d="M493.3,426.8c-1.8,0-3.5,0.7-4.9,2c-1.5,1.3-2.3,2.9-2.5,4.7c-0.2,1.9,0.2,3.4,1.3,4.7c1.1,1.3,2.6,1.9,4.4,1.9c1.8,0,3.5-0.7,4.9-2c1.5-1.3,2.3-2.9,2.5-4.7c0.2-1.9-0.2-3.4-1.4-4.7C496.5,427.4,495,426.8,493.3,426.8" />
            </g>

            {/* Christmas hat */}
            {showChristmasHat && (
                <g className="logo__christmas-hat">
                    <path className="logo__christmas-hat-shade" d="M710.2,175.3c29.1,8.3,49.9,34.9,49.9,59.7s17.4-2.1,17.4-2.1v-46.1L710.2,175.3z" />
                    <path className="logo__christmas-hat-fabric" d="M608.9,100c3.6-12.2,15.3-36.3,35.4-56.1s46-25.8,55.1-25.8s14.9,12.3,20,14.8c5,2.5,12.8,3.6,23.7,2.3c11-1.3,17.2,4.1,32.1,18.5s24.4,37.7,28,53.2c3.6,15.5,6.9,42.1,3.3,61.1s-12.8,61.9-25.5,79.8s-18.7-3.1-18.7-3.1s1.8-37.9-1.9-72.4S608.9,100,608.9,100z" />
                    <path className="logo__christmas-hat-fabric-shade" d="M730.1,132.2c1.3-13.5-0.8-29.7-10-47.5s-11-20.7-11-20.7s16.9,15.1,25.7,30.4s12.7,21.3,17,37.6S730.1,132.2,730.1,132.2z" />
                    <path className="logo__christmas-hat-fabric-shade" d="M780,231.4c6.2-15.2,16.6-49.3,16.8-68.2s-1-48-10.6-70.7c-8.7-20.5-13.3-27.7-32.5-41.1c-13.6-9.5-23.3-13-23.3-13s14.4-1.8,19.6-0.9s15.3,6.5,26.9,19.9c11.7,13.4,16.7,26.5,21.2,38.7s7.4,29,8.4,40.6s0,26.8-1.2,33.4c-1.2,6.7-3.5,18.2-5.9,27.1s-12.3,38.1-12.3,38.1L780,231.4z" />
                    <path className="logo__christmas-hat-fabric-highlight" d="M650.2,102.8c1.1-14,10.1-39.4,19.1-51.3c9-11.8,19.6-21.4,24.9-24.5c5.3-3.2,6.7-4.6,5.3-6.8c-1.3-2.2-24.4,3-38.3,13.8s-25.6,21.4-34.3,34.4s-14.8,29.2-14.8,29.2L650.2,102.8z" />
                    <path className="logo__christmas-hat-fabric-highlight" d="M766.9,176.1c0.6,10.8,1.1,28.9,0.9,34.3c-0.2,5.3-0.4,8-1.3,8.3c-0.9,0.2-1.9-6.9-2-8.7c-0.1-1.9,0.8-0.7-2.7-39.6c-3.5-38.9-20-67.8-23.4-73.3c-3.4-5.4-14.4-20.5-16.9-23.5c-2.5-3.1-2.8-4.1-1.9-4.9c0.9-0.8,3.3,1.9,4.6,3.4c1.3,1.5,10.2,10.9,17.7,24.1s12.4,21.4,16,33.6C761.5,141.7,766.3,164.8,766.9,176.1z" />
                    <path className="logo__christmas-hat-fluff-shade-dark" d="M750.5,127c0,0-39.1-15.6-76.3-25s-55-9.9-60.7-8.6c-5.7,1.3-11.9,3.5-17,22.7c-5.1,19.2-6.9,27.9-0.5,32.9c6.4,5,7.7,5.9,27.5,8.1c19.8,2.2,72.4,15,86.8,18.3c14.4,3.3,51.9,15.6,51.9,15.6s-1.7-26.3-4.6-37.5S750.5,127,750.5,127z" />
                    <path className="logo__christmas-hat-fluff-shade" d="M746.2,127.2c0,0-37.7-14.6-73.6-23.4s-53.1-9.3-58.5-8.1c-5.5,1.2-11.4,3.3-16.4,21.2s-6.7,26-0.5,30.7s7.5,5.5,26.5,7.6c19.1,2.1,69.8,14,83.7,17.2c13.9,3.1,50,14.6,50,14.6s-1.7-24.6-4.5-35C750.2,141.4,746.2,127.2,746.2,127.2z" />
                    <path className="logo__christmas-hat-fluff" d="M742.8,127c0,0-36.5-13.3-71.2-21.3c-34.7-8-51.3-8.5-56.6-7.4c-5.3,1.1-11,2.9-15.8,19s-6.4,23.4-0.4,27.6s7.2,5,25.6,6.9c18.4,1.9,67.5,12.8,81,15.7c13.5,2.8,48.4,13.3,48.4,13.3s-1.7-22.2-4.4-31.6S742.8,127,742.8,127z" />

                    <circle className="logo__christmas-hat-fluff" cx="775.4" cy="257.2" r="30" />
                    <path className="logo__christmas-hat-fluff-shade" d="M776.5,227.3c-2.1-0.1-4.3,0.1-6.3,0.4c9.4,2.7,16.1,11.6,15.8,21.8c-0.4,12.1-10.6,21.5-22.6,21.1c-7.5-0.3-14-4.3-17.7-10.1c1.6,14.6,13.6,26.2,28.7,26.7c16.5,0.6,30.4-12.3,31-28.9C806,241.7,793.1,227.9,776.5,227.3z" />
                    <path className="logo__christmas-hat-fluff-shade-dark" d="M776.5,227.3c-0.7,0-1.5,0-2.2,0c13.2,1.5,23.3,13,22.8,26.5c-0.5,14.2-12.4,25.3-26.7,24.8c-13.5-0.5-24.3-11.3-24.8-24.6c-0.1,0.7-0.1,1.4-0.2,2.2c-0.6,16.5,12.3,30.4,28.9,31c16.5,0.6,30.4-12.3,31-28.9C806,241.7,793.1,227.9,776.5,227.3z" />
                </g>
            )}
        </Svg>
    );
};

export default Logo;
/* eslint-enable max-len */
