import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useWindowSize } from 'react-use';

import { Svg } from '../../components';
import { Direction, Language, LocaleData } from '../../constants/locale';
import WaveHelper from '../../helpers/waveHelper';
import { useFontLoaded } from '../../hooks/useFontLoaded';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';
import { FontName } from '../../types';

import './FluidTitle.scss';

interface FluidTitleProps {
    isAnimationEnabled?: boolean;
    animationRatio: number;
    locale: LocaleData;
    title: string[];
    className?: string;
}

const viewBoxWidth: Record<Language, number> = {
    [Language.ar]: 300,
    [Language.de]: 1410,
    [Language.enGb]: 460,
    [Language.enUs]: 460,
    [Language.es]: 1080,
    [Language.fr]: 980,
    [Language.nl]: 820,
};

const FluidTitle: FC<FluidTitleProps> = ({
    isAnimationEnabled,
    animationRatio,
    locale,
    title,
    className = '',
}): ReactElement => {
    const { width: windowWidth } = useWindowSize();
    const prefersReducedMotion = usePrefersReducedMotion();
    const isFontLoaded = useFontLoaded([FontName.appetite]);
    const svgRef = useRef<SVGSVGElement>(null);
    const svgTitleRef = useRef<SVGGElement>(null);
    const maskRef = useRef<SVGPathElement>(null);
    const htmlTitleRef = useRef<HTMLHeadingElement>(null);

    const [waveHelperInstance, setWaveHelperInstance] = useState<WaveHelper | undefined>();

    const { language, direction } = locale;
    const isRtl = direction === Direction.rtl;
    const svgTextX = isRtl ? viewBoxWidth[language] : 0;
    const preserveAspectRatioX = isRtl ? 'xMax' : 'xMin';

    const htmlTitle = useMemo(() => title.map((text, index) => (
        <span key={text}>
            {text}
            {index !== title.length && <br />}
        </span>
    )), [title]);

    const svgText = useMemo(() => title.map((text, index) => (
        <text
            key={text}
            x={svgTextX}
            y="110"
            dy={`${index * 50}%`}
            className="fluid-title__svg-title"
        >
            {text}
        </text>
    )), [title, svgTextX]);

    const svgTextWidths = useMemo((): number[] => {
        if (svgTitleRef.current) {
            const { children } = svgTitleRef.current;
            return Array.from(children).map(text => text.getBoundingClientRect().width);
        }

        return [];
    }, [svgTitleRef, waveHelperInstance, windowWidth]);

    useEffect((): () => void => {
        if (maskRef.current && svgRef.current && isFontLoaded) {
            if (waveHelperInstance) {
                waveHelperInstance.destroy();
            }

            const helper = new WaveHelper({
                container: svgRef.current,
                sourceElement: maskRef.current,
                height: 50,
                heightOffset: animationRatio,
                bones: 3,
                amplitude: 60,
                speed: 0.2,
                timeOffset: 3,
            });

            setWaveHelperInstance(helper);
        }

        return (): void => {
            if (waveHelperInstance) {
                waveHelperInstance.destroy();
            }
        };
    }, [
        maskRef,
        svgRef,
        language,
        isFontLoaded,
    ]);

    useEffect((): void => {
        if (waveHelperInstance && !prefersReducedMotion) {
            waveHelperInstance.play();
        }
    }, [waveHelperInstance]);

    useEffect((): void => {
        if (waveHelperInstance) {
            waveHelperInstance.setHeightOffset(animationRatio);
        }
    }, [animationRatio]);

    useEffect((): void => {
        if (isAnimationEnabled && !prefersReducedMotion) {
            waveHelperInstance?.play();

            return;
        }

        waveHelperInstance?.pause();
    }, [isAnimationEnabled]);

    return (
        <div
            ref={htmlTitleRef}
            className={`fluid-title ${className}`}
        >
            <h1 className="fluid-title__html-title">
                {htmlTitle}
            </h1>

            <div className="fluid-title__shadow">
                {title.map((text, index) => (
                    <div
                        key={text}
                        style={{ width: `${svgTextWidths[index] || 0}px` }}
                        className="fluid-title__shadow-line"
                    />
                ))}
            </div>

            <Svg
                ref={svgRef}
                preserveAspectRatio={`${preserveAspectRatioX}YMid meet`}
                viewBoxWidth={viewBoxWidth[language]}
                viewBoxHeight={288}
                className="fluid-title__svg"
            >
                <rect
                    width="100%"
                    height="100%"
                    className="fluid-title__svg-bounding-box"
                />
                <mask id="fluid-title-wave-mask">
                    <path
                        fill="white"
                        ref={maskRef}
                        className="fluid-title__svg-mask"
                    />
                </mask>
                <g ref={svgTitleRef}>
                    {svgText}
                </g>
                <g
                    mask="url(#fluid-title-wave-mask)"
                    className="fluid-title__svg-masked-text"
                >
                    {svgText}
                </g>
            </Svg>
        </div>
    );
};

export default FluidTitle;
