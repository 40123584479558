/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={8} viewBoxHeight={14} className={className}>
        <path d="M.293.293A1 1 0 0 1 1.613.21l.094.083 6 6a1 1 0 0 1 .083 1.32l-.083.094-6 6a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .293 1.707A1 1 0 0 1 .21.387L.293.293z" />
    </Svg>
);

export default IconChevronRight;
/* eslint-enable max-len */
