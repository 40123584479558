import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { Article } from '../../entities/Article/Article';
import useTrans from '../../hooks/useTrans';
import { RelatedArticleItem } from './subcomponents';

import './RelatedArticles.scss';

interface RelatedArticlesProps {
    relatedArticles: Article[];
    className?: string;
}

const RelatedArticles: FC<RelatedArticlesProps> = ({
    relatedArticles,
    className = '',
}): ReactElement | null => {
    const trans = useTrans();

    if (relatedArticles.length === 0) {
        return null;
    }

    return (
        <div className={`related-articles ${className}`}>
            <Wrapper className="related-articles__wrapper">
                <h2 className="related-articles__title">
                    {trans('containers.relatedArticles.title')}
                </h2>

                <ul className="related-articles__list">
                    {relatedArticles.map(article => (
                        <RelatedArticleItem
                            key={article.slug}
                            article={article}
                            className="related-articles__item"
                        />
                    ))}
                </ul>
            </Wrapper>
        </div>
    );
};

export default RelatedArticles;
