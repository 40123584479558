import { defaultLocale, Language } from '../../constants/locale';
import { Image, PrismicImage } from '../@slices/Image/Image';
import { PrismicSlice, PrismicText } from '../Prismic/Prismic';

export interface PrismicFaqItem {
    question: string;
    answer: PrismicText;
    image: PrismicImage;
}

export type PrismicFaqSlice = PrismicSlice<unknown, PrismicFaqItem>;

export interface PrismicFaqTemplate {
    title: string;
    intro: PrismicText;
    body: PrismicFaqSlice[];
}

export interface FaqItem {
    question: string;
    answer: string;
    image: Image;
}

export interface FaqData {
    language: Language;
    title: string;
    intro: string;
    faqItems: FaqItem[];
    slices: PrismicSlice[];
}

export const defaultFaqData: FaqData = {
    language: defaultLocale.language,
    title: '',
    intro: '',
    faqItems: [],
    slices: [],
};
