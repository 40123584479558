import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { Vacancy } from '../../entities/Vacancy/Vacancy';
import { AsyncReduxState } from '../../types';

export type VacanciesState = AsyncReduxState<{
    totalPages: number;
    totalResults: number;
    vacancies: Vacancy[];
}>;

const initialState: VacanciesState = {
    ...initialAsyncReduxState,
    totalPages: 1,
    totalResults: 0,
    vacancies: [],
};

const vacanciesSlice = createSlice({
    name: 'vacanciesReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacanciesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacanciesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTotalPages(state, action: PayloadAction<number>): VacanciesState {
            return {
                ...state,
                totalPages: action.payload,
            };
        },
        setTotalResults(state, action: PayloadAction<number>): VacanciesState {
            return {
                ...state,
                totalResults: action.payload,
            };
        },
        setVacancies(state, action: PayloadAction<Vacancy[]>): VacanciesState {
            return {
                ...state,
                vacancies: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setTotalPages,
    setTotalResults,
    setVacancies,
} = vacanciesSlice.actions;

export default vacanciesSlice;
