import {
    FC,
    ReactElement,
    RefAttributes,
    RefObject,
    SVGAttributes,
    useEffect,
    useRef,
} from 'react';

import { noop } from 'react-use/lib/misc/util';

import { Svg } from '../../components';
import { gsap } from '../../entities/Gsap/GsapService';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';
import blobWaveHelper, { BlobWaveHelperOptions } from './helpers/blobWaveHelper';

import './BackgroundMorphSvg.scss';

interface BackgroundMorphSvgProps extends SVGAttributes<SVGSVGElement>, RefAttributes<SVGSVGElement> {
    isAnimationEnabled?: boolean;
    options?: Partial<BlobWaveHelperOptions>;
    pathRefObject: RefObject<SVGPathElement>;
    viewBoxWidth?: number;
    viewBoxHeight?: number;
    className?: string;
}

const BackgroundMorphSvg: FC<BackgroundMorphSvgProps> = ({
    isAnimationEnabled,
    options,
    pathRefObject,
    viewBoxWidth,
    viewBoxHeight,
    children,
    className = '',
    ...svgProps
}): ReactElement => {
    const prefersReducedMotion = usePrefersReducedMotion();

    const timeline = useRef<gsap.core.Timeline | null>(null);

    useEffect((): () => void => {
        timeline.current = gsap.timeline({ repeat: -1 });

        return (): void => {
            timeline.current?.kill();
        };
    }, []);

    useEffect((): () => void => {
        if (!timeline.current) {
            return noop;
        }

        if (pathRefObject.current) {
            const waveTimeline = blobWaveHelper(pathRefObject.current, {
                loose: true,
                angle: -10,
                length: 125,
                magnitude: 25,
                phase: 20,
                duration: 5,
                start: 1,
                end: 11,
                repeat: 10,
                ...options,
            });

            timeline.current.add(waveTimeline);
        }

        return (): void => {
            timeline.current?.kill();
        };
    }, [pathRefObject]);

    useEffect((): void => {
        if (isAnimationEnabled && !prefersReducedMotion) {
            timeline.current?.play();

            return;
        }

        timeline.current?.pause();
    }, [isAnimationEnabled, prefersReducedMotion]);

    return (
        <Svg
            {...svgProps}
            viewBoxWidth={viewBoxWidth}
            viewBoxHeight={viewBoxHeight}
            className={`background-morph-svg ${className}`}
        >
            {children}
        </Svg>
    );
};

export default BackgroundMorphSvg;
