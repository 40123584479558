import { IconName } from '../../components/Icon/Icon';
import { Language } from '../../constants/locale';
import { Image, PrismicImage } from '../@slices/Image/Image';
import { DateIsoString } from '../Date/Date';
import { CallToAction, PrismicLink } from '../Link/Link';
import { PrismicSlice } from '../Prismic/Prismic';
import { PrismicSeo, Seo } from '../Seo/Seo';

export enum PrismicVacancyIcon {
    design = 'design',
    engineering = 'engineering',
    finance = 'finance',
    marketing = 'marketing',
    sales = 'sales',
    service = 'service',
    softwareDevelopment = 'software-development',
    warehouseLogics = 'warehouse-and-logistics',
}

export enum PrismicVacancyEmploymentType {
    fullTime = 'Full time',
    partTime = 'Part time',
    contractor = 'Contractor',
    temporary = 'Temporary',
    volunteer = 'Volunteer',
    internship = 'Internship',
}

export enum PrismicVacancyWorkRemote {
    notRemote = 'Not remote',
    partlyRemote = 'Partly remote',
    fullyRemote = 'Fully remote',
}

export interface PrismicVacancyAddress {
    street: string | null;
    house_number: string | null;
    postal_code: string | null;
    city: string | null;
    region: string | null;
    country: string | null;
}

export enum PrismicVacancyCompensationType {
    hour = 'Hour',
    day = 'Day',
    week = 'Week',
    month = 'Month',
    year = 'Year',
}

export interface PrismicVacancyCompensation {
    minimum_value: number | null;
    maximum_value: number | null;
    salary_type: PrismicVacancyCompensationType | null;
}

export interface PrismicVacancyTemplate {
    title?: string;
    description?: string;
    icon?: PrismicVacancyIcon;
    expiration_date?: DateIsoString;
    education_level: string | null;
    employment_type?: PrismicVacancyEmploymentType;
    hours_per_week: number | null;
    work_remote?: PrismicVacancyWorkRemote;
    address: PrismicVacancyAddress[];
    compensation: PrismicVacancyCompensation[];
    apply_link: PrismicLink;
    apply_label: string | null;
    image: PrismicImage | Record<string, never>;
    seo: PrismicSeo[];
    body: PrismicSlice[];
}

export enum MetaVacancyEmploymentType {
    fullTime = 'FULL_TIME',
    partTime = 'PART_TIME',
    contractor = 'CONTRACTOR',
    temporary = 'TEMPORARY',
    volunteer = 'VOLUNTEER',
    internship = 'INTERN',
}

export enum MetaVacancyWorkRemote {
    notRemote = 'NO_TELECOMMUTE',
    partlyRemote = 'PARTLY_TELECOMMUTE',
    fullyRemote = 'TELECOMMUTE',
}

export enum MetaVacancyCompensationType {
    hour = 'HOUR',
    day = 'DAY',
    week = 'WEEK',
    month = 'MONTH',
    year = 'YEAR',
}

export interface VacancyOverviewInfo {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalResults: number;
}

export interface VacancyAddress {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    region: string;
    country: string;
}

export interface VacancyCompensation {
    type?: MetaVacancyCompensationType;
    min?: number;
    max?: number;
}

interface AlternativeLanguage {
    language: Language;
    slug: string;
}

export interface Vacancy {
    id: string;
    slug: string;
    title: string;
    description: string;
    icon?: IconName;
    createdAt?: DateIsoString;
    expirationDate?: DateIsoString;
    educationLevel?: string;
    employmentType?: PrismicVacancyEmploymentType;
    hoursPerWeek?: number;
    workRemote?: MetaVacancyWorkRemote;
    address: VacancyAddress;
    compensation: VacancyCompensation;
    applyCallToAction?: CallToAction;
    image?: Image;
    seo: Seo;
    body: PrismicSlice[];
    alternativeLanguages: AlternativeLanguage[];
}
