import { FC, ReactElement, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Wrapper } from '../../../components';
import { FlavoursCarousel, PersonalityTestCta } from '../../../compositions';
import { AppRoute, appRoutes } from '../../../constants/routing';
import { useCountry } from '../../../context/CountryContext';
import useTrans from '../../../hooks/useTrans';
import { PersonalityTestNavigateState } from '../../PersonalityTest/PersonalityTest';
import { splitFlavoursTitle } from './helpers/splitFlavoursTitle';

import './FlavoursSection.scss';

interface FlavoursSectionProps {
    className?: string;
}

const FlavoursSection: FC<FlavoursSectionProps> = ({ className = '' }): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { continent } = useCountry();
    const sectionRef = useRef<HTMLDivElement>(null);

    const titleParts = splitFlavoursTitle(trans('containers.flavoursSection.title'));

    const handleCtaButtonClick = (): void => {
        const state = { skipIntro: true } as PersonalityTestNavigateState;
        navigate(trans(appRoutes[AppRoute.personalityTest].path), { state });
    };

    return (
        <div
            ref={sectionRef}
            className={`flavours-section ${className}`}
        >
            <Wrapper className="flavours-section__wrapper">
                <h2 className="flavours-section__title">
                    {titleParts.map((word => (
                        <span key={word} className="flavours-section__title-part">
                            {word}
                        </span>
                    )))}
                </h2>

                <div className="flavours-section__description-wrapper">
                    <div className="flavours-section__description-empty-space" />

                    <p className="flavours-section__description">
                        {trans('containers.flavoursSection.description')}
                    </p>
                </div>
            </Wrapper>

            <FlavoursCarousel
                continent={continent}
                className="flavours-section__carousel"
            />

            <Wrapper className="flavours-section__personality-test-cta-wrapper">
                <PersonalityTestCta onCtaButtonClick={handleCtaButtonClick} />
            </Wrapper>
        </div>
    );
};

export default FlavoursSection;
