import { gsap } from '../../../entities/Gsap/GsapService';

const destroyFlavoursAnimations = (flavoursId: string, timeline?: gsap.core.Timeline): void => {
    timeline?.kill();

    const flavoursElement = document.getElementById(flavoursId);

    if (!flavoursElement) {
        return;
    }

    const flavours = Array.from(flavoursElement.childNodes);

    if (!flavours.length) {
        return;
    }

    const clones = Array.from(flavoursElement.querySelectorAll('[data-clone="true"]'));
    const flavourPictures = flavours.map(flavour => flavour.firstChild);
    const elements = [...flavours, ...flavourPictures];

    gsap.killTweensOf(elements);
    gsap.set(elements, { clearProps: 'all' });

    clones.forEach(clone => {
        if (flavoursElement.contains(clone)) {
            flavoursElement.removeChild(clone);
        }
    });
};

export default destroyFlavoursAnimations;
