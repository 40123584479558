/* eslint-disable max-len */
import {
    CSSProperties,
    FC,
    PointerEventHandler,
    ReactElement,
    useState,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import usePointerPosition from '../../../../hooks/usePointerPosition';
import useScrollSectionIntoView from '../../../../hooks/useScrollSectionIntoView';
import { PageCoords } from '../../../../types';
import { MorphingSvg } from '../../..';

import './FixedNavigationItem.scss';

interface FixedNavigationItemProps {
    isActive: boolean;
    blobIsRevealed: boolean;
    id: string;
    to: string;
    label: string;
    handleChangeBlobState: (state: boolean) => void;
    className?: string;
}

const defaultBlobPos = { x: 0, y: 0 };

const FixedNavigationItem: FC<FixedNavigationItemProps> = ({
    isActive,
    blobIsRevealed,
    id,
    to,
    label,
    handleChangeBlobState,
    className = '',
}): ReactElement => {
    const { scrollIntoView } = useScrollSectionIntoView();
    const pointerPosition = usePointerPosition();

    const [blobPos, setBlobPos] = useState<PageCoords>(defaultBlobPos);

    const handleLinkClick = (): void => scrollIntoView(id);

    const handlePointerEnter: PointerEventHandler<HTMLAnchorElement> = (): void => handleChangeBlobState(true);
    const handlePointerLeave: PointerEventHandler<HTMLAnchorElement> = (): void => handleChangeBlobState(false);

    const handlePointerMove: PointerEventHandler<HTMLAnchorElement> = (event): void => {
        if (event.currentTarget) {
            const { x, y } = event.currentTarget.getBoundingClientRect();
            const { width } = event.currentTarget.getBoundingClientRect();

            const { x: pointerX, y: pointerY } = pointerPosition;

            const r = width / 2;
            const factor = 3;

            const exponentX = Math.abs((x + r) - pointerX);
            const exponentY = Math.abs((y + r) - pointerY);

            const percentageX = Math.min(exponentX / r, 1);
            const percentageY = Math.min(exponentY / r, 1);

            const indicatorCenter = {
                x: x + r,
                y: y + r,
            };

            setBlobPos({
                x: (pointerX - indicatorCenter.x) * (percentageX ** factor),
                y: (pointerY - indicatorCenter.y) * (percentageY ** factor),
            });
        }
    };

    const handleResetBlobPos = (): void => setBlobPos(defaultBlobPos);

    const fixedNavigationItemClassNames = classNames('fixed-navigation-item', {
        'fixed-navigation-item--blob-is-revealed': blobIsRevealed,
        'fixed-navigation-item--is-active': isActive,
    }, className);

    const cssVariables = {
        '--fixed-navigation-blob-x': `${blobPos.x}px`,
        '--fixed-navigation-blob-y': `${blobPos.y}px`,
    } as CSSProperties;

    return (
        <li style={cssVariables} className={fixedNavigationItemClassNames}>
            <Link
                aria-label={label}
                to={to}
                onClick={handleLinkClick}
                onPointerEnter={handlePointerEnter}
                onPointerMove={handlePointerMove}
                onPointerLeave={handlePointerLeave}
                onFocus={handleResetBlobPos}
                onBlur={handleResetBlobPos}
                className="fixed-navigation-item__link"
            >
                <div className="fixed-navigation-item__indicator" />
            </Link>

            <span className="fixed-navigation-item__label">
                {label}
            </span>

            <div className="fixed-navigation-item__blob-wrapper">
                <MorphingSvg
                    fromPath="M69,13.3c8.8,5.5,15.1,20.1,16.6,36.7s-2,35.1-10.7,40.5s-22.8-2.1-32.5-7.8S4,59.6,4,47.7c0-14.1,25.4-28.5,36.6-32.9C49.2,11.4,60.3,7.8,69,13.3z"
                    toPath="M71,15.9c8.8,5.5,17.8,13,19.3,29.6S78.5,76.2,69.8,81.6s-30.9,9-40.6,3.3s-18-25.4-18-37.3c0-14.1,4.3-32.2,15.5-36.6C35.3,7.5,62.3,10.4,71,15.9z"
                    duration="5s"
                    className="fixed-navigation-item__blob"
                    pathClassName="fixed-navigation-item__blob-path"
                />
            </div>
        </li>
    );
};

export default FixedNavigationItem;
/* eslint-enable max-len */
