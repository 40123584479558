import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../../../components';
import { Flavour } from '../../../../constants/flavours';
import useTrans from '../../../../hooks/useTrans';
import { getFlavourTitleParts } from '../../helpers';
import { FlavourHeaderBackground } from '..';

import './FlavourHeader.scss';

interface FlavourHeaderProps {
    flavour: Flavour;
    className?: string;
}

const FlavourHeader: FC<FlavourHeaderProps> = ({ flavour, className = '' }): ReactElement => {
    const trans = useTrans();

    const titleParts = getFlavourTitleParts(trans(`flavours.${flavour}.name`));
    const sortedTitleParts = [...titleParts].sort((titlePart, comparisonTitlePart) => (
        comparisonTitlePart.length - titlePart.length
    ));

    const cssVariables = {
        '--flavour-header-title-length': sortedTitleParts[0].length,
    } as CSSProperties;

    const titleClassNames = classNames('flavour-header__title', {
        'flavour-header__title--is-single': titleParts.length === 1,
        'flavour-header__title--is-triple': titleParts.length === 3,
    });

    return (
        <div style={cssVariables} className={`flavour-header ${className}`}>
            <Wrapper className="flavour-header__wrapper">
                <div className="flavour-header__title-wrapper">
                    <h1 className={titleClassNames}>
                        {titleParts.map((titlePart, index) => (
                            <span
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${titlePart}-${index}`}
                                className="flavour-header__title-part"
                            >
                                {titlePart}
                            </span>
                        ))}
                    </h1>
                </div>

                <div className="flavour-header__description-wrapper">
                    <p className="flavour-header__description">
                        {trans(`flavours.${flavour}.intro`)}
                    </p>
                </div>
            </Wrapper>

            <div className="flavour-header__picture-wrapper">
                <Picture
                    loading="lazy"
                    src={`/images/flavours/header/${flavour}.png`}
                    sources={[
                        {
                            srcSet: `/images/flavours/header/${flavour}.avif, /images/flavours/header/${flavour}-2x.avif 2x`,
                            type: 'image/avif',
                        },
                        {
                            srcSet: `/images/flavours/header/${flavour}.webp, /images/flavours/header/${flavour}-2x.webp 2x`,
                            type: 'image/webp',
                        },
                    ]}
                    alt={trans(`flavours.${flavour}.imageAlts.header`)}
                    className="flavour-header__picture"
                />
            </div>

            <div className="flavour-header__blob-wrapper">
                <FlavourHeaderBackground
                    isAnimationEnabled
                    className="flavour-header__blob"
                />
            </div>
        </div>
    );
};

export default FlavourHeader;
