import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Wrapper } from '../../components';
import { SocialNavigation } from '../../compositions';
import { ConnectedLanguageDropZone } from '../../connectors';
import { ExternalRoute, footerSitemapList, footerSupportList } from '../../constants/routing';
import { isProduction } from '../../helpers';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import useFormatDate from '../../hooks/useFormatDate';
import useTrans from '../../hooks/useTrans';
import { ScrollToTop } from './subcomponents';

import './Footer.scss';

interface FooterProps {
    className?: string;
}

const Footer: FC<FooterProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const currentYear = formatDate(new Date().toISOString(), { year: 'numeric' });

    return (
        <footer className={`footer ${className}`}>
            <Wrapper className="footer__wrapper">
                <div className="footer__sitemap-wrapper">
                    <nav className="footer__nav">
                        <h2 className="footer__title">
                            {trans('containers.footer.main')}
                        </h2>

                        <ul className="footer__list">
                            {footerSitemapList.map(item => {
                                const isExternalRoute = getEnumKeyByEnumValue(ExternalRoute, item.key);

                                return (
                                    <li key={item.key} className="footer__item">
                                        <Link
                                            to={trans(item.path)}
                                            target={isExternalRoute ? '_blank' : undefined}
                                            rel={isExternalRoute ? 'noopener noreferrer' : undefined}
                                            className="footer__link"
                                        >
                                            {trans(item.label)}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>

                    <nav className="footer__nav">
                        <h2 className="footer__title">
                            {trans('containers.footer.support')}
                        </h2>

                        <ul className="footer__list">
                            {footerSupportList.map(item => {
                                const isExternalRoute = getEnumKeyByEnumValue(ExternalRoute, item.key);

                                return (
                                    <li key={item.key} className="footer__item">
                                        <Link
                                            to={trans(item.path)}
                                            target={isExternalRoute ? '_blank' : undefined}
                                            rel={isExternalRoute ? 'noopener noreferrer' : undefined}
                                            className="footer__link"
                                        >
                                            {trans(item.label)}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>

                <div className="footer__social-wrapper">
                    <h2 className="footer__title">
                        {trans('containers.footer.social')}
                    </h2>

                    <SocialNavigation
                        className="footer__nav footer__nav--social"
                        listClassName="footer__list footer__list--social"
                    />

                    <Link to={trans('routes.privacyStatement.path')} className="footer__privacy-statement-link">
                        {trans('routes.privacyStatement.label')}
                    </Link>

                    <p className="footer__copyright">
                        {`© ${currentYear} Yanga`}
                    </p>
                </div>
            </Wrapper>

            <ScrollToTop className="footer__scroll-to-top" />

            {!isProduction && <ConnectedLanguageDropZone />}
        </footer>
    );
};

export default Footer;
