export const getFlavourTitleParts = (title: string): string[] => {
    const titleParts: string[][] = [[], []];
    const splitTitle = title.split(' ');

    if (splitTitle.length < 4) {
        return splitTitle;
    }

    for (let i = 0; i < splitTitle.length; i += 1) {
        const [topRow, bottomRow] = titleParts;
        const newTitlePart = splitTitle[i];
        const topRowLength = topRow.join(' ').length + newTitlePart.length;

        if (i === 0) {
            titleParts[0].push(newTitlePart);
        } else if (bottomRow.length > 0) {
            titleParts[1].push(newTitlePart);
        } else if (topRowLength <= title.length / 2) {
            titleParts[0].push(newTitlePart);
        } else {
            titleParts[1].push(newTitlePart);
        }
    }

    return titleParts.map(row => row.join(' '));
};
