import { FC, ReactElement } from 'react';

import {
    Icon,
    LinkButton,
    Picture,
    Wrapper,
} from '../../../../components';
import { Flavour } from '../../../../constants/flavours';
import useTrans from '../../../../hooks/useTrans';
import { SpotifyPlaylistBackground, SpotifyPlaylistWidgetBackground } from '..';

import './SpotifyPlaylistSection.scss';

interface SpotifyPlaylistSectionProps {
    flavour: Flavour;
    className?: string;
}

const SpotifyPlaylistSection: FC<SpotifyPlaylistSectionProps> = ({
    flavour,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`spotify-playlist-section ${className}`}>
            <Wrapper className="spotify-playlist-section__wrapper">
                <h2 className="spotify-playlist-section__title">
                    {trans(`flavours.${flavour}.spotify.title`)}
                </h2>

                <div className="spotify-playlist-section__widget-wrapper">
                    <div className="spotify-playlist-section__whole-picture-wrapper">
                        <Picture
                            loading="lazy"
                            src={`/images/flavours/whole/${flavour}.png`}
                            sources={[
                                {
                                    srcSet: `/images/flavours/whole/${flavour}.avif, /images/flavours/whole/${flavour}-2x.avif 2x`,
                                    type: 'image/avif',
                                },
                                {
                                    srcSet: `/images/flavours/whole/${flavour}.webp, /images/flavours/whole/${flavour}-2x.webp 2x`,
                                    type: 'image/webp',
                                },
                            ]}
                            alt={trans(`flavours.${flavour}.imageAlts.fruitWhole`)}
                            className="spotify-playlist-section__whole-picture"
                        />
                    </div>

                    <div className="spotify-playlist-section__playlist-picture-wrapper">
                        <Picture
                            loading="lazy"
                            src={`/images/flavours/spotify/${flavour}.jpg`}
                            sources={[
                                {
                                    srcSet: `/images/flavours/spotify/${flavour}.avif, /images/flavours/spotify/${flavour}-2x.avif 2x`,
                                    type: 'image/avif',
                                },
                                {
                                    srcSet: `/images/flavours/spotify/${flavour}.webp, /images/flavours/spotify/${flavour}-2x.webp 2x`,
                                    type: 'image/webp',
                                },
                            ]}
                            alt={trans(`flavours.${flavour}.imageAlts.playlist`)}
                            className="spotify-playlist-section__playlist-picture"
                        />

                        <p className="spotify-playlist-section__description spotify-playlist-section__description--is-mobile">
                            {trans(`flavours.${flavour}.spotify.description`)}
                        </p>
                    </div>

                    <div className="spotify-playlist-section__text-wrapper">
                        <p className="spotify-playlist-section__description">
                            {trans(`flavours.${flavour}.spotify.description`)}
                        </p>

                        <div className="spotify-playlist-section__button-wrapper">
                            <div className="spotify-playlist-section__icon-wrapper">
                                <Icon name="spotify" className="spotify-playlist-section__icon" />
                            </div>

                            <LinkButton
                                to={trans(`flavours.${flavour}.spotify.playlistUrl`)}
                                target="_blank"
                                rel="noopener noreferrer"
                                text={trans('containers.flavourContent.spotify.button')}
                                className="spotify-playlist-section__link-button"
                            />
                        </div>
                    </div>

                    <div className="spotify-playlist-section__chunk-picture-wrapper">
                        <Picture
                            loading="lazy"
                            src={`/images/flavours/chunk/${flavour}.png`}
                            sources={[
                                {
                                    srcSet: `/images/flavours/chunk/${flavour}.avif, /images/flavours/chunk/${flavour}-2x.avif 2x`,
                                    type: 'image/avif',
                                },
                                {
                                    srcSet: `/images/flavours/chunk/${flavour}.webp, /images/flavours/chunk/${flavour}-2x.webp 2x`,
                                    type: 'image/webp',
                                },
                            ]}
                            alt={trans(`flavours.${flavour}.imageAlts.fruitChunk`)}
                            className="spotify-playlist-section__chunk-picture"
                        />
                    </div>
                </div>

                <div className="spotify-playlist-section__widget-blob-wrapper">
                    <SpotifyPlaylistWidgetBackground
                        isAnimationEnabled
                        className="spotify-playlist-section__widget-blob"
                    />
                </div>
            </Wrapper>

            <div className="spotify-playlist-section__blob-wrapper">
                <SpotifyPlaylistBackground
                    isAnimationEnabled
                    className="spotify-playlist-section__blob"
                />
            </div>
        </div>
    );
};

export default SpotifyPlaylistSection;
