import { useEffect, useState } from 'react';

import { isSSR } from '../helpers';

const query = '(hover: none) and (pointer: coarse)';

const useTouchScreen = (): boolean => {
    const [isTouchScreen, setIsTouchScreen] = useState<boolean>(
        !isSSR ? window.matchMedia(query).matches : false,
    );

    useEffect((): (() => void) => {
        const mediaQueryList = window.matchMedia(query);

        const listener = (event: MediaQueryListEvent): void => setIsTouchScreen(event.matches);

        mediaQueryList.addEventListener('change', listener);

        return (): void => mediaQueryList.removeEventListener('change', listener);
    }, []);

    return isTouchScreen;
};

export default useTouchScreen;
