import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CookieType } from '../../constants/cookieSettings';
import { Flavour } from '../../constants/flavours';
import { personalityTestFlavours } from '../../constants/personalityTest';
import { AppRoute, appRoutes } from '../../constants/routing';
import { PersonalityTestResult } from '../../containers';
import { useCookieSettings } from '../../context/CookieContext';
import { useLocale } from '../../context/LocaleContext';
import { useParamsDispatch } from '../../context/ParamContext';
import { Toast, ToastState } from '../../entities/Toast/Toast';
import { getFlavourFromPathname } from '../../helpers/flavours';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTrans from '../../hooks/useTrans';
import { useTypedDispatch } from '../../redux/store';
import { addNegativeToast, addPositiveToast, addWarningToast } from '../../redux/toast/toastActions';
import { AtLeast } from '../../types';

interface ConnectedPersonalityTestResultProps {
    className?: string;
}

const ConnectedPersonalityTestResult: FC<ConnectedPersonalityTestResultProps> = ({
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const trans = useTrans();
    const { language } = useLocale();
    const { pathname } = useLocation();

    const cookieSettings = useCookieSettings();

    const { flavour = '' } = useParams();
    const dispatchParams = useParamsDispatch();

    const [activeFlavourKey, setActiveFlavourKey] = useState<Flavour>();

    const flavourKey = getFlavourFromPathname(pathname, flavour);

    useEffect((): () => void => {
        const newFlavourKey = getFlavourFromPathname(pathname, flavour);

        setActiveFlavourKey(newFlavourKey);

        dispatchParams({ flavour });

        return (): void => {
            dispatchParams({});
        };
    }, [flavour, language]);

    // Navigate to personality test page if no flavour is found, and perform a soft-refresh directly after
    useEffect((): void => {
        const noFlavour = !flavourKey && !activeFlavourKey;
        const flavourIsAvailable = activeFlavourKey && personalityTestFlavours.includes(activeFlavourKey);

        if (noFlavour || (activeFlavourKey && !flavourIsAvailable)) {
            navigate(trans(appRoutes[AppRoute.personalityTest].path), { replace: true });
            navigate(0);
        }
    }, [flavourKey, activeFlavourKey]);

    useEffect((): void => {
        if (activeFlavourKey) {
            const translatedSlug = trans(`flavours.${activeFlavourKey}.slug`);
            const translatedPath = trans(appRoutes[AppRoute.personalityTestResult].path);
            const translatedRoute = replaceUrlParamKeysWithValues(translatedPath, {
                flavour: translatedSlug,
            });

            navigate(translatedRoute, { replace: true });
        }
    }, [activeFlavourKey, language]);

    const handleShareFeedback = (state: ToastState, toast: AtLeast<Toast, 'title'>): void => {
        if (state === ToastState.positive) {
            dispatch(addPositiveToast(toast));
        } else if (state === ToastState.negative) {
            dispatch(addNegativeToast(toast));
        } else {
            dispatch(addWarningToast(toast));
        }
    };

    if (!flavourKey) {
        return null;
    }

    return (
        <PersonalityTestResult
            allowedToTrack={cookieSettings[CookieType.analytical]}
            flavour={flavourKey}
            onShareFeedback={handleShareFeedback}
            className={className}
        />
    );
};

export default ConnectedPersonalityTestResult;
