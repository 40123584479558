import { combineSlices } from '@reduxjs/toolkit';

import aboutReducer, { AboutState } from './about/about';
import appReducer, { AppState } from './app/appReducer';
import articleDetailReducer, { ArticleDetailState } from './articleDetail/articleDetail';
import articlesReducer, { ArticlesState } from './articles/articles';
import contactFormReducer, { ContactFormState } from './contactForm/contactForm';
import faqReducer, { FaqState } from './faq/faq';
import nutritionReducer, { NutritionState } from './nutrition/nutrition';
import privacyStatementReducer, { PrivacyStatementState } from './privacyStatement/privacyStatement';
import toastReducer, { ToastState } from './toast/toast';
import vacanciesReducer, { VacanciesState } from './vacancies/vacancies';
import vacancyDetailReducer, { VacancyDetailState } from './vacancyDetail/vacancyDetail';
import workAtReducer, { WorkAtState } from './workAt/workAt';
import yourClubReducer, { YourClubState } from './yourClub/yourClub';

export interface Reducers {
    aboutReducer: AboutState;
    appReducer: AppState;
    articleDetailReducer: ArticleDetailState;
    articlesReducer: ArticlesState;
    contactFormReducer: ContactFormState;
    faqReducer: FaqState;
    nutritionReducer: NutritionState;
    privacyStatementReducer: PrivacyStatementState;
    toastReducer: ToastState;
    vacanciesReducer: VacanciesState;
    vacancyDetailReducer: VacancyDetailState;
    workAtReducer: WorkAtState;
    yourClubReducer: YourClubState;
}

export default combineSlices(
    aboutReducer,
    appReducer,
    articleDetailReducer,
    articlesReducer,
    contactFormReducer,
    faqReducer,
    nutritionReducer,
    privacyStatementReducer,
    toastReducer,
    vacanciesReducer,
    vacancyDetailReducer,
    workAtReducer,
    yourClubReducer,
);
