import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Button, Icon, Picture } from '../../../../components';
import { ButtonProps } from '../../../../components/@buttons/Button/Button';
import { PlaygroundSectionVideo } from '..';

import './PlaygroundSectionButton.scss';

interface PlaygroundSectionButtonProps extends ButtonProps {
    isPlaying?: boolean;
    showVideo?: boolean;
    imageSrc: string;
    text: string;
    videoSrc?: string;
    className?: string;
    pictureClassName?: string;
    wrapperClassName?: string;
}

const PlaygroundSectionButton: FC<PlaygroundSectionButtonProps> = ({
    isPlaying = false,
    showVideo = false,
    imageSrc,
    text,
    videoSrc,
    className = '',
    pictureClassName = '',
    wrapperClassName = '',
    ...buttonProps
}): ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect((): void => {
        if (!videoRef.current) {
            return;
        }

        if (isPlaying) {
            videoRef.current.play();

            return;
        }

        videoRef.current.pause();
    }, [isPlaying]);

    const buttonClassName = classNames('playground-section-button', {
        'playground-section-button--is-playing': isPlaying,
    }, className);

    return (
        <Button
            {...buttonProps}
            text={text}
            className={buttonClassName}
        >
            <div className={`playground-section-button__wrapper ${wrapperClassName}`}>
                <figure className="playground-section-button__figure">
                    {showVideo ? (
                        <div className="playground-section-button__video-wrapper">
                            <PlaygroundSectionVideo
                                ref={videoRef}
                                src={videoSrc}
                                className={`playground-section-button__video ${pictureClassName}`}
                            />
                        </div>
                    ) : (
                        <Picture
                            loading="lazy"
                            src={`${imageSrc}.jpg`}
                            sources={[
                                { srcSet: `${imageSrc}.avif`, type: 'image/avif' },
                                { srcSet: `${imageSrc}.webp`, type: 'image/webp' },
                            ]}
                            alt={text}
                            className={`playground-section-button__picture ${pictureClassName}`}
                            imageClassName="playground-section-button__picture-image"
                        />
                    )}

                    <div className="playground-section-button__icon-wrapper">
                        <Icon
                            name={!isPlaying ? 'play' : 'cross'}
                            className="playground-section-button__icon"
                        />
                    </div>
                </figure>

                <figcaption className="playground-section-button__caption">
                    {text}
                </figcaption>
            </div>
        </Button>
    );
};

export default PlaygroundSectionButton;
