import { FC, ReactElement } from 'react';

import { Button } from '../../../../components';
import { PersonalityTestInput } from '../../../../entities/PersonalityTest/PersonalityTest';
import useTrans from '../../../../hooks/useTrans';

import './PersonalityTestIntro.scss';

interface PersonalityTestIntroProps {
    answers?: PersonalityTestInput[];
    onStartClick: () => void;
    onRestartClick: () => void;
    className?: string;
}

const PersonalityTestIntro: FC<PersonalityTestIntroProps> = ({
    answers = [],
    onStartClick,
    onRestartClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`personality-test-intro ${className}`}>
            <h1 className="personality-test-intro__title">
                {trans('containers.personalityTest.title')}
            </h1>

            <p className="personality-test-intro__description">
                {trans('containers.personalityTest.intro')}
            </p>

            <div className="personality-test-intro__button-wrapper">
                {answers.length > 0 && (
                    <Button
                        text={trans('containers.personalityTest.restartButton')}
                        onClick={onRestartClick}
                        className="personality-test-intro__button"
                    />
                )}

                <Button
                    text={trans(`containers.personalityTest.${answers.length > 0 ? 'continueButton' : 'startButton'}`)}
                    onClick={onStartClick}
                    className="personality-test-intro__button"
                />
            </div>
        </div>
    );
};

export default PersonalityTestIntro;
