import { gsap, Quart } from '../../../entities/Gsap/GsapService';

const animateMobileFlavours = (flavoursId: string, activeIndex: number) => {
    const element = document.getElementById(flavoursId);

    if (!element) {
        return;
    }

    const flavours = Array.from(element.childNodes);
    const flavourElement = element.firstChild as HTMLDivElement;
    const flavourHeight = flavourElement.clientHeight;
    const scrollbarHeight = 20;
    const focusScale = ((element.clientHeight - scrollbarHeight) / flavourHeight);

    flavours.forEach((flavour, index) => {
        const picture = flavour.firstChild;
        const hasFocus = index === activeIndex;

        gsap.to(picture, {
            duration: 0.5,
            scale: hasFocus ? focusScale : 1,
            filter: `brightness(${hasFocus ? 1 : 0.875}`,
            ease: Quart.easeOut,
        });
    });
};

export default animateMobileFlavours;
