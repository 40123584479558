import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './FaqSkeletons.scss';

interface FaqSkeletonsProps {
    className?: string;
}

const FaqSkeletons: FC<FaqSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skeletonItems = generateIdArray(7);

    return (
        <Wrapper className={`faq-skeletons ${className}`}>
            <div className="faq-skeletons__intro-wrapper">
                <Skeleton className="faq-skeletons__title" />

                <div className="faq-skeletons__intro">
                    <Skeleton className="faq-skeletons__intro-line" />
                    <Skeleton className="faq-skeletons__intro-line" />
                    <Skeleton className="faq-skeletons__intro-line" />
                </div>
            </div>

            <div className="faq-skeletons__list">
                {skeletonItems.map(item => (
                    <div key={item} className="faq-skeletons__item">
                        <Skeleton className="faq-skeletons__question" />
                    </div>
                ))}
            </div>
        </Wrapper>
    );
};

export default FaqSkeletons;
