import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    Reducer,
    useContext,
    useEffect,
    useReducer,
} from 'react';

import { useLocalStorage } from 'react-use';

import { CookieSettingsData, defaultCookieSettings } from '../constants/cookieSettings';

export const CookieContext = createContext<CookieSettingsData>(defaultCookieSettings);
export const CookieDispatchContext = createContext((cookieSettingsData => cookieSettingsData) as Dispatch<CookieSettingsData>);

export const useCookieSettings = () => useContext(CookieContext);
export const useCookieSettingsDispatch = () => useContext(CookieDispatchContext);

const cookieSettingsReducer: Reducer<CookieSettingsData, CookieSettingsData> = (prevState, cookieSettingsData) => cookieSettingsData;

const CookieProvider: FC<PropsWithChildren> = ({ children }) => {
    const [storedCookieSettings, storeCookieSettings] = useLocalStorage<CookieSettingsData>('cookieSettings', undefined);

    const [cookieSettings, dispatch] = useReducer(cookieSettingsReducer, storedCookieSettings || defaultCookieSettings);

    useEffect((): void => {
        storeCookieSettings(cookieSettings);
    }, [cookieSettings]);

    return (
        <CookieContext.Provider value={cookieSettings}>
            <CookieDispatchContext.Provider value={dispatch}>
                {children}
            </CookieDispatchContext.Provider>
        </CookieContext.Provider>
    );
};

export default CookieProvider;
