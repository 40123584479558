import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { FlavourHelmet } from '../../compositions';
import { ConnectedFlavourContent } from '../../connectors';

import './FlavourDetailPage.scss';

const FlavourDetailPage: FC = (): ReactElement => (
    <Page className="flavour-detail-page">
        <FlavourHelmet />

        <ConnectedFlavourContent className="flavour-detail-page__content" />
    </Page>
);

export default FlavourDetailPage;
