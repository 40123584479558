import { useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { defaultLocale, Language } from '../constants/locale';
import { useLocale } from '../context/LocaleContext';
import { DateIsoString } from '../entities/Date/Date';
import { isSSR } from '../helpers';

interface DateFormatOptions extends Intl.DateTimeFormatOptions {
    dateStyle?: 'full' | 'long' | 'medium' | 'short';
    timeStyle?: 'full' | 'long' | 'medium' | 'short';
}

type DateFormatFunction = (date?: DateIsoString, options?: DateFormatOptions) => string;

const dateLocales: Record<Language, string> = {
    [Language.ar]: 'ar-SA',
    [Language.de]: 'de-DE',
    [Language.enGb]: 'en-GB',
    [Language.enUs]: 'en-US',
    [Language.es]: 'es-ES',
    [Language.fr]: 'fr-FR',
    [Language.nl]: 'nl-NL',
};

const useFormatDate = (): DateFormatFunction => {
    const { language } = useLocale();
    const { pathname } = useLocation();

    return useCallback<DateFormatFunction>((date, options) => {
        if (!date) return '';

        const dateObject = new Date(date);

        if (!isSSR) {
            const dateLocale = dateLocales[language];

            return new Intl.DateTimeFormat(dateLocale, options).format(dateObject);
        }

        const routeLanguage = pathname.split('/')[1] as Language;
        const ssrLanguage = Object.values(Language).includes(routeLanguage)
            ? routeLanguage
            : defaultLocale.language;

        const ssrDateLocale = dateLocales[ssrLanguage];

        return new Intl.DateTimeFormat(ssrDateLocale, options).format(dateObject);
    }, [language]);
};

export default useFormatDate;
