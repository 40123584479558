import {
    FC,
    forwardRef,
    ReactElement,
    RefAttributes,
    useEffect,
    useRef,
    VideoHTMLAttributes,
} from 'react';

import useCombinedRefs from '../../../../hooks/useCombinedRef';

interface PlaygroundSectionVideoProps extends VideoHTMLAttributes<HTMLVideoElement>, RefAttributes<HTMLVideoElement> {
    className?: string;
}

const PlaygroundSectionVideo: FC<PlaygroundSectionVideoProps> = forwardRef(({
    src,
    className = '',
}, ref): ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const combinedDialogRef = useCombinedRefs<HTMLVideoElement>(videoRef, ref);

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.volume = 0.5;
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
            autoPlay
            loop
            playsInline
            ref={combinedDialogRef}
            className={`playground-section-video ${className}`}
        >
            <source src={`${src}.mp4`} type="video/mp4" />
            <source src={`${src}.webm`} type="video/webm" />
        </video>
    );
});

export default PlaygroundSectionVideo;
