import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useWindowScroll, useWindowSize } from 'react-use';

import {
    LinkButton,
    Picture,
    SafeHtml,
    Wrapper,
} from '../../../components';
import { FluidTitle } from '../../../compositions';
import { AppRoute, appRoutes } from '../../../constants/routing';
import { useLocale } from '../../../context/LocaleContext';
import { Back, gsap, Linear } from '../../../entities/Gsap/GsapService';
import { clamp } from '../../../helpers/number';
import usePrefersReducedMotion from '../../../hooks/usePrefersReducedMotion';
import useElementScrollRatio from '../../../hooks/useSectionScrollPosition';
import useTrans from '../../../hooks/useTrans';
import { WelcomeSectionBackground } from './subcomponents';

import './WelcomeSection.scss';

interface WelcomeSectionProps {
    className?: string;
}

const introAnimationObject = { ratio: 1.5 };

const WelcomeSection: FC<WelcomeSectionProps> = ({ className = '' }): ReactElement => {
    const activeLocale = useLocale();
    const trans = useTrans();
    const prefersReduceMotion = usePrefersReducedMotion();
    const sectionRef = useRef<HTMLDivElement>(null);
    const { height: windowHeight } = useWindowSize();
    const sectionScrollRatio = useElementScrollRatio(sectionRef);
    const { y: scrollY } = useWindowScroll();

    const [introAnimationRatio, setIntroAnimationRatio] = useState(0);

    const scrollAnimationRatio = clamp((scrollY * 2) / windowHeight, 0, 1);

    const handleAnimationUpdate = (): void => {
        setIntroAnimationRatio(introAnimationObject.ratio);
    };

    useEffect((): () => void => {
        if (!prefersReduceMotion) {
            const timeline = gsap.timeline({ onUpdate: handleAnimationUpdate });

            timeline.add(gsap.to(introAnimationObject, 2, { ratio: -1, ease: Linear.easeNone }));
            timeline.add(gsap.to(introAnimationObject, 2, { ratio: 0, ease: Back.easeOut }));
        }

        return (): void => {
            gsap.killTweensOf(introAnimationObject);
        };
    }, []);

    return (
        <div
            ref={sectionRef}
            className={`welcome-section ${className}`}
        >
            <WelcomeSectionBackground
                isAnimationEnabled={sectionScrollRatio < 80}
                className="welcome-section__wave-background"
            />

            <div className="welcome-section__solid-background" />

            <Wrapper className="welcome-section__wrapper">
                <div className="welcome-section__picture-wrapper">
                    <Picture
                        loading="lazy"
                        src="/images/home-figure.png"
                        sources={[
                            { srcSet: '/images/home-figure.avif', type: 'image/avif' },
                            { srcSet: '/images/home-figure.webp', type: 'image/webp' },
                        ]}
                        alt={trans('containers.welcomeSection.picture')}
                        className="welcome-section__picture welcome-section__picture--desktop"
                        imageClassName="welcome-section__picture-image"
                    />
                </div>

                <div className="welcome-section__content-wrapper">
                    <div className="welcome-section__fluid-title-wrapper">
                        <div className="welcome-section__fluid-title-flexbox">
                            <FluidTitle
                                isAnimationEnabled={sectionScrollRatio < 100}
                                animationRatio={scrollAnimationRatio - introAnimationRatio}
                                locale={activeLocale}
                                title={[
                                    trans('containers.welcomeSection.heading.part1'),
                                    trans('containers.welcomeSection.heading.part2'),
                                ]}
                                className="welcome-section__fluid-title"
                            />
                        </div>
                    </div>

                    <Picture
                        loading="lazy"
                        src="/images/home-figure.png"
                        sources={[
                            { srcSet: '/images/home-figure.avif', type: 'image/avif' },
                            { srcSet: '/images/home-figure.webp', type: 'image/webp' },
                        ]}
                        alt={trans('containers.welcomeSection.picture')}
                        className="welcome-section__picture welcome-section__picture--mobile"
                        imageClassName="welcome-section__picture-image"
                    />

                    <SafeHtml
                        html={trans('containers.welcomeSection.description')}
                        className="welcome-section__description"
                    />

                    <LinkButton
                        to={trans(appRoutes[AppRoute.about].path)}
                        text={trans('containers.welcomeSection.cta')}
                        className="welcome-section__call-to-action"
                    />
                </div>
            </Wrapper>
        </div>
    );
};

export default WelcomeSection;
