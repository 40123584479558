/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconArrowLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={84.9} className={className}>
        <polygon points="100,37.4 19.1,37.4 49.5,7.1 42.4,0 7.1,35.4 0,42.4 7.1,49.5 42.4,84.9 49.5,77.8 19.1,47.4 100,47.4" />
    </Svg>
);

export default IconArrowLeft;
/* eslint-enable max-len */
