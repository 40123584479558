/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconPerfectMix: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={74} viewBoxHeight={80} className={className}>
        <path d="M28.3797 23.8443C14.3746 23.8443 3.02132 35.1976 3.02132 49.2026C3.02132 63.2077 14.3746 74.561 28.3797 74.561C32.0217 74.561 35.4802 73.7942 38.6061 72.415L39.9775 75.5231C36.4278 77.0894 32.503 77.9583 28.3797 77.9583C12.4984 77.9583 -0.375977 65.0839 -0.375977 49.2026C-0.375977 33.3213 12.4984 20.447 28.3797 20.447C34.8518 20.447 40.829 22.5872 45.6353 26.1978L43.5948 28.914C39.3566 25.7302 34.0908 23.8443 28.3797 23.8443Z" />
        <path d="M63.748 12.32C64.2828 13.0907 64.0916 14.1491 63.3208 14.6839C61.2811 16.0992 59.4856 18.6187 58.058 21.374C56.6478 24.0956 55.6961 26.8518 55.26 28.5204C55.0228 29.428 54.0947 29.9715 53.187 29.7343C52.2794 29.497 51.7359 28.5689 51.9731 27.6613C52.4672 25.7708 53.5054 22.7758 55.0415 19.8111C56.5603 16.8799 58.6676 13.7777 61.3841 11.8927C62.1548 11.3579 63.2132 11.5492 63.748 12.32Z" />
        <path d="M38.8862 29.5448C42.3862 26.3754 46.7898 24.848 51.4342 26.6259C51.9011 26.8046 52.3521 26.9996 52.7874 27.2104C61.5133 26.5152 66.8234 31.4658 69.2842 35.7362C73.6283 43.2744 77.6063 58.1573 65.9808 72.5933C61.1938 78.5376 55.4177 80.2861 50.1679 79.9604C45.019 79.641 40.4523 77.3411 37.7822 75.3061C37.7547 75.2852 37.7279 75.2634 37.7017 75.2408C28.5373 67.3276 27.7845 53.5592 31.0477 42.897C32.6933 37.52 35.4232 32.6807 38.8862 29.5448ZM57.7008 30.9081C61.7811 35.5083 62.8433 42.2008 62.5954 48.4834C62.2707 56.7127 59.6957 65.0028 57.6892 69.1183C57.278 69.9615 56.2612 70.3118 55.4179 69.9007C54.5747 69.4895 54.2244 68.4727 54.6355 67.6294C56.4492 63.9094 58.8958 56.0786 59.2007 48.3495C59.5092 40.5314 57.6252 33.5637 51.6713 30.4514C51.2156 30.2132 50.7323 29.9949 50.2196 29.7987C47.0989 28.604 44.0101 29.4881 41.1665 32.0631C38.2859 34.6716 35.8209 38.9094 34.2962 43.8912C31.224 53.9295 32.2203 65.981 39.8826 72.6353C42.1539 74.3539 46.0722 76.3025 50.3783 76.5697C54.6095 76.8322 59.2889 75.4866 63.3348 70.4625C73.7918 57.4774 70.2472 44.2113 66.3407 37.4324C64.8287 34.8086 62.0464 31.911 57.7008 30.9081Z" />
        <path d="M50.0189 5.85077C54.5053 10.0827 56.6436 15.8149 57.263 19.8254C57.4 20.7126 56.823 21.5533 55.9459 21.7444C45.0957 24.1086 37.9063 21.6363 33.4483 17.1906C29.0869 12.8412 27.6306 6.88634 27.5874 2.74247C27.579 1.93918 28.1345 1.23985 28.9188 1.06624C38.5756 -1.07126 45.4431 1.53439 50.0189 5.85077ZM31.059 4.10476C38.8921 2.73983 44.1765 5.00987 47.6878 8.32207C50.9414 11.3912 52.7848 15.4673 53.5856 18.748C44.5729 20.3352 39.1135 18.0423 35.8472 14.785C32.7384 11.6848 31.377 7.47646 31.059 4.10476Z" />
        <path d="M46.3368 36.5843C47.543 36.5843 48.5208 37.5621 48.5208 38.7683V55.0874C48.5208 56.2936 47.543 57.2714 46.3368 57.2714C45.1306 57.2714 44.1528 56.2936 44.1528 55.0874V38.7683C44.1528 37.5621 45.1306 36.5843 46.3368 36.5843Z" />
        <path d="M56.6807 46.9278C56.6807 48.134 55.7029 49.1118 54.4967 49.1118L38.1775 49.1118C36.9714 49.1118 35.9936 48.134 35.9936 46.9278C35.9936 45.7216 36.9714 44.7438 38.1775 44.7438L54.4967 44.7438C55.7029 44.7438 56.6807 45.7216 56.6807 46.9278Z" />
        <path d="M53.7988 63.8535C53.7988 65.0596 52.821 66.0374 51.6149 66.0374H41.1803C39.9741 66.0374 38.9963 65.0596 38.9963 63.8535C38.9963 62.6473 39.9741 61.6695 41.1803 61.6695H51.6149C52.821 61.6695 53.7988 62.6473 53.7988 63.8535Z" />
        <path d="M26.1265 31.6345C25.2673 32.0161 24.8371 32.4456 24.6732 32.7507C24.2838 33.4757 23.4306 33.8172 22.6485 33.5613C20.4331 32.8363 17.4054 33.9039 14.9477 36.2973C12.5007 38.6803 11.2823 41.7385 11.945 44.1409C12.1797 44.9915 11.724 45.8801 10.8963 46.186C10.3823 46.3759 9.79367 46.9943 9.46276 48.4114C9.14238 49.7833 9.16152 51.5738 9.56044 53.391C9.95877 55.2054 10.7052 56.9071 11.7063 58.1436C12.6957 59.3655 13.858 60.0541 15.1636 60.1309C15.8408 60.1707 16.4293 60.6097 16.6606 61.2474C17.5842 63.7934 19.1438 65.7896 21.5273 66.7793C23.9357 67.7793 27.5192 67.8916 32.7692 65.9416C33.6486 65.615 34.6263 66.0631 34.953 66.9425C35.2796 67.822 34.8315 68.7997 33.9521 69.1263C28.235 71.2498 23.7053 71.3621 20.2246 69.9169C17.0804 68.6114 15.0626 66.1486 13.8526 63.3737C11.8718 62.9553 10.2688 61.7669 9.06601 60.2814C7.65749 58.5418 6.72518 56.3197 6.24216 54.1194C5.75974 51.9218 5.69493 49.6067 6.15448 47.6388C6.49697 46.1722 7.19216 44.6865 8.41699 43.7263C8.00912 40.0576 9.99122 36.382 12.5775 33.8634C15.1708 31.3379 18.908 29.4587 22.4981 30.0395C23.1258 29.3958 23.9169 28.8985 24.7474 28.5296C26.0268 27.9614 27.5539 27.6258 29.1687 27.5437C32.3933 27.3797 36.1918 28.2174 39.4027 30.5783C40.1585 31.134 40.3207 32.1973 39.765 32.9531C39.2092 33.7089 38.146 33.8711 37.3902 33.3154C34.8937 31.4798 31.8938 30.8068 29.3412 30.9366C28.0674 31.0013 26.9612 31.2637 26.1265 31.6345Z" />
        <path d="M22.5714 30.042C23.4691 29.7694 24.4177 30.2762 24.6902 31.1739L28.4951 43.7077C28.7676 44.6054 28.2608 45.5541 27.3632 45.8266C26.4655 46.0991 25.5168 45.5923 25.2443 44.6946L21.4394 32.1608C21.1669 31.2631 21.6737 30.3145 22.5714 30.042Z" />
        <path d="M8.9982 44.336C9.23273 43.4277 10.1592 42.8815 11.0676 43.116L24.7205 46.6411C25.6288 46.8757 26.175 47.8021 25.9405 48.7105C25.706 49.6188 24.7795 50.1651 23.8711 49.9305L10.2182 46.4054C9.3099 46.1709 8.76366 45.2444 8.9982 44.336Z" />
        <path d="M25.7633 52.053C26.4021 52.74 26.363 53.8149 25.676 54.4537L16.1078 63.3504C15.4207 63.9893 14.3459 63.9502 13.7071 63.2632C13.0683 62.5761 13.1074 61.5013 13.7944 60.8625L23.3626 51.9657C24.0497 51.3269 25.1245 51.366 25.7633 52.053Z" />
    </Svg>
);

export default IconPerfectMix;
/* eslint-enable max-len */
