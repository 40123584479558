export const initializeStorepoint = (): void => {
    const scriptTag = document.createElement('script');
    const newScript = document.getElementsByTagName('script')[0];

    scriptTag.src = 'https://cdn.storepoint.co/api/v1/js/164db875061876.js';

    if (newScript.parentNode) {
        newScript.parentNode.insertBefore(scriptTag, newScript);
    }
};
