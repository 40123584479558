import { Language } from '../../constants/locale';
import { transformPrismicDocumentToArticle } from '../../entities/Article/ArticleTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicDetailApiCall, getPrismicOverviewApiCall } from '../../entities/Prismic/PrismicService';
import { TypedDispatch } from '../store';
import {
    setArticleData,
    setError,
    setIsLoading,
    setRelatedArticles,
} from './articleDetail';

export const fetchArticleContent = (slug: string, language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setArticleData(undefined));

    try {
        const type = PrismicType.article;
        const articleDetailResponse = await getPrismicDetailApiCall({ type, slug, language });

        if (!isFetchResultSuccessful(articleDetailResponse)) {
            console.error('[fetchArticleContent]', articleDetailResponse.error);

            dispatch(setError(articleDetailResponse.error));

            return;
        }

        const articleData = transformPrismicDocumentToArticle(articleDetailResponse.data);

        dispatch(setArticleData(articleData));
    } catch (error) {
        console.error('[fetchArticleContent]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchRelatedArticles = (slug: string, language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setRelatedArticles([]));

    try {
        const type = PrismicType.article;
        const articleDetailResponse = await getPrismicDetailApiCall({ type, slug, language });

        if (!isFetchResultSuccessful(articleDetailResponse)) {
            console.error('[fetchRelatedArticles]', articleDetailResponse.error);
            return;
        }

        const articleContent = transformPrismicDocumentToArticle(articleDetailResponse.data);

        const { id, categories } = articleContent;

        // Early exit if no categories/tags are found
        if (categories.length === 0) {
            dispatch(setRelatedArticles([]));
            return;
        }

        const randomCategoryIndex = Math.floor(Math.random() * categories.length);

        const relatedArticlesResponse = await getPrismicOverviewApiCall({
            type,
            language,
            category: categories[randomCategoryIndex],
            page: 1,
            pageSize: 8,
            excludeId: id,
        });

        if (!isFetchResultSuccessful(relatedArticlesResponse)) {
            console.error('[fetchRelatedArticles]', relatedArticlesResponse.error);
            return;
        }

        const relatedArticles = relatedArticlesResponse.data.results.map(transformPrismicDocumentToArticle);
        const cappedRelatedArticles = relatedArticles.slice(0, 3);

        dispatch(setRelatedArticles(cappedRelatedArticles));
    } catch (error) {
        console.error('[fetchRelatedArticles]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
