import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { TableCell, TableRow } from '../../../../components';
import { USNutritionTableRow } from '../../../../entities/NutritionTableRow/NutritionTableRow';
import useTrans from '../../../../hooks/useTrans';

import './NutritionTableUSRow.scss';

interface NutritionTableUSRowProps {
    data: USNutritionTableRow;
    nextSibling?: USNutritionTableRow;
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
}

const NutritionTableUSRow: FC<NutritionTableUSRowProps> = ({
    data,
    nextSibling,
    cellClassName,
    cellContentClassName,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const nextRowIsChild = !!nextSibling?.isChild;

    const rowClassName = classNames('nutrition-table-us-row', {
        'nutrition-table-us-row--is-child': data.isChild,
        'nutrition-table-us-row--is-last-child': data.isChild && !nextRowIsChild,
    }, className);

    return (
        <TableRow className={rowClassName}>
            <TableCell
                className={`nutrition-table-us-row__cell ${cellClassName}`}
                contentClassName={`nutrition-table-us-row__cell-content ${cellContentClassName}`}
            >
                <span>{trans(`containers.ingredients.nutrition.${data.ingredient}`)}</span>
                <span>{' '}</span>
                <span>{data.amount}</span>
                <span>{trans(`containers.ingredients.unitOfMeasure.${data.unitOfMeasure}`)}</span>
            </TableCell>

            {data.dailyValue !== undefined && (
                <TableCell
                    className={`nutrition-table-us-row__cell ${cellClassName}`}
                    contentClassName={`nutrition-table-us-row__cell-content ${cellContentClassName}`}
                >
                    {`${data.dailyValue}%`}
                </TableCell>
            )}
        </TableRow>
    );
};

export default NutritionTableUSRow;
