/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './ArticleOverviewBackground.scss';

interface ArticleOverviewBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const ArticleOverviewBackground: FC<ArticleOverviewBackgroundProps> = ({ isAnimationEnabled, className = '' }): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'article-overview-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                angle: 30,
                magnitude: 15,
            }}
            preserveAspectRatio="xMinYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1400}
            viewBoxHeight={1160}
            className={`article-overview-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="article-overview-background__gradient-start" />
                <stop offset="50%" className="article-overview-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M 1422.023 400.404 C 1405.787 647.189 1172.185 850.932 785.33 927.463 C 467.868 998.469 81.46 842.15 39.309 512.756 C 5.935 162.422 366.025 172.888 522.279 181.667 C 787.385 195.712 984.25 123.782 1127.914 57.48 C 1339.043 -27.269 1446.184 137.833 1422.607 400.29"
                className="article-overview-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default ArticleOverviewBackground;
/* eslint-enable max-len */
