import { defaultLocale, Language } from '../constants/locale';
import { AppRoute, appRoutes } from '../constants/routing';
import { PrismicLink, PrismicLinkResolver } from '../entities/Link/Link';
import { prismicLocales } from '../entities/Prismic/Prismic';
import { getTranslation } from './trans';
import { replaceUrlParamKeysWithValues } from './url';

export const prismicLinkResolver = (doc: PrismicLinkResolver, language: Language): string => {
    switch (doc.type) {
        case 'article': {
            const routePath = getTranslation(language, appRoutes[AppRoute.articleDetail].path);

            return replaceUrlParamKeysWithValues(routePath, {
                category: doc.tags[0].toLowerCase(),
                slug: doc.uid,
            });
        }
        case 'vacancy': {
            const routePath = getTranslation(language, appRoutes[AppRoute.vacancyDetail].path);

            return replaceUrlParamKeysWithValues(routePath, {
                category: doc.tags[0].toLowerCase(),
                slug: doc.uid,
            });
        }
        case 'about_yanga': {
            return getTranslation(language, appRoutes[AppRoute.about].path);
        }
        case 'yanga_in_your_club': {
            return getTranslation(language, appRoutes[AppRoute.yourClub].path);
        }
        case 'work_at_yanga': {
            return getTranslation(language, appRoutes[AppRoute.workAt].path);
        }
        case 'faq': {
            return getTranslation(language, appRoutes[AppRoute.faq].path);
        }
        case 'privacy_statement': {
            return getTranslation(language, appRoutes[AppRoute.privacyStatement].path);
        }
        default: {
            return `/${doc.uid}`;
        }
    }
};

export const prismicPagePreviewResolver = (doc: PrismicLink, language?: Language): string => {
    switch (doc.type) {
        case 'article': {
            const routePath = getTranslation(language, appRoutes[AppRoute.articleDetail].path);

            if (doc.tags) {
                replaceUrlParamKeysWithValues(routePath, {
                    category: doc.tags[0].toLowerCase(),
                    slug: doc.slug,
                });
            }

            return replaceUrlParamKeysWithValues(routePath, {
                slug: doc.slug,
            });
        }
        case 'vacancy': {
            const routePath = getTranslation(language, appRoutes[AppRoute.vacancyDetail].path);

            if (doc.tags) {
                return replaceUrlParamKeysWithValues(routePath, {
                    category: doc.tags[0].toLowerCase(),
                    slug: doc.slug,
                });
            }

            return replaceUrlParamKeysWithValues(routePath, {
                slug: doc.slug,
            });
        }
        case 'about_yanga': {
            return getTranslation(language, appRoutes[AppRoute.about].path);
        }
        case 'yanga_in_your_club': {
            return getTranslation(language, appRoutes[AppRoute.yourClub].path);
        }
        case 'work_at_yanga': {
            return getTranslation(language, appRoutes[AppRoute.workAt].path);
        }
        case 'faq': {
            return getTranslation(language, appRoutes[AppRoute.faq].path);
        }
        case 'privacy_statement': {
            return getTranslation(language, appRoutes[AppRoute.privacyStatement].path);
        }
        default: {
            return `/${doc.slug}`;
        }
    }
};

export const getLanguageFromPrismicLanguage = (prismicLanguage?: string): Language => {
    const languageEntry = Object.entries(prismicLocales).find(([, locale]) => (
        prismicLanguage === locale
    ));

    return languageEntry
        ? languageEntry[0] as Language
        : defaultLocale.language;
};
