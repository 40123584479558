import {
    FC,
    ReactElement,
    TableHTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, LoadingSpinner } from '../../../../components';
import { NonSortTable } from '../../../../compositions';
import { Continent } from '../../../../constants/countries';
import { useLocale } from '../../../../context/LocaleContext';
import { NutritionTableRow } from '../../../../entities/NutritionTableRow/NutritionTableRow';
import { isEUNutritionTableRow, isUSNutritionTableRow } from '../../../../entities/NutritionTableRow/NutritionTableRowHelpers';
import { elementHasScroll } from '../../../../helpers/scroll';
import useTrans from '../../../../hooks/useTrans';
import { NutritionTableEURow, NutritionTableUSRow } from '..';

import './NutritionTable.scss';

interface NutritionTableProps extends TableHTMLAttributes<HTMLTableElement> {
    isLoading: boolean;
    continent: Continent;
    data: NutritionTableRow[];
    className?: string;
}

const NutritionTable: FC<NutritionTableProps> = ({
    isLoading,
    continent,
    data,
    className = '',
    ...tableProps
}): ReactElement => {
    const { language } = useLocale();
    const trans = useTrans();

    const tableRef = useRef<HTMLTableElement>(null);

    const [tableHasScroll, setTableHasScroll] = useState<boolean>(false);
    const [tableIsScrolled, setTableIsScrolled] = useState<boolean>(false);
    const [tableHasReachedEnd, setTableHasReachedEnd] = useState<boolean>(false);

    useEffect((): () => void => {
        const checkTableHasScroll = (): void => {
            const hasScroll = elementHasScroll(tableRef);

            setTableHasScroll(hasScroll);
        };

        checkTableHasScroll();

        window.addEventListener('resize', checkTableHasScroll);

        return (): void => {
            window.removeEventListener('resize', checkTableHasScroll);
        };
    }, [tableRef, data, language]);

    const handleScroll = (): void => {
        if (!tableRef.current) return;

        const { scrollWidth, scrollLeft } = tableRef.current;
        const { width } = tableRef.current.getBoundingClientRect();
        const absoluteScrollLeft = Math.abs(scrollLeft);

        setTableIsScrolled(absoluteScrollLeft > 0);
        setTableHasReachedEnd((scrollWidth - width) === absoluteScrollLeft);
    };

    const naTableHeaders: string[] = [
        trans('containers.ingredients.amountPerServing'),
        trans('containers.ingredients.dailyValue'),
    ];

    const euTableHeaders: string[] = [
        trans('containers.ingredients.serving'),
        '100ml',
        '500ml',
    ];

    const tableHeaders = continent === Continent.northAmerica ? naTableHeaders : euTableHeaders;

    const tableClassNames = classNames('nutrition-table', {
        'nutrition-table--is-europe': continent !== Continent.northAmerica,
        'nutrition-table--is-loading': isLoading,
    }, className);

    const scrollHintClassNames = classNames('nutrition-table__scroll-hint', {
        'nutrition-table__scroll-hint--is-at-start': !tableIsScrolled,
        'nutrition-table__scroll-hint--is-at-end': tableHasReachedEnd,
    });

    if (isLoading) {
        return (
            <div className={tableClassNames}>
                <NonSortTable
                    {...tableProps}
                    tableHeaders={tableHeaders}
                    cellClassName="nutrition-table__cell"
                    className="nutrition-table__table"
                    cellContentClassName="nutrition-table__cell-content"
                    headerRowClassName="nutrition-table__header-row"
                />

                <div className="nutrition-table__loading-spinner-wrapper">
                    <LoadingSpinner className="nutrition-table__loading-spinner" />
                </div>
            </div>
        );
    }

    return (
        <div className={tableClassNames}>
            {tableHasScroll && (
                <div className="nutrition-table__scroll-hint-wrapper">
                    <div className={scrollHintClassNames}>
                        {trans('common.scroll')}

                        <Icon name="arrow-right" className="nutrition-table__scroll-hint-icon" />
                    </div>
                </div>
            )}

            <NonSortTable
                {...tableProps}
                ref={tableRef}
                tableHeaders={tableHeaders}
                cellClassName="nutrition-table__cell"
                className="nutrition-table__table"
                cellContentClassName="nutrition-table__cell-content"
                onScroll={handleScroll}
                headerRowClassName="nutrition-table__header-row"
            >
                {data.map((row, index) => {
                    const rowKey = `${row.ingredient}-${index}`;
                    const nextRow = data[index + 1];

                    if (isUSNutritionTableRow(row)) {
                        const nextSibling = nextRow && isUSNutritionTableRow(nextRow) ? nextRow : undefined;

                        return (
                            <NutritionTableUSRow
                                key={rowKey}
                                data={row}
                                nextSibling={nextSibling}
                                cellClassName="nutrition-table__cell"
                                className="nutrition-table__row"
                                cellContentClassName="nutrition-table__cell-content"
                            />
                        );
                    }

                    const nextSibling = nextRow && isEUNutritionTableRow(nextRow) ? nextRow : undefined;

                    return (
                        <NutritionTableEURow
                            key={rowKey}
                            data={row}
                            nextSibling={nextSibling}
                            cellClassName="nutrition-table__cell"
                            className="nutrition-table__row"
                            cellContentClassName="nutrition-table__cell-content"
                        />
                    );
                })}
            </NonSortTable>
        </div>
    );
};

export default NutritionTable;
