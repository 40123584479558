import { useContext, useEffect, useState } from 'react';

import { useDebounce, useWindowScroll } from 'react-use';

import { ScrollContext } from '../context/ScrollContext';

interface UseScrollIntoViewResult {
    isScrollingToSection: boolean;
    scrollIntoView: (sectionId: string) => void;
}

const useScrollSectionIntoView = (): UseScrollIntoViewResult => {
    const { isScrolling, setIsScrolling } = useContext(ScrollContext);
    const { y } = useWindowScroll();
    const [debouncedY, setDebouncedY] = useState<number>(y);

    const scrollIntoView = (sectionId: string): void => {
        const element = document.getElementById(sectionId);

        if (element) {
            setIsScrolling(true);
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useDebounce((): void => {
        setDebouncedY(y);
    }, 100, [y]);

    useEffect((): void => {
        if (isScrolling && y === debouncedY) {
            setIsScrolling(false);
        }
    }, [debouncedY]);

    return { isScrollingToSection: isScrolling, scrollIntoView };
};

export default useScrollSectionIntoView;
