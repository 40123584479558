import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { IconButton } from '../../../../compositions';
import { Flavour } from '../../../../constants/flavours';
import { flavourResultSearchParam } from '../../../../constants/personalityTest';
import { Toast, ToastState } from '../../../../entities/Toast/Toast';
import { gtmEvent } from '../../../../helpers/analytics';
import useTimeout from '../../../../hooks/useTimeout';
import useTrans from '../../../../hooks/useTrans';
import { AtLeast } from '../../../../types';

import './PersonalityTestResultShare.scss';

interface PersonalityTestResultShareProps {
    allowedToTrack?: boolean;
    flavour: Flavour;
    onShareFeedback: (state: ToastState, toast: AtLeast<Toast, 'title'>) => void;
    className?: string;
}

const PersonalityTestResultShare: FC<PersonalityTestResultShareProps> = ({
    allowedToTrack,
    flavour,
    onShareFeedback,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const flavourSlug = trans(`flavours.${flavour}.slug`);
    const flavourSearchParams = `?${flavourResultSearchParam}=${flavourSlug}`;
    const personalityTestPath = trans('routes.personalityTest.path');

    const [hasNativeShare, setHasNativeShare] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const copyDelay = 2000;

    useEffect((): void => {
        setHasNativeShare(!!navigator.share);
    }, []);

    useTimeout((): void => {
        if (isCopied) {
            setIsCopied(false);
        }
    }, copyDelay, [isCopied]);

    const openShareWindow = (url: string): void => {
        const { availWidth, availHeight } = window.screen;

        const width = Math.max(availWidth / 2, 480);
        const height = availHeight / 2;

        const windowFeatures = Object.entries({
            popup: true,
            top: height / 2,
            left: width / 2,
            width,
            height,
        }).map(entry => entry.join('=')).join(',');

        window.open(url, 'Share', windowFeatures);
    };

    const handleFacebookShare = (): void => {
        const url = window.location.origin + personalityTestPath + flavourSearchParams;
        const text = trans(`flavours.${flavour}.result.cardDescription`);
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${text}`;

        openShareWindow(shareUrl);
    };

    const handleWhatsAppShare = (): void => {
        const url = window.location.origin + personalityTestPath + flavourSearchParams;
        const text = trans(`flavours.${flavour}.result.cardDescription`);
        const shareUrl = `https://api.whatsapp.com/send?text=${text} ${encodeURIComponent(url)}`;

        openShareWindow(shareUrl);
    };

    const handleCopyLink = async (): Promise<void> => {
        const url = window.location.origin + personalityTestPath + flavourSearchParams;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(url).then((): void => {
                onShareFeedback(ToastState.positive, {
                    title: trans('containers.personalityTestResult.card.share.feedback.copied.title'),
                    description: trans('containers.personalityTestResult.card.share.feedback.copied.description'),
                });

                setIsCopied(true);
            });
        }
    };

    const handleNativeShare = async (): Promise<void> => {
        const shareData = {
            title: trans('pages.personalityTest.meta.title'),
            text: trans(`flavours.${flavour}.result.cardDescription`),
            url: window.location.origin + personalityTestPath + flavourSearchParams,
        };

        try {
            await navigator.share(shareData);

            if (allowedToTrack) {
                gtmEvent({
                    event: `quiz_share-${flavour}`,
                    state: 'Successfully shared',
                });
            }
        } catch (error) {
            if (allowedToTrack) {
                gtmEvent({
                    event: `quiz_share-${flavour}`,
                    // @ts-ignore
                    state: `Failed to share: ${error.message}`,
                });
            }
        }
    };

    return (
        <div className={`personality-test-result-share ${className}`}>
            <div className="personality-test-result-share__wrapper">
                <p className="personality-test-result-share__label">
                    {trans('containers.personalityTestResult.card.share.label')}
                </p>

                <ul className="personality-test-result-share__list">
                    {hasNativeShare && (
                        <li className="personality-test-result-share__item">
                            <IconButton
                                hideLabel
                                icon="share"
                                text={trans('containers.personalityTestResult.card.share.shareOptions')}
                                onClick={handleNativeShare}
                                className="personality-test-result-share__button"
                                iconClassName="personality-test-result-share__icon"
                            />
                        </li>
                    )}

                    {!hasNativeShare && (
                        <>
                            <li className="personality-test-result-share__item">
                                <IconButton
                                    hideLabel
                                    icon="facebook"
                                    text={trans('containers.personalityTestResult.card.share.facebook')}
                                    onClick={handleFacebookShare}
                                    className="personality-test-result-share__button"
                                    iconClassName="personality-test-result-share__icon"
                                />
                            </li>

                            <li className="personality-test-result-share__item">
                                <IconButton
                                    hideLabel
                                    icon="whatsapp"
                                    text={trans('containers.personalityTestResult.card.share.whatsApp')}
                                    onClick={handleWhatsAppShare}
                                    className="personality-test-result-share__button"
                                    iconClassName="personality-test-result-share__icon"
                                />
                            </li>
                        </>
                    )}

                    <li className="personality-test-result-share__item">
                        <IconButton
                            hideLabel
                            icon="link"
                            text={trans('containers.personalityTestResult.card.share.copy')}
                            disabled={isCopied}
                            onClick={handleCopyLink}
                            className="personality-test-result-share__button"
                            iconClassName="personality-test-result-share__icon"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PersonalityTestResultShare;
