import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicOverviewApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToVacancy, transformToVacancyOverviewInfo } from '../../entities/Vacancy/VacancyTransformers';
import { TypedDispatch } from '../store';
import { setVacancyData } from '../vacancyDetail/vacancyDetail';
import {
    setIsLoading,
    setTotalPages,
    setTotalResults,
    setVacancies,
} from './vacancies';

interface VacancyOverviewConfig {
    language: Language;
    category?: string;
    page: number;
}

export const fetchVacancyOverview = (config: VacancyOverviewConfig) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setTotalPages(0));
    dispatch(setTotalResults(0));
    dispatch(setVacancies([]));

    // Reset existing vacancies, to prevent flash of old content during render
    dispatch(setVacancyData(undefined));

    try {
        const vacancyOverviewResponse = await getPrismicOverviewApiCall({
            type: PrismicType.vacancy,
            category: config.category,
            language: config.language,
            page: config.page,
            pageSize: 8,
        });

        if (!isFetchResultSuccessful(vacancyOverviewResponse)) {
            console.error('[fetchVacancyOverview]', vacancyOverviewResponse.error);
            return;
        }

        const resultInfo = transformToVacancyOverviewInfo(vacancyOverviewResponse.data);
        const vacancies = vacancyOverviewResponse.data.results.map(vacancy => transformPrismicDocumentToVacancy(vacancy, config.language));

        dispatch(setTotalPages(resultInfo.totalPages));
        dispatch(setTotalResults(resultInfo.totalResults));

        dispatch(setVacancies(vacancies));
    } catch (error) {
        console.error('[fetchVacancyOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
