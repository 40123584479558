/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyEngineering: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={68} viewBoxHeight={100} className={className}>
        <path d="M41.1,61.1c-0.4,0-0.8,0-1.2,0h-1.2c-8.7,0-15.8-7.1-15.8-15.8l0-1.2c0-11.3,0-18.8,4.5-23.4c4.5-4.5,7-4.5,23.1-4.5l2,0c0,0,0,0,0,0c1.8,0,3.4,1.1,4,2.7c0.7,1.7,0.3,3.5-0.9,4.8l-9,9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0l9-9c1.3-1.3,3.2-1.6,4.8-0.9c1.7,0.7,2.7,2.3,2.7,4.1v2c0,16.1,0,18.5-4.5,23.1C58.9,60.9,51.8,61.1,41.1,61.1z M49.5,21.1c-15,0-15.7,0.2-18.6,3.1c-3.1,3.1-3.1,9.8-3.1,19.9l0,1.2c0,6,4.9,10.8,10.8,10.8h1.2c10.1,0,16.8,0,19.9-3.1c3-3,3.1-3.7,3.1-19.5v-0.5l-8,8c-3.3,3.3-8.6,3.3-11.8,0c-3.3-3.3-3.3-8.6,0-11.8l8-8h-0.5C50.1,21.1,49.8,21.1,49.5,21.1z M62.8,31.5C62.8,31.5,62.8,31.5,62.8,31.5L62.8,31.5z M52.4,21.1C52.4,21.1,52.4,21.1,52.4,21.1L52.4,21.1z" />
        <path d="M11.4,83.9c-2.9,0-5.9-1.1-8.1-3.3c-2.2-2.2-3.3-5-3.3-8.1c0-3.1,1.2-5.9,3.3-8.1l20.6-20.6c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8L6.2,67.2C4.8,68.6,4,70.5,4,72.5c0,2,0.8,3.8,2.2,5.3c2.9,2.9,7.6,2.9,10.5,0l20.6-20.6c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8L19.5,80.6C17.3,82.8,14.4,83.9,11.4,83.9z" />
        <path d="M12,73.9L12,73.9c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,73.9,12,73.9z" />
    </Svg>
);

export default IconVacancyEngineering;
/* eslint-enable max-len */
