import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Button, Logo, RootPortal } from '../../../../components';
import { IconButton, LiquidButton } from '../../../../compositions';
import { AppRoute, appRoutes, HomeSection, homeSections } from '../../../../constants/routing';
import useHandleClickOutside from '../../../../hooks/useHandleClickOutside';
import useTrans from '../../../../hooks/useTrans';

import './MenuSidebar.scss';

interface MenuSidebarProps {
    isExpanded: boolean;
    navigationLabel: string;
    onTransitionEnd: (isReady: boolean) => void;
    onClose?: () => void;
    className?: string;
}

const MenuSidebar: FC<PropsWithChildren<MenuSidebarProps>> = ({
    isExpanded,
    navigationLabel,
    onTransitionEnd,
    onClose,
    className = '',
    children,
}): ReactElement => {
    const trans = useTrans();

    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const navRef = useRef<HTMLElement>(null);

    useHandleClickOutside(navRef, (): void => {
        if (onClose) onClose();
    });

    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect((): void => {
        if (!isExpanded) {
            setIsReady(false);
            return;
        }

        if (closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [isExpanded]);

    const handleBubbleTransitionEnd = (): void => {
        if (isExpanded) setIsReady(true);

        onTransitionEnd(isExpanded);
    };

    const menuSidebarClassNames = classNames('menu-sidebar', {
        'menu-sidebar--is-expanding': isExpanded,
    }, className);

    return (
        <RootPortal>
            <div className={menuSidebarClassNames}>
                <nav
                    ref={navRef}
                    aria-label={navigationLabel}
                    className="menu-sidebar__nav"
                >
                    <div className="menu-sidebar__top-wrapper">
                        <NavLink
                            to={trans(homeSections[HomeSection.welcome].path)}
                            title={trans('containers.menu.menuSidebar.homepageLink')}
                            tabIndex={isExpanded ? 0 : -1}
                            className="menu-sidebar__home-link"
                        >
                            <Logo className="menu-sidebar__logo" />
                        </NavLink>

                        <LiquidButton
                            to={trans(appRoutes[AppRoute.gymLocator].path)}
                            text={trans('containers.menu.menuSidebar.findButton')}
                            tabIndex={isExpanded ? 0 : -1}
                            disabled={!isReady}
                            className="menu-sidebar__find-button"
                            fillClassName="menu-sidebar__find-button-fill"
                        />

                        <IconButton
                            ref={closeButtonRef}
                            icon="cross"
                            text={trans('containers.menu.menuSidebar.closeButton')}
                            hideLabel
                            tabIndex={isExpanded ? 0 : -1}
                            onClick={onClose}
                            className="menu-sidebar__close-button"
                            iconClassName="menu-sidebar__close-icon"
                        />
                    </div>

                    <div className="menu-sidebar__child-wrapper">
                        {children}

                        <Button
                            text={trans('containers.menu.menuSidebar.closeButton')}
                            tabIndex={isExpanded ? 0 : -1}
                            onClick={onClose}
                            className="menu-sidebar__tab-close-button"
                        />
                    </div>
                </nav>

                <div
                    onTransitionEnd={handleBubbleTransitionEnd}
                    className="menu-sidebar__bubble"
                />
            </div>
        </RootPortal>
    );
};

export default MenuSidebar;
