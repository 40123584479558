import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedVacancyContent } from '../../connectors';

import './VacancyDetailPage.scss';

const VacancyDetailPage: FC = (): ReactElement => (
    <Page className="vacancy-detail-page">
        <ConnectedVacancyContent />
    </Page>
);

export default VacancyDetailPage;
