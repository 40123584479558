/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconLink: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M27,100c-6.9,0-13.8-2.6-19.1-7.9c-10.5-10.5-10.5-27.7,0-38.2l15.3-15.3C33.8,28,50.9,28,61.4,38.5c2.1,2.1,2.1,5.5,0,7.6s-5.5,2.1-7.6,0c-6.4-6.4-16.7-6.4-23.1,0L15.5,61.5c-6.4,6.4-6.4,16.7,0,23.1c6.4,6.4,16.7,6.4,23.1,0c2.1-2.1,5.5-2.1,7.6,0c2.1,2.1,2.1,5.5,0,7.6C40.9,97.4,33.9,100,27,100z" />
        <path d="M57.6,69.4c-6.9,0-13.8-2.6-19.1-7.9c-2.1-2.1-2.1-5.5,0-7.6s5.5-2.1,7.6,0c6.4,6.4,16.7,6.3,23.1,0l15.3-15.3c6.4-6.4,6.4-16.7,0-23.1s-16.7-6.4-23.1,0c-2.1,2.1-5.5,2.1-7.6,0c-2.1-2.1-2.1-5.5,0-7.6c10.5-10.5,27.7-10.5,38.2,0C97.2,13,100,19.8,100,27c0,7.2-2.8,14-7.9,19.1L76.8,61.5C71.5,66.7,64.6,69.4,57.6,69.4z" />
    </Svg>
);

export default IconLink;
/* eslint-enable max-len */
