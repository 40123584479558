import {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
    TableHTMLAttributes,
} from 'react';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components';

import './NonSortTable.scss';

interface NonSortTableProps extends TableHTMLAttributes<HTMLTableElement>, RefAttributes<HTMLTableElement> {
    caption?: string;
    tableHeaders?: string[];
    headerRowClassName?: string;
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
}

const NonSortTable: ForwardRefExoticComponent<PropsWithChildren<NonSortTableProps>> = forwardRef(({
    caption,
    tableHeaders,
    headerRowClassName = '',
    cellClassName = '',
    cellContentClassName = '',
    className = '',
    children,
    ...tableProps
}, ref: Ref<HTMLTableElement>): ReactElement => (
    <Table
        {...tableProps}
        ref={ref}
        className={`non-sort-table ${className}`}
    >
        {caption && <TableCaption title={caption} />}

        {tableHeaders && (
            <TableHead>
                <TableRow className={`non-sort-table__header-row ${headerRowClassName}`}>
                    {tableHeaders.map(header => (
                        <TableCell
                            key={header}
                            isHead
                            className={cellClassName}
                            contentClassName={cellContentClassName}
                        >
                            {header}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )}

        <TableBody>
            {children}
        </TableBody>
    </Table>
));

export default NonSortTable;
