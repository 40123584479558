import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../components';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Article } from '../../../../entities/Article/Article';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './ArticlePreview.scss';

interface ArticlePreviewProps extends Article {
    className?: string;
}

const ArticlePreview: FC<ArticlePreviewProps> = ({
    thumbnail,
    title,
    intro,
    createdAt,
    author,
    slug,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const paramPath = trans(appRoutes[AppRoute.articleDetail].path);
    const articlePath = replaceUrlParamKeysWithValues(paramPath, { slug });

    return (
        <div className={`article-preview ${className}`}>
            <div className="article-preview__image-wrapper">
                {thumbnail && (
                    <img
                        src={thumbnail.src}
                        alt={thumbnail.alt}
                        className="article-preview__image"
                    />
                )}
            </div>

            <div className="article-preview__text-wrapper">
                <h2 className="article-preview__title">
                    {title}
                </h2>

                <div className="article-preview__article-info-wrapper">
                    <p className="article-preview__date">
                        {formatDate(createdAt, { dateStyle: 'medium' })}
                    </p>
                    <p className="article-preview__author">
                        {author}
                    </p>
                </div>

                <div className="article-preview__intro">
                    {intro}
                </div>

                <LinkButton
                    to={articlePath}
                    text={trans('containers.articleOverview.viewArticle')}
                    className="article-preview__button"
                />
            </div>
        </div>
    );
};

export default ArticlePreview;
