import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ArticleRendererSkeletons.scss';

interface ArticleRendererSkeletonsProps {
    className?: string;
}

const ArticleRendererSkeletons: FC<ArticleRendererSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skeletonParagraphs = generateIdArray(5);

    return (
        <div className={`article-renderer-skeletons ${className}`}>
            {skeletonParagraphs.map(paragraph => {
                const skeletonLines = generateIdArray(4);

                return (
                    <div key={paragraph} className="article-renderer-skeletons__paragraph">
                        <Skeleton className="article-renderer-skeletons__subtitle" />

                        {skeletonLines.map(line => (
                            <Skeleton
                                key={line}
                                className="article-renderer-skeletons__text"
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default ArticleRendererSkeletons;
