import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { MetaVacancyEmploymentType, MetaVacancyWorkRemote, Vacancy } from '../../../../entities/Vacancy/Vacancy';
import { transformToMetaVacancyEmploymentType } from '../../../../entities/Vacancy/VacancyTransformers';
import { getTranslation } from '../../../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useTrans from '../../../../hooks/useTrans';

interface VacancyHelmetProps {
    vacancy: Vacancy;
}

const VacancyHelmet: FC<VacancyHelmetProps> = ({
    vacancy,
}): ReactElement | null => {
    const { slug = '' } = useParams();
    const trans = useTrans();

    const siteUrl = trans('company.url');
    const vacancyDetailKey = trans('routes.vacancyDetail.path');
    const vacancyDetailPathname = replaceUrlParamKeysWithValues(vacancyDetailKey, { slug });
    const vacancyDetailUrl = siteUrl + vacancyDetailPathname;

    const logoUrl = `${siteUrl}/images/logo.webp`;

    const employmentType = vacancy.employmentType
        ? transformToMetaVacancyEmploymentType(vacancy.employmentType)
        : MetaVacancyEmploymentType.fullTime;

    if (!vacancy.seo.title) {
        return null;
    }

    return (
        <Helmet>
            <title>{vacancy.seo.title || vacancy.title}</title>
            <meta name="description" content={vacancy.seo.description || vacancy.description} />
            <meta name="keywords" content={vacancy.seo.keywords} />

            <meta property="og:site_name" content="Yanga" />
            <meta property="og:title" content={vacancy.seo.title || vacancy.title} />
            <meta property="og:description" content={vacancy.seo.description || vacancy.description} />

            <meta property="og:image" content={vacancy.seo.image?.src || vacancy.image?.src} />
            <meta property="og:image:alt" content={vacancy.seo.image?.alt || vacancy.image?.alt} />

            <meta property="og:url" content={vacancyDetailUrl} />

            <link rel="canonical" href={vacancyDetailUrl} />

            {vacancy && vacancy.alternativeLanguages.map(altLang => {
                const altLangKey = getTranslation(altLang.language, 'routes.vacancyDetail.path');
                const altLangPathname = replaceUrlParamKeysWithValues(altLangKey, {
                    slug: altLang.slug,
                });

                return (
                    <link
                        key={altLang.language}
                        rel="alternate"
                        hrefLang={altLang.language}
                        href={siteUrl + altLangPathname}
                    />
                );
            })}

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'JobPosting',
                    title: vacancy.seo.title || vacancy.title,
                    description: vacancy.seo.description || vacancy.description,
                    identifier: {
                        '@type': 'PropertyValue',
                        name: trans('company.name'),
                        value: trans('company.name'),
                    },
                    datePosted: vacancy.createdAt
                        ? new Date(vacancy.createdAt).toISOString()
                        : '',
                    validThrough: vacancy.expirationDate
                        ? new Date(vacancy.expirationDate).toISOString()
                        : '',
                    employmentType,
                    hiringOrganization: {
                        '@type': 'Organization',
                        name: trans('company.name'),
                        sameAs: trans('company.url'),
                        logo: logoUrl,
                    },
                    jobLocation: {
                        '@type': 'Place',
                        address: {
                            '@type': 'PostalAddress',
                            streetAddress: `${vacancy.address.street} ${vacancy.address.houseNumber}`,
                            addressLocality: vacancy.address.city,
                            addressRegion: vacancy.address.region,
                            postalCode: vacancy.address.postalCode,
                            addressCountry: vacancy.address.country,
                        },
                    },
                    jobLocationType: vacancy.workRemote === MetaVacancyWorkRemote.fullyRemote
                        ? vacancy.workRemote
                        : '',
                    baseSalary: {
                        '@type': 'MonetaryAmount',
                        currency: 'EUR',
                        value: {
                            '@type': 'QuantitativeValue',
                            minValue: vacancy.compensation.min ? String(vacancy.compensation.min) : '',
                            maxValue: vacancy.compensation.max ? String(vacancy.compensation.max) : '',
                            unitText: vacancy.compensation.type || '',
                        },
                    },
                })}
            </script>
        </Helmet>
    );
};

export default VacancyHelmet;
