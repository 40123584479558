import { FC, ReactElement, useEffect } from 'react';

import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useToggle, useWindowScroll } from 'react-use';

import { Logo, Wrapper } from '../../components';
import { LiquidButton } from '../../compositions';
import { CookieType } from '../../constants/cookieSettings';
import { Continent } from '../../constants/countries';
import { LocaleData } from '../../constants/locale';
import {
    AppRoute,
    appRoutes,
    HomeSection,
    homeSections,
    navigationList,
} from '../../constants/routing';
import { useCookieSettings } from '../../context/CookieContext';
import { useCountry } from '../../context/CountryContext';
import { useLocale } from '../../context/LocaleContext';
import { isSSR } from '../../helpers';
import { isCurrentRouteHomePage } from '../../helpers/url';
import useScrollSectionIntoView from '../../hooks/useScrollSectionIntoView';
import useTrans from '../../hooks/useTrans';
import {
    I18nSettings,
    MenuBackground,
    Navigation,
    SeoNavigation,
} from './subcomponents';

import './Menu.scss';

interface MenuProps {
    onShowCookieSettings: () => void;
    onSelectRegion: (continent: Continent) => void;
    onSelectLocale: (locale: LocaleData) => void;
    className?: string;
}

const Menu: FC<MenuProps> = ({
    onShowCookieSettings,
    onSelectRegion,
    onSelectLocale,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { y: windowScrollY } = useWindowScroll();
    const location = useLocation();

    const isHomePage = isCurrentRouteHomePage(location);

    const cookieSettings = useCookieSettings();
    const { cookieSettingsConfigured } = cookieSettings;
    const functionalCookiesAccepted = cookieSettings[CookieType.functional];

    const activeLocale = useLocale();
    const { continent: activeRegion } = useCountry();

    const { scrollIntoView } = useScrollSectionIntoView();

    const [navigationIsExpanded, toggleNavigationIsExpanded] = useToggle(false);
    const [i18nIsExpanded, toggleI18nIsExpanded] = useToggle(false);

    const collapseNavigation = (): void => toggleNavigationIsExpanded(false);
    const collapseI18n = (): void => toggleI18nIsExpanded(false);

    useEffect(collapseNavigation, [location]);

    const isScrolled = windowScrollY > 0;
    const sidebarTransitionDelay = 0.025;

    const handleLogoLinkClick = (): void => scrollIntoView(HomeSection.welcome);

    const handleShowCookieSettings = (): void => {
        collapseNavigation();
        collapseI18n();
        onShowCookieSettings();
    };

    const handleSelectRegion = (region: Continent): void => {
        collapseI18n();
        onSelectRegion(region);
    };

    const handleSelectLocale = (locale: LocaleData): void => {
        collapseI18n();
        onSelectLocale(locale);
    };

    const headerClassName = classNames('menu', {
        'menu--is-compact': !isHomePage || isScrolled,
    }, className);

    return (
        <header className={headerClassName}>
            <div className="menu__background-wrapper">
                <MenuBackground
                    isAnimationEnabled={isScrolled}
                    className="menu__background"
                />
            </div>
            <Wrapper className="menu__wrapper">
                <NavLink
                    to={trans(homeSections[HomeSection.welcome].path)}
                    title={trans('containers.menu.homepageLink')}
                    onClick={handleLogoLinkClick}
                    className="menu__home-link"
                >
                    <Logo className="menu__logo" />
                </NavLink>

                <div className="menu__buttons-wrapper">
                    <LiquidButton
                        to={trans(appRoutes[AppRoute.gymLocator].path)}
                        text={trans('containers.menu.findButton')}
                        className="menu__button menu__find-button"
                        fillClassName="menu__liquid-button-fill"
                    />

                    <LiquidButton
                        icon="globe"
                        text={trans(`containers.menu.i18nSettings.${i18nIsExpanded ? 'collapse' : 'expand'}`)}
                        hideLabel
                        onClick={toggleI18nIsExpanded}
                        className="menu__i18n-button"
                        iconClassName="menu__i18n-icon"
                        fillClassName="menu__liquid-button-fill"
                    />

                    <LiquidButton
                        icon="hamburger"
                        text={trans(`containers.menu.navigation.${navigationIsExpanded ? 'collapse' : 'expand'}`)}
                        hideLabel
                        onClick={toggleNavigationIsExpanded}
                        className="menu__hamburger-button"
                        iconClassName="menu__hamburger-icon"
                        fillClassName="menu__liquid-button-fill"
                    />
                </div>

                {isSSR && (
                    <SeoNavigation
                        label={trans('containers.menu.navigation.ariaLabel')}
                        navigationItems={navigationList}
                    />
                )}

                <Navigation
                    isExpanded={navigationIsExpanded}
                    navigationLabel={trans('containers.menu.navigation.ariaLabel')}
                    transitionDelay={sidebarTransitionDelay}
                    navigationItems={navigationList}
                    onShowCookieSettings={handleShowCookieSettings}
                    onClose={collapseNavigation}
                />

                <I18nSettings
                    isExpanded={i18nIsExpanded}
                    navigationLabel={trans('containers.menu.i18nSettings.ariaLabel')}
                    transitionDelay={sidebarTransitionDelay}
                    cookieSettingsRejected={cookieSettingsConfigured && !functionalCookiesAccepted}
                    activeLocale={activeLocale}
                    activeRegion={activeRegion}
                    onShowCookieSettings={handleShowCookieSettings}
                    onSelectRegion={handleSelectRegion}
                    onSelectLocale={handleSelectLocale}
                    onClose={collapseI18n}
                />
            </Wrapper>
        </header>
    );
};

export default Menu;
