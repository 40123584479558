import { FC, ReactNode, ReactPortal } from 'react';

import { createPortal } from 'react-dom';

import { isSSR } from '../../helpers';

interface RootPortalProps {
    children?: ReactNode;
}

const RootPortal: FC<RootPortalProps> = ({ children }): ReactPortal | null => {
    if (isSSR) {
        return null;
    }

    const portalRoot = document.getElementById('portal-root');

    return portalRoot && createPortal(
        children,
        portalRoot,
    );
};

export default RootPortal;
