import {
    CSSProperties,
    FC,
    FocusEvent,
    forwardRef,
    ForwardRefExoticComponent,
    MouseEvent,
    ReactElement,
    Ref,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button, Icon, LinkButton } from '../../../components';
import { ButtonProps } from '../../../components/@buttons/Button/Button';
import { LinkButtonProps } from '../../../components/@buttons/LinkButton/LinkButton';
import { IconName } from '../../../components/Icon/Icon';

import './LiquidButton.scss';

interface LiquidButtonProps extends ButtonProps {
    to?: string;
    icon?: IconName;
    iconPos?: 'left' | 'right';
    hideLabel?: boolean;
    iconClassName?: string;
    fillClassName?: string;
}

type HTMLLiquidButtonElement = HTMLButtonElement | HTMLAnchorElement;

const LiquidButton: ForwardRefExoticComponent<LiquidButtonProps> = forwardRef(({
    to,
    icon,
    iconPos,
    text,
    hideLabel,
    tabIndex,
    disabled,
    className = '',
    iconClassName = '',
    fillClassName = '',
    ...iconButtonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const [xPos, setXPos] = useState<number>(0);
    const [yPos, setYPos] = useState<number>(0);

    const setFillPosition = (event: MouseEvent<HTMLLiquidButtonElement>): void => {
        if (!event.currentTarget) return;

        const { clientX, clientY } = event;
        const { x, y } = event.currentTarget.getBoundingClientRect();

        setXPos(clientX - x);
        setYPos(clientY - y);
    };

    const handleFocus = (event: FocusEvent<HTMLLiquidButtonElement>): void => {
        if (!event.currentTarget) return;

        const { width, height } = event.currentTarget.getBoundingClientRect();

        setXPos(width / 2);
        setYPos(height / 2);
    };

    const buttonClassNames = classNames('liquid-button__button', {
        [`liquid-button__button--align-${iconPos}`]: iconPos,
        'liquid-button__button--is-disabled': disabled,
    }, className);

    const cssVariables = {
        '--liquid-button-x-pos': `${xPos}px`,
        '--liquid-button-y-pos': `${yPos}px`,
    } as CSSProperties;

    const ButtonComponent = (to ? LinkButton : Button) as unknown as FC<LinkButtonProps | ButtonProps>;

    return (
        <div className="liquid-button">
            <ButtonComponent
                {...iconButtonProps}
                to={to || ''}
                ref={ref}
                style={cssVariables}
                text={text}
                tabIndex={disabled ? -1 : tabIndex}
                disabled={disabled}
                onMouseEnter={setFillPosition}
                onMouseLeave={setFillPosition}
                onFocus={handleFocus}
                className={buttonClassNames}
            >
                {icon && (
                    <Icon
                        name={icon}
                        className={`liquid-button__icon ${iconClassName}`}
                    />
                )}

                {!hideLabel && (
                    <span className="liquid-button__label">
                        {text}
                    </span>
                )}

                <div className={`liquid-button__fill ${fillClassName}`} />
            </ButtonComponent>
        </div>
    );
});

export default LiquidButton;
