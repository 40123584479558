import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultPrismicDefaultPageData, PrismicDefaultPageData } from '../../entities/Prismic/Prismic';
import { AsyncReduxState } from '../../types';

export type WorkAtState = AsyncReduxState<{
    workAtData: PrismicDefaultPageData;
}>;

const initialState: WorkAtState = {
    ...initialAsyncReduxState,
    workAtData: defaultPrismicDefaultPageData,
};

const workAtSlice = createSlice({
    name: 'workAtReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): WorkAtState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): WorkAtState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setWorkAtData(state, action: PayloadAction<PrismicDefaultPageData>): WorkAtState {
            return {
                ...state,
                workAtData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setWorkAtData,
} = workAtSlice.actions;

export default workAtSlice;
