import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
    SVGAttributes,
} from 'react';

import './Svg.scss';

export interface SvgProps extends SVGAttributes<SVGSVGElement>, RefAttributes<SVGSVGElement> {
    viewBoxWidth?: number;
    viewBoxHeight?: number;
    className?: string;
}

const Svg: ForwardRefExoticComponent<SvgProps> = forwardRef(({
    viewBoxWidth = 100,
    viewBoxHeight = 100,
    className,
    children,
    ...svgProps
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg
        {...svgProps}
        ref={ref}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className={`svg ${className}`}
    >
        {children}
    </svg>
));

export default Svg;
