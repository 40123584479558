export const isSSR = typeof window === 'undefined';
export const isProduction = process.env.REACT_APP_APP_ENV === 'production';

export const isSafari = (): boolean => {
    if (isSSR) return false;

    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const localStorageExists = typeof localStorage !== 'undefined';
