import { FC, ReactElement, useEffect } from 'react';

import { Button, Picture } from '../../components';
import { Continent } from '../../constants/countries';
import { useCountry } from '../../context/CountryContext';
import { initializeStorepoint } from '../../helpers/initializeStorepoint';
import useTrans from '../../hooks/useTrans';

import './GymLocator.scss';

interface GymLocatorProps {
    allowedToLoad: boolean;
    disableCookieSettingsButton: boolean;
    onShowCookieSettings: () => void;
    className?: string;
}

const GymLocator: FC<GymLocatorProps> = ({
    allowedToLoad,
    disableCookieSettingsButton,
    onShowCookieSettings,
    className = '',
}): ReactElement => {
    const { continent } = useCountry();
    const trans = useTrans();

    const mapId = '164db875061876';
    const showKilometerUnits = continent !== Continent.northAmerica;

    useEffect((): void => {
        if (allowedToLoad) {
            initializeStorepoint();
        }
    }, [allowedToLoad, continent]);

    return (
        <div className={`gym-locator ${className}`}>
            {!allowedToLoad && (
                <div className="gym-locator__notice">
                    <div className="gym-locator__notice-card">
                        <h3 className="gym-locator__notice-title">
                            {trans('containers.gymLocator.notice.title')}
                        </h3>

                        <p className="gym-locator__notice-description">
                            {trans('containers.gymLocator.notice.description')}
                        </p>

                        <Button
                            text={trans('common.showCookieSettings')}
                            disabled={disableCookieSettingsButton}
                            onClick={onShowCookieSettings}
                            className="gym-locator__notice-button"
                        />
                    </div>

                    <Picture
                        loading="lazy"
                        src={`/images/gym-locator/${continent}.png`}
                        sources={[
                            { srcSet: `/images/gym-locator/${continent}.avif`, type: 'image/avif' },
                            { srcSet: `/images/gym-locator/${continent}.webp`, type: 'image/webp' },
                        ]}
                        alt={trans('containers.gymLocator.placeholderMap')}
                        imageClassName="gym-locator__placeholder-map"
                    />
                </div>
            )}

            <div
                key={continent}
                id="storepoint-container"
                data-map-id={mapId}
                data-language={showKilometerUnits ? 'kilometers' : undefined}
                className="gym-locator__embed"
            />
        </div>
    );
};

export default GymLocator;
