/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconGlobe: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M50,93.8c3.3,0,7.9-2.8,12.1-11.2c1.9-3.9,3.6-8.5,4.7-13.8H33.2c1.2,5.3,2.8,9.9,4.7,13.8C42.1,90.9,46.7,93.8,50,93.8z M32.1,62.5h35.8c0.5-3.9,0.8-8.1,0.8-12.5c0-4.4-0.3-8.6-0.8-12.5H32.1c-0.5,3.9-0.8,8.1-0.8,12.5C31.2,54.4,31.5,58.6,32.1,62.5z M33.2,31.2h33.6c-1.2-5.3-2.8-9.9-4.7-13.8C57.9,9.1,53.3,6.2,50,6.2c-3.3,0-7.9,2.8-12.1,11.2C36,21.3,34.4,26,33.2,31.2z M74.2,37.5c0.5,4,0.8,8.2,0.8,12.5s-0.3,8.5-0.8,12.5H92c1.2-4,1.8-8.2,1.8-12.5s-0.6-8.5-1.8-12.5H74.2z M89.6,31.2C84.5,20.6,75.3,12.3,64,8.5c4.1,5.5,7.3,13.4,9.2,22.7H89.6L89.6,31.2z M26.8,31.2C28.7,22,32,14.1,36.1,8.5c-11.3,3.8-20.5,12.1-25.6,22.7H26.8L26.8,31.2z M8.1,37.5c-1.2,4-1.8,8.2-1.8,12.5s0.6,8.5,1.8,12.5h17.7c-0.5-4-0.8-8.2-0.8-12.5s0.3-8.5,0.8-12.5H8.1L8.1,37.5z M64,91.5c11.3-3.8,20.5-12.1,25.6-22.7H73.2C71.3,78,68.1,85.9,64,91.5z M36,91.5c-4.1-5.5-7.3-13.4-9.2-22.7H10.5C15.5,79.4,24.7,87.7,36,91.5z M50,100c-13.3,0-26-5.3-35.4-14.6C5.3,76,0,63.3,0,50s5.3-26,14.6-35.4S36.7,0,50,0s26,5.3,35.4,14.6C94.7,24,100,36.7,100,50s-5.3,26-14.6,35.4C76,94.7,63.3,100,50,100z" />
    </Svg>
);

export default IconGlobe;
/* eslint-enable max-len */
