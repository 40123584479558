import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../../components';
import { HeaderDropletImage } from '../../../../compositions';
import useTrans from '../../../../hooks/useTrans';

import './FlavoursAndIngredientsHeader.scss';

interface FlavoursAndIngredientsHeaderProps {
    className?: string;
}

const FlavoursAndIngredientsHeader: FC<FlavoursAndIngredientsHeaderProps> = ({ className = '' }): ReactElement => {
    const trans = useTrans();

    return (
        <header className={`flavours-and-ingredients-header ${className}`}>
            <Wrapper className="flavours-and-ingredients-header__content-wrapper">
                <h1 className="flavours-and-ingredients-header__title">
                    {trans('containers.flavoursAndIngredients.title')}
                </h1>

                <HeaderDropletImage
                    dropletIndex={0}
                    image={{
                        src: '/images/flavours-and-ingredients-header.jpg',
                        alt: trans('containers.flavoursAndIngredients.photoAlt'),
                    }}
                    avifSource="/images/flavours-and-ingredients-header.avif"
                    webpSource="/images/flavours-and-ingredients-header.webp"
                />
            </Wrapper>
        </header>
    );
};

export default FlavoursAndIngredientsHeader;
