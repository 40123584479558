import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import useScrollSectionIntoView from '../../../../hooks/useScrollSectionIntoView';

import './NavigationItem.scss';

interface NavigationItemProps {
    isActive: boolean;
    style?: CSSProperties;
    id: string;
    to: string;
    label: string;
    tabIndex?: number;
    className?: string;
}

const NavigationItem: FC<NavigationItemProps> = ({
    isActive,
    style,
    id,
    to = '',
    label,
    tabIndex,
    className = '',
}): ReactElement => {
    const { scrollIntoView } = useScrollSectionIntoView();

    const handleLinkClick = (): void => scrollIntoView(id);

    const navLinkClassNames = classNames('navigation-item__link', {
        'navigation-item__link--is-active': isActive,
    });

    return (
        <li style={style} className={`navigation-item ${className}`}>
            <Link
                to={to}
                aria-current={isActive ? 'page' : undefined}
                tabIndex={tabIndex}
                onClick={handleLinkClick}
                className={navLinkClassNames}
            >
                <span className="navigation-item__label">
                    {label}
                </span>
            </Link>
        </li>
    );
};

export default NavigationItem;
