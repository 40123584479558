import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Page } from '../../components';
import { ConnectedContactSection, ConnectedWorkAt } from '../../connectors';
import { locales } from '../../constants/locale';
import { getTranslation } from '../../helpers/trans';
import useTrans from '../../hooks/useTrans';

import './WorkAtPage.scss';

const WorkAtPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="work-at-page">
            <Helmet>
                {Object.values(locales).map(locale => {
                    const localePath = getTranslation(locale.language, 'routes.workAt.path');

                    return (
                        <link
                            key={locale.name}
                            rel="alternate"
                            hrefLang={locale.language}
                            href={trans('company.url') + localePath}
                        />
                    );
                })}
            </Helmet>

            <ConnectedWorkAt />

            <div className="work-at-page__contact-section-wrapper">
                <ConnectedContactSection
                    title={trans('pages.workAt.contactSection.title')}
                    description={trans('pages.workAt.contactSection.description')}
                    className="work-at-page__contact-section"
                />
            </div>
        </Page>
    );
};

export default WorkAtPage;
