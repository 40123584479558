/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './NotFoundSectionBackground.scss';

interface NotFoundSectionBackgroundProps {
    className?: string;
}

const NotFoundSectionBackground: FC<NotFoundSectionBackgroundProps> = ({
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'not-found-section-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled
            options={{
                taperEnd: 80,
                invertFlow: false,
                taperStart: 2,
                length: 50,
                angle: -50,
                magnitude: 90,
                phase: 150,
                duration: 7.5,
                start: 2,
                end: 10,
                repeat: 10,
            }}
            preserveAspectRatio="none"
            pathRefObject={pathRef}
            viewBoxWidth={1500}
            viewBoxHeight={700}
            className={`not-found-section-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="not-found-section-background__gradient-start" />
                <stop offset="50%" className="not-found-section-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M-345.7,1055c677.4,14.1,1368.9,21.2,2206.2,7.1c49.4-526.9-390.1-883.5-737.5-883.5c-297.8,0-303,111.5-1091.8,120.3C-253.4,302-418.4,688.4-345.7,1055"
                className="not-found-section-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default NotFoundSectionBackground;
/* eslint-enable max-len */
