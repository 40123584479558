import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    Reducer,
    useContext,
    useReducer,
} from 'react';

import { Params } from 'react-router-dom';

export const ParamContext = createContext<Params>({});
export const ParamDispatchContext = createContext((paramData => paramData) as Dispatch<Params>);

export const useParams = () => useContext(ParamContext);
export const useParamsDispatch = () => useContext(ParamDispatchContext);

const paramReducer: Reducer<Params, Params> = (prevState, paramData) => paramData;

const ParamProvider: FC<PropsWithChildren> = ({ children }) => {
    const [params, dispatch] = useReducer(paramReducer, {});

    return (
        <ParamContext.Provider value={params}>
            <ParamDispatchContext.Provider value={dispatch}>
                {children}
            </ParamDispatchContext.Provider>
        </ParamContext.Provider>
    );
};

export default ParamProvider;
