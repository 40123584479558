import { FC, ReactElement } from 'react';

import {
    LinkButton,
    PrismicSliceTitle,
    SafeHtml,
    Wrapper,
} from '../../../components';
import { PagePreview } from '../../../entities/@slices/PagePreview/PagePreview';

import './PagePreviewSlice.scss';

interface PagePreviewSliceProps extends PagePreview {
    className?: string;
}

const PagePreviewSlice: FC<PagePreviewSliceProps> = ({
    title,
    titleSize,
    titleColour,
    description,
    callToAction,
    className = '',
}): ReactElement => (
    <Wrapper className={`page-preview-slice ${className}`}>
        <PrismicSliceTitle
            text={title}
            size={titleSize}
            colour={titleColour}
            className="page-preview-slice__title"
        />

        <SafeHtml
            html={description}
            className="page-preview-slice__description"
        />

        {callToAction?.label && (
            <LinkButton
                to={callToAction.url}
                text={callToAction.label}
                className="page-preview-slice__call-to-action"
            />
        )}
    </Wrapper>
);

export default PagePreviewSlice;
