import { FC, ReactElement } from 'react';

import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
    className?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className = '' }): ReactElement => (
    <div className={`loading-spinner ${className}`} />
);

export default LoadingSpinner;
