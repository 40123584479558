import {
    FC,
    PropsWithChildren,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useDebounce } from 'react-use';

import { CheckIntersection } from '../../components';

interface ConnectedSectionProps {
    id: string;
    onIntersectionToggle: (id: string) => void;
    className?: string;
}

const ConnectedSection: FC<PropsWithChildren<ConnectedSectionProps>> = ({
    id,
    onIntersectionToggle,
    className = '',
    children,
}): ReactElement => {
    const defaultThreshold = 0.5;
    const debounceDelay = 500;

    const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
    const sectionRef = useRef<HTMLElement>(null);

    const threshold = useMemo((): number => {
        if (!sectionRef.current) return defaultThreshold;

        const { height } = sectionRef.current.getBoundingClientRect();

        const defaultThresholdSize = window.innerHeight * defaultThreshold;

        if (height > defaultThresholdSize) {
            return (defaultThresholdSize / height) * defaultThreshold;
        }

        return 1;
    }, [sectionRef.current]);

    useDebounce((): void => {
        if (isIntersecting) {
            onIntersectionToggle(id);
        }
    }, debounceDelay, [isIntersecting]);

    return (
        <CheckIntersection threshold={threshold} onIntersectionToggle={setIsIntersecting}>
            {() => (
                <section
                    ref={sectionRef}
                    id={id}
                    className={className}
                >
                    {children}
                </section>
            )}
        </CheckIntersection>
    );
};

export default ConnectedSection;
