import { FC, ReactElement, useRef } from 'react';

import { Wrapper } from '../../components';
import { SocialNavigation } from '../../compositions';
import useDeviceWidth from '../../hooks/useDeviceWidth';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';
import useElementScrollRatio from '../../hooks/useSectionScrollPosition';
import useTrans from '../../hooks/useTrans';
import { PlaygroundSectionBackground, PlaygroundSectionDesktopPhotoList, PlaygroundSectionPhotoList } from './subcomponents';
import { PlaygroundSectionPhoto } from './types';

import './PlaygroundSection.scss';

interface PlaygroundSectionProps {
    className?: string;
}

const PlaygroundSection: FC<PlaygroundSectionProps> = ({ className = '' }): ReactElement => {
    const trans = useTrans();
    const prefersReduceMotion = usePrefersReducedMotion();

    const playgroundSectionRef = useRef<HTMLDivElement>(null);

    const scrollRatio = useElementScrollRatio(playgroundSectionRef);
    const { isMobile } = useDeviceWidth();

    const photos: PlaygroundSectionPhoto[] = [
        {
            imageSrc: '/images/playground/playground-photo-1',
            videoSrc: '/videos/social-1',
            caption: trans('containers.playgroundSection.photoAlt'),
        },
        {
            imageSrc: '/images/playground/playground-photo-2',
            videoSrc: '/videos/social-2',
            caption: trans('containers.playgroundSection.photoAlt'),
        },
        {
            imageSrc: '/images/playground/playground-photo-3',
            videoSrc: '/videos/social-3',
            caption: trans('containers.playgroundSection.photoAlt'),
        },
    ];

    return (
        <div ref={playgroundSectionRef} className={`playground-section ${className}`}>
            <Wrapper className="playground-section__wrapper">
                <h2 className="playground-section__title">
                    {trans('containers.playgroundSection.title')}
                </h2>

                <div className="playground-section__photos-and-description">
                    {isMobile ? (
                        <PlaygroundSectionPhotoList
                            photos={photos}
                            className="playground-section__photo-list"
                        />
                    ) : (
                        <PlaygroundSectionDesktopPhotoList
                            photos={photos}
                            className="playground-section__photo-list"
                        />
                    )}

                    <div className="playground-section__description-wrapper">
                        <p className="playground-section__description">
                            {trans('containers.playgroundSection.description')}
                        </p>

                        <p className="playground-section__hashtag">
                            #yangasportswater
                        </p>

                        <SocialNavigation className="playground-section__social-navigation" />
                    </div>

                    <div className="playground-section__svg-wrapper">
                        <PlaygroundSectionBackground
                            isAnimationEnabled={!prefersReduceMotion && scrollRatio > 0 && scrollRatio < 100}
                            className="playground-section__svg"
                        />
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default PlaygroundSection;
