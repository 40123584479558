import { FC, ReactElement } from 'react';

import { Icon, LinkButton } from '../../../../components';
import { AppRoute, appRoutes } from '../../../../constants/routing';
import { PrismicVacancyEmploymentType, Vacancy } from '../../../../entities/Vacancy/Vacancy';
import { getEnumKeyByEnumValue } from '../../../../helpers/enum';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useTrans from '../../../../hooks/useTrans';

import './VacancyOverviewItem.scss';

interface VacancyOverviewItemProps {
    vacancy: Vacancy;
    className?: string;
}

const VacancyOverviewItem: FC<VacancyOverviewItemProps> = ({
    vacancy,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const paramPath = trans(appRoutes[AppRoute.vacancyDetail].path);
    const vacancyPath = replaceUrlParamKeysWithValues(paramPath, {
        slug: vacancy.slug,
    });

    const employmentKey = vacancy.employmentType
        ? getEnumKeyByEnumValue(PrismicVacancyEmploymentType, vacancy.employmentType)
        : undefined;

    const fallbackEmploymentLabel = employmentKey
        ? trans(`containers.vacancyOverview.employment.${employmentKey}`)
        : vacancy.employmentType;

    const employmentLabel = vacancy.hoursPerWeek
        ? trans('containers.vacancyOverview.hoursPerWeek', { hours: vacancy.hoursPerWeek })
        : fallbackEmploymentLabel;

    return (
        <li className={`vacancy-overview-item ${className}`}>
            {vacancy.icon && (
                <Icon name={vacancy.icon} className="vacancy-overview-item__icon" />
            )}

            <div className="vacancy-overview-item__text-wrapper">
                <LinkButton
                    to={vacancyPath}
                    text={vacancy.title}
                    className="vacancy-overview-item__link-button"
                />

                <div className="vacancy-overview-item__meta-wrapper">
                    <p className="vacancy-overview-item__meta-label">
                        {employmentLabel}
                    </p>

                    <p className="vacancy-overview-item__meta-label">
                        {vacancy.address.city}
                    </p>
                </div>
            </div>
        </li>
    );
};

export default VacancyOverviewItem;
