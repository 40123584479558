import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDebounce } from 'react-use';
import { noop } from 'react-use/lib/misc/util';

import { ScrollTrigger } from '../../../../entities/Gsap/GsapService';
import useDocumentScroll from '../../../../hooks/useDocumentScroll';
import useDocumentScrollSize from '../../../../hooks/useDocumentScrollSize';
import { PlaygroundSectionPhoto } from '../../types';
import { PlaygroundSectionButton } from '..';

import './PlaygroundSectionPhotoList.scss';

interface PlaygroundSectionPhotoListProps {
    photos: PlaygroundSectionPhoto[];
    className?: string;
}

const PlaygroundSectionPhotoList: FC<PlaygroundSectionPhotoListProps> = ({ photos, className = '' }): ReactElement => {
    const listRef = useRef<HTMLDivElement>(null);
    const scrollY = useDocumentScroll();
    const { height: documentScrollHeight } = useDocumentScrollSize();

    const [activeVideo, setActiveVideo] = useState<number>();
    const [isMobileVideoPlaying, setIsMobileVideoPlaying] = useState<boolean>(true);

    const handleButtonClick = (index: number): void => {
        setActiveVideo(index);
        setIsMobileVideoPlaying(!isMobileVideoPlaying);
    };

    useEffect((): void => {
        setIsMobileVideoPlaying(false);
    }, [scrollY]);

    useEffect((): void => {
        setIsMobileVideoPlaying(activeVideo !== undefined);
    }, [activeVideo]);

    useEffect((): () => void => {
        if (!listRef.current) {
            return noop;
        }

        const buttons = Array.from(listRef.current.getElementsByTagName('button'));

        const gap = 24;
        const headerHeight = 100;

        const scrollTriggers = buttons
            .map((button, index) => ScrollTrigger.create({
                invalidateOnRefresh: true,
                pin: true,
                trigger: button,
                start: `-${headerHeight}px 0`,
                end: () => `+=${(button.scrollHeight + gap) * ((buttons.length - 1) - index)}px`,
            }));

        return () => {
            scrollTriggers.forEach(trigger => trigger.kill());
        };
    }, []);

    useDebounce((): void => {
        if (documentScrollHeight) {
            // ScrollTrigger only updates on a window resize. So we have to manually trigger refresh
            // when the document height changes.
            ScrollTrigger.refresh();
        }
    }, 100, [documentScrollHeight]);

    return (
        <div ref={listRef} className={`playground-section-photo-list ${className}`}>
            {photos.map((photo, index) => (
                <PlaygroundSectionButton
                    isPlaying={index === activeVideo && isMobileVideoPlaying}
                    showVideo={index === activeVideo}
                    key={photo.imageSrc}
                    text={photo.caption}
                    imageSrc={photo.imageSrc}
                    videoSrc={photo.videoSrc}
                    onClick={() => handleButtonClick(index)}
                    className="playground-section-photo-list__button"
                    wrapperClassName="playground-section-photo-list__button-wrapper"
                />
            ))}
        </div>
    );
};

export default PlaygroundSectionPhotoList;
