import { Locale, LocaleData, locales } from './locale';

export enum Continent {
    africa = 'africa',
    europe = 'europe',
    northAmerica = 'northAmerica',
    asia = 'asia',
    oceania = 'oceanica',
    middleEast = 'middleEast',
    southAmerica = 'southAmerica',
    antarctica = 'antarctica',
}

export interface Country {
    name: string;
    mainLocale?: LocaleData;
}

export interface CountryData extends Country {
    continent: Continent;
}

export const defaultCountry: CountryData = {
    name: 'United States of America',
    continent: Continent.northAmerica,
};

export const defaultContinentLocale: Record<Continent, LocaleData> = {
    [Continent.africa]: locales[Locale.englishUs],
    [Continent.europe]: locales[Locale.englishGb],
    [Continent.northAmerica]: locales[Locale.englishUs],
    [Continent.asia]: locales[Locale.englishUs],
    [Continent.oceania]: locales[Locale.englishUs],
    [Continent.middleEast]: locales[Locale.arabic],
    [Continent.southAmerica]: locales[Locale.spanish],
    [Continent.antarctica]: locales[Locale.englishUs],
};

export const customCountry = '{{custom}}';

export const continentOptions = [Continent.northAmerica, Continent.europe, Continent.middleEast];

const continents: Record<Continent, Country[]> = {
    [Continent.africa]: [
        { name: 'Algeria', mainLocale: locales[Locale.arabic] },
        { name: 'Angola' },
        { name: 'Benin', mainLocale: locales[Locale.french] },
        { name: 'Botswana', mainLocale: locales[Locale.englishUs] },
        { name: 'Burkina Faso', mainLocale: locales[Locale.french] },
        { name: 'Burundi', mainLocale: locales[Locale.french] },
        { name: 'Cabo Verde' },
        { name: 'Cameroon', mainLocale: locales[Locale.french] },
        { name: 'Central African Republic', mainLocale: locales[Locale.french] },
        { name: 'Chad', mainLocale: locales[Locale.arabic] },
        { name: 'Comoros', mainLocale: locales[Locale.arabic] },
        { name: 'Republic of the Congo', mainLocale: locales[Locale.french] },
        { name: 'Democratic Republic of the Congo', mainLocale: locales[Locale.french] },
        { name: 'Djibouti', mainLocale: locales[Locale.arabic] },
        { name: 'Equatorial Guinea', mainLocale: locales[Locale.spanish] },
        { name: 'Eritrea' },
        { name: 'Eswatini' },
        { name: 'Ethiopia' },
        { name: 'French Southern Territories' },
        { name: 'Gabon', mainLocale: locales[Locale.french] },
        { name: 'Gambia', mainLocale: locales[Locale.englishUs] },
        { name: 'Ghana', mainLocale: locales[Locale.englishUs] },
        { name: 'Guinea', mainLocale: locales[Locale.french] },
        { name: 'Guinea-Bissau' },
        { name: 'Ivory Coast', mainLocale: locales[Locale.french] },
        { name: 'Kenya', mainLocale: locales[Locale.englishUs] },
        { name: 'Lesotho', mainLocale: locales[Locale.englishUs] },
        { name: 'Liberia', mainLocale: locales[Locale.englishUs] },
        { name: 'Libya', mainLocale: locales[Locale.arabic] },
        { name: 'Madagascar', mainLocale: locales[Locale.french] },
        { name: 'Malawi', mainLocale: locales[Locale.englishUs] },
        { name: 'Mali', mainLocale: locales[Locale.french] },
        { name: 'Mauritania', mainLocale: locales[Locale.arabic] },
        { name: 'Mauritius', mainLocale: locales[Locale.french] },
        { name: 'Mayotte', mainLocale: locales[Locale.french] },
        { name: 'Morocco', mainLocale: locales[Locale.arabic] },
        { name: 'Mozambique' },
        { name: 'Namibia', mainLocale: locales[Locale.englishUs] },
        { name: 'Niger', mainLocale: locales[Locale.french] },
        { name: 'Nigeria', mainLocale: locales[Locale.englishUs] },
        { name: 'Réunion', mainLocale: locales[Locale.french] },
        { name: 'Rwanda', mainLocale: locales[Locale.french] },
        { name: 'Saint Helena, Ascension and Tristan da Cunha' },
        { name: 'Sao Tome and Principe' },
        { name: 'Senegal', mainLocale: locales[Locale.french] },
        { name: 'Seychelles', mainLocale: locales[Locale.englishUs] },
        { name: 'Sierra Leone', mainLocale: locales[Locale.englishUs] },
        { name: 'Somalia', mainLocale: locales[Locale.arabic] },
        { name: 'South Africa' },
        { name: 'South Sudan', mainLocale: locales[Locale.arabic] },
        { name: 'Sudan', mainLocale: locales[Locale.arabic] },
        { name: 'Tanzania', mainLocale: locales[Locale.englishUs] },
        { name: 'Togo', mainLocale: locales[Locale.french] },
        { name: 'Tunisia', mainLocale: locales[Locale.arabic] },
        { name: 'Uganda', mainLocale: locales[Locale.englishUs] },
        { name: 'Western Sahara', mainLocale: locales[Locale.arabic] },
        { name: 'Zambia', mainLocale: locales[Locale.englishUs] },
        { name: 'Zimbabwe' },
    ],
    [Continent.europe]: [
        { name: 'Åland Islands' },
        { name: 'Albania' },
        { name: 'Andorra' },
        { name: 'Austria', mainLocale: locales[Locale.german] },
        { name: 'Belarus' },
        { name: 'Belgium', mainLocale: locales[Locale.dutch] },
        { name: 'Bosnia and Herzegovina' },
        { name: 'Bulgaria' },
        { name: 'Croatia' },
        { name: 'Czechia' },
        { name: 'Denmark' },
        { name: 'Estonia' },
        { name: 'Faroe Islands' },
        { name: 'Finland' },
        { name: 'France', mainLocale: locales[Locale.french] },
        { name: 'Germany', mainLocale: locales[Locale.german] },
        { name: 'Gibraltar' },
        { name: 'Greece' },
        { name: 'Guernsey' },
        { name: 'Hungary' },
        { name: 'Iceland' },
        { name: 'Ireland', mainLocale: locales[Locale.englishGb] },
        { name: 'Isle of Man' },
        { name: 'Italy' },
        { name: 'Jersey' },
        { name: 'Latvia' },
        { name: 'Liechtenstein', mainLocale: locales[Locale.german] },
        { name: 'Lithuania' },
        { name: 'Luxembourg', mainLocale: locales[Locale.french] },
        { name: 'Malta' },
        { name: 'Moldova' },
        { name: 'Monaco', mainLocale: locales[Locale.french] },
        { name: 'Montenegro' },
        { name: 'Netherlands', mainLocale: locales[Locale.dutch] },
        { name: 'North Macedonia' },
        { name: 'Norway' },
        { name: 'Poland' },
        { name: 'Portugal' },
        { name: 'Romania' },
        { name: 'Russia' },
        { name: 'San Marino' },
        { name: 'Serbia' },
        { name: 'Slovakia' },
        { name: 'Slovenia' },
        { name: 'Spain', mainLocale: locales[Locale.spanish] },
        { name: 'Svalbard and Jan Mayen' },
        { name: 'Sweden' },
        { name: 'Switzerland', mainLocale: locales[Locale.german] },
        { name: 'Ukraine' },
        { name: 'United Kingdom', mainLocale: locales[Locale.englishGb] },
        { name: 'Holy See' },
    ],
    [Continent.northAmerica]: [
        { name: 'Anguilla' },
        { name: 'Antigua and Barbuda' },
        { name: 'Aruba', mainLocale: locales[Locale.dutch] },
        { name: 'Bahamas' },
        { name: 'Barbados' },
        { name: 'Belize' },
        { name: 'Bermuda' },
        { name: 'Canada', mainLocale: locales[Locale.englishUs] },
        { name: 'Caribbean Netherlands' },
        { name: 'Cayman Islands' },
        { name: 'Costa Rica', mainLocale: locales[Locale.spanish] },
        { name: 'Cuba', mainLocale: locales[Locale.spanish] },
        { name: 'Curaçao', mainLocale: locales[Locale.dutch] },
        { name: 'Dominica' },
        { name: 'Dominican Republic', mainLocale: locales[Locale.spanish] },
        { name: 'El Salvador', mainLocale: locales[Locale.spanish] },
        { name: 'Greenland' },
        { name: 'Grenada' },
        { name: 'Guadeloupe', mainLocale: locales[Locale.french] },
        { name: 'Guatemala', mainLocale: locales[Locale.spanish] },
        { name: 'Haiti', mainLocale: locales[Locale.french] },
        { name: 'Honduras', mainLocale: locales[Locale.spanish] },
        { name: 'Jamaica' },
        { name: 'Martinique', mainLocale: locales[Locale.french] },
        { name: 'Mexico', mainLocale: locales[Locale.spanish] },
        { name: 'Montserrat' },
        { name: 'Nicaragua', mainLocale: locales[Locale.spanish] },
        { name: 'Panama' },
        { name: 'Puerto Rico', mainLocale: locales[Locale.spanish] },
        { name: 'Saint Barthélemy' },
        { name: 'Saint Kitts and Nevis' },
        { name: 'Saint Lucia' },
        { name: 'Saint Martin', mainLocale: locales[Locale.french] },
        { name: 'Saint Pierre and Miquelon' },
        { name: 'Saint Vincent and the Grenadines' },
        { name: 'Sint Maarten', mainLocale: locales[Locale.dutch] },
        { name: 'Trinidad and Tobago' },
        { name: 'Turks and Caicos Islands' },
        { name: 'Virgin Islands (UK)' },
        { name: 'Virgin Islands (US)' },
        { name: 'United States Minor Outlying Islands' },
        { name: 'United States of America', mainLocale: locales[Locale.englishUs] },
    ],
    [Continent.asia]: [
        { name: 'Afghanistan' },
        { name: 'Armenia' },
        { name: 'Azerbaijan' },
        { name: 'Bangladesh' },
        { name: 'Bhutan' },
        { name: 'British Indian Ocean Territory', mainLocale: locales[Locale.englishGb] },
        { name: 'Brunei' },
        { name: 'Cambodia', mainLocale: locales[Locale.englishUs] },
        { name: 'China' },
        { name: 'Georgia' },
        { name: 'Hong Kong', mainLocale: locales[Locale.englishGb] },
        { name: 'India', mainLocale: locales[Locale.englishGb] },
        { name: 'Indonesia' },
        { name: 'Japan' },
        { name: 'Kazakhstan' },
        { name: 'Kyrgyzstan' },
        { name: 'Laos' },
        { name: 'Macao' },
        { name: 'Malaysia' },
        { name: 'Maldives' },
        { name: 'Mongolia' },
        { name: 'Myanmar' },
        { name: 'Nepal' },
        { name: 'North Korea' },
        { name: 'Pakistan', mainLocale: locales[Locale.englishUs] },
        { name: 'Philippines', mainLocale: locales[Locale.englishUs] },
        { name: 'Singapore', mainLocale: locales[Locale.englishUs] },
        { name: 'South Korea' },
        { name: 'Sri Lanka' },
        { name: 'Taiwan' },
        { name: 'Tajikistan' },
        { name: 'Thailand' },
        { name: 'Timor-Leste' },
        { name: 'Turkmenistan' },
        { name: 'Uzbekistan' },
        { name: 'Vietnam' },
    ],
    [Continent.oceania]: [
        { name: 'American Samoa', mainLocale: locales[Locale.englishUs] },
        { name: 'Australia', mainLocale: locales[Locale.englishGb] },
        { name: 'Christmas Island', mainLocale: locales[Locale.englishGb] },
        { name: 'Cocos Islands' },
        { name: 'Cook Islands', mainLocale: locales[Locale.englishGb] },
        { name: 'Fiji', mainLocale: locales[Locale.englishGb] },
        { name: 'French Polynesia', mainLocale: locales[Locale.french] },
        { name: 'Guam', mainLocale: locales[Locale.englishUs] },
        { name: 'Kiribati' },
        { name: 'Marshall Islands', mainLocale: locales[Locale.englishUs] },
        { name: 'Micronesia' },
        { name: 'Nauru' },
        { name: 'New Caledonia', mainLocale: locales[Locale.french] },
        { name: 'New Zealand', mainLocale: locales[Locale.englishGb] },
        { name: 'Niue', mainLocale: locales[Locale.englishGb] },
        { name: 'Norfolk Island', mainLocale: locales[Locale.englishGb] },
        { name: 'Northern Mariana Islands' },
        { name: 'Palau', mainLocale: locales[Locale.englishUs] },
        { name: 'Papua New Guinea' },
        { name: 'Pitcairn', mainLocale: locales[Locale.englishGb] },
        { name: 'Samoa', mainLocale: locales[Locale.englishUs] },
        { name: 'Solomon Islands' },
        { name: 'Tokelau' },
        { name: 'Tonga' },
        { name: 'Tuvalu', mainLocale: locales[Locale.englishGb] },
        { name: 'Vanuatu', mainLocale: locales[Locale.englishGb] },
        { name: 'Wallis and Futuna', mainLocale: locales[Locale.french] },
    ],
    [Continent.middleEast]: [
        { name: 'Bahrain', mainLocale: locales[Locale.arabic] },
        { name: 'Cyprus', mainLocale: locales[Locale.englishGb] },
        { name: 'Egypt', mainLocale: locales[Locale.arabic] },
        { name: 'Iran' },
        { name: 'Iraq', mainLocale: locales[Locale.arabic] },
        { name: 'Israel', mainLocale: locales[Locale.arabic] },
        { name: 'Jordan', mainLocale: locales[Locale.arabic] },
        { name: 'Kuwait', mainLocale: locales[Locale.arabic] },
        { name: 'Lebanon', mainLocale: locales[Locale.arabic] },
        { name: 'Oman', mainLocale: locales[Locale.arabic] },
        { name: 'Palestine', mainLocale: locales[Locale.arabic] },
        { name: 'Qatar', mainLocale: locales[Locale.arabic] },
        { name: 'Saudi Arabia', mainLocale: locales[Locale.arabic] },
        { name: 'Syria', mainLocale: locales[Locale.arabic] },
        { name: 'Türkiye' },
        { name: 'United Arab Emirates', mainLocale: locales[Locale.arabic] },
        { name: 'Yemen', mainLocale: locales[Locale.arabic] },
    ],
    [Continent.southAmerica]: [
        { name: 'Argentina', mainLocale: locales[Locale.spanish] },
        { name: 'Bolivia', mainLocale: locales[Locale.spanish] },
        { name: 'Brazil', mainLocale: locales[Locale.englishUs] },
        { name: 'Chile', mainLocale: locales[Locale.spanish] },
        { name: 'Colombia', mainLocale: locales[Locale.spanish] },
        { name: 'Ecuador', mainLocale: locales[Locale.spanish] },
        { name: 'Falkland Islands', mainLocale: locales[Locale.englishGb] },
        { name: 'French Guiana', mainLocale: locales[Locale.french] },
        { name: 'Guyana', mainLocale: locales[Locale.englishUs] },
        { name: 'Paraguay', mainLocale: locales[Locale.spanish] },
        { name: 'Peru', mainLocale: locales[Locale.spanish] },
        { name: 'South Georgia and the South Sandwich Islands', mainLocale: locales[Locale.englishGb] },
        { name: 'Suriname', mainLocale: locales[Locale.dutch] },
        { name: 'Uruguay', mainLocale: locales[Locale.spanish] },
        { name: 'Venezuela', mainLocale: locales[Locale.spanish] },
    ],
    [Continent.antarctica]: [
        { name: 'Antarctica' },
    ],
};

export const getCountryFromName = (countryName: string): CountryData => {
    const foundContinentData = Object.entries(continents).find(([, countries]) => (
        countries.find(continentCountry => continentCountry.name === countryName)
    ));

    if (foundContinentData) {
        const [foundContinent] = foundContinentData;
        const continent = foundContinent as Continent;
        const foundCountryData = continents[continent].find(country => (
            country.name === countryName
        ));

        if (foundCountryData) {
            return {
                name: countryName,
                continent,
                mainLocale: foundCountryData.mainLocale || defaultContinentLocale[continent],
            };
        }

        return defaultCountry;
    }

    return defaultCountry;
};
