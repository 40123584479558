import { RefObject, useEffect, useState } from 'react';

import { useWindowSize } from 'react-use';
import { useScrollYPosition } from 'react-use-scroll-position';

import { clamp } from '../helpers/number';

const useElementScrollRatio = (refElement: RefObject<Element>): number => {
    const { height: pageHeight } = useWindowSize();
    const scrollY = useScrollYPosition();

    const [refSize, setRefSize] = useState<number>(0);
    const [refStart, setRefStart] = useState<number>(0);
    const [refEnd, setRefEnd] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);

    useEffect((): void => {
        if (refElement.current) {
            const { height, top } = refElement.current.getBoundingClientRect();
            const justifiedTop = document.documentElement.scrollTop + top;

            const bottom = justifiedTop + height;

            setRefSize(height);
            setRefStart(justifiedTop);
            setRefEnd(bottom);
        }
    }, [refElement, pageHeight]);

    useEffect((): void => {
        if (refSize && refEnd) {
            const windowHeight = window.innerHeight;
            const clampedScrollPosition = clamp(scrollY, refStart - windowHeight, refEnd);
            const scrollPositionWithinRef = clampedScrollPosition - refStart + windowHeight;

            const newPercentage = Math.min(100, (scrollPositionWithinRef / (refSize + windowHeight)) * 100);

            setPercentage(newPercentage);
        }
    }, [refSize, refStart, refEnd, scrollY, pageHeight]);

    return percentage;
};

export default useElementScrollRatio;
