/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCaffeinatedDrinks: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M66.7,57.4L44.9,80.9c-0.2,0.2-0.5,0.4-0.9,0.5c-0.3,0.1-0.7,0-1-0.1c-0.3-0.2-0.5-0.4-0.7-0.7c-0.1-0.3-0.2-0.7-0.1-1l2.9-14.4l-11.2-4.3c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.3-0.7c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.3,0.2-0.5,0.4-0.7l21.8-23.6c0.2-0.2,0.5-0.4,0.9-0.5c0.3-0.1,0.7,0,1,0.1c0.3,0.2,0.5,0.4,0.7,0.7c0.1,0.3,0.2,0.7,0.1,1l-2.9,14.4l11.2,4.2c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0.1,0.3,0.1,0.5,0,0.8C67,56.9,66.9,57.2,66.7,57.4L66.7,57.4z" />
        <path d="M50,100c-11.7,0-23.7-5.9-30.8-15.1c-4.7-6.1-11.1-19-1.3-36C23.1,40,27,33.1,30.1,27.8c6.6-11.5,9.3-16.2,11.3-19.2C42,7.7,42.5,6.9,43,6.2l0.5-0.8c0.6-0.9,1.2-1.9,1.9-2.7C46.3,1.4,47.7,0,50,0c2.3,0,3.7,1.4,4.7,2.6c0.7,0.8,1.3,1.8,1.9,2.7L57,6.2c0.5,0.8,1,1.6,1.5,2.4c2,3,4.7,7.7,11.3,19.2C73,33.1,76.9,40,82,48.9l0,0c9.8,17,3.4,29.8-1.3,35.9C73.7,94.1,61.7,100,50,100z M50,4.3c-0.2,0-0.5,0-1.3,1c-0.5,0.6-1.1,1.5-1.6,2.3l-0.4,0.7c-0.5,0.9-1.1,1.7-1.6,2.6c-1.9,2.9-4.6,7.6-11.1,18.9c-3.1,5.4-7.1,12.3-12.2,21.2c-6.3,11-6,22.1,1,31.2c6.2,8.2,17,13.5,27.4,13.5c10.4,0,21.1-5.3,27.4-13.5c7-9.1,7.3-20.2,1-31.2c-5.1-8.9-9.1-15.8-12.2-21.1c-6.5-11.4-9.2-16.1-11.1-19c-0.6-0.9-1.1-1.7-1.6-2.5l-0.5-0.8c-0.5-0.8-1.1-1.7-1.6-2.3C50.5,4.3,50.2,4.3,50,4.3z" />
    </Svg>
);

export default IconCaffeinatedDrinks;
/* eslint-enable max-len */
