import { FC, ReactElement } from 'react';

import { Button } from '../../components';
import useTrans from '../../hooks/useTrans';

import './PersonalityTestCta.scss';

interface PersonalityTestCtaProps {
    onCtaButtonClick: () => void;
    className?: string;
}

const PersonalityTestCta: FC<PersonalityTestCtaProps> = ({
    onCtaButtonClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`personality-test-cta ${className}`}>
            <div className="personality-test-cta__wrapper">
                <h2 className="personality-test-cta__title">
                    {trans('compositions.personalityTestCta.title')}
                </h2>

                <p className="personality-test-cta__description">
                    {trans('compositions.personalityTestCta.description')}
                </p>

                <Button
                    text={trans('compositions.personalityTestCta.link')}
                    onClick={onCtaButtonClick}
                    className="personality-test-cta__link-button"
                />
            </div>
        </div>
    );
};

export default PersonalityTestCta;
