/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconPlay: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M27.2,84.4L83.5,50L27.2,15.6V84.4z M22.6,7.4c3-1.7,6.6-1.6,9.5,0.2L88.4,42c2.8,1.7,4.5,4.7,4.5,8c0,3.3-1.7,6.3-4.5,8L32.1,92.4c-2.9,1.8-6.5,1.8-9.5,0.2c-2.9-1.7-4.8-4.8-4.8-8.2V15.6C17.9,12.2,19.7,9.1,22.6,7.4z" />
    </Svg>
);

export default IconPlay;
/* eslint-enable max-len */
