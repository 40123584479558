import { RichText } from 'prismic-dom';

import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { getLanguageFromPrismicLanguage } from '../../helpers/prismic';
import { transformToImage } from '../@slices/Image/ImageTransformers';
import { transformToDateIsoString } from '../Date/DateTransformers';
import { PrismicDocument, PrismicSearchResponse, PrismicSliceTitleColour } from '../Prismic/Prismic';
import { defaultSeo } from '../Seo/Seo';
import { transformToSeo } from '../Seo/SeoTransformers';
import { Article, ArticleOverviewInfo, PrismicArticleTemplate } from './Article';

export const transformPrismicSearchResponseToArticleOverviewInfo = (prismicSearchResponse: PrismicSearchResponse): ArticleOverviewInfo => ({
    currentPage: prismicSearchResponse.page,
    pageSize: prismicSearchResponse.results_per_page,
    totalPages: prismicSearchResponse.total_pages,
    totalResults: prismicSearchResponse.total_results_size,
});

export const transformPrismicDocumentToArticle = (prismicDocument: PrismicDocument<PrismicArticleTemplate>): Article => {
    const titleText = RichText.asText(prismicDocument.data.title);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicDocument.data.title_colour);

    const introText = RichText.asText(prismicDocument.data.intro);

    const thumbnail = prismicDocument.data.thumbnail?.url
        ? transformToImage(prismicDocument.data.thumbnail)
        : undefined;

    const poster = prismicDocument.data.poster?.url
        ? transformToImage(prismicDocument.data.poster)
        : undefined;

    const posterDroplet = prismicDocument.data.droplet
        ? Number(prismicDocument.data.droplet.replace('Droplet ', ''))
        : 1;

    const seo = prismicDocument.data.seo?.length > 0
        ? transformToSeo(prismicDocument.data.seo[0])
        : defaultSeo;

    const alternativeLanguages = prismicDocument.alternate_languages.map(altLang => ({
        language: getLanguageFromPrismicLanguage(altLang.lang),
        slug: altLang.uid || '',
    }));

    const createdAt = prismicDocument.first_publication_date
        ? transformToDateIsoString(prismicDocument.first_publication_date)
        : undefined;

    const updatedAt = prismicDocument.last_publication_date
        ? transformToDateIsoString(prismicDocument.last_publication_date)
        : undefined;

    return {
        id: prismicDocument.id,
        slug: prismicDocument.uid,
        title: titleText,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        intro: introText,
        categories: prismicDocument.tags,
        author: prismicDocument.data.author,
        readTime: prismicDocument.data.read_time,
        thumbnail,
        poster,
        posterDroplet,
        seo,
        body: prismicDocument.data.body,
        alternativeLanguages,
        createdAt,
        updatedAt,
    };
};
