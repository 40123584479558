import { FC, ReactElement } from 'react';

import {
    IconArrowLeft,
    IconArrowRight,
    IconBin,
    IconBrita,
    IconCaffeinatedDrinks,
    IconCalories,
    IconCheck,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconChevronUp,
    IconColourantFree,
    IconCross,
    IconCrueltyFree,
    IconDumbbell,
    IconFacebook,
    IconFlavourIntensity,
    IconFlavours,
    IconGlobe,
    IconGlutenFree,
    IconGmoFree,
    IconHalal,
    IconHamburger,
    IconInstagram,
    IconIntensity,
    IconKosher,
    IconLink,
    IconLinkedIn,
    IconMinus,
    IconNaturalFlavouring,
    IconNoPlastic,
    IconPause,
    IconPerfectMix,
    IconPlay,
    IconPlus,
    IconRefillable,
    IconReplay,
    IconSearch,
    IconShare,
    IconSpotify,
    IconSugarFree,
    IconTikTok,
    IconVacancyDesign,
    IconVacancyEducation,
    IconVacancyEmployment,
    IconVacancyEngineering,
    IconVacancyFinance,
    IconVacancyLocation,
    IconVacancyMarketing,
    IconVacancySales,
    IconVacancyService,
    IconVacancySoftwareDevelopment,
    IconVacancyWarehouseLogistics,
    IconValueForMoney,
    IconVegan,
    IconVitamins,
    IconWhatsApp,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

const icons = {
    'arrow-left': IconArrowLeft,
    'arrow-right': IconArrowRight,
    bin: IconBin,
    brita: IconBrita,
    'caffeinated-drinks': IconCaffeinatedDrinks,
    calories: IconCalories,
    check: IconCheck,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'chevron-up': IconChevronUp,
    'colourant-free': IconColourantFree,
    cross: IconCross,
    'cruelty-free': IconCrueltyFree,
    dumbbell: IconDumbbell,
    facebook: IconFacebook,
    'flavour-intensity': IconFlavourIntensity,
    flavours: IconFlavours,
    globe: IconGlobe,
    'gluten-free': IconGlutenFree,
    'gmo-free': IconGmoFree,
    halal: IconHalal,
    hamburger: IconHamburger,
    instagram: IconInstagram,
    intensity: IconIntensity,
    kosher: IconKosher,
    link: IconLink,
    linkedin: IconLinkedIn,
    minus: IconMinus,
    'natural-flavouring': IconNaturalFlavouring,
    'no-plastic': IconNoPlastic,
    'perfect-mix': IconPerfectMix,
    pause: IconPause,
    play: IconPlay,
    plus: IconPlus,
    refillable: IconRefillable,
    replay: IconReplay,
    search: IconSearch,
    share: IconShare,
    spotify: IconSpotify,
    'sugar-free': IconSugarFree,
    tiktok: IconTikTok,
    'vacancy-design': IconVacancyDesign,
    'vacancy-education': IconVacancyEducation,
    'vacancy-employment': IconVacancyEmployment,
    'vacancy-engineering': IconVacancyEngineering,
    'vacancy-finance': IconVacancyFinance,
    'vacancy-location': IconVacancyLocation,
    'vacancy-marketing': IconVacancyMarketing,
    'vacancy-sales': IconVacancySales,
    'vacancy-service': IconVacancyService,
    'vacancy-software-development': IconVacancySoftwareDevelopment,
    'vacancy-warehouse-and-logistics': IconVacancyWarehouseLogistics,
    'value-for-money': IconValueForMoney,
    vegan: IconVegan,
    vitamins: IconVitamins,
    whatsapp: IconWhatsApp,
};

export type IconName = keyof typeof icons;

interface IconProps extends SvgIconProps {
    name: IconName;
}

const Icon: FC<IconProps> = ({
    name,
    className = '',
}): ReactElement | null => {
    const IconComponent = icons[name];

    return IconComponent
        ? <IconComponent className={`icon ${className}`} />
        : null;
};

export default Icon;
