/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronDown: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={58.8} className={className}>
        <path d="M2.1,2.2C-0.5,4.8-0.7,9,1.5,11.9l0.6,0.7l42.9,44.1c2.6,2.7,6.6,2.9,9.4,0.6l43.5-44.7c2.8-2.9,2.8-7.5,0-10.4c-2.6-2.7-6.6-2.9-9.4-0.6l-0.7,0.6L50,41.1L12.2,2.2C9.6-0.5,5.6-0.7,2.8,1.5L2.1,2.2z" />
    </Svg>
);

export default IconChevronDown;
/* eslint-enable max-len */
