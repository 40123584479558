import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultFaqData, FaqData } from '../../entities/Faq/Faq';
import { AsyncReduxState } from '../../types';

export type FaqState = AsyncReduxState<{
    faqData: FaqData;
}>;

const initialState: FaqState = {
    ...initialAsyncReduxState,
    faqData: defaultFaqData,
};

const faqSlice = createSlice({
    name: 'faqReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): FaqState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): FaqState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFaqData(state, action: PayloadAction<FaqData>): FaqState {
            return {
                ...state,
                faqData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setFaqData,
} = faqSlice.actions;

export default faqSlice;
