import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
    LinkButton,
    PrismicSliceTitle,
    SafeHtml,
    Wrapper,
} from '../../../components';
import { CleanHubSection } from '../../../entities/@slices/CleanHubSection/CleanHubSection';
import useCleanHubWidget from '../../../hooks/useCleanHubWidget';

import './CleanHubSectionSlice.scss';

interface CleanHubSectionSliceProps extends CleanHubSection {
    className?: string;
}

const CleanHubSectionSlice: FC<CleanHubSectionSliceProps> = ({
    isMirrored,
    isMirroredMobile,
    image,
    title,
    titleSize,
    titleColour,
    paragraphHtml,
    callToAction,
    className = '',
}): ReactElement => {
    useCleanHubWidget(process.env.REACT_APP_CLEAN_HUB_CLIENT_ID || '1vpnc4hbdg7eb0osjeb4mpokts9iq9pejpliqh75');

    const cleanHubSectionSliceClassNames = classNames('clean-hub-section-slice', {
        'clean-hub-section-slice--is-mirrored': isMirrored,
        'clean-hub-section-slice--is-mirrored-mobile': isMirroredMobile,
    }, className);

    return (
        <Wrapper className={cleanHubSectionSliceClassNames}>
            <div className="clean-hub-section-slice__image-wrapper">
                <img
                    src={image.src}
                    alt={image.alt}
                    className="clean-hub-section-slice__image"
                />
            </div>

            <div className="clean-hub-section-slice__text-wrapper">
                <PrismicSliceTitle
                    text={title}
                    size={titleSize}
                    colour={titleColour}
                    className="clean-hub-section-slice__title"
                />

                {paragraphHtml && (
                    <SafeHtml
                        html={paragraphHtml}
                        className="clean-hub-section-slice__paragraph"
                    />
                )}

                {callToAction?.label && (
                    <LinkButton
                        to={callToAction.url}
                        text={callToAction.label}
                        className="clean-hub-section-slice__call-to-action"
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default CleanHubSectionSlice;
