/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconTikTok: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M93.9,41c-8.6,0-17-2.7-24-7.7v34.9c0,6.5-2,12.8-5.7,18.1c-3.7,5.3-8.9,9.4-15,11.6c-6.1,2.3-12.7,2.6-18.9,1c-6.3-1.6-11.9-5.1-16.1-9.9C10,84.2,7.3,78.2,6.6,71.7c-0.7-6.4,0.6-12.9,3.6-18.6c3.1-5.7,7.8-10.3,13.6-13.2c5.8-2.9,12.3-4,18.7-3.1v17.6c-2.9-0.9-6.1-0.9-9,0.1c-2.9,1-5.4,2.8-7.2,5.3c-1.8,2.5-2.7,5.5-2.7,8.6c0,3.1,1,6.1,2.8,8.5c1.8,2.5,4.4,4.3,7.3,5.3c2.9,0.9,6.1,0.9,9,0c2.9-1,5.5-2.8,7.3-5.3c1.8-2.5,2.8-5.5,2.8-8.6V0h17.2c0,1.5,0.1,2.9,0.4,4.3c0.6,3.2,1.8,6.2,3.7,8.9c1.8,2.7,4.2,5,6.9,6.8c3.9,2.6,8.4,3.9,13.1,3.9V41z" />
    </Svg>
);

export default IconTikTok;
/* eslint-enable max-len */
