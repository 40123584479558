import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LinkButton, PrismicSliceTitle, Wrapper } from '../../../components';
import { FeaturedImage } from '../../../entities/@slices/FeaturedImage/FeaturedImage';

import './FeaturedImageSlice.scss';

interface FeaturedImageSliceProps extends FeaturedImage {
    className?: string;
}

const FeaturedImageSlice: FC<FeaturedImageSliceProps> = ({
    isMirrored,
    isMirroredMobile,
    image,
    title,
    titleSize,
    titleColour,
    paragraph,
    callToAction,
    className = '',
}): ReactElement => {
    const featuredImageSliceClassNames = classNames('featured-image-slice', {
        'featured-image-slice--is-mirrored': isMirrored,
        'featured-image-slice--is-mirrored-mobile': isMirroredMobile,
    }, className);

    return (
        <Wrapper className={featuredImageSliceClassNames}>
            <div className="featured-image-slice__image-wrapper">
                <img
                    src={image.src}
                    alt={image.alt}
                    className="featured-image-slice__image"
                />
            </div>

            <div className="featured-image-slice__text-wrapper">
                <PrismicSliceTitle
                    text={title}
                    size={titleSize}
                    colour={titleColour}
                    className="featured-image-slice__title"
                />

                {paragraph && (
                    <p className="featured-image-slice__paragraph">
                        {paragraph}
                    </p>
                )}

                {callToAction?.label && (
                    <LinkButton
                        to={callToAction.url}
                        text={callToAction.label}
                        className="featured-image-slice__call-to-action"
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default FeaturedImageSlice;
