import { FC, ReactElement } from 'react';

import { CookieType } from '../../constants/cookieSettings';
import { PersonalityTest } from '../../containers';
import { useCookieSettings } from '../../context/CookieContext';

interface ConnectedPersonalityTestProps {
    className?: string;
}

const ConnectedPersonalityTest: FC<ConnectedPersonalityTestProps> = ({ className = '' }): ReactElement => {
    const cookieSettings = useCookieSettings();

    return (
        <PersonalityTest
            allowedToStore={cookieSettings[CookieType.functional]}
            allowedToTrack={cookieSettings[CookieType.analytical]}
            className={className}
        />
    );
};

export default ConnectedPersonalityTest;
