import { FC, ReactElement, useEffect } from 'react';

import { ArticleOverview } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { setError as setArticleDetailError } from '../../redux/articleDetail/articleDetail';
import { fetchArticleOverview } from '../../redux/articles/articlesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticleOverviewProps {
    className?: string;
}

const ConnectedArticleOverview: FC<ConnectedArticleOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.articlesReducer.isLoading);
    const totalResults = useTypedSelector(state => state.articlesReducer.totalResults);
    const articles = useTypedSelector(state => state.articlesReducer.articles);

    useEffect((): void => {
        // Clear error from article detail, to prevent immediate redirect
        dispatch(setArticleDetailError(''));

        dispatch(fetchArticleOverview({ language, page: 1 }));
    }, [language]);

    return (
        <ArticleOverview
            isLoading={isLoading}
            totalResults={totalResults}
            articles={articles}
            className={className}
        />
    );
};

export default ConnectedArticleOverview;
