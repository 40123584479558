import { PersonalityTestAnswer } from '../entities/PersonalityTest/PersonalityTest';
import { Flavour } from './flavours';

export const flavourResultSearchParam = 'r';

export const personalityTestFlavours = [
    Flavour.berryBoost,
    Flavour.blackcurrant,
    Flavour.coconutPineapple,
    Flavour.lemon,
    Flavour.multiFruit,
    Flavour.orange,
    Flavour.passionFruit,
    Flavour.peach,
];

export const personalityTestScorecard = {
    [PersonalityTestAnswer.a]: {
        high: [Flavour.multiFruit],
        medium: [Flavour.lemon, Flavour.blackcurrant],
        low: [Flavour.passionFruit],
    },
    [PersonalityTestAnswer.b]: {
        high: [Flavour.berryBoost],
        medium: [Flavour.peach, Flavour.coconutPineapple],
        low: [Flavour.orange],
    },
    [PersonalityTestAnswer.c]: {
        high: [Flavour.coconutPineapple],
        medium: [Flavour.lemon, Flavour.passionFruit],
        low: [Flavour.multiFruit],
    },
    [PersonalityTestAnswer.d]: {
        high: [Flavour.blackcurrant],
        medium: [Flavour.berryBoost, Flavour.orange],
        low: [Flavour.peach],
    },
    [PersonalityTestAnswer.e]: {
        high: [Flavour.passionFruit],
        medium: [Flavour.coconutPineapple, Flavour.berryBoost],
        low: [Flavour.multiFruit],
    },
};
