/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './FlavourHeaderBackground.scss';

interface FlavourHeaderBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const FlavourHeaderBackground: FC<FlavourHeaderBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'flavour-header-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                angle: 30,
                magnitude: 15,
            }}
            preserveAspectRatio="xMinYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1000}
            viewBoxHeight={575}
            className={`flavour-header-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="flavour-header-background__gradient-start" />
                <stop offset="50%" className="flavour-header-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M808.6,109c132.9-16.4,203,74.7,137.2,229.7S650.3,569.6,446.2,535.2C242,500.8-11.6,328.9,37,145.7C85.7-37.5,322.9,31.3,414.1,56.9C414.1,56.9,675.7,125.4,808.6,109z"
                className="flavour-header-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default FlavourHeaderBackground;
/* eslint-enable max-len */
