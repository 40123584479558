import { useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import { defaultLocale, Language } from '../constants/locale';
import { useLocale } from '../context/LocaleContext';
import { isSSR } from '../helpers';
import { getTranslation, TranslatorFunction } from '../helpers/trans';

const useTrans = (): TranslatorFunction => {
    const { language } = useLocale();
    const { pathname } = useLocation();

    return useCallback<TranslatorFunction>((...args) => {
        if (!isSSR) return getTranslation(language, ...args);

        const routeLanguage = pathname.split('/')[1] as Language;
        const ssrLanguage = Object.values(Language).includes(routeLanguage) ? routeLanguage : defaultLocale.language;

        return getTranslation(ssrLanguage, ...args);
    }, [language]);
};

export default useTrans;
