import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Continent, customCountry } from '../../constants/countries';
import { Flavour, flavoursWithCaffeine } from '../../constants/flavours';
import { Ingredients } from '../../containers';
import { useCountry } from '../../context/CountryContext';
import { fetchNutritionTableData } from '../../redux/nutrition/nutritionActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { IngredientRegion, NutritionType } from '../../types/ingredient';

interface ConnectedIngredientsProps {
    flavour: Flavour;
    className?: string;
}

const ConnectedIngredients: FC<ConnectedIngredientsProps> = ({
    flavour,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { name: country, continent } = useCountry();

    const defaultIngredientRegion = useMemo((): IngredientRegion => (
        continent === Continent.northAmerica ? IngredientRegion.usa : IngredientRegion.europe
    ), [continent]);

    const [ingredientRegion, setIngredientRegion] = useState(defaultIngredientRegion);
    const [nutritionType, setNutritionType] = useState(NutritionType.flavouredWater);

    const { tableData, isLoading } = useTypedSelector(state => state.nutritionReducer);

    useEffect((): void => {
        dispatch(fetchNutritionTableData({ ingredientRegion, flavour, continent }));
    }, [ingredientRegion, flavour]);

    useEffect((): void => {
        if (flavour) {
            const isWithCaffeine = flavoursWithCaffeine.includes(flavour);

            setNutritionType(isWithCaffeine ? NutritionType.withCaffeine : NutritionType.flavouredWater);
        }
    }, [flavour]);

    useEffect((): void => {
        const constrainedIngredientRegion = continent === Continent.northAmerica && ingredientRegion === IngredientRegion.europe
            ? IngredientRegion.usa
            : ingredientRegion;

        const newIngredientRegion = continent === Continent.northAmerica
            ? constrainedIngredientRegion
            : IngredientRegion.europe;

        setIngredientRegion(newIngredientRegion);
    }, [continent]);

    useEffect((): void => {
        if (country === customCountry) return;

        if (continent === Continent.northAmerica) {
            const newIngredientRegion = country === 'Canada'
                ? IngredientRegion.canada
                : IngredientRegion.usa;

            setIngredientRegion(newIngredientRegion);
        } else {
            setIngredientRegion(IngredientRegion.europe);
        }
    }, [country]);

    return (
        <Ingredients
            showNutritionTypeControls={!flavour}
            isLoading={isLoading}
            flavour={flavour}
            ingredientRegion={ingredientRegion}
            nutritionType={nutritionType}
            data={tableData}
            onChangeIngredientRegion={setIngredientRegion}
            onChangeNutritionType={setNutritionType}
            className={className}
        />
    );
};

export default ConnectedIngredients;
