import { FC, PropsWithChildren, ReactElement } from 'react';

import './TableRow.scss';

interface TableRowProps {
    className?: string;
}

const TableRow: FC<PropsWithChildren<TableRowProps>> = ({ className = '', children }): ReactElement => (
    <tr className={`table-row ${className}`}>
        {children}
    </tr>
);

export default TableRow;
