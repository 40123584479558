import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '../../../../components';
import { PersonalityTestAnswer, PersonalityTestInput, PersonalityTestQuestion } from '../../../../entities/PersonalityTest/PersonalityTest';

import './PersonalityTestQuestionSlide.scss';

interface PersonalityTestQuestionSlideProps extends PersonalityTestQuestion {
    selectedAnswerKey?: string;
    onSelectAnswer: (input: PersonalityTestInput) => void;
    className?: string;
}

const PersonalityTestQuestionSlide: FC<PersonalityTestQuestionSlideProps> = ({
    selectedAnswerKey,
    index,
    question,
    answers,
    onSelectAnswer,
    className = '',
}): ReactElement => (
    <div className={`personality-test-question-slide ${className}`}>
        <h2 className="personality-test-question-slide__question">
            {question}
        </h2>

        <ul className="personality-test-question-slide__answer-list">
            {answers.map(answerItem => {
                const [key, answer] = Object.entries(answerItem)[0];

                const handleSelectAnswer = (): void => onSelectAnswer({
                    index,
                    question,
                    answer: key as PersonalityTestAnswer,
                });

                const answerButtonClassNames = classNames('personality-test-question-slide__answer-button', {
                    'personality-test-question-slide__answer-button--is-selected': key === selectedAnswerKey,
                });

                return (
                    <li key={key} className="personality-test-question-slide__answer-item">
                        <Button
                            aria-pressed={key === selectedAnswerKey}
                            text={answer}
                            onClick={handleSelectAnswer}
                            className={answerButtonClassNames}
                        />
                    </li>
                );
            })}
        </ul>
    </div>
);

export default PersonalityTestQuestionSlide;
