import { FC, ReactElement, useEffect } from 'react';

import { AppRoute } from '../../constants/routing';
import { PrismicPageContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchWorkAtData } from '../../redux/workAt/workAtActions';

interface ConnectedWorkAtProps {
    className?: string;
}

const ConnectedWorkAt: FC<ConnectedWorkAtProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.workAtReducer.isLoading);
    const workAtData = useTypedSelector(state => state.workAtReducer.workAtData);

    useEffect((): void => {
        if (!workAtData.title || language !== workAtData.language) {
            dispatch(fetchWorkAtData(language));
        }
    }, [language]);

    return (
        <PrismicPageContent
            {...workAtData}
            isLoading={isLoading}
            route={AppRoute.workAt}
            className={className}
        />
    );
};

export default ConnectedWorkAt;
