import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { LinkIconButton } from '../../compositions';
import { Direction } from '../../constants/locale';
import { AppRoute, appRoutes } from '../../constants/routing';
import { useLocale } from '../../context/LocaleContext';
import { Article } from '../../entities/Article/Article';
import useTrans from '../../hooks/useTrans';
import { SliceRenderer } from '..';
import { ArticleContentSkeletons } from './skeletons';
import { ArticleHeader, ArticleHelmet } from './subcomponents';

import './ArticleContent.scss';

interface ArticleContentProps {
    isLoading?: boolean;
    article?: Article;
    className?: string;
}

const ArticleContent: FC<ArticleContentProps> = ({
    isLoading,
    article,
    className = '',
}): ReactElement => {
    if (isLoading || !article) {
        return (
            <ArticleContentSkeletons className={`article-content ${className}`} />
        );
    }

    const { direction } = useLocale();
    const trans = useTrans();

    const backButtonIcon = direction === Direction.rtl
        ? 'arrow-right'
        : 'arrow-left';

    return (
        <article className={`article-content ${className}`}>
            <ArticleHelmet article={article} />

            <Wrapper className="article-content__breadcrumbs-wrapper">
                <LinkIconButton
                    to={trans(appRoutes[AppRoute.articles].path)}
                    icon={backButtonIcon}
                    text={trans('containers.articleContent.back')}
                    className="article-content__back-button"
                    iconClassName="article-content__back-button-icon"
                />
            </Wrapper>

            <ArticleHeader
                article={article}
                className="article-content__header"
            />

            <div className="article-content__body">
                <Wrapper className="article-content__intro-wrapper">
                    <p className="article-content__intro">
                        {article.intro}
                    </p>
                </Wrapper>

                <SliceRenderer
                    slices={article.body}
                    className="article-content__renderer"
                />
            </div>
        </article>
    );
};

export default ArticleContent;
