import { RichText } from 'prismic-dom';

import { Language } from '../../constants/locale';
import { getLanguageFromPrismicLanguage, prismicLinkResolver } from '../../helpers/prismic';
import { transformToImage } from '../@slices/Image/ImageTransformers';
import { PrismicDocument, PrismicSliceType } from '../Prismic/Prismic';
import {
    FaqData,
    FaqItem,
    PrismicFaqItem,
    PrismicFaqSlice,
    PrismicFaqTemplate,
} from './Faq';

export const transformToFaqItem = (
    prismicFaqItem: PrismicFaqItem,
    language: Language,
): FaqItem => {
    const answer = RichText.asHtml(prismicFaqItem.answer, doc => prismicLinkResolver(doc, language));
    const image = transformToImage(prismicFaqItem.image);

    return {
        question: prismicFaqItem.question,
        answer,
        image,
    };
};

const transformPrismicFaqSliceToFaqItems = (prismicFaqSlice: PrismicFaqSlice[], language: Language): FaqItem[] => {
    const data = prismicFaqSlice.find(slice => slice.slice_type === PrismicSliceType.faqItem || slice.slice_type === PrismicSliceType.faqList);

    if (!data) return [];

    return data.items.map((item: PrismicFaqItem) => transformToFaqItem(item, language));
};

export const transformPrismicDocumentToFaqData = (prismicDocument: PrismicDocument<PrismicFaqTemplate>, language: Language): FaqData => {
    const intro = RichText.asHtml(prismicDocument.data.intro, doc => prismicLinkResolver(doc, language));
    const faqItems = transformPrismicFaqSliceToFaqItems(prismicDocument.data.body, language);

    return {
        language: getLanguageFromPrismicLanguage(prismicDocument.lang),
        title: prismicDocument.data.title,
        intro,
        faqItems,
        slices: prismicDocument.data.body,
    };
};
