import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { TableCell, TableRow } from '../../../../components';
import { EUNutritionTableRow } from '../../../../entities/NutritionTableRow/NutritionTableRow';
import useTrans from '../../../../hooks/useTrans';

import './NutritionTableEURow.scss';

interface NutritionTableEURowProps {
    data: EUNutritionTableRow;
    nextSibling?: EUNutritionTableRow;
    cellClassName?: string;
    cellContentClassName?: string;
    className?: string;
}

const NutritionTableEURow: FC<NutritionTableEURowProps> = ({
    data,
    nextSibling,
    cellClassName,
    cellContentClassName,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const nextRowIsHeader = !!nextSibling?.isHeader;

    const rowClassName = classNames('nutrition-table-eu-row', {
        'nutrition-table-eu-row--is-header': data.isHeader,
        'nutrition-table-eu-row--is-last-header': data.isHeader && !nextRowIsHeader,
    }, className);

    return (
        <TableRow className={rowClassName}>
            <TableCell
                className={`nutrition-table-eu-row__cell ${cellClassName}`}
                contentClassName={`nutrition-table-eu-row__cell-content ${cellContentClassName}`}
            >
                {trans(`containers.ingredients.nutrition.${data.ingredient}`)}
            </TableCell>

            <TableCell
                className={`nutrition-table-eu-row__cell ${cellClassName}`}
                contentClassName={`nutrition-table-eu-row__cell-content ${cellContentClassName}`}
            >
                <span>{data.amount100ml}</span>
                <span>{' '}</span>
                <span>{trans(`containers.ingredients.unitOfMeasure.${data.unitOfMeasure}`)}</span>
            </TableCell>

            <TableCell
                className={`nutrition-table-eu-row__cell ${cellClassName}`}
                contentClassName={`nutrition-table-eu-row__cell-content ${cellContentClassName}`}
            >
                <span>{data.amount500ml}</span>
                <span>{' '}</span>
                <span>{trans(`containers.ingredients.unitOfMeasure.${data.unitOfMeasure}`)}</span>
            </TableCell>
        </TableRow>
    );
};

export default NutritionTableEURow;
