import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../Prismic/Prismic';
import { CenteredText, PrismicCenteredText } from './CenteredText';

export const transformToCenteredText = (
    prismicCenteredText: PrismicCenteredText,
): CenteredText => {
    const titleSizeKey = getEnumKeyByEnumValue(PrismicSliceTitleSize, prismicCenteredText.centered_text_title_size);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicCenteredText.centered_text_title_colour);

    return {
        title: prismicCenteredText.centered_text_title,
        titleSize: titleSizeKey
            ? PrismicSliceTitleSize[titleSizeKey]
            : undefined,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        paragraph: prismicCenteredText.centered_text_paragraph,
    };
};
