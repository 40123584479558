/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyEducation: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M51.8,95.7c-0.6,0-1.3,0-1.9,0c-11.2-0.3-17.6-2.2-25.7-7.2c-3.5-2.2-5.6-6.1-5.6-10.4V53.8c-8.1-4.5-15.1-9.7-17.7-15c-1-2-1.1-4.2-0.2-6.1C4.9,23.7,28,11,46,5.1c2.5-0.8,5.3-0.7,7.8,0.2c15.3,5.6,36,15.3,43.8,24.2c1.8,2,2.5,4.3,2.3,6.8c0,0,0,0.1,0,0.1V64c0,1.8-1.5,3.3-3.3,3.3s-3.3-1.5-3.3-3.3V46.4c-0.1,0.1-0.2,0.2-0.3,0.3c-3.6,2.6-7.4,5-11.7,7.4v23.9c0,4.4-2.1,8.3-5.7,10.5C67.2,93.7,60.9,95.7,51.8,95.7z M25.2,57.3V78c0,2,0.9,3.8,2.5,4.8C34.8,87.2,40,88.7,50,89c9,0.2,14.1-1.2,22-6.2c1.6-1,2.6-2.9,2.6-4.9V57.4c-5.5,2.6-12.1,5.4-20.3,8.5c-2.8,1.1-6,1.1-8.8,0.1C39.8,63.8,32.4,60.8,25.2,57.3z M6.7,35.5c0,0.1,0,0.2,0.1,0.3c3.3,6.5,19,15.7,41.1,23.9c1.3,0.5,2.7,0.5,4.1,0c18.5-7.2,28.3-12,37.1-18.5c2.3-1.7,3.9-3.7,4.3-5.3v-0.2c0-0.2,0-0.4,0-0.6c0-0.4-0.2-0.8-0.6-1.2c-5.6-6.3-22.6-15.5-41.2-22.3c-1.1-0.4-2.3-0.5-3.5-0.1C29,17.6,9.4,29.8,6.7,35.5z M6.7,35.5C6.7,35.5,6.7,35.5,6.7,35.5C6.7,35.5,6.7,35.5,6.7,35.5zM3.7,34.1L3.7,34.1L3.7,34.1z" />
    </Svg>
);

export default IconVacancyEducation;
/* eslint-enable max-len */
