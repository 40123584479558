import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { range } from '../../../../helpers/array';

import './VacancyOverviewSkeletons.scss';

interface VacancyOverviewSkeletonsProps {
    className?: string;
}

const VacancyOverviewSkeletons: FC<VacancyOverviewSkeletonsProps> = ({ className = '' }): ReactElement => {
    const vacancies = range(0, 4);

    return (
        <ul className={`vacancy-overview-skeletons ${className}`}>
            {vacancies.map(vacancy => (
                <li key={vacancy} className="vacancy-overview-skeletons__item">
                    <div className="vacancy-overview-skeletons__icon" />

                    <div className="vacancy-overview-skeletons__text-wrapper">
                        <div className="vacancy-overview-skeletons__link-button-wrapper">
                            <Skeleton className="vacancy-overview-skeletons__link-button" />
                        </div>

                        <div className="vacancy-overview-skeletons__meta-wrapper">
                            <Skeleton className="vacancy-overview-skeletons__meta-label" />
                            <Skeleton className="vacancy-overview-skeletons__meta-label" />
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default VacancyOverviewSkeletons;
