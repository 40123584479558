/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './MenuBackground.scss';

interface MenuBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const MenuBackground: FC<MenuBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);

    return (
        <div className={`menu-background ${className}`}>
            <BackgroundMorphSvg
                isAnimationEnabled={isAnimationEnabled}
                options={{
                    magnitude: 10,
                    angle: -2,
                    start: 2,
                    end: 15,
                    length: 100,
                }}
                preserveAspectRatio="xMidYMin meet"
                pathRefObject={pathRef}
                viewBoxWidth={1600}
                viewBoxHeight={111}
                className="menu-background__svg"
            >
                <path
                    ref={pathRef}
                    d="M 3.415 -12.307 C 70.015 57.993 144.755 112.013 298.675 112.753 C 460.735 113.493 425.955 48.373 649.035 46.9993 C 880.315 47.633 1069.755 129.773 1376.419 112.973 C 1565.786 100.016 1601.666 37.226 1599.911 -12.904 H 1600.234 L 1600.789 -119.339 L 2.705 -109.695 Z"
                    className="menu-background__svg-wave"
                />
            </BackgroundMorphSvg>
        </div>
    );
};

export default MenuBackground;
/* eslint-enable max-len */
