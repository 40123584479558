import { Location, Params } from 'react-router-dom';

import { defaultLocale, Language } from '../constants/locale';
import { getLocaleFromLanguageString, getPathFromTranslation, getTranslation } from './trans';

export const replaceUrlValuesWithParamKeys = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(value, `:${key}`);
    }, url);
};

export const replaceUrlParamKeysWithValues = (url: string, params: Params): string => {
    const paramEntries = Object.entries(params);

    return paramEntries.reduce((accumulatedUrl, [key, value]) => {
        if (!value) return accumulatedUrl;

        return accumulatedUrl.replace(`:${key}`, value);
    }, url);
};

export const getUrlWithLocationParams = (url: string, location: Location): string => {
    const { pathname, hash, search } = location;
    const decodedUrl = decodeURIComponent(pathname + (hash || '') + (search || ''));
    const params = decodedUrl.split('?')[1];

    return params ? `${url}?${params}` : url;
};

export const isCurrentRouteHomePage = (location: Location): boolean => {
    const { pathname, hash } = location;
    const [pathnameWithoutParams] = pathname.split('?');
    const decodedUrl = decodeURIComponent(pathnameWithoutParams + hash);

    const routeLanguage = decodedUrl.split('/')[1];
    const routeLocale = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

    return pathnameWithoutParams.replace(`/${routeLocale.language}`, '') === '/';
};

export const getTranslatedLocation = (language: Language, location: Location, params: Params): string => {
    const { pathname, hash, search } = location;
    const decodedUrl = decodeURIComponent(pathname + hash + (search || ''));
    const [decodedUrlWithoutParams] = decodedUrl.split('?');

    const slugUrl = replaceUrlValuesWithParamKeys(decodedUrlWithoutParams, params);

    const routeLanguage = decodedUrl.split('/')[1];
    const routeLocale = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

    const isHomePage = isCurrentRouteHomePage(location);
    const category = isHomePage ? 'homeSections' : 'routes';

    if (isHomePage && !hash) {
        return getUrlWithLocationParams(`/${language}/`, location);
    }

    const routeKey = getPathFromTranslation(routeLocale.language, category, slugUrl);
    const routeTranslation = getTranslation(language, routeKey);
    const route = routeKey ? replaceUrlParamKeysWithValues(routeTranslation, params) : '';

    return route ? getUrlWithLocationParams(route, location) : '';
};
