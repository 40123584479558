import { Continent } from './countries';

export enum Gym {
    basicFit = 'basic-fit',
    circleFit = 'circle-fit',
    crunchFitness = 'crunch-fitness',
    fitlaneFitnessCenter = 'fitlane-fitness-center',
    fitnessEvolution = 'fitness-evolution',
    fitnessPark = 'fitness-park',
    gigaFit = 'giga-fit',
    healthCity = 'health-city',
    ilFiore = 'il-fiore',
    jims = 'jims',
    lemonGym = 'lemon-gym',
    magicForm = 'magic-form',
    nrg = 'nrg',
    peopleFitnessClub = 'people-fitness-club',
    planetFitness = 'planet-fitness',
    profitGym = 'profit-gym',
    pureFitness = 'pure-fitness',
    pureGym = 'pure-gym',
    pureGymSa = 'pure-gym-sa',
    sportCity = 'sport-city',
    theGymGroup = 'the-gym-group',
    theRealGym = 'the-real-gym',
    vitaLiberte = 'vita-liberte',
    vivaGym = 'viva-gym',
    vivEden = 'viv-eden',
}

export const gymSets: Record<Continent, Gym[]> = {
    [Continent.northAmerica]: [
        Gym.crunchFitness,
        Gym.pureFitness,
        Gym.fitnessEvolution,
    ],
    [Continent.europe]: [
        Gym.basicFit,
        Gym.circleFit,
        Gym.fitlaneFitnessCenter,
        Gym.fitnessPark,
        Gym.gigaFit,
        Gym.healthCity,
        Gym.ilFiore,
        Gym.jims,
        Gym.lemonGym,
        Gym.magicForm,
        Gym.nrg,
        Gym.peopleFitnessClub,
        Gym.profitGym,
        Gym.pureGym,
        Gym.sportCity,
        Gym.theGymGroup,
        Gym.theRealGym,
        Gym.vitaLiberte,
        Gym.vivaGym,
        Gym.vivEden,
    ],
    [Continent.middleEast]: [
        Gym.pureGymSa,
    ],
    [Continent.africa]: [],
    [Continent.antarctica]: [],
    [Continent.asia]: [],
    [Continent.oceania]: [],
    [Continent.southAmerica]: [],
};
