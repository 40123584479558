import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { LoadingSpinner, Wrapper } from '../../../components';
import { Table } from '../../../entities/@slices/Table/Table';
import { fetchCsvData } from '../../../entities/Csv/CsvService';
import useTrans from '../../../hooks/useTrans';

import './TableSlice.scss';

interface TableSliceProps extends Table {
    className?: string;
}

const TableSlice: FC<TableSliceProps> = ({
    csvUrl,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<string[][]>([]);

    useEffect((): void => {
        const fetchTableData = async (): Promise<void> => {
            setIsLoading(true);

            try {
                const csvResponse = await fetchCsvData(csvUrl);

                if (csvResponse) {
                    const tableRows = csvResponse.split('\n');

                    setTableData(tableRows.map(row => row.split(',')));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTableData();
    }, [csvUrl]);

    const [tableHead, ...tableBody] = tableData;

    return (
        <section className={`table ${className}`}>
            <Wrapper className="table-slice__wrapper">
                <div className="table-slice__table-wrapper">
                    {isLoading && (
                        <LoadingSpinner />
                    )}

                    {!isLoading && tableData.length === 0 && (
                        <p className="table-slice__no-data">
                            {trans('compositions.tableSlice.noData')}
                        </p>
                    )}

                    {!isLoading && tableData.length > 0 && (
                        <table className="table-slice__table">
                            <thead className="table-slice__header">
                                <tr className="table-slice__row">
                                    {tableHead.map(column => (
                                        <td key={column} className="table-slice__cell">
                                            {column}
                                        </td>
                                    ))}
                                </tr>
                            </thead>

                            <tbody className="table-slice__body">
                                {tableBody.map((row, rowIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={rowIndex} className="table-slice__row">
                                        {row.map((cell, cellIndex) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <td key={`${cell}-${cellIndex}`} className="table-slice__cell">
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </Wrapper>
        </section>
    );
};

export default TableSlice;
