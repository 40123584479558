import { getCurrentVersion, getLatestVersion } from '../../entities/AppService';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import {
    setCurrentVersion,
    setError,
    setIsLoading,
    setLatestVersion,
} from './appReducer';

export const fetchAppVersions = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const currentVersionResponse = await getCurrentVersion();
        const latestVersionResponse = await getLatestVersion();

        if (!isFetchResultSuccessful(currentVersionResponse)) {
            dispatch(setError({
                status: currentVersionResponse.status,
                title: currentVersionResponse.error,
            }));
            return;
        }

        if (!isFetchResultSuccessful(latestVersionResponse)) {
            dispatch(setError({
                status: latestVersionResponse.status,
                title: latestVersionResponse.error,
            }));
            return;
        }

        const currentVersion = currentVersionResponse.data;
        const latestVersion = latestVersionResponse.data;

        dispatch(setCurrentVersion(currentVersion));
        dispatch(setLatestVersion(latestVersion));
    } catch (error) {
        console.error('[fetchAppVersions]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
