/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVegan: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={77} viewBoxHeight={82} className={className}>
        <path d="M67.7309 0.954393C68.5296 0.717755 69.3843 1.07686 69.7744 1.81288C79.09 19.3925 76.8876 32.9242 70.0481 42.3843C63.3276 51.6798 52.3313 56.7628 44.4346 58.062C43.7445 58.1755 43.0531 57.8673 42.6762 57.2781C38.4316 50.6412 35.4661 39.3549 38.1817 28.3001C40.9348 17.0923 49.4658 6.36621 67.7309 0.954393ZM45.0201 54.3933C41.5627 48.3627 39.2497 38.624 41.5806 29.1351C43.9415 19.5242 51.1235 9.94077 67.3448 4.73597C75.3396 20.6462 73.0101 32.3137 67.2118 40.3336C61.4582 48.2916 52.0972 52.9239 45.0201 54.3933ZM53.2501 16.0494C54.1414 15.6757 55.167 16.0952 55.5407 16.9865L58.7459 24.6295L67.1967 22.3925C68.131 22.1452 69.0889 22.7021 69.3362 23.6365C69.5835 24.5708 69.0266 25.5287 68.0923 25.776L58.1689 28.4028C57.3247 28.6262 56.445 28.1931 56.1073 27.3878L52.313 18.34C51.9393 17.4487 52.3588 16.4232 53.2501 16.0494ZM48.6819 31.5797C48.3082 30.6884 47.2826 30.2688 46.3913 30.6426C45.5 31.0164 45.0805 32.0419 45.4543 32.9332L49.2485 41.981C49.5862 42.7863 50.4659 43.2194 51.3101 42.9959L61.2335 40.3692C62.1678 40.1219 62.7247 39.1639 62.4774 38.2296C62.2301 37.2953 61.2722 36.7384 60.3379 36.9857L51.8871 39.2227L48.6819 31.5797Z" fillRule="evenodd" clipRule="evenodd" />
        <path d="M2.26758 28.0243C2.26758 28.0243 8.10485 21.1072 11.6072 26.1272C15.1096 31.1473 27.8543 63.4371 33.7888 78.9545C42.3502 58.1835 61.8077 17.6631 63.5589 14.4526" fill="none" stroke="currentColor" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
);

export default IconVegan;
/* eslint-enable max-len */
