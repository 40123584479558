import { FC, ReactElement } from 'react';

import { SafeHtml, Wrapper } from '../../../components';
import { VacancyExpectations } from '../../../entities/@slices/VacancyExpectations/VacancyExpectations';

import './VacancyExpectationsSlice.scss';

interface VacancyExpectationsSliceProps extends VacancyExpectations {
    className?: string;
}

const VacancyExpectationsSlice: FC<VacancyExpectationsSliceProps> = ({
    fromCompany,
    fromApplicant,
    className = '',
}): ReactElement => (
    <Wrapper className={`vacancy-expectations-slice ${className}`}>
        <div className="vacancy-expectations-slice__company-wrapper">
            <h2 className="vacancy-expectations-slice__title">
                {fromCompany.title}
            </h2>

            <SafeHtml
                html={fromCompany.description}
                className="vacancy-expectations-slice__description"
            />
        </div>

        <div className="vacancy-expectations-slice__applicant-wrapper">
            <h2 className="vacancy-expectations-slice__title">
                {fromApplicant.title}
            </h2>

            <SafeHtml
                html={fromApplicant.description}
                className="vacancy-expectations-slice__description"
            />
        </div>
    </Wrapper>
);

export default VacancyExpectationsSlice;
