import { FC, ReactElement, useEffect } from 'react';

import { AppRoute } from '../../constants/routing';
import { PrismicPageContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchYourClubData } from '../../redux/yourClub/yourClubActions';

interface ConnectedYourClubProps {
    className?: string;
}

const ConnectedYourClub: FC<ConnectedYourClubProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.yourClubReducer.isLoading);
    const yourClubData = useTypedSelector(state => state.yourClubReducer.yourClubData);

    useEffect((): void => {
        if (!yourClubData.title || language !== yourClubData.language) {
            dispatch(fetchYourClubData(language));
        }
    }, [language]);

    return (
        <PrismicPageContent
            {...yourClubData}
            isLoading={isLoading}
            route={AppRoute.yourClub}
            className={className}
        />
    );
};

export default ConnectedYourClub;
