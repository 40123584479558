import { transformToImage } from '../@slices/Image/ImageTransformers';
import { PrismicSeo, Seo } from './Seo';

export const transformToSeo = (
    prismicSeo: PrismicSeo,
): Seo => {
    const image = prismicSeo.seo_image.url
        ? transformToImage(prismicSeo.seo_image)
        : undefined;

    return {
        title: prismicSeo.seo_title,
        description: prismicSeo.seo_description,
        keywords: prismicSeo.seo_keywords,
        image,
    };
};
