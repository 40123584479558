import {
    FC,
    ReactElement,
    useCallback,
    useState,
} from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Button } from '../../../../components';
import { SocialNavigation } from '../../../../compositions';
import { AppRoute } from '../../../../constants/routing';
import useTrans from '../../../../hooks/useTrans';
import { RouteData } from '../../../../LocalizedRoutes';
import { MenuSidebar, NavigationItem } from '..';

import './Navigation.scss';

interface NavigationProps {
    isExpanded: boolean;
    navigationLabel: string;
    transitionDelay?: number;
    navigationItems: RouteData<AppRoute>[];
    onShowCookieSettings: () => void;
    onClose?: () => void;
    className?: string;
}

const Navigation: FC<NavigationProps> = ({
    isExpanded,
    navigationLabel,
    transitionDelay = 0,
    navigationItems,
    onShowCookieSettings,
    onClose,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const location = useLocation();

    const [isReady, setIsReady] = useState<boolean>(false);

    const getTransitionDelay = useCallback((index: number): string => {
        if (!isExpanded) return '0s';

        return `${index * transitionDelay}s`;
    }, [isExpanded]);

    const socialNavigationTransitionDelay = getTransitionDelay(navigationItems.length);
    const cookieSettingsTransitionDelay = getTransitionDelay(navigationItems.length + 1);

    const navigationClassNames = classNames('navigation', {
        'navigation--is-ready': isExpanded && isReady,
    }, className);

    return (
        <MenuSidebar
            isExpanded={isExpanded}
            navigationLabel={navigationLabel}
            onTransitionEnd={setIsReady}
            onClose={onClose}
            className={navigationClassNames}
        >
            <ul className="navigation__main-list">
                {navigationItems.map((navItem, index) => {
                    const { pathname, hash } = location;

                    const isActive = decodeURIComponent(pathname + hash) === trans(navItem.path);
                    const style = { transitionDelay: getTransitionDelay(index) };

                    return (
                        <NavigationItem
                            {...navItem}
                            key={navItem.key}
                            isActive={isActive}
                            id={navItem.key}
                            style={style}
                            to={trans(navItem.path)}
                            label={trans(navItem.label)}
                            tabIndex={isExpanded ? 0 : -1}
                            className="navigation__main-item"
                        />
                    );
                })}
            </ul>

            <div
                style={{ transitionDelay: socialNavigationTransitionDelay }}
                className="navigation__social-navigation-wrapper"
            >
                <SocialNavigation
                    className="navigation__social-navigation"
                    listClassName="navigation__social-list"
                />
            </div>

            <div
                style={{ transitionDelay: cookieSettingsTransitionDelay }}
                className="navigation__cookie-settings-wrapper"
            >
                <Button
                    text={trans('common.showCookieSettings')}
                    onClick={onShowCookieSettings}
                    className="navigation__cookie-settings-button"
                />
            </div>
        </MenuSidebar>
    );
};

export default Navigation;
