import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';

gsap.registerPlugin(CustomEase, MorphSVGPlugin, ScrollTrigger, SplitText);

export * from 'gsap';
export {
    CustomEase,
    MorphSVGPlugin,
    ScrollTrigger,
    SplitText,
};
