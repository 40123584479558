import { Flavour } from '../constants/flavours';
import { defaultLocale } from '../constants/locale';
import { flavourResultSearchParam } from '../constants/personalityTest';
import { getLocaleFromLanguageString, getPathFromTranslation } from './trans';

export const getFlavourFromPathname = (pathname: string, flavour: string): Flavour | undefined => {
    const routeLanguage = pathname.split('/')[1];
    const { language } = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

    const flavourKeyFromTranslation = getPathFromTranslation(language, 'flavours', flavour);

    if (!flavourKeyFromTranslation) {
        return undefined;
    }

    const [, key] = flavourKeyFromTranslation.split('.');
    const flavourKey = key as Flavour;

    const keyIsFlavour = Object.values(Flavour).includes(flavourKey);

    return keyIsFlavour ? flavourKey : undefined;
};

export const getFlavourFromSearchParams = (pathname: string, searchParams: URLSearchParams): Flavour | undefined => {
    const flavour = searchParams.get(flavourResultSearchParam);

    if (!flavour) return undefined;

    const routeLanguage = pathname.split('/')[1];
    const { language } = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

    const flavourKeyFromTranslation = getPathFromTranslation(language, 'flavours', flavour);

    if (!flavourKeyFromTranslation) {
        return undefined;
    }

    const [, key] = flavourKeyFromTranslation.split('.');
    const flavourKey = key as Flavour;

    const keyIsFlavour = Object.values(Flavour).includes(flavourKey);

    return keyIsFlavour ? flavourKey : undefined;
};
