import { useEffect, useState } from 'react';

const useDocumentScroll = (): number => {
    const [scrollY, setScrollY] = useState(window.scrollY);

    const handleScroll = (): void => {
        setScrollY(window.scrollY);
    };

    useEffect((): () => void => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollY;
};

export default useDocumentScroll;
