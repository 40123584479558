/* eslint-disable max-len */
import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { Button, Icon, Wrapper } from '../../../components';
import { Video } from '../../../entities/@slices/Video/Video';
import useTrans from '../../../hooks/useTrans';
import { MorphingSvg } from '../..';

import './VideoSlice.scss';

interface VideoSliceProps extends Video {
    className?: string;
}

const VideoSlice: FC<VideoSliceProps> = ({
    isFullWidth,
    videoSrc,
    poster,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [videoIsPlaying, toggleVideoIsPlaying] = useToggle(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect((): void => {
        if (videoRef.current) {
            if (videoIsPlaying) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [videoIsPlaying]);

    const extractedFileType = /(?:\.([^.]+))?$/.exec(videoSrc);
    const fileType = extractedFileType
        ? `video/${extractedFileType[1]}`
        : undefined;

    const handleVideoEnded = (): void => {
        toggleVideoIsPlaying(false);
    };

    const videoSliceClassNames = classNames('video-slice', {
        'video-slice--is-full-width': isFullWidth,
    }, className);

    return (
        <Wrapper className={videoSliceClassNames}>
            <div className="video-slice__play-button-wrapper">
                <Button
                    text={trans(`compositions.videoSlice.${videoIsPlaying ? 'pause' : 'play'}`)}
                    onClick={toggleVideoIsPlaying}
                    className="video-slice__play-button"
                >
                    <Icon
                        name={videoIsPlaying ? 'pause' : 'play'}
                        className="video-slice__play-icon"
                    />

                    <MorphingSvg
                        fromPath="M69,13.3c8.8,5.5,15.1,20.1,16.6,36.7s-2,35.1-10.7,40.5s-22.8-2.1-32.5-7.8S4,59.6,4,47.7c0-14.1,25.4-28.5,36.6-32.9C49.2,11.4,60.3,7.8,69,13.3z"
                        toPath="M71,15.9c8.8,5.5,17.8,13,19.3,29.6S78.5,76.2,69.8,81.6s-30.9,9-40.6,3.3s-18-25.4-18-37.3c0-14.1,4.3-32.2,15.5-36.6C35.3,7.5,62.3,10.4,71,15.9z"
                        duration="5s"
                        className="video-slice__play-blob"
                        pathClassName="video-slice__play-blob-path"
                    />
                </Button>
            </div>

            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                ref={videoRef}
                poster={poster?.src}
                onEnded={handleVideoEnded}
                className="video-slice__video"
            >
                {fileType && <source src={videoSrc} type={fileType} />}

                <a href={videoSrc}>
                    {trans('compositions.videoSlice.download')}
                </a>
            </video>
        </Wrapper>
    );
};

export default VideoSlice;
/* eslint-enable max-len */
