import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { defaultPrismicDefaultPageData, PrismicDefaultPageData } from '../../entities/Prismic/Prismic';
import { AsyncReduxState } from '../../types';

export type AboutState = AsyncReduxState<{
    aboutData: PrismicDefaultPageData;
}>;

const initialState: AboutState = {
    ...initialAsyncReduxState,
    aboutData: defaultPrismicDefaultPageData,
};

const aboutSlice = createSlice({
    name: 'aboutReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AboutState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AboutState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setAboutData(state, action: PayloadAction<PrismicDefaultPageData>): AboutState {
            return {
                ...state,
                aboutData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setAboutData,
} = aboutSlice.actions;

export default aboutSlice;
