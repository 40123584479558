export enum IngredientRegion {
    canada = 'canada',
    usa = 'usa',
    europe = 'europe',
}

export enum NutritionType {
    flavouredWater = 'flavouredWater',
    withCaffeine = 'withCaffeine',
}
