/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronUp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={57.1} className={className}>
        <path d="M97.9,55.1c2.5-2.5,2.8-6.6,0.6-9.4L97.9,45L55,2.1c-2.5-2.5-6.6-2.8-9.4-0.6l-0.7,0.6L2.1,45c-2.8,2.8-2.8,7.3,0,10.1c2.5,2.5,6.6,2.8,9.4,0.6l0.7-0.6L50,17.3l37.8,37.8c2.5,2.5,6.6,2.8,9.4,0.6L97.9,55.1z" />
    </Svg>
);

export default IconChevronUp;
/* eslint-enable max-len */
