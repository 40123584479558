import { ChangeEvent, FC, ReactElement } from 'react';

import { Icon, InputLabel } from '../../../../components';
import { Continent } from '../../../../constants/countries';
import useTrans from '../../../../hooks/useTrans';

import './RegionSelector.scss';

interface RegionSelectorProps {
    options: Continent[];
    value: Continent;
    onSelectRegion: (region: Continent) => void;
    className?: string;
}

const RegionSelector: FC<RegionSelectorProps> = ({
    options,
    value,
    onSelectRegion,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleSelectRegion = (event: ChangeEvent<HTMLSelectElement>): void => {
        const selectedRegion = event.currentTarget.value;

        onSelectRegion(selectedRegion as Continent);
    };

    return (
        <label className={`region-selector ${className}`}>
            <InputLabel
                label={trans('containers.menu.regionSelector.label')}
                className="region-selector__label"
            />

            <div className="region-selector__input-wrapper">
                <select
                    aria-label={trans('containers.menu.regionSelector.ariaLabel')}
                    value={value}
                    onChange={handleSelectRegion}
                    className="region-selector__input"
                >
                    {options.map(option => (
                        <option
                            key={option}
                            value={option}
                            className="region-selector__option"
                        >
                            {trans(`containers.menu.regionSelector.options.${option}`)}
                        </option>
                    ))}
                </select>

                <Icon name="chevron-down" className="region-selector__icon" />
            </div>
        </label>
    );
};

export default RegionSelector;
