import { ContactFormData } from '../../../../entities/Contact/Contact';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
    validatePhoneNumber,
    validateRequiredString,
    validateShortDescription,
} from '../../../../entities/FormValidation/FormValidationService';

export type ContactFormErrors = FormErrors<ContactFormData>;

export const validateContactFormData = (formData: ContactFormData): FormValidation<ContactFormErrors> => {
    const errors: ContactFormErrors = {
        clientType: validateRequiredString('clientType', formData.clientType),
        name: validateName('name', formData.name),
        email: validateEmailAddress(formData.email),
        phoneNumber: validatePhoneNumber(formData.phoneNumber),
        comment: validateShortDescription('comment', formData.comment),
    };

    return validateForm<ContactFormErrors>(errors);
};
