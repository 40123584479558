import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { LinkButton, Picture, Wrapper } from '../../components';
import { Flavour } from '../../constants/flavours';
import { AppRoute, appRoutes } from '../../constants/routing';
import { Toast, ToastState } from '../../entities/Toast/Toast';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTrans from '../../hooks/useTrans';
import { AtLeast } from '../../types';
import { PersonalityTestResultBackground, PersonalityTestResultCard } from './subcomponents';

import './PersonalityTestResult.scss';

interface PersonalityTestResultProps {
    allowedToTrack?: boolean;
    flavour: Flavour;
    onShareFeedback: (state: ToastState, toast: AtLeast<Toast, 'title'>) => void;
    className?: string;
}

const PersonalityTestResult: FC<PersonalityTestResultProps> = ({
    allowedToTrack,
    flavour,
    onShareFeedback,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const flavourPath = trans(appRoutes[AppRoute.flavourDetail].path);
    const flavourLink = replaceUrlParamKeysWithValues(flavourPath, {
        flavour: trans(`flavours.${flavour}.slug`),
    });

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect((): void => {
        setIsLoaded(true);
    }, []);

    const personalityTestResultClassNames = classNames('personality-test-result', {
        'personality-test-result--is-loaded': isLoaded,
    }, className);

    return (
        <section className={personalityTestResultClassNames}>
            <Wrapper className="personality-test-result__wrapper">
                <PersonalityTestResultCard
                    allowedToTrack={allowedToTrack}
                    flavour={flavour}
                    onShareFeedback={onShareFeedback}
                    className="personality-test-result__card"
                />

                <div className="personality-test-result__flavour-wrapper">
                    <div className="personality-test-result__description-wrapper">
                        <h1 className="personality-test-result__title">
                            {trans(`flavours.${flavour}.name`)}
                        </h1>

                        <p className="personality-test-result__description">
                            {trans(`flavours.${flavour}.result.description`)}
                        </p>

                        <LinkButton
                            to={flavourLink}
                            text={trans('containers.personalityTestResult.readMore', {
                                flavour: trans(`flavours.${flavour}.name`),
                            })}
                            className="personality-test-result__read-more-link"
                        />
                    </div>

                    <Picture
                        loading="lazy"
                        src={`/images/flavours/test-result/primary/${flavour}.png`}
                        sources={[
                            {
                                srcSet: [
                                    `/images/flavours/test-result/primary/${flavour}.avif`,
                                    `/images/flavours/test-result/primary/${flavour}-2x.avif 2x`,
                                ].join(', '),
                                type: 'image/avif',
                            },
                            {
                                srcSet: [
                                    `/images/flavours/test-result/primary/${flavour}.webp`,
                                    `/images/flavours/test-result/primary/${flavour}-2x.webp 2x`,
                                ].join(', '),
                                type: 'image/webp',
                            },
                        ]}
                        alt={trans(`flavours.${flavour}.imageAlts.testResult.primary`)}
                        className="personality-test-result__picture personality-test-result__picture--primary"
                    />

                    <Picture
                        loading="lazy"
                        src={`/images/flavours/test-result/secondary/${flavour}.png`}
                        sources={[
                            {
                                srcSet: [
                                    `/images/flavours/test-result/secondary/${flavour}.avif`,
                                    `/images/flavours/test-result/secondary/${flavour}-2x.avif 2x`,
                                ].join(', '),
                                type: 'image/avif',
                            },
                            {
                                srcSet: [
                                    `/images/flavours/test-result/secondary/${flavour}.webp`,
                                    `/images/flavours/test-result/secondary/${flavour}-2x.webp 2x`,
                                ].join(', '),
                                type: 'image/webp',
                            },
                        ]}
                        alt={trans(`flavours.${flavour}.imageAlts.testResult.secondary`)}
                        className="personality-test-result__picture personality-test-result__picture--secondary"
                    />
                </div>
            </Wrapper>

            <PersonalityTestResultBackground className="personality-test-result__wave-background" />
        </section>
    );
};

export default PersonalityTestResult;
