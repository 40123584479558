import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { VacancyRendererSkeletons } from '..';

import './VacancyContentSkeletons.scss';

interface VacancyContentSkeletonsProps {
    className?: string;
}

const VacancyContentSkeletons: FC<VacancyContentSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <Wrapper className={`vacancy-content-skeletons ${className}`}>
        <div className="vacancy-content-skeletons__back-button">
            <Skeleton />
        </div>

        <div className="vacancy-content-skeletons__title-wrapper">
            <Skeleton className="vacancy-content-skeletons__title" />
            <Skeleton className="vacancy-content-skeletons__title" />
        </div>

        <div className="vacancy-content-skeletons__feature-list">
            <Skeleton className="vacancy-content-skeletons__feature-item" />
            <Skeleton className="vacancy-content-skeletons__feature-item" />
            <Skeleton className="vacancy-content-skeletons__feature-item" />
        </div>

        <div className="vacancy-content-skeletons__date-wrapper">
            <Skeleton className="vacancy-content-skeletons__date-label" />
            <Skeleton className="vacancy-content-skeletons__date-label" />
        </div>

        <Skeleton className="vacancy-content-skeletons__apply-button" />

        <div className="vacancy-content-skeletons__body">
            <VacancyRendererSkeletons className="vacancy-content-skeletons__renderer" />
        </div>
    </Wrapper>
);

export default VacancyContentSkeletons;
