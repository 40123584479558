import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicDetailApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToVacancy } from '../../entities/Vacancy/VacancyTransformers';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setVacancyData } from './vacancyDetail';

export const fetchVacancyContent = (slug: string, language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setVacancyData(undefined));

    try {
        const type = PrismicType.vacancy;
        const vacancyDetailResponse = await getPrismicDetailApiCall({ type, slug, language });

        if (!isFetchResultSuccessful(vacancyDetailResponse)) {
            console.error('[fetchVacancyContent]', vacancyDetailResponse.error);

            dispatch(setError(vacancyDetailResponse.error));

            return;
        }

        const vacancyData = transformPrismicDocumentToVacancy(vacancyDetailResponse.data, language);

        dispatch(setVacancyData(vacancyData));
    } catch (error) {
        console.error('[fetchVacancyContent]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
