/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconCross: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M13.8,2.4l83.8,83.8c3.2,3.2,3.2,8.3,0,11.4s-8.3,3.2-11.4,0L2.4,13.8c-3.2-3.2-3.2-8.3,0-11.4S10.6-0.8,13.8,2.4z" />
        <path d="M2.4,86.2L86.2,2.4c3.2-3.2,8.3-3.2,11.4,0s3.2,8.3,0,11.4L13.8,97.6c-3.2,3.2-8.3,3.2-11.4,0S-0.8,89.4,2.4,86.2z" />
    </Svg>
);

export default IconCross;
/* eslint-enable max-len */
