import { FC, ReactElement } from 'react';

import { RichText } from 'prismic-dom';

import { SafeHtml, Wrapper } from '../../components';
import {
    CenteredTextSlice,
    CleanHubSectionSlice,
    FaqSlice,
    FeaturedImageSlice,
    ImageGridSlice,
    ImageSlice,
    PagePreviewSlice,
    PartnerGrid,
    TableSlice,
    TwoColumnTextSlice,
    VacancyCallToActionSlice,
    VacancyExpectationsSlice,
    VideoSlice,
} from '../../compositions';
import { ConnectedVacancyOverview } from '../../connectors';
import { useLocale } from '../../context/LocaleContext';
import { PrismicCenteredTextSlice } from '../../entities/@slices/CenteredText/CenteredText';
import { transformToCenteredText } from '../../entities/@slices/CenteredText/CenteredTextTransformers';
import { PrismicCleanHubSectionSlice } from '../../entities/@slices/CleanHubSection/CleanHubSection';
import { transformToCleanHubSection } from '../../entities/@slices/CleanHubSection/CleanHubSectionTransformers';
import { PrismicFeaturedImageSlice } from '../../entities/@slices/FeaturedImage/FeaturedImage';
import { transformToFeaturedImage } from '../../entities/@slices/FeaturedImage/FeaturedImageTransformers';
import { PrismicImageGridSlice, PrismicImageSlice } from '../../entities/@slices/Image/Image';
import { transformToImage } from '../../entities/@slices/Image/ImageTransformers';
import { PrismicPagePreviewSlice } from '../../entities/@slices/PagePreview/PagePreview';
import { transformToPagePreview } from '../../entities/@slices/PagePreview/PagePreviewTransformers';
import { PrismicTableSlice } from '../../entities/@slices/Table/Table';
import { transformToTable } from '../../entities/@slices/Table/TableTransformers';
import { PrismicTwoColumnTextSlice } from '../../entities/@slices/TwoColumnText/TwoColumnText';
import { transformToTwoColumnText } from '../../entities/@slices/TwoColumnText/TwoColumnTextTransformers';
import { PrismicVacancyCallToActionSlice } from '../../entities/@slices/VacancyCallToAction/VacancyCallToAction';
import { transformToVacancyCallToAction } from '../../entities/@slices/VacancyCallToAction/VacancyCallToActionTransformers';
import { PrismicVacancyExpectationsSlice } from '../../entities/@slices/VacancyExpectations/VacancyExpectations';
import { transformToVacancyExpectations } from '../../entities/@slices/VacancyExpectations/VacancyExpectationsTransformers';
import { PrismicVideoSlice } from '../../entities/@slices/Video/Video';
import { transformToVideo } from '../../entities/@slices/Video/VideoTransformers';
import { PrismicFaqSlice } from '../../entities/Faq/Faq';
import { transformToFaqItem } from '../../entities/Faq/FaqTransformers';
import { PrismicLinkResolver } from '../../entities/Link/Link';
import { PrismicSlice, PrismicSliceType, PrismicTextSlice } from '../../entities/Prismic/Prismic';
import { prismicLinkResolver } from '../../helpers/prismic';

import './SliceRenderer.scss';

interface SliceRendererProps {
    slices: PrismicSlice[];
    className?: string;
}

const SliceRenderer: FC<SliceRendererProps> = ({
    slices,
    className = '',
}): ReactElement => {
    const { language } = useLocale();

    const allowedSlices = [
        PrismicSliceType.centeredText,
        PrismicSliceType.cleanHubSection,
        PrismicSliceType.faqList,
        PrismicSliceType.faqItem,
        PrismicSliceType.featuredImage,
        PrismicSliceType.image,
        PrismicSliceType.imageGrid,
        PrismicSliceType.pagePreview,
        PrismicSliceType.partnerGrid,
        PrismicSliceType.table,
        PrismicSliceType.text,
        PrismicSliceType.twoColumnText,
        PrismicSliceType.vacancyCallToAction,
        PrismicSliceType.vacancyExpectations,
        PrismicSliceType.vacancyOverview,
        PrismicSliceType.video,
    ];

    const filteredSlices = slices.filter(slice => allowedSlices.includes(slice.slice_type));

    const renderCurrentSlice = (slice: PrismicSlice) => {
        switch (slice.slice_type) {
            case PrismicSliceType.centeredText: {
                const centeredTextSlice = slice as PrismicCenteredTextSlice;
                const centeredTextProps = transformToCenteredText(centeredTextSlice.primary);

                return (
                    <CenteredTextSlice
                        {...centeredTextProps}
                        className="slice-renderer__centered-text-slice"
                    />
                );
            }
            case PrismicSliceType.cleanHubSection: {
                const cleanHubSectionSlice = slice as PrismicCleanHubSectionSlice;
                const cleanHubSectionProps = transformToCleanHubSection(cleanHubSectionSlice.primary, language);

                return (
                    <CleanHubSectionSlice
                        {...cleanHubSectionProps}
                        className="slice-renderer__clean-hub-section-slice"
                    />
                );
            }
            case PrismicSliceType.faqList:
            case PrismicSliceType.faqItem: {
                const faqSlice = slice as PrismicFaqSlice;
                const faqItems = faqSlice.items.map(faqItem => transformToFaqItem(faqItem, language));

                return (
                    <FaqSlice
                        faqItems={faqItems}
                        className="slice-renderer__faq-slice"
                    />
                );
            }
            case PrismicSliceType.featuredImage: {
                const featuredImage = slice as PrismicFeaturedImageSlice;
                const featuredImageProps = transformToFeaturedImage(featuredImage.primary, language);

                return (
                    <FeaturedImageSlice
                        {...featuredImageProps}
                        className="slice-renderer__featured-image-slice"
                    />
                );
            }
            case PrismicSliceType.image: {
                const imageSlice = slice as PrismicImageSlice;
                const imageProps = transformToImage(imageSlice.primary.image);

                return (
                    <ImageSlice
                        {...imageProps}
                        className="slice-renderer__image-slice"
                    />
                );
            }
            case PrismicSliceType.imageGrid: {
                const imageGridSlice = slice as PrismicImageGridSlice;
                const images = imageGridSlice.items.map(item => transformToImage(item.image));

                return (
                    <Wrapper className="slice-renderer__image-grid-slice">
                        <ImageGridSlice images={images} />
                    </Wrapper>
                );
            }
            case PrismicSliceType.pagePreview: {
                const pagePreviewSlice = slice as PrismicPagePreviewSlice;
                const pagePreviewProps = transformToPagePreview(pagePreviewSlice.primary, language);

                return (
                    <PagePreviewSlice
                        {...pagePreviewProps}
                        className="slice-renderer__page-preview-slice"
                    />
                );
            }
            case PrismicSliceType.partnerGrid: {
                return (
                    <PartnerGrid className="slice-renderer__partner-grid-slice" />
                );
            }
            case PrismicSliceType.table: {
                const tableSlice = slice as PrismicTableSlice;
                const tableProps = transformToTable(tableSlice.primary);

                return (
                    <TableSlice
                        {...tableProps}
                        className="slice-renderer__table-slice"
                    />
                );
            }
            case PrismicSliceType.text: {
                const textSlice = slice as PrismicTextSlice;
                const { content } = textSlice.primary;
                const linkResolver = (doc: PrismicLinkResolver) => prismicLinkResolver(doc, language);

                return (
                    <Wrapper className="slice-renderer__text-slice">
                        <SafeHtml
                            html={RichText.asHtml(content, linkResolver)}
                            className="slice-renderer__text-slice-html"
                        />
                    </Wrapper>
                );
            }
            case PrismicSliceType.twoColumnText: {
                const twoColumnTextSlice = slice as PrismicTwoColumnTextSlice;
                const twoColumnTextProps = transformToTwoColumnText(twoColumnTextSlice.primary);

                return (
                    <TwoColumnTextSlice
                        {...twoColumnTextProps}
                        className="slice-renderer__two-column-text-slice"
                    />
                );
            }
            case PrismicSliceType.vacancyCallToAction: {
                const vacancyCallToActionSlice = slice as PrismicVacancyCallToActionSlice;
                const vacancyCallToActionProps = transformToVacancyCallToAction(vacancyCallToActionSlice.primary);

                return (
                    <VacancyCallToActionSlice
                        {...vacancyCallToActionProps}
                        className="slice-renderer__vacancy-call-to-action-slice"
                    />
                );
            }
            case PrismicSliceType.vacancyExpectations: {
                const vacancyExpectationsSlice = slice as PrismicVacancyExpectationsSlice;
                const vacancyExpectationsProps = transformToVacancyExpectations(vacancyExpectationsSlice.primary, language);

                return (
                    <VacancyExpectationsSlice
                        {...vacancyExpectationsProps}
                        className="slice-renderer__vacancy-expectations-slice"
                    />
                );
            }
            case PrismicSliceType.vacancyOverview: {
                return (
                    <ConnectedVacancyOverview className="slice-renderer__vacancy-overview-slice" />
                );
            }
            case PrismicSliceType.video: {
                const videoSlice = slice as PrismicVideoSlice;
                const videoProps = transformToVideo(videoSlice.primary);

                return (
                    <VideoSlice
                        {...videoProps}
                        className="slice-renderer__video-slice"
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className={`slice-renderer ${className}`}>
            {filteredSlices.map(slice => (
                <div key={slice.id} className="slice-renderer__slice">
                    {renderCurrentSlice(slice)}
                </div>
            ))}
        </div>
    );
};

export default SliceRenderer;
