import { RouteData } from '../LocalizedRoutes';

export enum AppRoute {
    home = 'routes.home',
    about = 'routes.about',
    gymLocator = 'routes.gymLocator',
    flavours = 'routes.flavours',
    flavourDetail = 'routes.flavourDetail',
    personalityTest = 'routes.personalityTest',
    personalityTestResult = 'routes.personalityTestResult',
    yourClub = 'routes.yourClub',
    workAt = 'routes.workAt',
    vacancyDetail = 'routes.vacancyDetail',
    articles = 'routes.articles',
    articleDetail = 'routes.articleDetail',
    contact = 'routes.contact',
    faq = 'routes.faq',
    usp = 'routes.usp',
    privacyStatement = 'routes.privacyStatement',
    notFound = 'routes.notFound',
}

export enum ExternalRoute {
    partnerPortal = 'externalRoutes.partnerPortal',
    servicePortal = 'externalRoutes.servicePortal',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.entries<string>(AppRoute).map(([, value]) => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);

export const externalRoutes = Object.fromEntries<RouteData<ExternalRoute>>(
    Object.entries<string>(ExternalRoute).map(([, value]) => ([value, {
        key: value as ExternalRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);

const navigationItems = [
    AppRoute.home,
    AppRoute.about,
    AppRoute.flavours,
    AppRoute.yourClub,
    AppRoute.workAt,
    AppRoute.articles,
    AppRoute.contact,
];

const footerSitemapItems = [
    AppRoute.about,
    AppRoute.gymLocator,
    AppRoute.yourClub,
    AppRoute.workAt,
];

const footerSupportItems = [
    AppRoute.contact,
    AppRoute.faq,
    ExternalRoute.partnerPortal,
    ExternalRoute.servicePortal,
];

export const navigationList = Object.values(appRoutes).filter(item => navigationItems.includes(item.key));
export const footerSitemapList = Object.values(appRoutes).filter(item => footerSitemapItems.includes(item.key));
export const footerSupportList = [
    ...Object.values(appRoutes).filter(item => footerSupportItems.includes(item.key)),
    ...Object.values(externalRoutes).filter(item => footerSupportItems.includes(item.key)),
];

export enum HomeSection {
    welcome = 'homeSections.welcome',
    hydrationStation = 'homeSections.hydrationStation',
    flavours = 'homeSections.flavours',
    benefits = 'homeSections.benefits',
    playground = 'homeSections.playground',
    gymLocator = 'homeSections.gymLocator',
    contact = 'homeSections.contact'
}

export const homeSections = Object.fromEntries<RouteData<HomeSection>>(
    Object.entries<string>(HomeSection).map(([, value]) => ([value, {
        key: value as HomeSection,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
