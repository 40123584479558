/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancySoftwareDevelopment: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M79,72H21c-8.8,0-16-7.2-16-16V26c0-8.8,7.2-16,16-16h58c8.8,0,16,7.2,16,16v30C95,64.8,87.8,72,79,72z M21,14c-6.6,0-12,5.4-12,12v30c0,6.6,5.4,12,12,12h58c6.6,0,12-5.4,12-12V26c0-6.6-5.4-12-12-12H21z" />
        <path d="M44,55.5c-0.3,0-0.7-0.1-1-0.2c-1.3-0.6-1.8-2-1.3-3.3l12-27c0.6-1.3,2-1.8,3.3-1.3c1.3,0.6,1.8,2,1.3,3.3l-12,27C45.9,54.9,45,55.5,44,55.5z" />
        <path d="M64,53.5c-0.6,0-1.3-0.2-1.8-0.7c-1-1-1-2.6,0-3.5l9.2-9.2l-9.2-9.2c-1-1-1-2.6,0-3.5c1-1,2.6-1,3.5,0l11,11c1,1,1,2.6,0,3.5l-11,11C65.3,53.3,64.6,53.5,64,53.5z" />
        <path d="M35,53.5c-0.6,0-1.3-0.2-1.8-0.7l-11-11c-1-1-1-2.6,0-3.5l11-11c1-1,2.6-1,3.5,0c1,1,1,2.6,0,3.5L27.5,40l9.2,9.2c1,1,1,2.6,0,3.5C36.3,53.3,35.6,53.5,35,53.5z" />
        <path d="M42,89c-1.1,0-2-0.9-2-2V76c0-1.1,0.9-2,2-2s2,0.9,2,2v11C44,88.1,43.1,89,42,89z" />
        <path d="M58,89c-1.1,0-2-0.9-2-2V76c0-1.1,0.9-2,2-2s2,0.9,2,2v11C60,88.1,59.1,89,58,89z" />
        <path d="M64,90H36c-1.1,0-2-0.9-2-2s0.9-2,2-2h28c1.1,0,2,0.9,2,2S65.1,90,64,90z" />
        <path d="M57,65H43c-1.1,0-2-0.9-2-2s0.9-2,2-2h14c1.1,0,2,0.9,2,2S58.1,65,57,65z" />
    </Svg>
);

export default IconVacancySoftwareDevelopment;
/* eslint-enable max-len */
