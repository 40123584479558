import { FC, ReactElement, useMemo } from 'react';

import { Picture, Wrapper } from '../../components';
import { Gym, gymSets } from '../../constants/gyms';
import { useCountry } from '../../context/CountryContext';
import useTrans from '../../hooks/useTrans';

import './PartnerGrid.scss';

interface PartnerGridProps {
    className?: string;
}

const PartnerGrid: FC<PartnerGridProps> = ({
    className = '',
}): ReactElement => {
    const { continent } = useCountry();
    const trans = useTrans();

    const gyms = useMemo((): Gym[] => (
        gymSets[continent]
    ), [continent]);

    return (
        <div className={`partner-grid ${className}`}>
            <Wrapper className="partner-grid__wrapper">
                <ul className="partner-grid__list">
                    {gyms.map(gym => (
                        <li key={gym} className="partner-grid__item">
                            <Picture
                                loading="lazy"
                                src={`/images/gym-logos/${gym}.png`}
                                sources={[
                                    {
                                        srcSet: `/images/gym-logos/${gym}.avif, /images/gym-logos/${gym}-2x.avif 2x`,
                                        type: 'image/avif',
                                    },
                                    {
                                        srcSet: `/images/gym-logos/${gym}.webp, /images/gym-logos/${gym}-2x.webp 2x`,
                                        type: 'image/webp',
                                    },
                                ]}
                                alt={trans('compositions.partnerGrid.logoAlt', { gym })}
                                className="partner-grid__picture"
                            />
                        </li>
                    ))}
                </ul>
            </Wrapper>
        </div>
    );
};

export default PartnerGrid;
