/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconWhatsApp: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M52.5,1.3c13.1,0.5,25.2,6,34.1,15.5c8.9,9.5,13.6,21.9,13.1,34.9c-0.9,26.8-23.7,47.9-50.6,47C40.9,98.4,33,96.1,26,92l-26.2,5.9l7.8-25c-4-7.5-6-16-5.7-24.6C2.9,21.4,25.6,0.4,52.5,1.3z M52.3,9.5C29.8,8.7,11,26.3,10.2,48.6c-0.3,7.6,1.6,15.2,5.5,21.8l0.9,1.6l-4.6,14.8l15.5-3.5l1.5,0.9c6.1,3.9,13.2,6.1,20.5,6.4c22.4,0.8,41.3-16.7,42.1-39c0.4-10.8-3.5-21.1-10.9-29C73.2,14.5,63.1,9.9,52.3,9.5z" />
        <path d="M34.3,27.7c0.8,0,1.6,0.1,2.3,0.1c0.7,0.1,1.7-0.2,2.6,2.1c0.3,0.7,0.7,1.8,1.1,3l0.7,1.8c0.8,2,1.5,3.9,1.6,4.2c0.3,0.6,0.5,1.3,0,2.1c-0.4,0.8-0.6,1.3-1.3,1.9c-0.6,0.7-1.3,1.5-1.9,2c-0.6,0.6-1.3,1.2-0.6,2.4c0.7,1.2,3,5.2,6.4,8.5c4.5,4.2,8.3,5.6,9.5,6.3c1.2,0.6,1.9,0.6,2.6-0.2c0.7-0.8,3.1-3.4,4-4.5c0.8-1.2,1.6-0.9,2.7-0.5c1.1,0.4,6.9,3.5,8.1,4.2c1.2,0.6,2,1,2.3,1.5c0.3,0.5,0.2,2.9-0.9,5.7c-1.1,2.8-6,5.1-8.4,5.4c-2.1,0.2-4.7,0.3-7.6-0.7c-1.7-0.6-4-1.4-6.8-2.8c-11.9-5.6-19.4-17.9-20-18.7c-0.6-0.8-4.7-6.6-4.5-12.5c0.2-5.9,3.4-8.7,4.6-9.8C32.2,27.9,33.5,27.7,34.3,27.7z" />
    </Svg>
);

export default IconWhatsApp;
/* eslint-enable max-len */
