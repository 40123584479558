/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconPause: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M34,15.4L34,15.4v69.1h-9.5V15.4H34 M34,6.3h-9.5c-5.1,0-9.2,4.1-9.2,9.2v69.1c0,5.1,4.1,9.2,9.2,9.2H34c5.1,0,9.2-4.1,9.2-9.2V15.4C43.2,10.4,39.1,6.3,34,6.3L34,6.3z" />
        <path d="M75.5,15.4v69.1H66V15.4H75.5 M75.5,6.3H66c-5.1,0-9.2,4.1-9.2,9.2v69.1c0,5.1,4.1,9.2,9.2,9.2h9.5c5.1,0,9.2-4.1,9.2-9.2V15.4C84.7,10.4,80.6,6.3,75.5,6.3L75.5,6.3z" />
    </Svg>
);

export default IconPause;
/* eslint-enable max-len */
