import { ContactFormData } from '../../entities/Contact/Contact';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { sendContactFormEmail } from '../../entities/Postmark/PostmarkService';
import { TypedDispatch } from '../store';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setIsLoading, setIsSuccessful } from './contactForm';

export const sendContactForm = (formData: ContactFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));

    try {
        const postmarkResponse = await sendContactFormEmail(formData);

        if (!isFetchResultSuccessful(postmarkResponse)) {
            console.error('[sendContactForm]', postmarkResponse.error.description);

            dispatch(addNegativeToast(postmarkResponse.error));

            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(addPositiveToast(postmarkResponse.data));
    } catch (error) {
        console.error('[sendContactForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
