import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../../../components';
import { HeaderDropletImage } from '../../../../compositions';
import { Article } from '../../../../entities/Article/Article';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './ArticleHeader.scss';

interface ArticleHeaderProps {
    article: Article;
    className?: string;
}

const ArticleHeader: FC<ArticleHeaderProps> = ({
    article,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const createdAt = formatDate(article.createdAt, { dateStyle: 'long' });

    const dropletIndex = article.posterDroplet - 1;

    const titleClassNames = classNames('article-header__title', {
        [`article-header__title--colour-${article.titleColour}`]: article.titleColour,
    });

    return (
        <header className={`article-header ${className}`}>
            <Wrapper className="article-header__content-wrapper">
                <ul className="article-header__category-list">
                    {article.categories.map(category => (
                        <li key={category} className="article-header__category-item">
                            {category}
                        </li>
                    ))}
                </ul>

                <h1 className={titleClassNames}>
                    {article.title}
                </h1>

                <div className="article-header__meta-wrapper">
                    {article.readTime && (
                        <p className="article-header__read-time">
                            {trans('containers.articleContent.readTime', {
                                minutes: article.readTime,
                            })}
                        </p>
                    )}

                    <p className="article-header__created-date">
                        {trans('containers.articleContent.createdAt', { createdAt })}
                    </p>
                </div>

                {article.poster && (
                    <HeaderDropletImage
                        dropletIndex={dropletIndex}
                        image={article.poster}
                        className="article-header__poster"
                    />
                )}
            </Wrapper>
        </header>
    );
};

export default ArticleHeader;
