import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { PrismicSliceTitle, Wrapper } from '../../../components';
import { TwoColumnText } from '../../../entities/@slices/TwoColumnText/TwoColumnText';

import './TwoColumnTextSlice.scss';

interface TwoColumnTextSliceProps extends TwoColumnText {
    className?: string;
}

const TwoColumnTextSlice: FC<TwoColumnTextSliceProps> = ({
    isMirrored,
    title,
    titleSize,
    titleColour,
    paragraph,
    className = '',
}): ReactElement => {
    const twoColumnTextSliceClassName = classNames('two-column-text-slice', {
        'two-column-text-slice--is-mirrored': isMirrored,
    }, className);

    return (
        <Wrapper className={twoColumnTextSliceClassName}>
            <PrismicSliceTitle
                text={title}
                size={titleSize}
                colour={titleColour}
                className="two-column-text-slice__title"
            />

            <p className="two-column-text-slice__paragraph">
                {paragraph}
            </p>
        </Wrapper>
    );
};

export default TwoColumnTextSlice;
