import { FC, useEffect, useMemo } from 'react';

import {
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { defaultLocale, LocaleData } from './constants/locale';
import { AppRoute } from './constants/routing';
import { useLocale, useLocaleDispatch } from './context/LocaleContext';
import { getLocaleFromLanguageString } from './helpers/trans';
import useTrans from './hooks/useTrans';
import {
    AboutPage,
    ArticleDetailPage,
    ArticlesPage,
    ContactPage,
    FaqPage,
    FlavourDetailPage,
    FlavoursPage,
    GymLocatorPage,
    HomePage,
    NotFoundPage,
    PersonalityTestPage,
    PersonalityTestResultPage,
    PrivacyStatementPage,
    UspPage,
    VacancyDetailPage,
    WorkAtPage,
    YourClubPage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

const routes = [
    { key: AppRoute.home, element: <HomePage /> },
    { key: AppRoute.about, element: <AboutPage /> },
    { key: AppRoute.gymLocator, element: <GymLocatorPage /> },
    { key: AppRoute.flavours, element: <FlavoursPage /> },
    { key: AppRoute.flavourDetail, element: <FlavourDetailPage /> },
    { key: AppRoute.personalityTest, element: <PersonalityTestPage /> },
    { key: AppRoute.personalityTestResult, element: <PersonalityTestResultPage /> },
    { key: AppRoute.yourClub, element: <YourClubPage /> },
    { key: AppRoute.workAt, element: <WorkAtPage /> },
    { key: AppRoute.vacancyDetail, element: <VacancyDetailPage /> },
    { key: AppRoute.articles, element: <ArticlesPage /> },
    { key: AppRoute.articleDetail, element: <ArticleDetailPage /> },
    { key: AppRoute.contact, element: <ContactPage /> },
    { key: AppRoute.faq, element: <FaqPage /> },
    { key: AppRoute.usp, element: <UspPage /> },
    { key: AppRoute.privacyStatement, element: <PrivacyStatementPage /> },
    { key: AppRoute.notFound, element: <NotFoundPage /> },
];

const LocalizedRoutes: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLocale();
    const dispatchLocale = useLocaleDispatch();
    const trans = useTrans();

    const [storedLocale] = useLocalStorage<LocaleData>('locale');

    // Redirect to language set in URL, and switch locale if it differs from active one
    useEffect((): void => {
        const { pathname, hash, search } = location;
        const decodedUrl = decodeURIComponent(pathname + hash + search);

        const routeLanguage = decodedUrl.split('/')[1];
        const routeLocale = getLocaleFromLanguageString(routeLanguage);

        const updatedLocale = routeLocale ?? defaultLocale;
        const updatedUrl = routeLocale
            ? decodedUrl.replace(`/${routeLanguage}/`, `/${updatedLocale.language}/`)
            : `/${defaultLocale.language}${decodedUrl}`;

        navigate(updatedUrl, { replace: true });

        // Update user locale, but only if one has been stored already
        if (storedLocale && routeLanguage !== language) {
            dispatchLocale(updatedLocale);
        }
    }, []);

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => ({
        ...route,
        path: route.key !== AppRoute.notFound
            ? trans(`${route.key}.path`)
            : '*',
        label: trans(`${route.key}.label`),
    })), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};

export default LocalizedRoutes;
