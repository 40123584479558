import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useTimeout = (callback: () => void, delay = 1000, deps: any[] = []): void => {
    useEffect((): () => void => {
        const timer = setTimeout(callback, delay);
        return (): void => clearTimeout(timer);
    }, deps);
};

export default useTimeout;
