import { FC, ReactElement } from 'react';

import './TableCaption.scss';

interface TableCaptionProps {
    title: string;
    className?: string;
}

const TableCaption: FC<TableCaptionProps> = ({ title, className = '' }): ReactElement => (
    <caption className={`table-caption ${className}`}>
        {title}
    </caption>
);

export default TableCaption;
