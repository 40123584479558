import { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useWindowScroll } from 'react-use';

import { LinkButton } from '../../components';
import { HomeSection, homeSections } from '../../constants/routing';
import useScrollSectionIntoView from '../../hooks/useScrollSectionIntoView';
import useTrans from '../../hooks/useTrans';
import { FixedNavigationItem } from './subcomponents';

import './FixedNavigation.scss';

interface FixedNavigationProps {
    navigationLabel: string;
    className?: string;
}

const FixedNavigation: FC<FixedNavigationProps> = ({
    navigationLabel,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const location = useLocation();
    const { y } = useWindowScroll();
    const { scrollIntoView } = useScrollSectionIntoView();

    const [blobIsRevealed, setBlobIsRevealed] = useState<boolean>(false);

    const handleDiscoverClick = (): void => scrollIntoView(HomeSection.hydrationStation);

    const homeSectionValues = Object.values(homeSections);

    const discoverButtonClassNames = classNames('fixed-navigation__discover-button', {
        'fixed-navigation__discover-button--is-revealed': y === 0,
    });

    return (
        <div className={`fixed-navigation ${className}`}>
            <nav aria-label={navigationLabel} className="fixed-navigation__nav">
                <ul className="fixed-navigation__list">
                    {homeSectionValues.map(section => {
                        const { pathname, hash } = location;
                        const isActive = decodeURIComponent(pathname + hash) === trans(section.path);

                        return (
                            <FixedNavigationItem
                                key={section.key}
                                blobIsRevealed={blobIsRevealed}
                                isActive={isActive}
                                id={section.key}
                                to={trans(section.path)}
                                label={trans(section.label)}
                                handleChangeBlobState={setBlobIsRevealed}
                                className="fixed-navigation__item"
                            />
                        );
                    })}
                </ul>
            </nav>

            <LinkButton
                to={trans(homeSections[HomeSection.hydrationStation].path)}
                text={trans('common.discover')}
                tabIndex={y === 0 ? 0 : -1}
                onClick={handleDiscoverClick}
                className={discoverButtonClassNames}
            />
        </div>
    );
};

export default FixedNavigation;
