import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedArticleContent, ConnectedRelatedArticles } from '../../connectors';

import './ArticleDetailPage.scss';

const ArticleDetailPage: FC = (): ReactElement => (
    <Page className="article-detail-page">
        <ConnectedArticleContent />

        <ConnectedRelatedArticles className="article-detail-page__related-articles" />
    </Page>
);

export default ArticleDetailPage;
