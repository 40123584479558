import {
    FC,
    MouseEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useDebounce, useWindowSize } from 'react-use';

import { LinkButton, Wrapper } from '../../../../../components';
import { IconButton } from '../../../../../compositions';
import { Direction } from '../../../../../constants/locale';
import { AppRoute, appRoutes } from '../../../../../constants/routing';
import { Usp } from '../../../../../constants/usp';
import { useLocale } from '../../../../../context/LocaleContext';
import { gsap } from '../../../../../entities/Gsap/GsapService';
import { getEnumKeyByEnumValue } from '../../../../../helpers/enum';
import useDeviceWidth from '../../../../../hooks/useDeviceWidth';
import useTrans from '../../../../../hooks/useTrans';
import scrollToUspItem from '../../helpers/scrollToUspItem';
import { DesktopScrollPos } from '../../types/DeskopScrollPos';
import { UspCarouselListItem } from '..';

import './UspCarousel.scss';

interface UspCarouselProps {
    usps: Usp[];
    className?: string;
}

const UspCarousel: FC<UspCarouselProps> = ({ usps, className = '' }): ReactElement => {
    const trans = useTrans();
    const { direction } = useLocale();

    const { width } = useWindowSize();
    const { isMobile, isTabletPortrait } = useDeviceWidth();

    const isNarrowDevice = isMobile || isTabletPortrait;

    const scrollRef = useRef<HTMLDivElement>(null);
    const sizerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);

    const [debouncedWidth, setDebouncedWidth] = useState<number>();
    const [activeMobileItem, setActiveMobileItem] = useState<Usp>();
    const [debouncedActiveMobileItem, setDebouncedActiveMobileItem] = useState<Usp>();
    const [activeDesktopItem, setActiveDesktopItem] = useState<Usp>();
    const [paddingInline, setPaddingInline] = useState<string>('0');
    const [desktopScrollPos, setDesktopScrollPos] = useState<DesktopScrollPos>(DesktopScrollPos.start);

    const activeItem = isNarrowDevice ? debouncedActiveMobileItem : activeDesktopItem;

    const reset = (): void => {
        if (scrollRef.current) {
            scrollRef.current.scroll({ left: 0 });
        }

        gsap.set(scrollRef.current, { clearProps: 'all' });
        setActiveMobileItem(usps[0]);
        setDebouncedActiveMobileItem(usps[0]);
        setActiveDesktopItem(undefined);
        setDesktopScrollPos(DesktopScrollPos.start);
    };

    useDebounce((): void => {
        setDebouncedActiveMobileItem(activeMobileItem);
    }, 300, [activeMobileItem]);

    useDebounce((): void => {
        setDebouncedWidth(width);
    }, 100, [width]);

    useEffect((): void => {
        if (debouncedWidth && sizerRef.current) {
            const offsetX = isNarrowDevice ? '50%' : `${sizerRef.current.getBoundingClientRect().x}px`;
            setPaddingInline(offsetX);
        }
    }, [debouncedWidth]);

    useEffect((): void => {
        reset();
    }, [isNarrowDevice, paddingInline, direction]);

    const handleListItemFocus = (value: string): void => {
        const key = getEnumKeyByEnumValue(Usp, value);

        if (!isNarrowDevice && key) {
            setActiveDesktopItem(Usp[key]);
        }
    };

    const scrollToItem = (increment: -1 | 1): void => {
        if (!scrollRef.current || !listRef.current || !listRef.current.firstChild) {
            return;
        }

        const newIndex = usps.findIndex(item => item === activeMobileItem) + increment;

        if (isNarrowDevice && (newIndex < 0 || newIndex >= usps.length)) {
            return;
        }

        const newDesktopScrollPos = scrollToUspItem(
            isNarrowDevice,
            scrollRef.current,
            listRef.current,
            usps,
            increment,
            newIndex,
            direction,
        );

        setDesktopScrollPos(newDesktopScrollPos || DesktopScrollPos.start);
        setActiveMobileItem(isNarrowDevice ? usps[newIndex] : undefined);
    };

    const handlePrevButtonClick = (): void => scrollToItem(-1);
    const handleNextButtonClick = (): void => scrollToItem(1);

    const handleScroll = (e: MouseEvent<HTMLDivElement>): void => {
        const element = e.target as HTMLDivElement;

        if (element && listRef.current) {
            const justifiedScrollLeft = direction === Direction.ltr ? element.scrollLeft : -element.scrollLeft;
            const ratio = justifiedScrollLeft / listRef.current.clientWidth;
            const itemIndex = Math.round(ratio * (usps.length - 1));

            setActiveMobileItem(usps[itemIndex]);
        }
    };

    return (
        <div className={`usp-carousel ${className}`}>
            <Wrapper className="usp-carousel__wrapper-sizer">
                <div ref={sizerRef} className="usp-carousel__wrapper-sizer-inner" />
            </Wrapper>

            <Wrapper className="usp-carousel__button-wrapper">
                <div className="usp-carousel__control-wrapper">
                    <IconButton
                        disabled={isNarrowDevice ? activeItem === usps[0] : desktopScrollPos === DesktopScrollPos.start}
                        hideLabel
                        icon={direction === Direction.rtl ? 'chevron-right' : 'chevron-left'}
                        text={trans('common.prev')}
                        onClick={handlePrevButtonClick}
                        className="usp-carousel__control-button"
                    />
                    <IconButton
                        disabled={isNarrowDevice ? activeItem === usps[usps.length - 1] : desktopScrollPos === DesktopScrollPos.end}
                        hideLabel
                        icon={direction === Direction.rtl ? 'chevron-left' : 'chevron-right'}
                        text={trans('common.next')}
                        onClick={handleNextButtonClick}
                        className="usp-carousel__control-button"
                    />
                </div>

                <LinkButton
                    to={trans(appRoutes[AppRoute.usp].path)}
                    text={trans('containers.uspSection.readMore')}
                    className="usp-carousel__link-button"
                />
            </Wrapper>

            <div className="usp-carousel__scroller-wrapper">
                <div
                    ref={scrollRef}
                    onScroll={handleScroll}
                    className="usp-carousel__scroller"
                >
                    <div
                        style={{ paddingInline }}
                        className="usp-carousel__list-wrapper"
                    >
                        <ul ref={listRef} className="usp-carousel__list">
                            {usps.map(usp => (
                                <UspCarouselListItem
                                    key={usp}
                                    isActive={usp === activeItem}
                                    usp={usp}
                                    onFocus={handleListItemFocus}
                                    className="usp-carousel__list-item"
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UspCarousel;
