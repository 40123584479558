import { FC, ReactElement } from 'react';

import { PrismicSliceTitle, Wrapper } from '../../../components';
import { CenteredText } from '../../../entities/@slices/CenteredText/CenteredText';

import './CenteredTextSlice.scss';

interface CenteredTextSliceProps extends CenteredText {
    className?: string;
}

const CenteredTextSlice: FC<CenteredTextSliceProps> = ({
    title,
    titleSize,
    titleColour,
    paragraph,
    className = '',
}): ReactElement => (
    <Wrapper className={`centered-text-slice ${className}`}>
        <PrismicSliceTitle
            text={title}
            size={titleSize}
            colour={titleColour}
            className="centered-text-slice__title"
        />

        <p className="centered-text-slice__paragraph">
            {paragraph}
        </p>
    </Wrapper>
);

export default CenteredTextSlice;
