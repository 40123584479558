import { FC, ReactElement } from 'react';

import { SafeHtml, Wrapper } from '../../components';
import { FaqData } from '../../entities/Faq/Faq';
import { SliceRenderer } from '..';
import { FaqSkeletons } from './skeletons';

import './Faq.scss';

interface FaqProps extends Omit<FaqData, 'language'> {
    isLoading: boolean;
    className?: string;
}

const Faq: FC<FaqProps> = ({
    isLoading,
    title,
    intro,
    slices,
    className = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <FaqSkeletons className={`faq ${className}`} />
        );
    }

    return (
        <article className={`faq ${className}`}>
            <Wrapper className="faq__wrapper">
                <h1 className="faq__title">
                    {title}
                </h1>

                <SafeHtml html={intro} className="faq__intro" />
            </Wrapper>

            <SliceRenderer
                slices={slices}
                className="faq__slice-renderer"
            />
        </article>
    );
};

export default Faq;
