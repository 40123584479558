import { gsap, Linear, Power1 } from '../../../../entities/Gsap/GsapService';

export const getMainTimeline = (
    wrapper: HTMLDivElement,
    paragraph: HTMLParagraphElement,
    heading: HTMLHeadingElement,
): gsap.core.Timeline => {
    const wrapperStyle = getComputedStyle(wrapper);
    const wrapperWidth = wrapper.clientWidth;
    const surplusHeight = Math.max(0, wrapperWidth - wrapper.clientHeight);
    const wrapperPadding = parseFloat(wrapperStyle.paddingTop) * 2;
    const wrapperInnerHeight = wrapperWidth - wrapperPadding;
    const newHeadingTop = Math.max((wrapperInnerHeight / 2) - (heading.clientHeight / 2), 0);
    const wrapperMinHeight = heading.clientHeight + wrapperPadding;

    const timeline = gsap.timeline({ paused: true });

    gsap.set(wrapper, { minHeight: Math.max(wrapperWidth, wrapperMinHeight) });
    gsap.set([heading, paragraph], { y: surplusHeight / 2 });

    timeline.from(wrapper, {
        duration: 1,
        height: wrapperWidth,
        ease: Linear.easeNone,
    }, 0);

    timeline.from(heading, {
        duration: 1,
        y: newHeadingTop,
        ease: Linear.easeNone,
    }, 0);

    timeline.from(paragraph, {
        duration: 0.25,
        opacity: 0,
        ease: Power1.easeOut,
    }, 0.75);

    return timeline;
};

export const destroyMainTimeline = (
    wrapper: HTMLDivElement | null,
    paragraph: HTMLParagraphElement | null,
    heading: HTMLHeadingElement | null,
    timeline?: gsap.core.Timeline,
): void => {
    if (timeline) {
        timeline.kill();
    }

    if (!wrapper || !paragraph || !heading) {
        return;
    }

    gsap.killTweensOf([wrapper, heading, paragraph]);
    gsap.set([wrapper, heading, paragraph], { clearProps: 'all' });
};
