import { FC, PropsWithChildren, ReactElement } from 'react';

import './TableHead.scss';

interface TableHeadProps {
    className?: string;
}

const TableHead: FC<PropsWithChildren<TableHeadProps>> = ({ className = '', children }): ReactElement => (
    <thead className={`table-head ${className}`}>
        {children}
    </thead>
);

export default TableHead;
