import { FC, ReactElement } from 'react';

import { Icon, SafeHtml } from '../../../../../components';
import { FaqItem as FaqItemData } from '../../../../../entities/Faq/Faq';

import './FaqItem.scss';

interface FaqItemProps extends FaqItemData {
    className?: string;
}

const FaqItem: FC<FaqItemProps> = ({
    question,
    answer,
    image,
    className = '',
}): ReactElement => (
    <li className={`faq-item ${className}`}>
        <details className="faq-item__details">
            <summary className="faq-item__summary">
                {question}

                <Icon name="chevron-down" className="faq-item__icon" />
            </summary>

            <div className="faq-item__answer-wrapper">
                <SafeHtml
                    html={answer}
                    className="faq-item__answer-text"
                />

                {image.src && (
                    <img
                        src={image.src}
                        alt={image.alt}
                        className="faq-item__answer-image"
                    />
                )}
            </div>
        </details>
    </li>
);

export default FaqItem;
