import { FC, ReactElement, useEffect } from 'react';

import { VacancyOverview } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchVacancyOverview } from '../../redux/vacancies/vacanciesActions';

interface ConnectedVacancyOverviewProps {
    className?: string;
}

const ConnectedVacancyOverview: FC<ConnectedVacancyOverviewProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.vacanciesReducer.isLoading);
    const vacancies = useTypedSelector(state => state.vacanciesReducer.vacancies);

    useEffect((): void => {
        dispatch(fetchVacancyOverview({ language, page: 1 }));
    }, [language]);

    return (
        <VacancyOverview
            isLoading={isLoading}
            vacancies={vacancies}
            className={className}
        />
    );
};

export default ConnectedVacancyOverview;
