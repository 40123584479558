/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyEmployment: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M50,100C22.4,100,0,77.6,0,50C0,22.4,22.4,0,50,0c27.6,0,50,22.4,50,50C100,77.6,77.6,100,50,100z M50,7C26.3,7,7,26.3,7,50s19.3,43,43,43s43-19.3,43-43S73.7,7,50,7z" />
        <path d="M59.3,62.8c-0.9,0-1.8-0.3-2.5-1l-9.3-9.3c-0.7-0.7-1-1.5-1-2.5V31.4c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5v17.2l8.3,8.3c1.4,1.4,1.4,3.6,0,4.9C61.1,62.5,60.2,62.8,59.3,62.8z" />
    </Svg>
);

export default IconVacancyEmployment;
/* eslint-enable max-len */
