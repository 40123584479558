/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconArrowRight: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={84.9} className={className}>
        <polygon points="92.9,35.4 57.6,0 50.5,7.1 80.9,37.4 0,37.4 0,47.4 80.9,47.4 50.5,77.8 57.6,84.9 92.9,49.5 100,42.4" />
    </Svg>
);

export default IconArrowRight;
/* eslint-enable max-len */
