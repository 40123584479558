import { Language } from '../../constants/locale';
import { prismicPagePreviewResolver } from '../../helpers/prismic';
import { CallToAction, PrismicLink } from './Link';

export const transformToLink = (
    prismicLink: PrismicLink,
    language?: Language,
): string => {
    if (prismicLink.link_type === 'Document') {
        return prismicPagePreviewResolver(prismicLink, language);
    }

    return prismicLink.url || '';
};

export const transformToCallToAction = (
    prismicCallToActionLink: PrismicLink,
    prismicCallToActionLabel?: string,
    language?: Language,
): CallToAction => ({
    url: transformToLink(prismicCallToActionLink, language),
    label: prismicCallToActionLabel || '',
});
