import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { Article } from '../../../../entities/Article/Article';
import { getTranslation } from '../../../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useTrans from '../../../../hooks/useTrans';

interface ArticleHelmetProps {
    article: Article;
}

const ArticleHelmet: FC<ArticleHelmetProps> = ({
    article,
}): ReactElement | null => {
    const { slug = '' } = useParams();
    const trans = useTrans();

    const siteUrl = trans('company.url');
    const articleDetailKey = trans('routes.articleDetail.path');
    const articleDetailPathname = replaceUrlParamKeysWithValues(articleDetailKey, { slug });
    const articleDetailUrl = siteUrl + articleDetailPathname;

    const logoUrl = `${siteUrl}/images/logo.webp`;

    if (!article.seo.title) {
        return null;
    }

    return (
        <Helmet>
            <title>{article.seo.title}</title>
            <meta name="description" content={article.seo.description} />
            <meta name="keywords" content={article.seo.keywords} />

            <meta property="og:site_name" content="Yanga" />
            <meta property="og:title" content={article.seo.title} />
            <meta property="og:description" content={article.seo.description} />

            <meta property="og:image" content={article.seo.image?.src} />
            <meta property="og:image:alt" content={article.seo.image?.alt} />

            <meta property="og:url" content={articleDetailUrl} />

            <link rel="canonical" href={articleDetailUrl} />

            {article && article.alternativeLanguages.map(altLang => {
                const altLangKey = getTranslation(altLang.language, 'routes.articleDetail.path');
                const altLangPathname = replaceUrlParamKeysWithValues(altLangKey, {
                    slug: altLang.slug,
                });

                return (
                    <link
                        key={altLang.language}
                        rel="alternate"
                        hrefLang={altLang.language}
                        href={siteUrl + altLangPathname}
                    />
                );
            })}

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BlogPosting',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                    },
                    author: article.author ? {
                        '@type': 'Person',
                        name: article.author,
                    } : {
                        '@type': 'Organization',
                        name: 'Yanga',
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'Yanga',
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrl,
                        },
                    },
                    headline: article.seo.title,
                    image: article.seo.image?.src ? [article.seo.image.src] : undefined,
                    datePublished: article.createdAt,
                    dateModified: article.updatedAt,
                })}
            </script>
        </Helmet>
    );
};

export default ArticleHelmet;
