import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { LocalizedHelmet } from '../../compositions';
import { ConnectedContactSection } from '../../connectors';
import useTrans from '../../hooks/useTrans';

import './ContactPage.scss';

const ContactPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="contact-page">
            <LocalizedHelmet pageKey="contact" />

            <ConnectedContactSection
                title={trans('pages.contact.contactSection.title')}
                description={trans('pages.contact.contactSection.description')}
            />
        </Page>
    );
};

export default ContactPage;
