import TagManager from 'react-gtm-module';

export const initTagManager = (dataLayer: Record<string, string>): void => {
    const gtmId = process.env.REACT_APP_GTM_KEY;

    if (gtmId) {
        TagManager.initialize({
            gtmId,
            dataLayer,
        });
    }
};

export const gtmEvent = (dataLayer: Record<string, unknown>): void => {
    TagManager.dataLayer({ dataLayer });
};
