import { Image, PrismicImage } from '../@slices/Image/Image';

export interface PrismicSeo {
    seo_title: string;
    seo_description: string;
    seo_keywords: string;
    seo_image: PrismicImage;
}

export interface Seo {
    title: string;
    description: string;
    keywords: string;
    image?: Image;
}

export const defaultSeo = {
    title: '',
    description: '',
    keywords: '',
};
