import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { Page } from '../../components';
import { NotFoundSection } from '../../containers';
import useTrans from '../../hooks/useTrans';

import './NotFoundPage.scss';

const NotFoundPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="not-found-page">
            <Helmet>
                <title>{trans('pages.notFound.meta.title')}</title>
                <meta property="og:title" content={trans('pages.notFound.meta.title')} />
                <meta name="description" content={trans('pages.notFound.meta.description')} />
                <meta property="og:description" content={trans('pages.notFound.meta.description')} />
            </Helmet>

            <NotFoundSection className="not-found-page__section" />
        </Page>
    );
};

export default NotFoundPage;
