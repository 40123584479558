import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { locales } from '../../../constants/locale';
import { flavourResultSearchParam } from '../../../constants/personalityTest';
import { getFlavourFromPathname, getFlavourFromSearchParams } from '../../../helpers/flavours';
import { getTranslation } from '../../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useTrans from '../../../hooks/useTrans';

const PersonalityTestResultHelmet: FC<PropsWithChildren> = ({ children }): ReactElement => {
    const trans = useTrans();

    const { pathname } = useLocation();
    const { flavour = '' } = useParams();
    const [searchParams] = useSearchParams();

    const pathnameFlavourKey = getFlavourFromPathname(pathname, flavour);
    const searchParamsFlavourKey = getFlavourFromSearchParams(pathname, searchParams);

    const paramPath = trans('routes.personalityTestResult.path');
    const updatedPathname = replaceUrlParamKeysWithValues(paramPath, {
        flavour: flavour || searchParams.get(flavourResultSearchParam) || '',
    });

    const siteUrl = trans('company.url');

    const flavourKey = pathnameFlavourKey || searchParamsFlavourKey;

    if (!flavourKey) {
        return (
            <Helmet>
                {children}
            </Helmet>
        );
    }

    return (
        <Helmet>
            <title>{trans(`flavours.${flavourKey}.result.meta.title`)}</title>
            <meta name="description" content={trans(`flavours.${flavourKey}.result.meta.description`)} />

            <meta property="og:title" content={trans(`flavours.${flavourKey}.result.meta.title`)} />
            <meta property="og:description" content={trans(`flavours.${flavourKey}.result.meta.description`)} />
            <meta property="og:image" content={`${siteUrl}/images/flavours/test-result/card/${flavourKey}.jpg`} />
            <meta property="og:image:alt" content={trans(`flavours.${flavourKey}.imageAlts.testResult.card`)} />

            <link rel="canonical" href={siteUrl + updatedPathname} />

            {Object.values(locales).map(locale => {
                const localePath = getTranslation(locale.language, 'routes.personalityTestResult.path');
                const translatedFlavour = getTranslation(locale.language, `flavours.${flavourKey}.slug`);

                const updatedLocalePathname = replaceUrlParamKeysWithValues(localePath, {
                    flavour: translatedFlavour,
                });

                return (
                    <link
                        key={locale.name}
                        rel="alternate"
                        hrefLang={locale.language}
                        href={siteUrl + updatedLocalePathname}
                    />
                );
            })}

            {children}
        </Helmet>
    );
};

export default PersonalityTestResultHelmet;
