/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconInstagram: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M71.4,100H28.6C12.6,100,0,87.5,0,71.4V28.6C0,12.6,12.6,0,28.6,0h42.9c16,0,28.6,12.6,28.6,28.6v42.9C100,87.5,87.5,100,71.4,100z M28.6,7.1c-12,0-21.4,9.4-21.4,21.4v42.9c0,12,9.4,21.4,21.4,21.4h42.9c12,0,21.4-9.4,21.4-21.4V28.6c0-12-9.4-21.4-21.4-21.4H28.6z" />

        <circle cx="75" cy="21.4" r="3.6" />

        <path d="M50,75c-13.8,0-25-11.2-25-25c0-13.8,11.2-25,25-25c13.8,0,25,11.2,25,25C75,63.8,63.8,75,50,75z M50,32.1c-9.8,0-17.9,8-17.9,17.9s8,17.9,17.9,17.9s17.9-8,17.9-17.9S59.8,32.1,50,32.1z" />
    </Svg>
);

export default IconInstagram;
/* eslint-enable max-len */
