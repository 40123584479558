/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './SpotifyPlaylistWidgetBackground.scss';

interface SpotifyPlaylistWidgetBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const SpotifyPlaylistWidgetBackground: FC<SpotifyPlaylistWidgetBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'spotify-playlist-widget-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                angle: 30,
                magnitude: 15,
            }}
            preserveAspectRatio="xMinYMid meet"
            pathRefObject={pathRef}
            viewBoxWidth={1000}
            viewBoxHeight={500}
            className={`spotify-playlist-widget-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="spotify-playlist-widget-background__gradient-start" />
                <stop offset="50%" className="spotify-playlist-widget-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M779,457.2c126.1,30.7,212.6-46.3,179.4-198.6C925.2,106.3,716.7,8.9,510.9,17.4C305.2,25.9,24,157.1,34.8,333.8c10.8,176.6,255.6,139.8,349.5,126.5C384.3,460.3,652.8,426.5,779,457.2z"
                className="spotify-playlist-widget-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default SpotifyPlaylistWidgetBackground;
/* eslint-enable max-len */
