/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconChevronLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={8} viewBoxHeight={14} className={className}>
        <path d="M 7.707 0.293 A 1 1 90 0 0 6.387 0.21 l -0.094 0.083 l -6 6 a 1 1 90 0 0 -0.083 1.32 l 0.083 0.094 l 6 6 a 1 1 90 0 0 1.497 -1.32 l -0.083 -0.094 L 2.415 7 L 7.707 1.707 A 1 1 90 0 0 7.79 0.387 L 7.707 0.293 z" />
    </Svg>
);

export default IconChevronLeft;
/* eslint-enable max-len */
