import { Language } from '../../../constants/locale';
import { getEnumKeyByEnumValue } from '../../../helpers/enum';
import { transformToCallToAction } from '../../Link/LinkTransformers';
import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../Prismic/Prismic';
import { transformToImage } from '../Image/ImageTransformers';
import { FeaturedImage, PrismicFeaturedImage } from './FeaturedImage';

export const transformToFeaturedImage = (
    prismicFeaturedImage: PrismicFeaturedImage,
    language: Language,
): FeaturedImage => {
    const titleSizeKey = getEnumKeyByEnumValue(PrismicSliceTitleSize, prismicFeaturedImage.featured_image_title_size);
    const titleColourKey = getEnumKeyByEnumValue(PrismicSliceTitleColour, prismicFeaturedImage.featured_image_title_colour);

    const callToAction = prismicFeaturedImage.featured_image_call_to_action_link
        ? transformToCallToAction(
            prismicFeaturedImage.featured_image_call_to_action_link,
            prismicFeaturedImage.featured_image_call_to_action_label,
            language,
        )
        : undefined;

    return {
        isMirrored: prismicFeaturedImage.featured_image_is_mirrored,
        isMirroredMobile: prismicFeaturedImage.featured_image_is_mirrored_mobile,
        image: transformToImage(prismicFeaturedImage.featured_image),
        title: prismicFeaturedImage.featured_image_title,
        titleSize: titleSizeKey
            ? PrismicSliceTitleSize[titleSizeKey]
            : undefined,
        titleColour: titleColourKey
            ? PrismicSliceTitleColour[titleColourKey]
            : undefined,
        paragraph: prismicFeaturedImage.featured_image_paragraph,
        callToAction,
    };
};
