import { FC, ReactElement, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import { useLocale } from '../../context/LocaleContext';
import { fetchFaqData } from '../../redux/faq/faqActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedFaqHelmet: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const { language } = useLocale();

    const faqData = useTypedSelector(state => state.faqReducer.faqData);

    useEffect((): void => {
        dispatch(fetchFaqData(language));
    }, [language]);

    if (!faqData) {
        return null;
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    mainEntity: faqData.faqItems.map(faqItem => ({
                        '@type': 'Question',
                        name: faqItem.question,
                        acceptedAnswer: {
                            '@type': 'Answer',
                            text: faqItem.answer,
                        },
                    })),
                })}
            </script>
        </Helmet>
    );
};

export default ConnectedFaqHelmet;
