import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useWindowScroll } from 'react-use';

import { CheckIntersection, Wrapper } from '../../components';
import { Usp, uspSets } from '../../constants/usp';
import { useCountry } from '../../context/CountryContext';
import useTouchScreen from '../../hooks/useTouchScreen';
import { UspCard, UspGridBackground } from './subcomponents';

import './UspGrid.scss';

interface UspGridProps {
    className?: string;
}

const UspGrid: FC<UspGridProps> = ({
    className = '',
}): ReactElement => {
    const { continent } = useCountry();
    const isTouchScreen = useTouchScreen();
    const { y: scrollY } = useWindowScroll();

    const [selectedUsp, setSelectedUsp] = useState<Usp | undefined>();

    const regionUsps = uspSets[continent];

    useEffect((): void => {
        if (isTouchScreen && selectedUsp) {
            setSelectedUsp(undefined);
        }
    }, [scrollY]);

    const backgroundClassNames = classNames('usp-grid__background', {
        'usp-grid__background--is-blurry': !!selectedUsp,
    });

    return (
        <section className={`usp-grid ${className}`}>
            <Wrapper className="usp-grid__wrapper">
                <ul className="usp-grid__list">
                    {regionUsps.map(usp => (
                        <UspCard
                            key={usp}
                            usp={usp}
                            selectedUsp={selectedUsp}
                            onSelectUsp={setSelectedUsp}
                            className="usp-grid__item"
                        />
                    ))}
                </ul>
            </Wrapper>

            <CheckIntersection threshold={0.5} className={backgroundClassNames}>
                {isInView => (
                    <UspGridBackground
                        isAnimationEnabled={isInView}
                        className="usp-grid__svg"
                    />
                )}
            </CheckIntersection>
        </section>
    );
};

export default UspGrid;
