/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconNoPlastic: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={81} viewBoxHeight={80} className={className}>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.634 0.681885C34.1247 0.681885 32.9012 1.90537 32.9012 3.41462V7.7255C32.9012 9.23475 34.1247 10.4582 35.634 10.4582H41.9651C43.4743 10.4582 44.6978 9.23475 44.6978 7.72551V3.41462C44.6978 1.90537 43.4743 0.681885 41.9651 0.681885H35.634ZM36.3793 6.98021V4.15991H41.2198V6.98021H36.3793ZM34.8633 12.5061C33.9028 12.5061 33.1242 13.2847 33.1242 14.2451V15.6509C31.1084 20.6363 27.408 33.6327 27.2923 49.37C27.2631 53.3418 27.2108 56.7158 27.1663 59.5882C27.1006 63.8278 27.0519 66.9744 27.1192 69.3371C27.2315 73.2761 27.6542 75.6541 29.3412 77.5215C30.6602 78.9815 32.3766 79.8535 34.3169 79.8851C35.8377 79.9098 37.3566 79.417 38.7991 78.4977C40.2418 79.417 41.7609 79.9098 43.2818 79.8851C45.2221 79.8535 46.9388 78.9816 48.2579 77.5215C49.9449 75.6541 50.3676 73.2761 50.4799 69.3371C50.5472 66.9745 50.4985 63.8279 50.4328 59.5884V59.5882C50.3883 56.7159 50.336 53.3418 50.3068 49.37C50.1911 33.6327 46.4907 20.6363 44.4749 15.6509V14.2451C44.4749 13.2847 43.6963 12.5061 42.7358 12.5061H34.8633ZM36.6023 15.9947V15.9841H40.9968V15.9947C40.9968 16.2256 41.0428 16.4543 41.1321 16.6672C42.9426 20.9844 46.7139 33.766 46.8289 49.3956C46.8589 53.4862 46.9122 56.901 46.957 59.7746L46.957 59.7751C47.0216 63.9221 47.0687 66.9416 47.0032 69.238C46.8917 73.1498 46.4413 74.3439 45.6771 75.1899C44.9216 76.0262 44.085 76.3935 43.2252 76.4075C42.3363 76.422 41.1977 76.06 39.8933 75.0041C39.2553 74.4876 38.343 74.4876 37.705 75.0041C36.4005 76.0601 35.2621 76.422 34.3735 76.4075C33.514 76.3935 32.6776 76.0263 31.922 75.1899C31.1578 74.3439 30.7074 73.1498 30.5959 69.238C30.5304 66.9416 30.5775 63.9221 30.6421 59.7751C30.6869 56.9015 30.7402 53.4865 30.7702 49.3956C30.8852 33.766 34.6565 20.9844 36.467 16.6672C36.5563 16.4543 36.6023 16.2256 36.6023 15.9947Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.91574 1.63346C2.8179 0.731294 4.2806 0.731296 5.18276 1.63346L17.2144 13.7064C18.1166 14.6085 18.1166 16.0712 17.2144 16.9734C16.3122 17.8756 14.8495 17.8756 13.9474 16.9734L1.91574 4.90048C1.01357 3.99832 1.01357 2.53562 1.91574 1.63346ZM61.8851 61.8314C62.7873 60.9292 64.25 60.9292 65.1522 61.8314L78.4683 75.0056C79.3705 75.9078 79.3705 77.3705 78.4683 78.2727C77.5662 79.1748 76.1035 79.1748 75.2013 78.2727L61.8851 65.0984C60.983 64.1962 60.983 62.7335 61.8851 61.8314Z" />
    </Svg>
);

export default IconNoPlastic;
/* eslint-enable max-len */
