import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { AppRoute } from '../../../../constants/routing';
import { Seo } from '../../../../entities/Seo/Seo';
import useTrans from '../../../../hooks/useTrans';

interface PrismicPageContentProps {
    route: AppRoute;
    seo: Seo;
}

const PrismicPageContent: FC<PrismicPageContentProps> = ({
    route,
    seo,
}): ReactElement => {
    const trans = useTrans();

    return (
        <Helmet>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />

            <meta property="og:site_name" content={trans('company.name')} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />

            <meta property="og:image" content={seo.image?.src} />
            <meta property="og:image:alt" content={seo.image?.alt} />

            <link rel="canonical" href={trans('company.url') + trans(`${route}.path`)} />
        </Helmet>
    );
};

export default PrismicPageContent;
