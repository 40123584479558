/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyDesign: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={65} viewBoxHeight={100} className={className}>
        <path d="M18.7,66.1c-0.7,0-1.3-0.3-1.8-0.7c-0.6-0.6-0.9-1.5-0.7-2.3c1.2-5.2,2.4-10.1,3.8-12.6c1.4-2.4,3.2-4.3,6.8-7.9l17.5-17.5l0,0l4.7-4.7c1.7-1.7,4-2.7,6.4-2.7c2.4,0,4.7,0.9,6.4,2.7c1.7,1.7,2.7,4,2.7,6.4c0,2.4-0.9,4.7-2.7,6.4L57.2,38l0,0L39.7,55.4c-3.6,3.6-5.5,5.5-7.9,6.8c-2.4,1.4-7.3,2.5-12.6,3.8C19.1,66,18.9,66.1,18.7,66.1zM46,30.4L30.4,46.1c-3.3,3.3-5,5-6,6.8c-0.7,1.3-1.5,4.2-2.3,7.3c3.1-0.8,6-1.6,7.3-2.3c1.8-1,3.5-2.7,6.8-6l15.7-15.7L46,30.4zM49.6,26.8l5.8,5.8l2.9-2.9c0.8-0.8,1.2-1.8,1.2-2.9c0-1.1-0.4-2.1-1.2-2.9c-0.8-0.8-1.8-1.2-2.9-1.2c-1.1,0-2.1,0.4-2.9,1.2L49.6,26.8z" />
        <path d="M32,82.2c-14.7,0-22,0-27-5s-5-12.3-5-27c0-14.7,0-22,5-27c5-5,12.3-5,27-5c1.1,0,2,0.9,2,2s-0.9,2-2,2c-13.6,0-20.4,0-24.2,3.8C4,29.9,4,36.7,4,50.2s0,20.4,3.8,24.2c3.8,3.8,10.6,3.8,24.2,3.8s20.4,0,24.2-3.8S60,63.8,60,50.2c0-1.1,0.9-2,2-2s2,0.9,2,2c0,14.7,0,22-5,27S46.7,82.2,32,82.2z" />
    </Svg>
);

export default IconVacancyDesign;
/* eslint-enable max-len */
