import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Article } from '../../entities/Article/Article';

export type ArticleDetailState = Readonly<{
    isLoading: boolean;
    error: string;
    articleData?: Article;
    relatedArticles: Article[];
}>;

const initialState: ArticleDetailState = {
    isLoading: false,
    error: '',
    relatedArticles: [],
};

const articleDetailSlice = createSlice({
    name: 'articleDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ArticleDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ArticleDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setArticleData(state, action: PayloadAction<Article | undefined>): ArticleDetailState {
            return {
                ...state,
                articleData: action.payload,
            };
        },
        setRelatedArticles(state, action: PayloadAction<Article[]>): ArticleDetailState {
            return {
                ...state,
                relatedArticles: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setArticleData,
    setRelatedArticles,
} = articleDetailSlice.actions;

export default articleDetailSlice;
