import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../../../constants/routing';
import { Article } from '../../../../entities/Article/Article';
import { trimToMaxLength } from '../../../../helpers/string';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import useFormatDate from '../../../../hooks/useFormatDate';
import useTrans from '../../../../hooks/useTrans';

import './RelatedArticleItem.scss';

interface RelatedArticleItemProps {
    article: Article;
    className?: string;
}

const RelatedArticleItem: FC<RelatedArticleItemProps> = ({
    article,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const paramPath = trans(appRoutes[AppRoute.articleDetail].path);
    const articlePath = replaceUrlParamKeysWithValues(paramPath, {
        slug: article.slug,
    });

    return (
        <li className={`related-article-item ${className}`}>
            <div className="related-article-item__header">
                {article.thumbnail && (
                    <img
                        src={article.thumbnail.src}
                        alt={article.thumbnail.alt}
                        className="related-article-item__thumbnail"
                    />
                )}
            </div>

            <div className="related-article-item__text-wrapper">
                <Link to={articlePath} className="related-article-item__link">
                    <h3 className="related-article-item__title">
                        {article.title}
                    </h3>
                </Link>

                <p className="related-article-item__date">
                    {formatDate(article.createdAt, { day: 'numeric', month: 'short', year: 'numeric' })}
                </p>

                <p className="related-article-item__intro">
                    {trimToMaxLength(article.intro, 140)}
                </p>
            </div>
        </li>
    );
};

export default RelatedArticleItem;
