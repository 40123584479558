export enum PersonalityTestDirection {
    forwards = 'forwards',
    backwards = 'backwards',
}

export enum PersonalityTestAnswer {
    a = 'a',
    b = 'b',
    c = 'c',
    d = 'd',
    e = 'e',
}

export interface PersonalityTestQuestion {
    index: number;
    question: string;
    answers: Record<PersonalityTestAnswer, string>[];
}

export interface PersonalityTestInput extends Omit<PersonalityTestQuestion, 'answers'> {
    answer: PersonalityTestAnswer;
}
