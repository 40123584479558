import { RichText } from 'prismic-dom';

import { Language } from '../../../constants/locale';
import { prismicLinkResolver } from '../../../helpers/prismic';
import { PrismicLinkResolver } from '../../Link/Link';
import { PrismicVacancyExpectations, VacancyExpectations } from './VacancyExpectations';

export const transformToVacancyExpectations = (
    prismicVacancyExpectations: PrismicVacancyExpectations,
    language: Language,
): VacancyExpectations => {
    const linkResolver = (doc: PrismicLinkResolver) => prismicLinkResolver(doc, language);

    const fromApplicantDescription = RichText.asHtml(prismicVacancyExpectations.expectations_from_applicant_description, linkResolver);
    const fromCompanyDescription = RichText.asHtml(prismicVacancyExpectations.expectations_from_company_description, linkResolver);

    return {
        fromApplicant: {
            title: prismicVacancyExpectations.expectations_from_applicant_title,
            description: fromApplicantDescription,
        },
        fromCompany: {
            title: prismicVacancyExpectations.expectations_from_company_title,
            description: fromCompanyDescription,
        },
    };
};
