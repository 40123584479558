/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancySales: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={69} viewBoxHeight={100} className={className}>
        <path d="M34.2,84.2c-15.6,0-23.4,0-28.8-5.4S0,65.6,0,50s0-23.4,5.4-28.8c5.4-5.4,13.2-5.4,28.8-5.4s23.4,0,28.8,5.4c5.4,5.4,5.4,13.2,5.4,28.8c0,15.6,0,23.4-5.4,28.8S49.8,84.2,34.2,84.2z M34.2,20.8c-14.2,0-21.4,0-25.3,3.9C5,28.6,5,35.8,5,50c0,14.2,0,21.4,3.9,25.3s11,3.9,25.3,3.9s21.4,0,25.3-3.9s3.9-11,3.9-25.3s0-21.4-3.9-25.3C55.5,20.8,48.4,20.8,34.2,20.8zM50.8,72.5c-1.4,0-2.5-1.1-2.5-2.5V53.3c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5V70C53.3,71.4,52.2,72.5,50.8,72.5z M34.2,72.5c-1.4,0-2.5-1.1-2.5-2.5V60c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C36.7,71.4,35.5,72.5,34.2,72.5z M17.5,72.5c-1.4,0-2.5-1.1-2.5-2.5v-6.7c0-1.4,1.1-2.5,2.5-2.5S20,62,20,63.3V70C20,71.4,18.9,72.5,17.5,72.5z" />
        <path d="M15.8,50.3c-0.6,0-1.2,0-1.7,0c-1.1,0-2-1-1.9-2.1s1-2,2.1-1.9c7.8,0.3,22.1-1.2,30.5-13.9l-3.6,0.6c-1.1,0.2-2.1-0.6-2.3-1.7c-0.2-1.1,0.6-2.1,1.7-2.3l6.3-1c1.8-0.2,3.7,0.9,4.3,2.5c0,0,0,0.1,0,0.1l1.7,5.5c0.3,1.1-0.3,2.2-1.3,2.5c-1.1,0.3-2.2-0.3-2.5-1.3l-0.8-2.7C39.2,48,24.7,50.3,15.8,50.3z" />
    </Svg>
);

export default IconVacancySales;
/* eslint-enable max-len */
