/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../../compositions';

import './ContactSectionBackground.scss';

interface ContactSectionBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const ContactSectionBackground: FC<ContactSectionBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'contact-section-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            pathRefObject={pathRef}
            options={{
                angle: -2,
                start: 2,
                end: 15,
                length: 100,
            }}
            viewBoxWidth={1500}
            viewBoxHeight={1000}
            className={`contact-section-background ${className}`}
        >
            <radialGradient id={gradientId} cx="15%" cy="5%" r="60%">
                <stop offset="0%" className="contact-section-background__gradient-start" />
                <stop offset="100%" className="contact-section-background__gradient-end" />
            </radialGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M 1500.65 563.83 C 1487.86 806.86 1142.5 1015.17 756.95 938.42 C 457.27 870.82 111.96 693.42 44.31 443.23 C -14.16 225.79 107.42 46.9 311.63 45.68 C 624.02 46.9 810.7 128.33 1188.32 125.71 C 1428.27 123.08 1512.25 281.22 1502.48 549.21 Z"
                className="contact-section-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default ContactSectionBackground;
/* eslint-enable max-len */
