import { FC, ReactElement, useEffect } from 'react';

import { AppRoute } from '../../constants/routing';
import { PrismicPageContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { fetchAboutData } from '../../redux/about/aboutActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedAboutProps {
    className?: string;
}

const ConnectedAbout: FC<ConnectedAboutProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.aboutReducer.isLoading);
    const aboutData = useTypedSelector(state => state.aboutReducer.aboutData);

    useEffect((): void => {
        if (!aboutData.title || language !== aboutData.language) {
            dispatch(fetchAboutData(language));
        }
    }, [language]);

    return (
        <PrismicPageContent
            {...aboutData}
            isLoading={isLoading}
            route={AppRoute.about}
            className={className}
        />
    );
};

export default ConnectedAbout;
