import React, { FC, ReactElement } from 'react';

import { ErrorLabel, InputLabel, Select } from '../../../components';
import { SelectProps } from '../../../components/@inputs/Select/Select';

import './SelectInput.scss';

interface SelectInputProps extends SelectProps {
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    className?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    isLoading,
    isLoadingMessage,
    label,
    additionalLabel,
    hideLabel,
    name,
    value,
    options,
    placeholder,
    disabled = false,
    error = '',
    onChange,
    className = '',
}): ReactElement => (
    <label
        aria-label={hideLabel ? label : undefined}
        className={`select-input ${className}`}
    >
        {!hideLabel && (
            <InputLabel
                label={label}
                additionalLabel={additionalLabel}
                className="select-input__label"
            />
        )}

        <Select
            isLoading={isLoading}
            isLoadingMessage={isLoadingMessage}
            name={name}
            value={value}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            onChange={onChange}
        />

        {error && (
            <ErrorLabel
                text={error}
                className="select-input__error-label"
            />
        )}
    </label>
);

export default SelectInput;
