/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconFacebook: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M67.5,15.9H78v-15C76.1,0.6,71.9,0,64,0C47.6,0,38,8.3,38,27.3v12.6H22v16h16V100h20V55.9h15.8l2.5-16H58V30C58,22.5,60.5,15.9,67.5,15.9z" />
    </Svg>
);

export default IconFacebook;
/* eslint-enable max-len */
