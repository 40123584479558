import { FC, ReactElement } from 'react';

import { SafeHtml } from '../../components';
import useTrans from '../../hooks/useTrans';

import './RecaptchaDisclaimer.scss';

interface RecaptchaDisclaimerProps {
    className?: string;
}

const RecaptchaDisclaimer: FC<RecaptchaDisclaimerProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <SafeHtml
            html={trans('compositions.recaptchaDisclaimer.disclaimer')}
            className={`recaptcha-disclaimer ${className}`}
        />
    );
};

export default RecaptchaDisclaimer;
