import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { VacancyCallToAction } from '../../../entities/@slices/VacancyCallToAction/VacancyCallToAction';

import './VacancyCallToActionSlice.scss';

interface VacancyCallToActionSliceProps extends VacancyCallToAction {
    className?: string;
}

const VacancyCallToActionSlice: FC<VacancyCallToActionSliceProps> = ({
    title,
    description,
    contactName,
    contactPhone,
    className = '',
}): ReactElement => (
    <Wrapper className={`vacancy-call-to-action-slice ${className}`}>
        <div className="vacancy-call-to-action-slice__inner-wrapper">
            <h2 className="vacancy-call-to-action-slice__title">
                {title}
            </h2>

            <p className="vacancy-call-to-action-slice__description">
                {description}
            </p>

            <div className="vacancy-call-to-action-slice__contact-wrapper">
                <p className="vacancy-call-to-action-slice__contact-name">
                    {contactName}
                </p>

                <a
                    href={`tel:${contactPhone}`}
                    className="vacancy-call-to-action-slice__contact-phone"
                >
                    {contactPhone}
                </a>
            </div>
        </div>
    </Wrapper>
);

export default VacancyCallToActionSlice;
