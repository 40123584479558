import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './VacancyRendererSkeletons.scss';

interface VacancyRendererSkeletonsProps {
    className?: string;
}

const VacancyRendererSkeletons: FC<VacancyRendererSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skeletonParagraphs = generateIdArray(5);

    return (
        <div className={`vacancy-renderer-skeletons ${className}`}>
            {skeletonParagraphs.map(paragraph => {
                const skeletonLines = generateIdArray(4);

                return (
                    <div key={paragraph} className="vacancy-renderer-skeletons__paragraph">
                        <Skeleton className="vacancy-renderer-skeletons__subtitle" />

                        {skeletonLines.map(line => (
                            <Skeleton
                                key={line}
                                className="vacancy-renderer-skeletons__text"
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

export default VacancyRendererSkeletons;
