/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconGmoFree: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M3.5,65.1c-0.7,0-1.4-0.3-1.8-0.9c-0.7-1-0.5-2.4,0.5-3.1l14.9-10.6c9.9-7.1,22.8-8,33.6-2.4c9.3,4.8,20.4,4,29-2.1l14.9-10.6c1-0.7,2.4-0.5,3.1,0.5c0.7,1,0.5,2.4-0.5,3.1L82.4,49.7c-9.9,7.1-22.8,8-33.6,2.4c-9.3-4.8-20.4-4-29,2.1L4.8,64.7C4.5,65,4,65.1,3.5,65.1z" />
        <path d="M36.9,98.5c-0.5,0-0.9-0.1-1.3-0.4c-1-0.7-1.2-2.1-0.5-3.1l10.6-14.9c6.1-8.5,6.9-19.7,2.1-29c-5.6-10.8-4.7-23.7,2.4-33.6L60.8,2.5c0.7-1,2.1-1.2,3.1-0.5c1,0.7,1.2,2.1,0.5,3.1L53.8,20.1c-6.1,8.5-6.9,19.7-2.1,29c5.6,10.8,4.7,23.7-2.4,33.6L38.7,97.6C38.3,98.2,37.6,98.5,36.9,98.5z" />
        <path d="M84.4,39.1c-0.5,0-1-0.2-1.4-0.6L58.6,12.9c-0.7-0.8-0.7-2,0.1-2.7c0.8-0.7,2-0.7,2.7,0.1l24.4,25.7c0.7,0.8,0.7,2-0.1,2.7C85.4,39,84.9,39.1,84.4,39.1z" />
        <path d="M75.4,44.3c-0.5,0-1-0.2-1.4-0.6L50.9,19.3c-0.7-0.8-0.7-2,0.1-2.7c0.8-0.7,2-0.7,2.7,0.1L76.8,41c0.7,0.8,0.7,2-0.1,2.7C76.4,44.1,75.9,44.3,75.4,44.3z" />
        <path d="M63.9,48.1c-0.5,0-1-0.2-1.4-0.6L47.1,30.8c-0.7-0.8-0.7-2,0.1-2.7c0.8-0.7,2-0.7,2.7,0.1l15.4,16.7c0.7,0.8,0.7,2-0.1,2.7C64.8,48,64.3,48.1,63.9,48.1z" />
        <path d="M51,72.5c-0.5,0-1-0.2-1.4-0.6L34.2,55.2c-0.7-0.8-0.7-2,0.1-2.7c0.8-0.7,2-0.7,2.7,0.1l15.4,16.7c0.7,0.8,0.7,2-0.1,2.7C52,72.4,51.5,72.5,51,72.5z" />
        <path d="M47.2,84.1c-0.5,0-1-0.2-1.4-0.6L22.7,59.1c-0.7-0.8-0.7-2,0.1-2.7c0.8-0.7,2-0.7,2.7,0.1l23.1,24.4c0.7,0.8,0.7,2-0.1,2.7C48.1,83.9,47.7,84.1,47.2,84.1z" />
        <path d="M39.5,90.5c-0.5,0-1-0.2-1.4-0.6L15,66.8c-0.8-0.8-0.8-2,0-2.7c0.8-0.8,2-0.8,2.7,0l23.1,23.1c0.8,0.8,0.8,2,0,2.7C40.5,90.3,40,90.5,39.5,90.5z" />
        <path d="M0.9,0.8c1.2-1.2,3-1.2,4.2,0L28.2,24c1.2,1.2,1.2,3,0,4.2c-1.2,1.2-3,1.2-4.2,0L0.9,5C-0.3,3.8-0.3,2,0.9,0.8z M70.1,70.4c1.2-1.2,3-1.2,4.2,0L99.1,95c1.2,1.2,1.2,3,0,4.2c-1.2,1.2-3,1.2-4.2,0L70.1,74.6C69,73.4,69,71.5,70.1,70.4z" />
    </Svg>
);

export default IconGmoFree;
/* eslint-enable max-len */
