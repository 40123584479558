import {
    createContext,
    FC,
    PropsWithChildren,
    useMemo,
    useState,
} from 'react';

import { noop } from 'react-use/lib/misc/util';

interface ScrollContextValue {
    isScrolling: boolean;
    setIsScrolling: (isScrolling: boolean) => void;
}

const defaultValue: ScrollContextValue = {
    isScrolling: false,
    setIsScrolling: () => noop,
};

export const ScrollContext = createContext<ScrollContextValue>(defaultValue);

const ScrollProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isScrolling, setIsScrolling] = useState(false);

    const value = useMemo(() => (
        { isScrolling, setIsScrolling }
    ), [isScrolling, setIsScrolling]);

    return (
        <ScrollContext.Provider value={value}>
            {children}
        </ScrollContext.Provider>
    );
};

export default ScrollProvider;
