import { IconName } from '../components/Icon/Icon';
import { Continent } from './countries';

export enum Usp {
    caffeinatedDrinks = 'caffeinatedDrinks',
    calories = 'calories',
    colourantFree = 'colourantFree',
    crueltyFree = 'crueltyFree',
    filtered = 'filtered',
    flavours = 'flavours',
    glutenFree = 'glutenFree',
    gmoFree = 'gmoFree',
    gyms = 'gyms',
    halal = 'halal',
    intensity = 'intensity',
    kosher = 'kosher',
    naturalFlavouring = 'naturalFlavouring',
    noPlastic = 'noPlastic',
    perfectMix = 'perfectMix',
    refillable = 'refillable',
    sugarFree = 'sugarFree',
    valueForMoney = 'valueForMoney',
    vegan = 'vegan',
    vitamins = 'vitamins',
}

export const uspIcons: Record<Usp, IconName> = {
    [Usp.caffeinatedDrinks]: 'caffeinated-drinks',
    [Usp.calories]: 'calories',
    [Usp.colourantFree]: 'colourant-free',
    [Usp.crueltyFree]: 'cruelty-free',
    [Usp.filtered]: 'brita',
    [Usp.flavours]: 'flavours',
    [Usp.glutenFree]: 'gluten-free',
    [Usp.gmoFree]: 'gmo-free',
    [Usp.gyms]: 'dumbbell',
    [Usp.halal]: 'halal',
    [Usp.intensity]: 'intensity',
    [Usp.kosher]: 'kosher',
    [Usp.naturalFlavouring]: 'natural-flavouring',
    [Usp.noPlastic]: 'no-plastic',
    [Usp.perfectMix]: 'flavour-intensity',
    [Usp.refillable]: 'refillable',
    [Usp.sugarFree]: 'sugar-free',
    [Usp.valueForMoney]: 'value-for-money',
    [Usp.vegan]: 'vegan',
    [Usp.vitamins]: 'vitamins',
};

const defaultUsps: Usp[] = [
    Usp.sugarFree,
    Usp.gmoFree,
    Usp.calories,
    Usp.filtered,
    Usp.vitamins,
    Usp.kosher,
    Usp.caffeinatedDrinks,
    Usp.colourantFree,
    Usp.glutenFree,
    Usp.naturalFlavouring,
    Usp.intensity,
    Usp.noPlastic,
    Usp.vegan,
    Usp.flavours,
    Usp.perfectMix,
    Usp.gyms,
    Usp.halal,
    Usp.crueltyFree,
    Usp.refillable,
    Usp.valueForMoney,
];

const emeaUsps: Usp[] = [
    Usp.calories,
    Usp.sugarFree,
    Usp.vitamins,
    Usp.noPlastic,
    Usp.naturalFlavouring,
    Usp.halal,
    Usp.caffeinatedDrinks,
    Usp.colourantFree,
    Usp.glutenFree,
    Usp.intensity,
    Usp.vegan,
    Usp.flavours,
    Usp.filtered,
    Usp.perfectMix,
    Usp.gyms,
    Usp.crueltyFree,
    Usp.refillable,
    Usp.valueForMoney,
    Usp.gmoFree,
    Usp.kosher,
];

export const uspSets: Record<Continent, Usp[]> = {
    [Continent.northAmerica]: defaultUsps,
    [Continent.europe]: emeaUsps,
    [Continent.middleEast]: emeaUsps,
    [Continent.africa]: defaultUsps,
    [Continent.antarctica]: defaultUsps,
    [Continent.asia]: defaultUsps,
    [Continent.oceania]: defaultUsps,
    [Continent.southAmerica]: defaultUsps,
};
