import { FC, ReactElement } from 'react';

import { CheckIntersection, Wrapper } from '../../../components';
import { uspSets } from '../../../constants/usp';
import { useCountry } from '../../../context/CountryContext';
import useTrans from '../../../hooks/useTrans';
import splitUspTitle from './helpers/splitUspTitle';
import { UspCarousel, UspSectionBackground } from './subcomponents';

import './UspSection.scss';

interface UspSectionProps {
    className?: string;
}

const UspSection: FC<UspSectionProps> = ({ className = '' }): ReactElement => {
    const { continent } = useCountry();
    const trans = useTrans();

    const titleParts = splitUspTitle(trans('containers.uspSection.title'));

    const regionUsps = uspSets[continent];
    const uspPreviewList = regionUsps.slice(0, 10);

    return (
        <div className={`usp-section ${className}`}>
            <Wrapper className="usp-section__title-wrapper">
                <h2 className="usp-section__title">
                    {titleParts.map((word, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={`${word}-${index}`} className="usp-section__title-part">
                            {word}
                        </span>
                    ))}
                </h2>
            </Wrapper>

            <UspCarousel
                usps={uspPreviewList}
                className="usp-section__carousel"
            />

            <CheckIntersection threshold={0.5} className="usp-section__svg-wrapper">
                {isInView => (
                    <UspSectionBackground
                        isAnimationEnabled={isInView}
                        className="usp-section__svg"
                    />
                )}
            </CheckIntersection>
        </div>
    );
};

export default UspSection;
