export enum CookieType {
    functional = 'functional',
    analytical = 'analytical',
    marketing = 'marketing',
}

export type CookieTypeConfiguration = Record<CookieType, boolean>;

export interface CookieSettingsData extends CookieTypeConfiguration {
    cookieSettingsVisible: boolean;
    cookieSettingsConfigured: boolean;
}

export const defaultCookieSettings: CookieSettingsData = {
    cookieSettingsVisible: true,
    cookieSettingsConfigured: false,
    [CookieType.functional]: false,
    [CookieType.analytical]: false,
    [CookieType.marketing]: false,
};
