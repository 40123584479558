import { useEffect, useState } from 'react';

const useDocumentScrollSize = () => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect((): () => void => {
        const updateSize = (): void => {
            setSize({
                width: document.documentElement.scrollWidth,
                height: document.documentElement.scrollHeight,
            });
        };

        const resizeObserver = new ResizeObserver(updateSize);
        resizeObserver.observe(document.documentElement);

        updateSize();

        return () => resizeObserver.disconnect();
    }, []);

    return size;
};

export default useDocumentScrollSize;
