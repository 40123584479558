/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import { BackgroundMorphSvg } from '../../../compositions';

import './HydrationStationSectionBackground.scss';

interface HydrationStationSectionBackgroundProps {
    isAnimationEnabled: boolean;
    className?: string;
}

const HydrationStationSectionBackground: FC<HydrationStationSectionBackgroundProps> = ({
    isAnimationEnabled,
    className = '',
}): ReactElement => {
    const pathRef = useRef<SVGPathElement>(null);
    const gradientId = 'hydration-station-section-background-linear-gradient';

    return (
        <BackgroundMorphSvg
            isAnimationEnabled={isAnimationEnabled}
            options={{
                length: 22,
                angle: 95,
                magnitude: 350,
                phase: 45,
                duration: 8,
                start: 2,
                end: 12,
                repeat: 10,
            }}
            preserveAspectRatio="none"
            pathRefObject={pathRef}
            viewBoxWidth={1500}
            viewBoxHeight={800}
            className={`hydration-station-section-background ${className}`}
        >
            <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" className="hydration-station-section-background__gradient-start" />
                <stop offset="50%" className="hydration-station-section-background__gradient-end" />
            </linearGradient>
            <path
                ref={pathRef}
                fill={`url(#${gradientId})`}
                d="M -345.734 1115.01 C 331.654 1129.122 1023.155 1136.178 1860.483 1122.066 C 1909.876 595.208 1866.721 60.589 1512.047 68.356 C 1123.717 78.711 931.698 405.042 422.135 489.884 C 62.283 564.961 -362.29 655.572 -345.734 1115.01"
                className="hydration-station-section-background__svg-wave"
            />
        </BackgroundMorphSvg>
    );
};

export default HydrationStationSectionBackground;
/* eslint-enable max-len */
