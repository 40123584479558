import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { defaultPrismicDefaultPageData, PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicPageApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToPrismicDefaultPageData } from '../../entities/Prismic/PrismicTransformers';
import { TypedDispatch } from '../store';
import { setIsLoading, setPrivacyStatementData } from './privacyStatement';

export const fetchPrivacyStatementData = (language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setPrivacyStatementData(defaultPrismicDefaultPageData));

    try {
        const privacyStatementResponse = await getPrismicPageApiCall({
            type: PrismicType.privacyStatement,
            language,
        });

        if (!isFetchResultSuccessful(privacyStatementResponse)) {
            console.error('[fetchPrivacyStatementData]', privacyStatementResponse.error);
            return;
        }

        const privacyStatementData = transformPrismicDocumentToPrismicDefaultPageData(privacyStatementResponse.data, language);

        dispatch(setPrivacyStatementData(privacyStatementData));
    } catch (error) {
        console.error('[fetchPrivacyStatementData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
