import { FC, ReactElement, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Page } from '../../components';
import { LocalizedHelmet, PersonalityTestResultHelmet } from '../../compositions';
import { ConnectedPersonalityTest } from '../../connectors';
import { flavourResultSearchParam } from '../../constants/personalityTest';
import { scrollToTop } from '../../helpers/scroll';

import './PersonalityTestPage.scss';

const PersonalityTestPage: FC = (): ReactElement => {
    const [searchParams] = useSearchParams();
    const hasFlavourResult = !!searchParams.get(flavourResultSearchParam);

    useEffect((): void => {
        scrollToTop();
    }, []);

    return (
        <Page className="personality-test-page">
            {hasFlavourResult ? (
                <PersonalityTestResultHelmet />
            ) : (
                <LocalizedHelmet pageKey="personalityTest" />
            )}

            <ConnectedPersonalityTest />
        </Page>
    );
};

export default PersonalityTestPage;
