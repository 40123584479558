import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Dialog } from '../../../../compositions';
import { PlaygroundSectionPhoto } from '../../types';
import { PlaygroundSectionButton, PlaygroundSectionVideo } from '..';

import './PlaygroundSectionDesktopPhotoList.scss';

interface PlaygroundSectionDesktopPhotoListProps {
    photos: PlaygroundSectionPhoto[];
    className?: string;
}

const PlaygroundSectionDesktopPhotoList: FC<PlaygroundSectionDesktopPhotoListProps> = ({ photos, className = '' }): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [activeVideo, setActiveVideo] = useState<number>();

    const videoSrc = activeVideo !== undefined ? photos[activeVideo].videoSrc : undefined;
    const videoCaption = activeVideo !== undefined ? photos[activeVideo].caption : undefined;

    const handleButtonClick = (index: number): void => {
        setActiveVideo(index);
        setShowDialog(true);
    };

    const handleCloseDialog = (): void => {
        setShowDialog(false);
    };

    useEffect((): void => {
        if (!dialogRef.current) {
            return;
        }

        if (showDialog) {
            dialogRef.current.showModal();
            closeButtonRef.current?.blur();

            return;
        }

        dialogRef.current.close();
    }, [showDialog]);

    return (
        <div className={`playground-section-desktop-photo-list ${className}`}>
            {photos.map((photo, index) => (
                <PlaygroundSectionButton
                    key={photo.imageSrc}
                    text={photo.caption}
                    imageSrc={photo.imageSrc}
                    videoSrc={photo.videoSrc}
                    onClick={() => handleButtonClick(index)}
                    className="playground-section-desktop-photo-list__button"
                    wrapperClassName="playground-section-desktop-photo-list__button-wrapper"
                />
            ))}

            <Dialog
                enableBackdropClose
                ref={dialogRef}
                closeButtonRef={closeButtonRef}
                onClose={handleCloseDialog}
                className="playground-section-desktop-photo-list__dialog"
                contentClassName="playground-section-desktop-photo-list__dialog-content"
            >
                {showDialog && (
                    <>
                        <PlaygroundSectionVideo
                            ref={videoRef}
                            src={videoSrc}
                            className="playground-section-desktop-photo-list__dialog-video"
                        />

                        <h3 className="playground-section-desktop-photo-list__dialog-video-caption">
                            {videoCaption}
                        </h3>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default PlaygroundSectionDesktopPhotoList;
