import { Language } from '../../constants/locale';
import { isSSR } from '../../helpers';
import trans from '../../helpers/trans';
import { FetchResult, FetchResultType } from '../FetchResult';
import { PrismicDocument, PrismicSearchResponse, PrismicType } from './Prismic';

interface PrismicOverviewApiCallConfig {
    type: string;
    category?: string;
    language: Language;
    page: number;
    pageSize: number;
    excludeId?: string;
}

interface PrismicDetailApiCallConfig {
    type: PrismicType;
    slug: string;
    language: Language;
}

interface PrismicPageApiCallConfig {
    type: PrismicType;
    language: Language;
}

const prismicApiUrl = isSSR
    ? `${process.env.PUBLIC_URL}/prismic`
    : `${window.location.origin}/prismic`;

export const getPrismicOverviewApiCall = async (config: PrismicOverviewApiCallConfig): Promise<FetchResult<PrismicSearchResponse, string>> => {
    try {
        const { language } = config;

        const prismicResponse = await fetch(`${prismicApiUrl}/overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ ...config, language }),
        });

        const prismicData = await prismicResponse.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: prismicData as PrismicSearchResponse,
        };
    } catch (error) {
        console.error('[getPrismicOverviewApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPrismicDetailApiCall = async (config: PrismicDetailApiCallConfig): Promise<FetchResult<PrismicDocument, string>> => {
    try {
        const { type, slug, language } = config;

        const prismicResponse = await fetch(`${prismicApiUrl}/detail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ language, type, slug }),
        });

        const prismicData = await prismicResponse.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: prismicData as PrismicDocument,
        };
    } catch (error) {
        console.error('[getPrismicDetailApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getPrismicPageApiCall = async (config: PrismicPageApiCallConfig): Promise<FetchResult<PrismicDocument, string>> => {
    try {
        const { type, language } = config;

        const prismicResponse = await fetch(`${prismicApiUrl}/page`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ language, type }),
        });

        const prismicData = await prismicResponse.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: prismicData as PrismicDocument,
        };
    } catch (error) {
        console.error('[getPrismicPageApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
