import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { LocalizedHelmet } from '../../compositions';
import { ConnectedArticleOverview } from '../../connectors';

const ArticlesPage: FC = (): ReactElement => (
    <Page>
        <LocalizedHelmet pageKey="articles" />

        <ConnectedArticleOverview />
    </Page>
);

export default ArticlesPage;
