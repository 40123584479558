import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useDebounce, useWindowSize } from 'react-use';

import { Icon } from '../../../../../components';
import { Usp, uspIcons } from '../../../../../constants/usp';
import { gsap, Power4 } from '../../../../../entities/Gsap/GsapService';
import { useFontLoaded } from '../../../../../hooks/useFontLoaded';
import usePrefersReducedMotion from '../../../../../hooks/usePrefersReducedMotion';
import useTrans from '../../../../../hooks/useTrans';
import { FontName } from '../../../../../types';
import { destroyMainTimeline, getMainTimeline } from '../../helpers/timeline';

import './UspCarouselStat.scss';

interface UspCarouselStatProps {
    hasTimeline?: boolean;
    isActive: boolean;
    usp: Usp;
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
    className?: string;
}

const UspCarouselStat: FC<UspCarouselStatProps> = ({
    hasTimeline,
    isActive,
    usp,
    onBlur,
    onFocus,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { width } = useWindowSize();
    const isFontLoaded = useFontLoaded([FontName.appetite]);
    const prefersReducedMotion = usePrefersReducedMotion();

    const headingRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);
    const paragraphRef = useRef<HTMLParagraphElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const timeline = useRef<gsap.core.Timeline | null>(null);

    const [debouncedWidth, setDebouncedWidth] = useState<number>();

    const isInitialized = !!timeline.current;
    const wrapperClassName = classNames('usp-carousel-stat', {
        'usp-carousel-stat--is-active': isActive,
        'usp-carousel-stat--is-initialized': isInitialized,
    }, className);

    useDebounce((): void => {
        setDebouncedWidth(width);
    }, 100, [width]);

    useEffect((): () => void => {
        if (isFontLoaded
          && debouncedWidth
          && titleRef.current
          && paragraphRef.current
          && wrapperRef.current
          && headingRef.current
          && hasTimeline
        ) {
            destroyMainTimeline(
                wrapperRef.current,
                paragraphRef.current,
                headingRef.current,
                timeline.current || undefined,
            );

            timeline.current = getMainTimeline(
                wrapperRef.current,
                paragraphRef.current,
                headingRef.current,
            );
        }

        return (): void => {
            destroyMainTimeline(
                wrapperRef.current,
                paragraphRef.current,
                headingRef.current,
                timeline.current || undefined,
            );
        };
    }, [isFontLoaded, debouncedWidth]);

    useEffect((): void => {
        if (!timeline.current) {
            return;
        }

        const mainTimelineProgress = isActive ? timeline.current.duration() : 0;
        const duration = prefersReducedMotion ? 0 : 1;

        gsap.killTweensOf([timeline.current]);

        gsap.to(timeline.current, duration, { time: mainTimelineProgress, ease: Power4.easeOut });
    }, [isActive, isInitialized]);

    const handleBlur = (): void => {
        if (onBlur) {
            onBlur(usp);
        }
    };

    const handleFocus = (): void => {
        if (onFocus) {
            onFocus(usp);
        }
    };

    return (
        <div
            onBlur={handleBlur}
            onFocus={handleFocus}
            onMouseOut={handleBlur}
            onMouseOver={handleFocus}
            className={wrapperClassName}
        >
            <div ref={wrapperRef} className="usp-carousel-stat__wrapper">
                <div ref={headingRef} className="usp-carousel-stat__heading">
                    <div ref={iconRef} className="usp-carousel-stat__icon-wrapper">
                        <Icon name={uspIcons[usp]} className="usp-carousel-stat__icon" />
                    </div>

                    <h3 ref={titleRef} className="usp-carousel-stat__title">
                        {trans(`usps.${usp}.title`)}
                    </h3>
                </div>

                <p ref={paragraphRef} className="usp-carousel-stat__description">
                    {trans(`usps.${usp}.description`)}
                </p>
            </div>
        </div>
    );
};

export default UspCarouselStat;
