import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { defaultLocale, locales } from '../../../constants/locale';
import { getLocaleFromLanguageString, getTranslation } from '../../../helpers/trans';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useTrans from '../../../hooks/useTrans';

interface LocalizedHelmetProps {
    pageKey: string;
    params?: Record<string, string>;
}

const LocalizedHelmet: FC<PropsWithChildren<LocalizedHelmetProps>> = ({
    pageKey,
    params,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const decodedUrl = decodeURIComponent(pathname);
    const routeLanguage = decodedUrl.split('/')[1];
    const { language } = getLocaleFromLanguageString(routeLanguage) || defaultLocale;

    const paramPath = getTranslation(language, `routes.${pageKey}.path`);
    const updatedPathname = params
        ? replaceUrlParamKeysWithValues(paramPath, params)
        : paramPath;

    return (
        <Helmet>
            <title>{trans(`pages.${pageKey}.meta.title`, params)}</title>
            <meta name="description" content={trans(`pages.${pageKey}.meta.description`, params)} />
            <meta name="keywords" content={trans(`pages.${pageKey}.meta.keywords`, params)} />

            <meta property="og:title" content={trans(`pages.${pageKey}.meta.title`, params)} />
            <meta property="og:description" content={trans(`pages.${pageKey}.meta.description`, params)} />

            <meta property="og:image" content={`${siteUrl}/images/metadata-preview.jpg`} />
            <meta property="og:image:alt" content={trans('company.name')} />

            <link rel="canonical" href={siteUrl + updatedPathname} />

            {Object.values(locales).map(locale => {
                const localePath = getTranslation(locale.language, `routes.${pageKey}.path`);
                const updatedLocalePathname = params
                    ? replaceUrlParamKeysWithValues(localePath, params)
                    : localePath;

                return (
                    <link
                        key={locale.name}
                        rel="alternate"
                        hrefLang={locale.language}
                        href={siteUrl + updatedLocalePathname}
                    />
                );
            })}

            {children}
        </Helmet>
    );
};

export default LocalizedHelmet;
