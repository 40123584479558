import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Vacancy } from '../../entities/Vacancy/Vacancy';

export type VacancyDetailState = Readonly<{
    isLoading: boolean;
    error: string;
    vacancyData?: Vacancy;
}>;

const initialState: VacancyDetailState = {
    isLoading: false,
    error: '',
};

const vacancyDetailSlice = createSlice({
    name: 'vacancyDetailReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): VacancyDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): VacancyDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setVacancyData(state, action: PayloadAction<Vacancy | undefined>): VacancyDetailState {
            return {
                ...state,
                vacancyData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setVacancyData,
} = vacancyDetailSlice.actions;

export default vacancyDetailSlice;
