import { ChangeEvent, FC, ReactElement } from 'react';

import { InputProps } from '../../../components/@inputs/Input/Input';

import './Toggle.scss';

interface ToggleProps extends Omit<InputProps, 'onChange'> {
    label: string;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

const Toggle: FC<ToggleProps> = ({
    label,
    onChange,
    className = '',
    ...toggleProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.checked);
    };

    return (
        <label className={`toggle ${className}`}>
            <input
                {...toggleProps}
                type="checkbox"
                className="toggle__input"
                onChange={handleChange}
            />

            <span className="toggle__handle-wrapper">
                <span className="toggle__handle" />

                <span className="toggle__accent-wrapper">
                    <span className="toggle__accent" />
                </span>
            </span>

            <span className="toggle__label">{label}</span>
        </label>
    );
};

export default Toggle;
