import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAsyncReduxState } from '../../constants';
import { NutritionTableRow } from '../../entities/NutritionTableRow/NutritionTableRow';
import { AsyncReduxState } from '../../types';

export type NutritionState = AsyncReduxState<{
    tableData: NutritionTableRow[];
}>;

const initialState: NutritionState = {
    ...initialAsyncReduxState,
    tableData: [],
};

export const NutritionSlice = createSlice({
    name: 'nutritionReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): NutritionState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setTableData(state, action: PayloadAction<NutritionTableRow[]>): NutritionState {
            return {
                ...state,
                tableData: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NutritionState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setTableData,
    setError,
} = NutritionSlice.actions;

export default NutritionSlice;
