import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { ConnectedIngredients } from '../../connectors';
import { Flavour, flavourSets } from '../../constants/flavours';
import { useCountry } from '../../context/CountryContext';
import useTrans from '../../hooks/useTrans';
import { FlavourHeader, NextFlavour, SpotifyPlaylistSection } from './subcomponents';

import './FlavourContent.scss';

interface FlavourContentProps {
    flavour: Flavour;
    className?: string;
}

const FlavourContent: FC<FlavourContentProps> = ({
    flavour,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { continent } = useCountry();

    const regionFlavours = flavourSets[continent];

    return (
        <article className={`flavour-content ${className}`}>
            <FlavourHeader
                flavour={flavour}
                className="flavour-content__header"
            />

            <SpotifyPlaylistSection
                flavour={flavour}
                className="flavour-content__spotify-playlist-section"
            />

            <Wrapper className="flavour-content__ingredients-title-wrapper">
                <h2 className="flavour-content__ingredients-title">
                    {trans('containers.flavourContent.ingredients.title')}
                </h2>
            </Wrapper>

            <Wrapper className="flavour-content__ingredients-wrapper">
                <ConnectedIngredients flavour={flavour} />
            </Wrapper>

            <footer className="flavour-content__footer">
                <NextFlavour
                    flavour={flavour}
                    regionFlavours={regionFlavours}
                    className="flavour-content__next-flavour"
                />
            </footer>
        </article>
    );
};

export default FlavourContent;
