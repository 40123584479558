/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconNaturalFlavouring: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M65.3,0.9c2.4,0,4.5,1.4,5.9,3.5c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0.1,0.2c0.1,0.2,0.3,0.5,0.5,0.9c0.5,0.9,1.4,2.4,2.8,4.9c2.9,4.9,8.1,13.7,17.4,28.9c10,16.4,9.7,31.3,3.4,42.2c-6.3,10.8-18.2,17.3-30.9,17.3c-14.7,0-29.8-8.8-34.5-23.5c-0.4-1.1,0.3-2.4,1.4-2.7s2.4,0.3,2.7,1.4c4,12.6,17.1,20.5,30.3,20.5c11.2,0,21.7-5.8,27.2-15.1c5.4-9.3,6-22.5-3.3-37.7c-9.3-15.2-14.5-24-17.4-28.9c-1.5-2.5-2.3-4-2.9-4.9c-0.3-0.5-0.4-0.8-0.5-1c0-0.1-0.1-0.1-0.1-0.2c-0.8-1.2-1.6-1.5-2.2-1.5c-0.6,0-1.6,0.4-2.7,1.7c-0.8,1-2.8,4.1-5.4,8.4c-0.6,1-2,1.4-3,0.7c-1-0.6-1.4-2-0.7-3C56,9,58.2,5.6,59.2,4.3C60.8,2.3,63,0.9,65.3,0.9z" />
        <path d="M4.6,16.5C22.1,23.4,37.1,36,48.4,48.7c3.3,3.8,6.3,7.5,8.9,11.1c-2.3,0.1-5.3,0.1-8.6-0.4c-5.8-0.7-12.5-2.5-19-5.7C17.5,47.7,6.3,36.5,4.6,16.5z M64,61.9c0.1-0.3,0.1-0.7,0-1.1C58.9,35.6,51.9,22,41.5,15.3C31.2,8.7,18,9.4,1.9,11.1c-1.1,0.1-2,1.1-1.9,2.2c0.8,23.9,13.7,37.4,27.7,44.3c7,3.4,14.2,5.3,20.4,6.1c4.7,0.6,8.9,0.6,12,0.1c0.9,1.4,1.8,2.7,2.6,4c3.6,5.9,5.8,10.8,6.5,13.9c0.3,1.2,1.5,1.9,2.6,1.6c1.2-0.3,1.9-1.5,1.6-2.6c-0.9-3.8-3.3-9.1-7-15.1C65.7,64.4,64.9,63.2,64,61.9z M57.9,53.3c-1.9-2.5-4-5-6.3-7.5C41.2,34,27.4,22.1,11.3,14.6C23.1,13.8,32,14.4,39.2,19C46.9,23.9,53,33.9,57.9,53.3z" />
        <path d="M32.9,19.4c1.1-0.5,2.4-0.1,2.9,1l-2,1c2-1,2-1,2-1l0,0l0,0l0,0.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.3,0.6,0.5,1c0.4,0.9,0.8,2.1,1.3,3.4c0.4,1.3,0.8,2.9,1,4.4c0.2,1.4,0.2,3.1-0.3,4.6c-0.1,0.3-0.3,0.6-0.6,0.8c-1,1-2.5,1.4-4,1.7c-1.5,0.2-3.3,0.3-5.2,0.1c-3.9-0.3-8.5-1.4-13-3.6c-1.1-0.5-1.5-1.8-1-2.9c0.5-1.1,1.8-1.5,2.9-1c3.9,1.9,8.1,2.9,11.4,3.2c1.7,0.1,3.1,0.1,4.2-0.1c0.6-0.1,1-0.2,1.3-0.3c0.1-0.5,0.1-1.1,0-1.9c-0.1-1.1-0.5-2.4-0.9-3.6c-0.4-1.2-0.8-2.3-1.1-3.1c-0.2-0.4-0.3-0.7-0.4-0.9c0-0.1-0.1-0.2-0.1-0.2l0-0.1l0,0l0,0C31.4,21.2,31.8,19.9,32.9,19.4z" />
        <path d="M46.5,33.6c1.1-0.5,2.4-0.1,2.9,1l-2,1c2-1,2-1,2-1l0,0l0,0l0,0l0,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.5,0.4,0.9c0.3,0.7,0.7,1.8,1.1,3c0.4,1.2,0.8,2.5,1,3.9c0.2,1.3,0.3,2.8,0,4.2c-0.1,0.4-0.3,0.8-0.6,1.1c-1,0.9-2.4,1.4-3.7,1.6c-1.4,0.2-3,0.3-4.8,0.2c-3.6-0.2-8.1-0.9-12.8-2.3c-1.2-0.3-1.8-1.6-1.5-2.7c0.3-1.2,1.6-1.8,2.7-1.5c4.4,1.3,8.5,2,11.7,2.1c1.6,0.1,2.9,0,3.9-0.2c0.3-0.1,0.6-0.1,0.9-0.2c0-0.5,0-1-0.2-1.6c-0.2-1-0.5-2.2-0.8-3.2c-0.3-1-0.7-2-1-2.6c-0.1-0.3-0.3-0.6-0.3-0.8c0-0.1-0.1-0.2-0.1-0.2l0,0l0,0c0,0,0,0,0,0 M46.5,33.6c-1.1,0.5-1.5,1.8-1,2.9L46.5,33.6z" />
    </Svg>
);

export default IconNaturalFlavouring;
/* eslint-enable max-len */
