import { FC, ReactElement } from 'react';

import { Svg } from '../../components';
import { SvgProps } from '../../components/Svg/Svg';

import './MorphingSvg.scss';

interface MorphingSvgProps extends SvgProps {
    fromPath: string;
    toPath: string;
    duration?: string;
    pathClassName?: string;
}

const MorphingSvg: FC<MorphingSvgProps> = ({
    viewBoxWidth,
    viewBoxHeight,
    fromPath,
    toPath,
    duration = '10s',
    className = '',
    pathClassName = '',
}): ReactElement => (
    <Svg
        viewBoxWidth={viewBoxWidth}
        viewBoxHeight={viewBoxHeight}
        className={`morphing-svg ${className}`}
    >
        <path className={pathClassName}>
            <animate
                attributeName="d"
                values={[fromPath, toPath, fromPath].join(';')}
                repeatCount="indefinite"
                dur={duration}
                calcMode="spline"
                keySplines="0.5 0 0.25 1; 0.5 0 0.25 1"
            />
        </path>
    </Svg>
);

export default MorphingSvg;
