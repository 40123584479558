import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { PrismicSliceTitleColour, PrismicSliceTitleSize } from '../../entities/Prismic/Prismic';

import './PrismicSliceTitle.scss';

interface PrismicSliceTitleProps {
    text: string;
    size?: PrismicSliceTitleSize;
    colour?: PrismicSliceTitleColour;
    className?: string;
}

const PrismicSliceTitle: FC<PrismicSliceTitleProps> = ({
    text,
    size,
    colour,
    className = '',
}): ReactElement => {
    const prismicSliceTitleClassNames = classNames('prismic-slice-title', {
        [`prismic-slice-title--size-${size}`]: size,
        [`prismic-slice-title--colour-${colour}`]: colour,
    }, className);

    return (
        <h2 className={prismicSliceTitleClassNames}>
            {text}
        </h2>
    );
};

export default PrismicSliceTitle;
