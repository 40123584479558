import { FC, ReactElement } from 'react';

import { Icon, Wrapper } from '../../components';
import { Vacancy } from '../../entities/Vacancy/Vacancy';
import useTrans from '../../hooks/useTrans';
import { VacancyOverviewSkeletons } from './skeletons';
import { VacancyOverviewItem } from './subcomponents';

import './VacancyOverview.scss';

interface VacancyOverviewProps {
    isLoading: boolean;
    vacancies: Vacancy[];
    className?: string;
}

const VacancyOverview: FC<VacancyOverviewProps> = ({
    isLoading,
    vacancies,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`vacancy-overview ${className}`}>
            <Wrapper className="vacancy-overview__wrapper">
                {isLoading && (
                    <VacancyOverviewSkeletons className="vacancy-overview__list" />
                )}

                {!isLoading && vacancies.length === 0 && (
                    <div className="vacancy-overview__no-results-wrapper">
                        <Icon name="search" className="vacancy-overview__no-results-icon" />

                        <h2 className="vacancy-overview__no-results-text">
                            {trans('containers.vacancyOverview.noResults')}
                        </h2>
                    </div>
                )}

                {!isLoading && vacancies.length > 0 && (
                    <ul className="vacancy-overview__list">
                        {vacancies.map(vacancy => (
                            <VacancyOverviewItem
                                key={vacancy.id}
                                vacancy={vacancy}
                                className="vacancy-overview__item"
                            />
                        ))}
                    </ul>
                )}
            </Wrapper>
        </div>
    );
};

export default VacancyOverview;
