import { Direction } from '../../../../constants/locale';
import { Usp } from '../../../../constants/usp';
import { gsap, Quart } from '../../../../entities/Gsap/GsapService';
import { DesktopScrollPos } from '../types/DeskopScrollPos';

const getDesktopScrollPos = (scrollPosition: number, maxScrollableWidth: number): DesktopScrollPos => {
    if (scrollPosition === 0) {
        return DesktopScrollPos.start;
    }

    if (scrollPosition === maxScrollableWidth) {
        return DesktopScrollPos.end;
    }

    return DesktopScrollPos.middle;
};

const scrollToUspItem = (
    isNarrowDevice: boolean,
    scrollElement: HTMLDivElement,
    listElement: HTMLUListElement,
    usps: Usp[],
    increment: -1 | 1,
    activeItemIndex: number,
    direction: Direction,
): DesktopScrollPos | undefined => {
    const firstItem = listElement.firstChild as HTMLLIElement | null;
    const secondItem = firstItem?.nextSibling as HTMLLIElement | null;

    if (!scrollElement || !listElement || !firstItem) {
        return undefined;
    }

    if (!isNarrowDevice) {
        const maxScrollableWidth = Math.round(scrollElement.clientWidth - window.innerWidth);
        const gap = secondItem ? Math.abs(secondItem.offsetLeft - firstItem.offsetLeft) - firstItem.clientWidth : 0;
        const scrollPosition = Math.round(scrollElement.getBoundingClientRect().x);
        const justifiedScrollPosition = direction === Direction.ltr ? -scrollPosition : scrollPosition + maxScrollableWidth;
        const incrementWidth = increment * ((firstItem.clientWidth + gap) * 3);
        const xPos = Math.min(Math.max(justifiedScrollPosition + incrementWidth, 0), maxScrollableWidth);

        gsap.to(scrollElement, { duration: 0.5, x: direction === Direction.ltr ? -xPos : xPos, ease: Quart.easeOut });

        return getDesktopScrollPos(xPos, maxScrollableWidth);
    }

    if (activeItemIndex < 0 || activeItemIndex >= usps.length) {
        return undefined;
    }

    const element = listElement.firstChild as HTMLDivElement;
    const justifiedWidth = direction === Direction.ltr ? element.clientWidth : -element.clientWidth;

    scrollElement.scroll({ left: (activeItemIndex + 1) * justifiedWidth, behavior: 'smooth' });

    return undefined;
};

export default scrollToUspItem;
