/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconIntensity: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M99.6,64.4c0,1.3-1.1,2.4-2.4,2.4H82.7c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4h14.4C98.5,62,99.6,63,99.6,64.4z" />
        <path d="M89.9,74c-1.3,0-2.4-1.1-2.4-2.4V57.2c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4v14.4C92.4,72.9,91.3,74,89.9,74z" />
        <path d="M19.3,64.4c0,1.3-1.1,2.4-2.4,2.4H2.5c-1.3,0-2.4-1.1-2.4-2.4C0,63,1.1,62,2.5,62h14.4C18.2,62,19.3,63,19.3,64.4z" />
        <path d="M53.1,70.8c-6.2,0-12-3.2-14.9-8.5C36.4,59,34.1,52,39.9,42.5l0,0C48,29.3,49.2,27,49.2,26.9c0-0.1,0.1-0.1,0.1-0.2c0.8-1.2,2-2,3.3-2c1.4-0.1,2.7,0.6,3.7,1.9c1.5,1.9,8.7,14.2,9.7,15.9c6.3,9.2,4.3,16.1,2.6,19.4C65.7,67.3,59.7,70.8,53.1,70.8z M43.2,44.6c-3.5,5.8-4.1,11.4-1.6,15.9c2.2,4,6.6,6.5,11.5,6.5c5,0,9.8-2.7,12-6.8c2.3-4.3,1.4-9.8-2.4-15.4c0,0-0.1-0.1-0.1-0.1c-3.9-6.7-8.4-14.4-9.4-15.5c-0.2-0.3-0.4-0.4-0.5-0.4c0,0,0,0.1-0.1,0.2C52.3,29.4,50.6,32.5,43.2,44.6L43.2,44.6z" />
        <path d="M27.4,32.3c-3.8,0-7.2-2-9-5.1c-1.1-2-2.5-6.2,0.9-11.9l0,0c4.4-7.3,5.1-8.6,5.2-8.6c0-0.1,0.1-0.1,0.1-0.2C25.2,5.6,26.1,5,27.1,5c1-0.1,2.1,0.4,2.8,1.4c0.9,1.1,4.6,7.5,5.4,8.9c3.7,5.5,2.5,9.6,1.5,11.6C35,30.2,31.4,32.3,27.4,32.3zM27.3,9.6c-0.7,1.1-2,3.4-4.6,7.7l0,0c-1.8,2.9-2.1,5.7-0.9,7.9c1.1,1.9,3.2,3.1,5.6,3.1c2.5,0,4.8-1.3,5.9-3.3c1.1-2.1,0.7-4.8-1.3-7.6c0,0-0.1-0.1-0.1-0.1C30,14,28.2,11,27.3,9.6z M21,16.3L21,16.3L21,16.3z" />
        <path d="M0,87.2c0-4.3,3.5-7.8,7.8-7.8h84.4c4.3,0,7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8H7.8C3.5,95,0,91.5,0,87.2zM7.8,83.2c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4h84.4c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4H7.8z" />
        <path d="M69,80.7c1,0.4,1.5,1.5,1.1,2.4l-3.8,10.2c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4l3.8-10.2C66.9,80.8,68,80.3,69,80.7z" />
        <path d="M61.2,80.7c1,0.4,1.5,1.5,1.1,2.4l-3.8,10.2c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4l3.8-10.2C59.1,80.8,60.2,80.3,61.2,80.7z" />
        <path d="M49.9,80.7c1,0.4,1.5,1.5,1.1,2.4l-3.8,10.2c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4l3.8-10.2C47.8,80.8,48.9,80.3,49.9,80.7z" />
        <path d="M31.9,80.7c1,0.4,1.5,1.5,1.1,2.4l-3.8,10.2c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4l3.8-10.2C29.9,80.8,30.9,80.3,31.9,80.7z" />
        <path d="M76.3,80.7c1,0.4,1.5,1.5,1.1,2.4l-3.8,10.2c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4l3.8-10.2C74.3,80.8,75.3,80.3,76.3,80.7z" />
        <path d="M74.5,81.3h22.7v11.3H74.5V81.3z" />
    </Svg>
);

export default IconIntensity;
/* eslint-enable max-len */
