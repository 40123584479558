import { FC, memo, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { range } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './PrismicPageSkeletons.scss';

interface PrismicPageSkeletonsProps {
    className?: string;
}

const PrismicPageSkeletons: FC<PrismicPageSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const amountOfParagraphs = randomInBetweenValue(4, 6);
    const paragraphs = range(0, amountOfParagraphs);

    const lineAmounts = paragraphs.map(() => range(0, randomInBetweenValue(4, 6)));

    return (
        <div className={`prismic-page-skeletons ${className}`}>
            <Wrapper className="prismic-page-skeletons__wrapper">
                <Skeleton className="prismic-page-skeletons__title" />

                {paragraphs.map((paragraph, index) => {
                    const lines = lineAmounts[index];

                    return (
                        <div key={paragraph} className="prismic-page-skeletons__paragraph">
                            {lines.map(line => (
                                <Skeleton key={paragraph + line} className="prismic-page-skeletons__line" />
                            ))}
                        </div>
                    );
                })}
            </Wrapper>
        </div>
    );
};

export default memo(PrismicPageSkeletons);
