import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { Article } from '../../entities/Article/Article';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';
import useTrans from '../../hooks/useTrans';
import { ArticleOverviewSkeletons } from './skeletons';
import { ArticleOverviewBackground, ArticlePreview } from './subcomponents';

import './ArticleOverview.scss';

interface ArticleOverviewProps {
    isLoading: boolean;
    totalResults: number;
    articles: Article[];
    className?: string;
}

const ArticleOverview: FC<ArticleOverviewProps> = ({
    isLoading,
    articles,
    className = '',
}): ReactElement => {
    const prefersReducedMotion = usePrefersReducedMotion();
    const trans = useTrans();

    if (isLoading) {
        return (
            <ArticleOverviewSkeletons className={`article-overview ${className}`} />
        );
    }

    return (
        <section className={`article-overview ${className}`}>
            <Wrapper>
                <header className="article-overview__header">
                    <h1 className="article-overview__title">
                        {trans('containers.articleOverview.heading')}
                    </h1>

                    <p className="article-overview__description">
                        {trans('containers.articleOverview.description')}
                    </p>
                </header>
            </Wrapper>

            <ul className="article-overview__list">
                {articles.map((article, index) => (
                    <li key={article.title} className="article-overview__item">
                        {index === 0 && (
                            <ArticleOverviewBackground
                                isAnimationEnabled={!prefersReducedMotion}
                                className="article-overview__background"
                            />
                        )}

                        <Wrapper className="article-overview__item-wrapper">
                            <ArticlePreview {...article} />
                        </Wrapper>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default ArticleOverview;
