import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../constants/routing';
import { ArticleContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { useParamsDispatch } from '../../context/ParamContext';
import useTrans from '../../hooks/useTrans';
import { fetchArticleContent } from '../../redux/articleDetail/articleDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticleContentProps {
    className?: string;
}

const ConnectedArticleContent: FC<ConnectedArticleContentProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();
    const navigate = useNavigate();
    const trans = useTrans();

    const { slug = '' } = useParams();
    const dispatchParams = useParamsDispatch();

    const isLoading = useTypedSelector(state => state.articleDetailReducer.isLoading);
    const articleData = useTypedSelector(state => state.articleDetailReducer.articleData);
    const error = useTypedSelector(state => state.articleDetailReducer.error);

    useEffect((): () => void => {
        dispatchParams({ slug });

        dispatch(fetchArticleContent(slug, language));

        return (): void => {
            dispatchParams({});
        };
    }, [slug, language]);

    useEffect((): void => {
        if (error) {
            navigate(trans(appRoutes[AppRoute.articles].path), { replace: true });
        }
    }, [error]);

    return (
        <ArticleContent
            isLoading={isLoading}
            article={articleData}
            className={className}
        />
    );
};

export default ConnectedArticleContent;
