import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { LinkButton, Wrapper } from '../../../../components';
import { droplets } from '../../../../constants/droplets';
import { PrismicDefaultPageData } from '../../../../entities/Prismic/Prismic';

import './PrismicPageHeader.scss';

interface PrismicPageHeaderProps extends Omit<PrismicDefaultPageData, 'language' | 'seo' | 'slices'> {
    className?: string;
}

const PrismicPageHeader: FC<PrismicPageHeaderProps> = ({
    title,
    titleColour,
    intro,
    callToAction,
    poster,
    posterDroplet = 1,
    className = '',
}): ReactElement => {
    const dropletIndex = posterDroplet - 1;

    const titleClassNames = classNames('prismic-page-header__title', {
        [`prismic-page-header__title--colour-${titleColour}`]: titleColour,
    });

    const posterWrapperClassNames = classNames('prismic-page-header__poster-wrapper', {
        [`prismic-page-header__poster-wrapper--droplet-${dropletIndex}`]: dropletIndex,
    });

    return (
        <header className={`prismic-page-header ${className}`}>
            <Wrapper className="prismic-page-header__content-wrapper">
                <div className="prismic-page-header__text-wrapper">
                    <h1 className={titleClassNames}>
                        {title}
                    </h1>

                    <p className="prismic-page-header__intro">
                        {intro}
                    </p>

                    {callToAction && (
                        <LinkButton
                            to={callToAction.url}
                            text={callToAction.label}
                            className="prismic-page-header__call-to-action"
                        />
                    )}
                </div>

                <div className={posterWrapperClassNames}>
                    {poster && (
                        <img
                            src={poster.src}
                            alt={poster.alt}
                            className="prismic-page-header__poster"
                        />
                    )}
                </div>

                {droplets[dropletIndex] && (
                    <svg width="0" height="0" className="prismic-page-header__poster-clip">
                        <defs>
                            <clipPath id="prismic-page-header-clip" clipPathUnits="objectBoundingBox">
                                <path d={droplets[dropletIndex]} />
                            </clipPath>
                        </defs>
                    </svg>
                )}
            </Wrapper>
        </header>
    );
};

export default PrismicPageHeader;
