import { FC, ReactElement } from 'react';

import { Usp } from '../../../../../constants/usp';
import { UspCarouselStat } from '..';

import './UspCarouselListItem.scss';

interface UspCarouselListItemProps {
    isActive: boolean;
    usp: Usp;
    onBlur?: (id: string) => void;
    onFocus?: (id: string) => void;
    className?: string;
}

const UspCarouselListItem: FC<UspCarouselListItemProps> = ({
    isActive,
    usp,
    onBlur,
    onFocus,
    className = '',
}): ReactElement => (
    <li className={`usp-carousel-list-item ${className}`}>
        <UspCarouselStat
            hasTimeline
            isActive={isActive}
            usp={usp}
            className="usp-carousel-list-item__stat"
        />
        <UspCarouselStat
            isActive
            usp={usp}
            onBlur={onBlur}
            onFocus={onFocus}
            className="usp-carousel-list-item__sizer"
        />
    </li>
);

export default UspCarouselListItem;
