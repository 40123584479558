import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { FaqItem as FaqItemData } from '../../../entities/Faq/Faq';
import { FaqItem } from './subcomponents';

import './FaqSlice.scss';

interface FaqSliceProps {
    faqItems: FaqItemData[];
    className?: string;
}

const FaqSlice: FC<FaqSliceProps> = ({
    faqItems,
    className = '',
}): ReactElement => (
    <Wrapper className={`faq-slice ${className}`}>
        <ul className="faq-slice__list">
            {faqItems.map(item => (
                <FaqItem
                    {...item}
                    key={item.question}
                    className="faq-slice__item"
                />
            ))}
        </ul>
    </Wrapper>
);

export default FaqSlice;
