import {
    FC,
    ReactElement,
    useCallback,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button } from '../../../../components';
import { Continent, continentOptions } from '../../../../constants/countries';
import { defaultLocale, languageOptions, LocaleData } from '../../../../constants/locale';
import { getLocaleFromLanguageString } from '../../../../helpers/trans';
import useTrans from '../../../../hooks/useTrans';
import { MenuSidebar, RegionSelector } from '..';

import './I18nSettings.scss';

interface I18nSettingsProps {
    isExpanded: boolean;
    navigationLabel: string;
    transitionDelay?: number;
    cookieSettingsRejected: boolean;
    activeRegion: Continent;
    activeLocale: LocaleData;
    onShowCookieSettings: () => void;
    onSelectRegion: (region: Continent) => void;
    onSelectLocale: (locale: LocaleData) => void;
    onClose?: () => void;
    className?: string;
}

const I18nSettings: FC<I18nSettingsProps> = ({
    isExpanded,
    navigationLabel,
    transitionDelay = 0,
    cookieSettingsRejected,
    activeRegion,
    activeLocale,
    onShowCookieSettings,
    onSelectRegion,
    onSelectLocale,
    onClose,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [isReady, setIsReady] = useState<boolean>(false);

    const getTransitionDelay = useCallback((index: number): string => {
        if (!isExpanded) return '0s';

        return `${index * transitionDelay}s`;
    }, [isExpanded]);

    const initialTransitionDelay = getTransitionDelay(0);

    const i18nSettingsClassNames = classNames('i18n-settings', {
        'i18n-settings--is-ready': isExpanded && isReady,
    }, className);

    return (
        <MenuSidebar
            isExpanded={isExpanded}
            navigationLabel={navigationLabel}
            onTransitionEnd={setIsReady}
            onClose={onClose}
            className={i18nSettingsClassNames}
        >
            {cookieSettingsRejected && (
                <div
                    style={{ transitionDelay: initialTransitionDelay }}
                    className="i18n-settings__cookie-notice-wrapper"
                >
                    <p className="i18n-settings__cookie-notice">
                        {trans('containers.menu.cookieNotice.description')}
                    </p>

                    <Button
                        text={trans('common.showCookieSettings')}
                        onClick={onShowCookieSettings}
                        className="i18n-settings__cookie-button"
                    />
                </div>
            )}

            <div
                style={{ transitionDelay: initialTransitionDelay }}
                className="i18n-settings__region-selector-wrapper"
            >
                <RegionSelector
                    value={activeRegion}
                    options={continentOptions}
                    onSelectRegion={onSelectRegion}
                    className="i18n-settings__region-selector"
                />
            </div>

            <ul className="i18n-settings__language-list">
                {languageOptions.map((language, index) => {
                    const selectedLocale = getLocaleFromLanguageString(language.value);
                    const handleSelectLocale = (): void => onSelectLocale(selectedLocale || defaultLocale);

                    const style = { transitionDelay: getTransitionDelay(index + 1) };

                    const languageButtonClassNames = classNames('i18n-settings__language-button', {
                        'i18n-settings__language-button--is-active': language.value === activeLocale.language,
                    });

                    return (
                        <li
                            key={language.value}
                            style={style}
                            className="i18n-settings__language-item"
                        >
                            <Button
                                text={language.label}
                                onClick={handleSelectLocale}
                                tabIndex={isExpanded ? 0 : -1}
                                className={languageButtonClassNames}
                            >
                                <span className="i18n-settings__language-label">
                                    {language.label}
                                </span>
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </MenuSidebar>
    );
};

export default I18nSettings;
