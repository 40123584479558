/* eslint-disable max-len */
import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { Picture } from '../../../../components';
import { BackgroundMorphSvg } from '../../../../compositions';
import { isSafari } from '../../../../helpers';
import useTrans from '../../../../hooks/useTrans';

import './PersonalityTestBackground.scss';

interface PersonalityTestBackgroundProps {
    isAnimating?: boolean;
    isEnding?: boolean;
    className?: string;
}

const PersonalityTestBackground: FC<PersonalityTestBackgroundProps> = ({
    isAnimating,
    isEnding,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const videoRef = useRef<HTMLVideoElement>(null);
    const pathRef = useRef<SVGPathElement>(null);

    const videoData = isSafari()
        ? { src: '/videos/personality-test.mp4', type: 'video/mp4' }
        : { src: '/videos/personality-test.webm', type: 'video/webm' };

    const gradientId = 'personality-test-background-linear-gradient';

    const personalityTestBackgroundClassNames = classNames('personality-test-background', {
        'personality-test-background--is-ending': isEnding,
    }, className);

    return (
        <div className={personalityTestBackgroundClassNames}>
            <div className="personality-test-background__fruit-wrapper">
                {isMobile ? (
                    <Picture
                        loading="lazy"
                        src="/images/personality-test.png"
                        sources={[
                            {
                                srcSet: '/images/personality-test.avif, /images/personality-test-2x.avif 2x',
                                type: 'image/avif',
                            },
                            {
                                srcSet: '/images/personality-test.webp, /images/personality-test-2x.webp 2x',
                                type: 'image/webp',
                            },
                        ]}
                        alt={trans('containers.personalityTest.backgroundImageAlt')}
                        className="personality-test-background__picture"
                    />
                ) : (
                    <video
                        ref={videoRef}
                        muted
                        playsInline
                        autoPlay
                        loop
                        className="personality-test-background__video"
                    >
                        <source src={videoData.src} type={videoData.type} />
                    </video>
                )}
            </div>

            <BackgroundMorphSvg
                isAnimationEnabled={isAnimating}
                options={{
                    taperEnd: 80,
                    invertFlow: false,
                    taperStart: 2,
                    length: 50,
                    angle: -50,
                    magnitude: 90,
                    phase: 110,
                    duration: 5,
                    start: 2,
                    end: 10,
                    repeat: 10,
                }}
                preserveAspectRatio="none"
                pathRefObject={pathRef}
                viewBoxWidth={1500}
                viewBoxHeight={700}
                className="personality-test-background__svg"
            >
                <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" className="personality-test-background__gradient-start" />
                    <stop offset="50%" className="personality-test-background__gradient-end" />
                </linearGradient>

                <path
                    ref={pathRef}
                    fill={`url(#${gradientId})`}
                    d="M -345.734 1055.01 C 331.654 1069.122 1023.155 1076.178 1860.483 1062.066 C 1909.876 535.208 1728.769 -29.282 1326.569 163.585 C 1077.252 302.356 750.318 330.58 416.328 325.876 C 131.731 323.524 -451.576 295.3 -345.734 1055.01"
                    className="personality-test-background__svg-wave"
                />
            </BackgroundMorphSvg>
        </div>
    );
};

export default PersonalityTestBackground;
/* eslint-enable max-len */
