/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconLinkedIn: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <rect x="1.7" y="37.1" width="21" height="62.9" />
        <path d="M12.2,24.5c-7,0-11.9-5.6-11.9-12.6S5.9,0,12.9,0c6.3,0,11.9,5.6,11.9,11.9C24.8,19.6,19.2,25.2,12.2,24.5z" />

        <path d="M99.7,100h-21V67.1c0-9.8-4.2-13.3-9.8-13.3c-7,0.7-11.9,6.3-11.2,13.3l0,0v0.7V100h-21V37.1H57v9.1c4.2-6.3,11.2-9.8,18.9-9.8c11.2,0,23.8,6.3,23.8,25.9V100z" />
    </Svg>
);

export default IconLinkedIn;
/* eslint-enable max-len */
