import trans from '../helpers/trans';
import { FetchResult, FetchResultType } from './FetchResult';

enum AppVersion {
    current = 'current',
    latest = 'latest',
}

export const getAppVersion = async (appVersion: AppVersion): Promise<FetchResult<string, string>> => {
    try {
        const versionResponse = await fetch(`${process.env.PUBLIC_URL}/version-${appVersion}.json`, {
            headers: { 'Cache-Control': 'no-cache' },
        });

        const { version } = await versionResponse.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: version,
        };
    } catch (error) {
        console.error('[getAppVersion]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getCurrentVersion = async () => getAppVersion(AppVersion.current);
export const getLatestVersion = async () => getAppVersion(AppVersion.latest);
