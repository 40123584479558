import { IconName } from '../../components/Icon/Icon';
import { Language } from '../../constants/locale';
import { getLanguageFromPrismicLanguage } from '../../helpers/prismic';
import { PrismicImage } from '../@slices/Image/Image';
import { transformToImage } from '../@slices/Image/ImageTransformers';
import { transformToDateIsoString } from '../Date/DateTransformers';
import { transformToCallToAction } from '../Link/LinkTransformers';
import { PrismicDocument, PrismicSearchResponse } from '../Prismic/Prismic';
import { defaultSeo } from '../Seo/Seo';
import { transformToSeo } from '../Seo/SeoTransformers';
import {
    MetaVacancyCompensationType,
    MetaVacancyEmploymentType,
    MetaVacancyWorkRemote,
    PrismicVacancyAddress,
    PrismicVacancyCompensation,
    PrismicVacancyCompensationType,
    PrismicVacancyEmploymentType,
    PrismicVacancyTemplate,
    PrismicVacancyWorkRemote,
    Vacancy,
    VacancyAddress,
    VacancyCompensation,
    VacancyOverviewInfo,
} from './Vacancy';

export const transformToVacancyOverviewInfo = (prismicSearchResponse: PrismicSearchResponse): VacancyOverviewInfo => ({
    currentPage: prismicSearchResponse.page,
    pageSize: prismicSearchResponse.results_per_page,
    totalPages: prismicSearchResponse.total_pages,
    totalResults: prismicSearchResponse.total_results_size,
});

export const transformToMetaVacancyEmploymentType = (prismicVacancyEmploymentType: PrismicVacancyEmploymentType): MetaVacancyEmploymentType => {
    if (prismicVacancyEmploymentType === PrismicVacancyEmploymentType.internship) return MetaVacancyEmploymentType.internship;
    if (prismicVacancyEmploymentType === PrismicVacancyEmploymentType.volunteer) return MetaVacancyEmploymentType.volunteer;
    if (prismicVacancyEmploymentType === PrismicVacancyEmploymentType.temporary) return MetaVacancyEmploymentType.temporary;
    if (prismicVacancyEmploymentType === PrismicVacancyEmploymentType.contractor) return MetaVacancyEmploymentType.contractor;
    if (prismicVacancyEmploymentType === PrismicVacancyEmploymentType.partTime) return MetaVacancyEmploymentType.partTime;

    return MetaVacancyEmploymentType.fullTime;
};

const transformToVacancyWorkRemote = (prismicVacancyWorkRemote: PrismicVacancyWorkRemote): MetaVacancyWorkRemote => {
    if (prismicVacancyWorkRemote === PrismicVacancyWorkRemote.fullyRemote) return MetaVacancyWorkRemote.fullyRemote;
    if (prismicVacancyWorkRemote === PrismicVacancyWorkRemote.partlyRemote) return MetaVacancyWorkRemote.partlyRemote;

    return MetaVacancyWorkRemote.notRemote;
};

const transformToVacancyAddress = (prismicVacancyAddress: PrismicVacancyAddress): VacancyAddress => ({
    street: prismicVacancyAddress.street || '',
    houseNumber: prismicVacancyAddress.house_number || '',
    postalCode: prismicVacancyAddress.postal_code || '',
    city: prismicVacancyAddress.city || '',
    region: prismicVacancyAddress.region || '',
    country: prismicVacancyAddress.country || '',
});

const transformToVacancyCompensationType = (prismicVacancyCompensationType: PrismicVacancyCompensationType): MetaVacancyCompensationType => {
    if (prismicVacancyCompensationType === PrismicVacancyCompensationType.hour) return MetaVacancyCompensationType.hour;
    if (prismicVacancyCompensationType === PrismicVacancyCompensationType.day) return MetaVacancyCompensationType.day;
    if (prismicVacancyCompensationType === PrismicVacancyCompensationType.week) return MetaVacancyCompensationType.week;
    if (prismicVacancyCompensationType === PrismicVacancyCompensationType.year) return MetaVacancyCompensationType.year;

    return MetaVacancyCompensationType.month;
};

const transformToVacancyCompensation = (prismicVacancyCompensation: PrismicVacancyCompensation): VacancyCompensation => {
    const type = prismicVacancyCompensation.salary_type
        ? transformToVacancyCompensationType(prismicVacancyCompensation.salary_type)
        : undefined;

    return {
        type,
        min: prismicVacancyCompensation.minimum_value || undefined,
        max: prismicVacancyCompensation.maximum_value || undefined,
    };
};

export const transformPrismicDocumentToVacancy = (prismicDocument: PrismicDocument<PrismicVacancyTemplate>, language: Language): Vacancy => {
    const icon: IconName | undefined = prismicDocument.data.icon
        ? `vacancy-${prismicDocument.data.icon}`
        : undefined;

    const createdAt = prismicDocument.first_publication_date
        ? transformToDateIsoString(prismicDocument.first_publication_date)
        : undefined;

    const expirationDate = prismicDocument.data.expiration_date
        ? transformToDateIsoString(prismicDocument.data.expiration_date)
        : undefined;

    const workRemote = prismicDocument.data.work_remote
        ? transformToVacancyWorkRemote(prismicDocument.data.work_remote)
        : undefined;

    const address = transformToVacancyAddress(prismicDocument.data.address[0]);
    const compensation = transformToVacancyCompensation(prismicDocument.data.compensation[0]);

    const applyCallToAction = (prismicDocument.data.apply_link && prismicDocument.data.apply_label)
        ? transformToCallToAction(
            prismicDocument.data.apply_link,
            prismicDocument.data.apply_label,
            language,
        )
        : undefined;

    const image = prismicDocument.data.image?.url
        ? transformToImage(prismicDocument.data.image as PrismicImage)
        : undefined;

    const seo = prismicDocument.data.seo?.length > 0
        ? transformToSeo(prismicDocument.data.seo[0])
        : defaultSeo;

    const alternativeLanguages = prismicDocument.alternate_languages.map(altLang => ({
        language: getLanguageFromPrismicLanguage(altLang.lang),
        slug: altLang.uid || '',
    }));

    return {
        id: prismicDocument.id,
        slug: prismicDocument.uid,
        title: prismicDocument.data.title || '',
        description: prismicDocument.data.description || '',
        icon,
        createdAt,
        expirationDate,
        educationLevel: prismicDocument.data.education_level || undefined,
        employmentType: prismicDocument.data.employment_type || undefined,
        hoursPerWeek: prismicDocument.data.hours_per_week || undefined,
        workRemote,
        address,
        compensation,
        applyCallToAction,
        image,
        seo,
        body: prismicDocument.data.body,
        alternativeLanguages,
    };
};
