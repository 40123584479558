import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

interface CheckIntersectionProps {
    once?: boolean;
    threshold?: number;
    onIntersectionToggle?: (isInView: boolean) => void;
    className?: string;
    children: (isInView: boolean) => ReactElement;
}

const CheckIntersection: FC<CheckIntersectionProps> = ({
    once,
    threshold = 1,
    onIntersectionToggle,
    className = '',
    children,
}): ReactElement => {
    const [isInView, setIsInView] = useState<boolean>(false);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect((): () => void => {
        let observer: IntersectionObserver;
        const subject = childRef.current;

        if ('IntersectionObserver' in window && subject) {
            observer = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
                for (let i = 0; i < entries.length; i += 1) {
                    if (entries[i].isIntersecting) {
                        setIsInView(true);
                    } else if (!once) {
                        setIsInView(false);
                    }
                }
            }, { threshold });

            observer.observe(subject);
        } else {
            setIsInView(true);
        }

        return (): void => {
            if (subject) {
                observer.unobserve(subject);
            }
        };
    }, [childRef, threshold]);

    useEffect((): void => {
        if (onIntersectionToggle) {
            onIntersectionToggle(isInView);
        }
    }, [isInView]);

    return (
        <div ref={childRef} className={className}>
            {children ? children(isInView) : null}
        </div>
    );
};

export default CheckIntersection;
