export const generateUuid = (): string => crypto.randomUUID();

export const trimToMaxLength = (string: string, maxLength = 100): string => {
    if (string.length <= maxLength) return string;

    const words = string.substring(0, maxLength).split(' ');
    words.pop();

    const output = words.join(' ').trim();

    return `${output}…`;
};
