import {
    CSSProperties,
    FC,
    ReactElement,
    SyntheticEvent,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from 'react-use';

import { LinkButton, Wrapper } from '../../components';
import { HomeSection, homeSections } from '../../constants/routing';
import { isSafari } from '../../helpers';
import useTimeout from '../../hooks/useTimeout';
import useTrans from '../../hooks/useTrans';
import { NotFoundSectionBackground } from './subcomponents';

import './NotFoundSection.scss';

interface NotFoundSectionProps {
    className?: string;
}

const NotFoundSection: FC<NotFoundSectionProps> = ({ className = '' }): ReactElement => {
    const trans = useTrans();
    const { width: windowWidth } = useWindowSize();

    const [videoIsLoaded, setVideoIsLoaded] = useState<boolean>(false);
    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const wrapperOffsetRatio = useMemo((): number => {
        if (!wrapperRef.current) return 1;

        const computedStyle = getComputedStyle(wrapperRef.current);
        const wrapperPadding = parseFloat(computedStyle.paddingLeft) * 2;
        const wrapperWidth = wrapperRef.current.clientWidth - wrapperPadding;
        const wrapperOffset = (windowWidth - wrapperWidth) / 2;

        return 1 + (wrapperOffset / windowWidth);
    }, [windowWidth, wrapperRef.current]);

    const revealDelay = useMemo((): number => {
        const initialDelay = isMobile ? 50 : 300;

        return initialDelay * wrapperOffsetRatio;
    }, [isMobile, wrapperOffsetRatio]);

    const revealDuration = useMemo((): number => {
        if (isMobile) return 800;

        return 150;
    }, [isMobile]);

    useTimeout((): void => {
        if (videoIsLoaded) {
            setIsRevealed(true);
        }
    }, revealDelay, [videoIsLoaded]);

    // Reveal text after delay if video is still not loaded
    useTimeout((): void => {
        if (!videoIsLoaded) {
            setIsRevealed(true);
        }
    }, 2500, [videoIsLoaded]);

    const handleVideoLoadedData = (): void => {
        setVideoIsLoaded(true);

        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleTimeUpdate = (event: SyntheticEvent<HTMLVideoElement, Event>): void => {
        if (event.currentTarget.currentTime > 0) {
            setVideoIsLoaded(true);
        }
    };

    const videoData = isSafari()
        ? { src: '/videos/not-found.mp4', type: 'video/mp4' }
        : { src: '/videos/not-found.webm', type: 'video/webm' };

    const notFoundSectionClassNames = classNames('not-found-section', {
        'not-found-section--is-mobile': isMobile,
    }, className);

    const revealWrapperClassNames = classNames('not-found-section__reveal-wrapper', {
        'not-found-section__reveal-wrapper--is-revealed': isRevealed,
    });

    const cssVariables = {
        '--not-found-section-reveal-duration': `${revealDuration}ms`,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={notFoundSectionClassNames}>
            <Wrapper ref={wrapperRef} className="not-found-section__wrapper">
                <div className={revealWrapperClassNames}>
                    <h1 className="not-found-section__title">
                        {trans('containers.notFoundSection.title')}
                    </h1>

                    <LinkButton
                        to={trans(homeSections[HomeSection.welcome].path)}
                        text={trans('containers.notFoundSection.linkButton')}
                        className="not-found-section__link-button"
                    />
                </div>
            </Wrapper>

            <video
                ref={videoRef}
                muted
                playsInline
                autoPlay={isMobile}
                onLoadedData={!isMobile ? handleVideoLoadedData : undefined}
                onTimeUpdate={isMobile ? handleTimeUpdate : undefined}
                className="not-found-section__video"
            >
                {isMobile ? (
                    <source src="/videos/not-found-mobile.mp4" type="video/mp4" />
                ) : (
                    <source src={videoData.src} type={videoData.type} />
                )}
            </video>

            <NotFoundSectionBackground className="not-found-section__background" />
        </div>
    );
};

export default NotFoundSection;
