import { Language } from '../../constants/locale';
import { transformPrismicDocumentToArticle, transformPrismicSearchResponseToArticleOverviewInfo } from '../../entities/Article/ArticleTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicOverviewApiCall } from '../../entities/Prismic/PrismicService';
import { setArticleData } from '../articleDetail/articleDetail';
import { TypedDispatch } from '../store';
import {
    setArticles,
    setIsLoading,
    setTotalPages,
    setTotalResults,
} from './articles';

interface ArticleOverviewConfig {
    language: Language;
    category?: string;
    page: number;
}

export const fetchArticleOverview = (config: ArticleOverviewConfig) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setTotalPages(0));
    dispatch(setTotalResults(0));
    dispatch(setArticles([]));

    // Reset existing articles, to prevent flash of old content during render
    dispatch(setArticleData(undefined));

    try {
        const articleOverviewResponse = await getPrismicOverviewApiCall({
            type: PrismicType.article,
            category: config.category,
            language: config.language,
            page: config.page,
            pageSize: 8,
        });

        if (!isFetchResultSuccessful(articleOverviewResponse)) {
            console.error('[fetchArticleOverview]', articleOverviewResponse.error);
            return;
        }

        const resultInfo = transformPrismicSearchResponseToArticleOverviewInfo(articleOverviewResponse.data);
        const articles = articleOverviewResponse.data.results.map(transformPrismicDocumentToArticle);

        dispatch(setTotalPages(resultInfo.totalPages));
        dispatch(setTotalResults(resultInfo.totalResults));

        dispatch(setArticles(articles));
    } catch (error) {
        console.error('[fetchArticleOverview]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
