/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconKosher: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={100} className={className}>
        <path d="M50,100C22.4,100,0,77.6,0,50C0,22.4,22.4,0,50,0c27.6,0,50,22.4,50,50C100,77.6,77.6,100,50,100z M50,4.4C24.8,4.4,4.4,24.8,4.4,50S24.8,95.6,50,95.6S95.6,75.2,95.6,50S75.2,4.4,50,4.4z" />
        <path d="M25,68.8h15V50.9l15.4,17.8H75L63.9,55.9L52.8,43l18.1-11.8H58.1l-9.1,5.9L40,43V31.2H25V68.8z" />
    </Svg>
);

export default IconKosher;
/* eslint-enable max-len */
