import { FC, ReactElement, useEffect } from 'react';

import { AppRoute } from '../../constants/routing';
import { PrismicPageContent } from '../../containers';
import { useLocale } from '../../context/LocaleContext';
import { fetchPrivacyStatementData } from '../../redux/privacyStatement/privacyStatementActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedPrivacyStatementProps {
    className?: string;
}

const ConnectedPrivacyStatement: FC<ConnectedPrivacyStatementProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const { language } = useLocale();

    const isLoading = useTypedSelector(state => state.privacyStatementReducer.isLoading);
    const privacyStatementData = useTypedSelector(state => state.privacyStatementReducer.privacyStatementData);

    useEffect((): void => {
        if (!privacyStatementData.title || language !== privacyStatementData.language) {
            dispatch(fetchPrivacyStatementData(language));
        }
    }, [language]);

    return (
        <PrismicPageContent
            {...privacyStatementData}
            isLoading={isLoading}
            route={AppRoute.privacyStatement}
            className={className}
        />
    );
};

export default ConnectedPrivacyStatement;
