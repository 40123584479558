import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ArticleOverviewSkeletons.scss';

interface ArticleOverviewSkeletonsProps {
    className?: string;
}

const ArticleOverviewSkeletons: FC<ArticleOverviewSkeletonsProps> = ({
    className = '',
}): ReactElement => {
    const skeletonItems = generateIdArray(4);

    return (
        <Wrapper className={`article-overview-skeletons ${className}`}>
            <div className="article-overview-skeletons__title-wrapper">
                <Skeleton className="article-overview-skeletons__title" />
            </div>

            <div className="article-overview-skeletons__list">
                {skeletonItems.map(item => (
                    <div key={item} className="article-overview-skeletons__item">
                        <div className="article-overview-skeletons__thumbnail" />

                        <div className="article-overview-skeletons__text-wrapper">
                            <Skeleton className="article-overview-skeletons__item-title" />

                            <Skeleton className="article-overview-skeletons__info" />

                            <div className="article-overview-skeletons__intro-wrapper">
                                <Skeleton className="article-overview-skeletons__intro-line" />
                                <Skeleton className="article-overview-skeletons__intro-line" />
                                <Skeleton className="article-overview-skeletons__intro-line" />
                            </div>

                            <div className="article-overview-skeletons__button" />
                        </div>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
};

export default ArticleOverviewSkeletons;
