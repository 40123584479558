import {
    CSSProperties,
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { Button, Icon } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { Usp, uspIcons } from '../../../../constants/usp';
import { randomInBetweenValue } from '../../../../helpers/number';
import usePrefersReducedMotion from '../../../../hooks/usePrefersReducedMotion';
import useTouchScreen from '../../../../hooks/useTouchScreen';
import useTrans from '../../../../hooks/useTrans';

import './UspCard.scss';

interface UspCardProps {
    usp: Usp;
    selectedUsp?: Usp;
    onSelectUsp: (usp?: Usp) => void;
    className?: string;
}

const UspCard: FC<UspCardProps> = ({
    usp,
    selectedUsp,
    onSelectUsp,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const isTouchScreen = useTouchScreen();
    const prefersReducedMotion = usePrefersReducedMotion();

    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const [isDeselecting, setIsDeselecting] = useState<boolean>(false);

    const randomAnimationDuration = useMemo((): number => randomInBetweenValue(5, 6), [usp]);
    const randomRotation = useMemo((): number => randomInBetweenValue(-2, 2), [usp]);

    const handleSelectUsp = (): void => {
        if (!isTouchScreen && isTransitioning) return;

        onSelectUsp(usp);
        setIsTransitioning(true);
    };

    const handleDeselectUsp = (): void => {
        onSelectUsp(undefined);
        setIsDeselecting(true);
    };

    const handleTransitionEnd = (): void => {
        setIsTransitioning(false);
        setIsDeselecting(false);
    };

    const uspCardClassNames = classNames('usp-card', {
        'usp-card--prefers-reduced-motion': prefersReducedMotion,
        'usp-card--is-deselecting': isDeselecting,
        'usp-card--is-selected': selectedUsp && selectedUsp === usp,
        'usp-card--is-not-selected': selectedUsp && selectedUsp !== usp,
    }, className);

    const cssVariables = {
        '--usp-card-animation-duration': `${randomAnimationDuration}s`,
        '--usp-card-rotation': `${randomRotation || -1}deg`,
    } as CSSProperties;

    return (
        <li
            style={cssVariables}
            onMouseEnter={handleSelectUsp}
            onFocus={handleSelectUsp}
            onMouseLeave={handleDeselectUsp}
            onBlur={handleDeselectUsp}
            className={uspCardClassNames}
        >
            <div
                onTransitionEnd={handleTransitionEnd}
                className="usp-card__flip-wrapper"
            >
                <div className="usp-card__front-wrapper">
                    <div className="usp-card__inner-wrapper">
                        <Icon name={uspIcons[usp]} className="usp-card__icon" />

                        <Button text={trans(`usps.${usp}.title`)} className="usp-card__title-button">
                            <h2 className="usp-card__title">
                                {trans(`usps.${usp}.title`)}
                            </h2>
                        </Button>

                        <IconButton
                            icon="plus"
                            text={trans('common.moreInfo')}
                            hideLabel
                            onClick={handleSelectUsp}
                            className="usp-card__flip-button"
                        />
                    </div>
                </div>

                <div className="usp-card__back-wrapper">
                    <div className="usp-card__inner-wrapper">
                        <h2 className="usp-card__title usp-card__title--is-small">
                            {trans(`usps.${usp}.title`)}
                        </h2>

                        <p className="usp-card__description">
                            {trans(`usps.${usp}.description`)}
                        </p>

                        <IconButton
                            icon="cross"
                            hideLabel
                            text={trans('common.close')}
                            onClick={handleDeselectUsp}
                            className="usp-card__close-button"
                            iconClassName="usp-card__close-icon"
                        />
                    </div>
                </div>
            </div>
        </li>
    );
};

export default UspCard;
