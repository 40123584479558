import { Continent } from '../../../constants/countries';
import { Flavour, flavourSets } from '../../../constants/flavours';
import { Language } from '../../../constants/locale';
import { personalityTestScorecard } from '../../../constants/personalityTest';
import { PersonalityTestInput, PersonalityTestQuestion } from '../../../entities/PersonalityTest/PersonalityTest';
import { gtmEvent } from '../../../helpers/analytics';
import { range, shuffleArray } from '../../../helpers/array';
import { getTranslation } from '../../../helpers/trans';

export const generatePersonalityTestQuestions = (lang: Language, amountOfQuestions: number): PersonalityTestQuestion[] => {
    const questionRange = range(0, amountOfQuestions);

    return questionRange.map(index => {
        const shuffledAnswers = shuffleArray<Record<string, string>>([
            { a: getTranslation(lang, `containers.personalityTest.questions.${index}.answers.a`) || '' },
            { b: getTranslation(lang, `containers.personalityTest.questions.${index}.answers.b`) || '' },
            { c: getTranslation(lang, `containers.personalityTest.questions.${index}.answers.c`) || '' },
            { d: getTranslation(lang, `containers.personalityTest.questions.${index}.answers.d`) || '' },
            { e: getTranslation(lang, `containers.personalityTest.questions.${index}.answers.e`) || '' },
        ]);

        return {
            index,
            question: getTranslation(lang, `containers.personalityTest.questions.${index}.question`),
            answers: shuffledAnswers,
        };
    });
};

export const generateAnswerList = (inputList: PersonalityTestInput[], input: PersonalityTestInput): PersonalityTestInput[] => {
    const filteredAnswers = inputList.filter(answer => answer.index !== input.index);
    const newAnswers = [...filteredAnswers, input];

    return newAnswers.sort((answer, comparisonAnswer) => answer.index - comparisonAnswer.index);
};

export const trackTestInput = (input: PersonalityTestInput): void => {
    const question = getTranslation(Language.enGb, `containers.personalityTest.questions.${input.index}.question`);
    const answer = getTranslation(Language.enGb, `containers.personalityTest.questions.${input.index}.answers.${input.answer}`);

    gtmEvent({
        event: `quiz_${question}`,
        answer,
    });
};

export const getPersonalityTestResult = (continent: Continent, inputList: PersonalityTestInput[]): Flavour => {
    // Add multipliers per flavour, based on the scorecard
    const flavourPoints = inputList.map(input => {
        const { answer } = input;

        const { high, medium, low } = personalityTestScorecard[answer];
        const highPoints = [...high, ...high, ...high];
        const mediumPoints = [...medium, ...medium];

        return [...highPoints, ...mediumPoints, ...low];
    }).flat();

    // Remove all flavours that are unavailable in the region of user
    const continentFlavours = flavourSets[continent];
    const continentFlavourPoints = flavourPoints.filter(flavour => continentFlavours.indexOf(flavour) >= 0);

    // Retrieve points for all region flavours with a score
    const points: Partial<Record<Flavour, number>> = {};

    for (let i = 0; i < continentFlavourPoints.length; i += 1) {
        const flavour = continentFlavourPoints[i];
        const n = points[flavour];

        points[flavour] = n ? n + 1 : 1;
    }

    // Retrieve flavour with the most points
    const sortedPointEntries = Object.entries(points).sort(([, flavourValue], [, comparisonFlavourValue]) => comparisonFlavourValue - flavourValue);
    const [result] = sortedPointEntries[0];

    return result as Flavour;
};
