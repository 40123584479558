/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconVacancyService: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={72} viewBoxHeight={100} className={className}>
        <path d="M59.2,69.2c-0.2,0-0.4,0-0.6,0c-1.2-0.1-2.2-0.5-3.8-1.3c-1.6-0.7-3.7-1.7-4.5-4.2C50,62.8,50,62,50,60.7V46c0-1.3,0-2.1,0.3-3c0.8-2.5,2.9-3.4,4.4-4.1c1.7-0.8,2.7-1.2,3.9-1.3c1.3-0.1,2.7,0.2,3.8,0.8c1.2,0.7,2,1.7,2.6,2.5c0.1,0.2,0.2,0.3,0.4,0.5c3.4,4.1,5.1,6.1,5.8,8.8c0.6,2.1,0.6,4.4,0,6.5c-0.8,3-2.9,5.5-4.8,7.6c-0.4,0.5-0.8,0.9-1.1,1.3c-1.1,1.4-1.8,2.2-2.8,2.8C61.4,68.9,60.3,69.2,59.2,69.2z M59.2,42.5c0,0-0.1,0-0.1,0c-0.3,0-1.1,0.4-2.2,0.9c-1.2,0.5-1.7,0.8-1.8,1.2c0,0.2,0,0.8,0,1.4v14.6c0,0.6,0,1.3,0,1.4c0.1,0.4,0.6,0.7,1.7,1.2c1.2,0.5,1.9,0.9,2.2,0.9c0.3,0,0.7,0,1-0.2c0.2-0.1,0.8-0.8,1.4-1.6c0.4-0.5,0.8-0.9,1.2-1.4c1.6-1.8,3.2-3.7,3.8-5.7c0.3-1.3,0.3-2.6,0-3.9c-0.4-1.6-1.9-3.4-4.8-6.9c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-0.5-0.8-1-1.1-1.2C59.8,42.6,59.5,42.5,59.2,42.5z" />
        <path d="M12.5,69.2c-3.1,0-4.9-2.2-6.1-3.6c-0.4-0.4-0.7-0.9-1.1-1.3c-1.9-2.2-4-4.6-4.8-7.6c-0.6-2.1-0.6-4.4,0-6.5c0.7-2.6,2.4-4.7,5.8-8.8l0.1-0.1c1.4-1.7,3.3-4,6.8-3.7c1.2,0.1,2.2,0.5,3.8,1.3c1.3,0.6,2,0.9,2.7,1.5c2.1,1.8,2.1,4.1,2.1,5.7v14.6c0,1.5,0,3.9-2.1,5.7c-0.7,0.6-1.5,1-2.7,1.5c-1.7,0.8-2.7,1.2-3.8,1.3C12.8,69.2,12.7,69.2,12.5,69.2z M12.5,42.5c-0.7,0-1.2,0.5-2.3,1.9l-0.1,0.1c-2.9,3.5-4.4,5.3-4.8,6.9c-0.3,1.3-0.3,2.6,0,3.9c0.5,1.9,2.2,3.8,3.8,5.7c0.4,0.5,0.8,1,1.2,1.4c1.3,1.5,1.7,1.9,2.4,1.8c0.3,0,1.1-0.4,2.1-0.9c0.8-0.3,1.4-0.6,1.5-0.8c0.3-0.2,0.4-0.5,0.4-1.9V46c0-1.4-0.1-1.7-0.4-1.9c-0.2-0.1-0.8-0.4-1.5-0.8c-1.1-0.5-1.8-0.8-2.2-0.9C12.6,42.5,12.5,42.5,12.5,42.5z" />
        <path d="M61.2,42h-4v-2c0-9.9-9.6-18-21.3-18c-11.8,0-21.3,8.1-21.3,18v2h-4v-2c0-12.1,11.4-22,25.3-22s25.3,9.9,25.3,22V42z" />
        <path d="M45.8,82h-6.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.7c6.2,0,11.3-3.9,11.3-8.7v-2.7c0-1.1,0.9-2,2-2s2,0.9,2,2v2.7C61.2,76.3,54.3,82,45.8,82z" />
    </Svg>
);

export default IconVacancyService;
/* eslint-enable max-len */
