import { FC, ReactElement } from 'react';

import { Image } from '../../../entities/@slices/Image/Image';

import './ImageGridSlice.scss';

interface ImageGridSliceProps {
    images: Image[];
    className?: string;
}

const ImageGridSlice: FC<ImageGridSliceProps> = ({
    images,
    className = '',
}): ReactElement => {
    const [firstImage, ...restImages] = images.slice(0, 3);

    return (
        <div className={`image-grid-slice ${className}`}>
            <div className="image-grid-slice__image-wrapper image-grid-slice__image-wrapper--inline-start">
                <img
                    src={firstImage.src}
                    alt={firstImage.alt}
                    className="image-grid-slice__image"
                />
            </div>

            <div className="image-grid-slice__image-wrapper image-grid-slice__image-wrapper--inline-end">
                {restImages.map(image => (
                    <div key={image.src} className="image-grid-slice__image-wrapper">
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="image-grid-slice__image"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageGridSlice;
