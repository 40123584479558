import {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
    ReactElement,
    Ref,
    RefAttributes,
    TableHTMLAttributes,
} from 'react';

import './Table.scss';

interface TableProps extends TableHTMLAttributes<HTMLTableElement>, RefAttributes<HTMLTableElement> {
    className?: string;
}

const Table: ForwardRefExoticComponent<PropsWithChildren<TableProps>> = forwardRef(({
    className = '',
    children,
    ...tableProps
}, ref: Ref<HTMLTableElement>): ReactElement => (
    <table
        {...tableProps}
        ref={ref}
        className={`table ${className}`}
    >
        {children}
    </table>
));

export default Table;
