import { Continent } from '../../constants/countries';
import { EUNutritionTableRow, USNutritionTableRow } from './NutritionTableRow';

export const transformToUSNutritionTableRow = (resource: string[]): USNutritionTableRow => {
    const [
        resourceIngredient,
        resourceAmount,
        resourceUnitOfMeasure,
        resourceDailyValue,
    ] = resource;

    const isChild = resourceIngredient[0] === '>';
    const ingredient = isChild ? resourceIngredient.substring(1) : resourceIngredient;
    const dailyValue = !Number.isNaN(Number(resourceDailyValue)) ? Number(resourceDailyValue) : undefined;

    return {
        isChild,
        amount: resourceAmount,
        dailyValue,
        ingredient,
        unitOfMeasure: resourceUnitOfMeasure,
        type: Continent.northAmerica,
    };
};

export const transformToEUNutritionTableRow = (resource: string[]): EUNutritionTableRow => {
    const [
        resourceIngredient,
        resourceAmount100ml,
        resourceAmount500ml,
        resourceUnitOfMeasure,
    ] = resource;

    const isHeader = resourceIngredient.substring(0, 1) === '-';
    const ingredient = isHeader ? resourceIngredient.substring(1) : resourceIngredient;

    return {
        isHeader,
        ingredient,
        amount100ml: resourceAmount100ml,
        amount500ml: resourceAmount500ml,
        unitOfMeasure: resourceUnitOfMeasure,
        type: Continent.europe,
    };
};
