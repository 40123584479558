import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../components';
import useTrans from '../../hooks/useTrans';

import './SocialNavigation.scss';

interface SocialNavigationProps {
    className?: string;
    listClassName?: string;
}

const SocialNavigation: FC<SocialNavigationProps> = ({
    className = '',
    listClassName = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <nav
            aria-label={trans('compositions.socialNavigation.ariaLabel')}
            className={`social-navigation ${className}`}
        >
            <ul className={`social-navigation__list ${listClassName}`}>
                <li className="social-navigation__item">
                    <Link
                        to={trans('company.instagram')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={trans('compositions.socialNavigation.instagram')}
                        className="social-navigation__link"
                    >
                        <div className="social-navigation__icon-wrapper">
                            <Icon name="instagram" className="social-navigation__icon" />
                        </div>
                    </Link>
                </li>

                <li className="social-navigation__item">
                    <Link
                        to={trans('company.facebook')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={trans('compositions.socialNavigation.facebook')}
                        className="social-navigation__link"
                    >
                        <div className="social-navigation__icon-wrapper">
                            <Icon name="facebook" className="social-navigation__icon" />
                        </div>
                    </Link>
                </li>

                <li className="social-navigation__item">
                    <Link
                        to={trans('company.linkedIn')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={trans('compositions.socialNavigation.linkedIn')}
                        className="social-navigation__link"
                    >
                        <div className="social-navigation__icon-wrapper">
                            <Icon name="linkedin" className="social-navigation__icon" />
                        </div>
                    </Link>
                </li>

                <li className="social-navigation__item">
                    <Link
                        to={trans('company.tikTok')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={trans('compositions.socialNavigation.tikTok')}
                        className="social-navigation__link"
                    >
                        <div className="social-navigation__icon-wrapper">
                            <Icon name="tiktok" className="social-navigation__icon" />
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default SocialNavigation;
