/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Svg } from '../..';
import { SvgIconProps } from '../Icon';

const IconHamburger: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <Svg viewBoxWidth={100} viewBoxHeight={78.1} className={className}>
        <path d="M24.7,0h70.6c2.6,0,4.7,2.1,4.7,4.7l0,0c0,2.6-2.1,4.7-4.7,4.7H24.7c-2.6,0-4.7-2.1-4.7-4.7l0,0C20,2.1,22.1,0,24.7,0z" />
        <path d="M4.7,34.4h90.6c2.6,0,4.7,2.1,4.7,4.7l0,0c0,2.6-2.1,4.7-4.7,4.7H4.7c-2.6,0-4.7-2.1-4.7-4.7l0,0C0,36.5,2.1,34.4,4.7,34.4z" />
        <path d="M34.7,68.8h60.6c2.6,0,4.7,2.1,4.7,4.7l0,0c0,2.6-2.1,4.7-4.7,4.7H34.7c-2.6,0-4.7-2.1-4.7-4.7l0,0C30,70.8,32.1,68.8,34.7,68.8z" />
    </Svg>
);

export default IconHamburger;
/* eslint-enable max-len */
