import { ChangeEvent, FC, ReactElement } from 'react';

import classNames from 'classnames';

import { ErrorLabel, InputLabel } from '../../../components';
import { FormOption } from '../../../types';

import './Switch.scss';

interface SwitchProps {
    label: string;
    hideLabel?: boolean;
    name: string;
    value: string;
    options: [FormOption, FormOption];
    disabled?: boolean;
    tabIndex?: 0 | -1;
    error?: string;
    onChange: (value: string) => void;
    className?: string;
    labelClassName?: string;
}

const Switch: FC<SwitchProps> = ({
    label,
    hideLabel,
    name,
    value,
    options,
    disabled,
    tabIndex,
    error,
    onChange,
    className = '',
    labelClassName = '',
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    const optionWrapperClassNames = classNames('switch__option-wrapper', {
        'switch__option-wrapper--has-error': !!error,
    });

    const activeIndicatorClassNames = classNames('switch__active-indicator', {
        'switch__active-indicator--selected-second': value === options[1].value,
    });

    return (
        <div className={`switch ${className}`}>
            {!hideLabel && (
                <InputLabel
                    label={label}
                    className={`switch__label ${labelClassName}`}
                />
            )}

            <div className={optionWrapperClassNames}>
                {options.map(option => {
                    const optionLabelClassNames = classNames('switch__option-label', {
                        'switch__option-label--is-selected': option.value === value,
                    });

                    return (
                        <label key={option.value} className="switch__label-wrapper">
                            <input
                                type="radio"
                                name={name}
                                checked={option.value === value}
                                value={option.value}
                                disabled={disabled}
                                tabIndex={tabIndex}
                                onChange={handleChange}
                                className="switch__input"
                            />

                            <span className={optionLabelClassNames}>
                                {option.label}
                            </span>
                        </label>
                    );
                })}

                <div className={activeIndicatorClassNames} />
            </div>

            {error && (
                <ErrorLabel
                    text={error}
                    className="switch__error-label"
                />
            )}
        </div>
    );
};

export default Switch;
