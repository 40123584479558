import { Language } from '../../constants/locale';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { defaultPrismicDefaultPageData, PrismicType } from '../../entities/Prismic/Prismic';
import { getPrismicPageApiCall } from '../../entities/Prismic/PrismicService';
import { transformPrismicDocumentToPrismicDefaultPageData } from '../../entities/Prismic/PrismicTransformers';
import { TypedDispatch } from '../store';
import { setIsLoading, setWorkAtData } from './workAt';

export const fetchWorkAtData = (language: Language) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setWorkAtData(defaultPrismicDefaultPageData));

    try {
        const workAtResponse = await getPrismicPageApiCall({
            type: PrismicType.workAt,
            language,
        });

        if (!isFetchResultSuccessful(workAtResponse)) {
            console.error('[fetchWorkAtData]', workAtResponse.error);
            return;
        }

        const workAtData = transformPrismicDocumentToPrismicDefaultPageData(workAtResponse.data, language);

        dispatch(setWorkAtData(workAtData));
    } catch (error) {
        console.error('[fetchWorkAtData]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
