import { useEffect, useState } from 'react';

import { FontName } from '../types';

export const useFontLoaded = (fontNames: FontName[]) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        Promise.all(fontNames.map(
            (fontName) => document.fonts.load(`16px "${fontName}"`),
        )).then(() => {
            setIsLoaded(true);
        });
    }, [fontNames]);

    return isLoaded;
};
